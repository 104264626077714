<template>
    <div class="Contact_page">
  <profileApp></profileApp>
</div>
</template>

<script>
import profileApp from '@/components/profile/profileApp.vue';
export default {
    name:'Profile',
    data:function(){
        return{}
    },
    components:{
        profileApp
    },
      mounted(){
         document.body.classList.add('ProfilePage');
    },
    destroyed(){
      document.body.classList.remove('ProfilePage');
    }
}
</script>