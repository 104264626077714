<template>
  <div class="login_wraps">
    <div class="row">
      <div class="col-lg-4 col-md-5 img_nones">
        <div class="img_bg">
          <img src="~@/assets/login.jpg" alt="" />
        </div>
      </div>
      <div class="col-lg-8 col-md-7">
        <div class="form_wraps">
          <form autocomplete="off">
            <div class="title_form">
              <h1>Get more things done with Loggin platform.</h1>
              <p>
                Access to the most powerful platform in medical devices and the pharmaceutical industry.
              </p>
            </div>
            <div class="flex_login_reg">
               <router-link to="/login">Login</router-link>
              <router-link to="/register" class="active">Register</router-link>
            </div>
            <div class="form_input">
              <input
                class="form-control"
                type="text"
                placeholder="Your Name"
                 autocomplete="off"
                required=""
              />
              <input
                class="form-control"
                type="text"
                name="username"
                placeholder="E-mail Address"
                 autocomplete="off"
                required=""
              />
               <div class="fileds phone_input">
                 <vue-tel-input  v-model="mobile" @validate="writeMobile($event)" 
                  :dropdownOptions="opt" autocomplete="off"></vue-tel-input>
                   <template v-if="valid_mobile">
                      <span class="successMSG">correct</span>
        </template>
        <template v-if="!valid_mobile && mobile !== ''">
          <span class="errorMSG">incorrect</span>
        </template>
        </div>
        <input
                class="form-control"
                type="text"
                placeholder="Phone Code..."
                autocomplete="off"
                required=""
              />
              <input
                class="form-control"
                type="password"
                name="password"
                placeholder="Password"
                autocomplete="off"
                required=""
              />
              <input
                class="form-control"
                type="password"
                name="password"
                placeholder="confirm Password"
                autocomplete="off"
                required=""
              />
              <div class="form-button">
                <vs-button id="submit" type="submit" class="ibtn"
                  >Login</vs-button
                >
                <a href="forget3.html">Forget password?</a>
              </div>
            </div>
            <div class="other-links">
              <span>Or login with</span
              ><a href="#"><i class="fab fa-facebook-f"></i></a
              ><a href="#"><i class="fab fa-google"></i></a
              ><a href="#"><i class="fab fa-linkedin-in"></i></a>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "RegisterArea",
  data(){
    return{
       valid_mobile:false,
         mobile:'',
          opt:{
                disabled:false,
            showDialCodeInList:true,
            showDialCodeInSelection:false,
            showFlags:true,
            showSearchBox:false,
            tabindex:0,
            width:"390px"
          },
    }
  },
  methods:{
     writeMobile(event){
          this.valid_mobile  = event.valid;
        },
  }
};
</script>
<style lang="scss" scoped>
.login_wraps {
  min-height: 100vh;
  overflow-x: hidden;
  .row{
    min-height: 100vh;
  }
  .img_bg {
    min-height: 100vh;
    img {
      height: 100vh;
      object-fit: cover;
    }
  }
  .form_wraps {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
    padding: 60px;
    form {
      max-width: 400px;
      h1 {
        font-size: 24px;
        font-weight: 900;
        margin-bottom: 10px;
      }
      p {
        font-size: 18px;
        font-weight: 300;
        line-height: 20px;
        margin-bottom: 20px;
      }
      .flex_login_reg {
        a {
          position: relative;
          display: inline-block;
          text-decoration: none;
          color: #000;
          font-weight: 300;
          font-size: 15px;
          margin-right: 20px;
          -webkit-transition: all 0.3s ease;
          transition: all 0.3s ease;
          cursor: pointer;
          &:hover {
            &::after {
              background-color: #0092fe;
            }
          }
          &::after {
            position: absolute;
            content: "";
            width: 100%;
            height: 2px;
            left: 0;
            bottom: -10px;
            background-color: rgba(222, 222, 222, 0.7);
            -webkit-transition: all 0.3s ease;
            transition: all 0.3s ease;
          }
        }
        a.active {
          font-weight: 700;
          &::after {
            background-color: #0092fe;
          }
        }
      }
      .form_input {
        margin-top: 30px;
        input {
          border: 0;
          background-color: #f7f7f7;
          color: #000;
          width: 100%;
          padding: 9px 20px;
          text-align: left;
          border: 0;
          outline: 0;
          border-radius: 6px;
          background-color: #fff;
          font-size: 15px;
          font-weight: 300;
          color: #8d8d8d;
          -webkit-transition: all 0.3s ease;
          transition: all 0.3s ease;
          margin-bottom: 14px;
        }
      }
      .other-links span {
        font-size: 12px;
        font-weight: 300;
        color: #000;
        margin-right: 20px;
      }
      .other-links a {
        font-size: 12px;
        font-weight: 700;
        color: #fff;
        margin-right: 10px;
      }
      .other-links a i {
        display: inline-block;
        width: 25px;
        height: 25px;
        background-color: #000;
        color: #fff;
        border-radius: 25px;
        text-align: center;
        padding-top: 5px;
        font-size: 15px;
        margin: 0 5px;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
      }
      .other-links a i[class*="fa-facebook"] {
        background-color: #3b5998;
      }
      .other-links a i[class*="fa-google"] {
        background-color: #dd4b39;
      }
      .other-links a i[class*="fa-linkedin"] {
        background-color: #007bb6;
      }
      .form-button{
            display: flex;
    align-items: center;
    margin-bottom: 20px;
    a{
      font-size: 13px;
    font-weight: 700;
    color: #000;
    margin-left: 10px;
    }
      }
    }
  }
}
.phone_input{
position: relative;
margin-bottom: 18px;
}
    .successMSG , .errorMSG{
position: absolute;
    left: 60px;
    right: 0;
    color: white;
    font-size: 12px;
    /* padding-top: 10px; */
    bottom: -18px;
    }
    .successMSG{
          color: rgb(23, 201, 100);
    }
    .errorMSG{
        color: rgb(242, 19, 93);
    }

@media(max-width:767px){
  .img_nones{
    display: none;
  }
}
</style>
