<template>
 <div class="RfqArea_section">
   <!-- section steps -->
   <div class="step_color_wraps">
    <div class="row">
       <div class="col-xl-4 border-right">
        <div class="row">
          <div class="col-12">
            <div class="box_fade">
            <h4>Biochemistry analyser,heamatology analyser</h4>
            <div class="row">
              <div class="col-lg-6">
               <div class="flex_values">
                 <div class="text_data_val">country/Region:</div>
                <div class="text_data_val2"><img src="~@/assets/flag1.png" alt=""> Usa</div>
               </div>
              </div>
              <div class="col-lg-6">
                  <div class="flex_values">
                 <div class="text_data_val">Purchase Quantity:</div>
                <div class="text_data_val2">Negotiable</div>
               </div>
              </div>
              <div class="col-lg-6">
                <div class="flex_values">
                 <div class="text_data_val">Post time:</div>
                <div class="text_data_val2">2022-07-22</div>
               </div>
              </div>
              <div class="col-lg-6">
                 <div class="flex_values">
                 <div class="text_data_val">Valid to:</div>
                <div class="text_data_val2">2022-07-25</div>
               </div>
              </div>
            </div>
          
        </div>
          </div>
             <div class="col-12">
            <div class="box_fade">
            <h4>Biochemistry analyser,heamatology analyser</h4>
            <div class="row">
              <div class="col-lg-6">
               <div class="flex_values">
                 <div class="text_data_val">country/Region:</div>
                <div class="text_data_val2"><img src="~@/assets/flag1.png" alt=""> Usa</div>
               </div>
              </div>
              <div class="col-lg-6">
                  <div class="flex_values">
                 <div class="text_data_val">Purchase Quantity:</div>
                <div class="text_data_val2">Negotiable</div>
               </div>
              </div>
              <div class="col-lg-6">
                <div class="flex_values">
                 <div class="text_data_val">Post time:</div>
                <div class="text_data_val2">2022-07-22</div>
               </div>
              </div>
              <div class="col-lg-6">
                 <div class="flex_values">
                 <div class="text_data_val">Valid to:</div>
                <div class="text_data_val2">2022-07-25</div>
               </div>
              </div>
            </div>
          
        </div>
          </div>
          <div class="col-12">
            <div class="box_fade">
            <h4>Biochemistry analyser,heamatology analyser</h4>
            <div class="row">
              <div class="col-lg-6">
               <div class="flex_values">
                 <div class="text_data_val">country/Region:</div>
                <div class="text_data_val2"><img src="~@/assets/flag1.png" alt=""> Usa</div>
               </div>
              </div>
              <div class="col-lg-6">
                  <div class="flex_values">
                 <div class="text_data_val">Purchase Quantity:</div>
                <div class="text_data_val2">Negotiable</div>
               </div>
              </div>
              <div class="col-lg-6">
                <div class="flex_values">
                 <div class="text_data_val">Post time:</div>
                <div class="text_data_val2">2022-07-22</div>
               </div>
              </div>
              <div class="col-lg-6">
                 <div class="flex_values">
                 <div class="text_data_val">Valid to:</div>
                <div class="text_data_val2">2022-07-25</div>
               </div>
              </div>
            </div>
          
        </div>
          </div>
           <div class="col-12">
            <div class="box_fade">
            <h4>Biochemistry analyser,heamatology analyser</h4>
            <div class="row">
              <div class="col-lg-6">
               <div class="flex_values">
                 <div class="text_data_val">country/Region:</div>
                <div class="text_data_val2"><img src="~@/assets/flag1.png" alt=""> Usa</div>
               </div>
              </div>
              <div class="col-lg-6">
                  <div class="flex_values">
                 <div class="text_data_val">Purchase Quantity:</div>
                <div class="text_data_val2">Negotiable</div>
               </div>
              </div>
              <div class="col-lg-6">
                <div class="flex_values">
                 <div class="text_data_val">Post time:</div>
                <div class="text_data_val2">2022-07-22</div>
               </div>
              </div>
              <div class="col-lg-6">
                 <div class="flex_values">
                 <div class="text_data_val">Valid to:</div>
                <div class="text_data_val2">2022-07-25</div>
               </div>
              </div>
            </div>
          
        </div>
          </div>
        </div>
       </div>
      <div class="col-xl-8">
        <h2>Post 1 sourcing request, get dozens of quotations.</h2>
        <div class="step_color_text">
           <div><span class="num_color">1</span><span class="val_color">Release RFQ <ion-icon name="chevron-forward-outline"></ion-icon></span></div>
           <div><span class="num_color">2</span><span class="val_color">Compare Quotations <ion-icon name="chevron-forward-outline"></ion-icon></span></div>
           <div><span class="num_color">3</span><span class="val_color">Inquiry Suppliers <ion-icon name="chevron-forward-outline"></ion-icon></span></div>
        </div>
       <div class="steps_color_wraps">
         <div class="step_color"></div>
        <div class="step_color"></div>
        <div class="step_color"></div>
       </div>
       <h3 class="title_gray">Get your target products with ONE SIMPLE CLICK!</h3>
       <div class="form_color">
        <h3>One Request, Multiple Quotes.</h3>
        <div class="row">
          <div class="col-lg-6">
            <div class="row">
              <div class="col-12 parent_col">
                <vs-input  placeholder="Keywords of products you are looking for..." />
              </div>
              <div class="col-md-6 parent_col mbb-0">
                <vs-input  placeholder="Quantity" />
              </div>
              <div class="col-md-6 parent_col mbb-0">
                   <vs-select placeholder="Select" v-model="value_qty">
                    <vs-option label="Vial(s)" value="1">
                      Vial(s)
                    </vs-option>
                    <vs-option label="Ampoule(s)" value="2">
                      Ampoule(s)
                    </vs-option>
                    <vs-option label="g(s)" value="3">
                      g(s)
                    </vs-option>
                    <vs-option  label="Kg(s)" value="4">
                      Kg(s)
                    </vs-option>
                    <vs-option label="Piece(s)" value="5">
                      Piece(s)
                    </vs-option>
                    <vs-option label="Bag(s)" value="6">
                      Bag(s)
                    </vs-option>
                    <vs-option label="Box(es)" value="7">
                      Box(es)
                    </vs-option>
                  </vs-select>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <textarea name="" id="" class="uk-textarea textarea_form"></textarea>
          </div>
          <div class="button_wraps">
             <vs-button warn gradient>Request For Quotation</vs-button>
          </div>
        </div>
       </div>
      </div>
     
    </div>
   </div>
   <!-- end step -->
    </div>
</template>
<script>
export default {
  name: "RfqArea",
    data(){
    return{
       value_qty:1
    }
  },
}
</script>
<style lang="scss" scoped>
// color steps
.step_color_wraps{
  background-color: #fff;
  padding: 40px 0px;
  h2{
    font-size: 1.8rem;
    color: black;
    font-weight: 500;
    margin-bottom: 14px;
  }
  .steps_color_wraps{
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    .step_color{
    height: 6px;
    flex: 0 0 calc(100% / 3);
    &:nth-child(1){
      background-color: #f74843;
    }
     &:nth-child(2){
      background-color: #faaf4a;
    }
    &:nth-child(3){
      background-color: #59b634;
    }
    }
  }
    .step_color_text{
    display: flex;
    justify-content: space-around;
    font-size: 15px;
    font-weight: 500;
    margin-top: 30px;
    .num_color{
    width: 23px;
    height: 23px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    margin-right: 8px;
    margin-bottom: 10px;
    font-weight: 500;
    }
    .val_color{
      ion-icon{
            vertical-align: middle;
            margin-top: -3px;
      }
    }
     :nth-child(1) .num_color{
       border: 3px solid #f74843;
    }
     :nth-child(2) .num_color{
       border: 3px solid #faaf4a;
    }
     :nth-child(3) .num_color{
       border: 3px solid #59b634;
    }
    }
    h3{
      font-size: 1.6rem;
      font-weight: 500;
      margin-bottom: 30px;
      color: #546278;
    }
    h3.title_gray{
      color: #000;
      opacity: 0.5;
    }
    .form_color{
      width: 100%;
    margin-top: 12px;
    border: solid 1px #d9e3ed;
    padding: 28px 4%;
    background: #f5f8fa;
    position: relative;
    .vs-input {
    width: 100%;
    background-color: white;
    border-radius: 0px;
     height: 38px;
}
.vs-select__input {
    width: 100% !important;
    background-color: white;
    border-radius: 0px;  
}
.parent_col{
  margin-bottom: 20px;
}
.mbb-0{
  margin-bottom: 0px;
}
.textarea_form{
  height: 100%;
  width: 100%;
   border: solid 1px #e4e4e4;
}
.button_wraps{
 text-align: right;
 margin-top: 20px; 
  width: 220px;
  margin-left: auto;
  button{
    font-weight: 500;
  }
}
    }
    // 
    .box_fade{
      width: 96%;
    margin: 0 auto;
    padding: 10px 10px;
    padding-bottom: 15px;
    background: #f5f8fa;
    margin-bottom: 20px;
     h4{
       width: 100%;
    height: 32px;
    line-height: 30px;
    color: #546278;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 4px;
     }
    }
    .flex_values{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
    .text_data_val{
          margin-right: 14px;
    }
    .text_data_val , .text_data_val2{
    color: #999;
    font-size: 11px;
    font-weight: 500;
    }
    .text_data_val2{
      color: #999;
      img{
    height: 18px;
    vertical-align: bottom;
    margin-right: 5px;
      }
    }
    }
    .border-right{
      position: relative;
    &::after{
     content: "";
    position: absolute;
    width: 2px;
    height: 100%;
    background-color: #dcdcdc7d;
    right: 6px;
    top: 0%;
    }
    }
}
// 
</style>