<template>
  <div class="container">
    <div class="home_page">
    <heroArea></heroArea>
    <whichcategoryArea></whichcategoryArea>
    <productsArea></productsArea>
    <bannerArea></bannerArea>
    <rfqArea></rfqArea>
    <categorysectionArea></categorysectionArea>
    <bannerArea></bannerArea>
    <processArea></processArea>
    <bannerArea></bannerArea>
    <testmonialArea></testmonialArea>
    <supplierArea></supplierArea>
    <aboutArea></aboutArea>
  </div>
  </div>
</template>

<script>
import heroArea from "@/components/home/heroArea.vue";
import bannerArea from "@/components/home/bannerArea.vue";
import categorysectionArea from "@/components/home/categorysectionArea.vue";
import aboutArea from "@/components/home/aboutArea.vue";
import processArea from "@/components/home/processArea.vue";
import productsArea from "@/components/home/productsArea.vue";
import rfqArea from "@/components/home/rfqArea.vue";
import supplierArea from "@/components/home/supplierArea.vue";
import testmonialArea from "@/components/home/testmonialArea.vue";
import whichcategoryArea from "@/components/home/whichcategoryArea.vue";

export default {
  name: "Home",
  data() {
    return {};
  },
  components: {
    heroArea,
    aboutArea,
    bannerArea,
    processArea,
    productsArea,
    rfqArea,
    supplierArea,
    testmonialArea,
    whichcategoryArea,
    categorysectionArea,
  },
  methods: {},
  mounted() {
    document.body.classList.add("HomePage");
  },
  destroyed() {
    document.body.classList.remove("HomePage");
  },
};
</script>
