<template>
  <div class="dashoard_user_info">
    <div class="row">
      <div class="col-lg-3 borders">
        <div class="img_upload">
          <div class="img_up">
            <vs-avatar size="135" badge badge-color="success" dark>
              <img src="https://vuesax.com/avatars/avatar-9.png" alt="" />
              <!-- <i class='bx bxs-user'></i> -->
            </vs-avatar>
            <span
              class="upload_text"
              @click="$refs.fileInput.click()"
              upload_text
              >Upload Image</span
            >
            <input
              type="file"
              style="display: none"
              ref="fileInput"
              placeholder="Upload Image"
            />
          </div>
        </div>
      </div>
      <div class="col-lg-6 borders">
        <div class="user_info">
          <div class="flex_verfied_date">
            <div class="user_id">
              <div class="id_num">
                <span class="key"
                  ><i class="bx bx-hash"></i> Your member ID:
                </span>
                <span class="val">jo1041003251</span>
              </div>
              <div class="icons_verfied">
                <img
                  src="https://img.icons8.com/stickers/500/id-verified.png"
                  width="40"
                  height="40"
                  alt=""
                />
              </div>
            </div>
            <div class="reg_date">
              <span class="key"
                ><i class="bx bxs-calendar-heart"></i> Member since :</span
              >
              <span class="val"> 12-Oct-2022</span>
            </div>
          </div>
          <div class="user_info_data">
            <div class="info_text user_name">
              <span class="key_info"
                >
                <!-- <i class="bx bxs-user-circle"></i> -->
                <div class="title_ico">
                    <vs-button icon  circle color="tumblr">
                    <i class="bx bxs-user-circle" style="color:#fff;margin-bottom:0"></i>
                  </vs-button>
                </div>
                 Personal Name :</span
              >
              <span class="val_info">Omar Eyad</span>
            </div>
            <div class="info_text company_name">
              <span class="key_info"
                >
                <!-- <i class="bx bx-buildings"></i>  -->
                 <div class="title_ico">
                    <vs-button icon  circle color="tumblr">
                    <i class="bx bx-buildings" style="color:#fff;margin-bottom:0"></i>
                  </vs-button>
                </div>
                Company Name :</span
              >
              <span class="val_info">Omar Eyad Company</span>
            </div>
            <div class="info_text email_name">
              <div class="text_change">
                <span class="key_info"
                  >
                  <!-- <i class="bx bx-envelope"></i>  -->
                   <div class="title_ico">
                    <vs-button icon  circle color="tumblr">
                    <i class="bx bx-envelope" style="color:#fff;margin-bottom:0"></i>
                  </vs-button>
                </div>
                  Email:</span
                >
                <span class="val_info"
                  >omareyad@gmail.com
                  <span>
                    <i class="bx bxs-badge-check"></i>
                    <span class="verfi">[Verified]</span>
                  </span>
                </span>
              </div>
              <div class="link_change">
                <a href="#">change email Address</a>
              </div>
            </div>
            <div class="info_text phone_name">
              <div class="text_change">
                <span class="key_info"
                  >
                  <!-- <i class="bx bxs-phone"></i> -->
                  <div class="title_ico">
                    <vs-button icon  circle color="tumblr">
                    <i class="bx bxs-phone" style="color:#fff;margin-bottom:0"></i>
                  </vs-button>
                </div>
                   Linked Mobile:</span
                >
                <span class="val_info"
                  >+962787525719
                  <span>
                    <i class="bx bxs-badge-check"></i>
                    <span class="verfi">[Verified]</span>
                  </span>
                </span>
              </div>
              <div class="link_change">
                <a href="#">change Mobile number</a>
              </div>
            </div>
            <div class="your_rate info_text">
              <b-rate
                icon-pack="fas"
                v-model="rate"
                :disabled="true"
                custom-text="(12 votes )"
              ></b-rate>
            </div>
            <div class="info_text share_store">
              <span class="key_info"
                > 
                <div class="title_ico">
                    <vs-button icon  circle color="tumblr">
                    <i class="bx bxs-share-alt"></i>
                  </vs-button>
                </div>
                  Share your webstore</span
              >
              <div class="social_share">
                <div class="center">
                  <vs-button icon floating circle color="facebook">
                    <i class="bx bxl-facebook-square"></i>
                  </vs-button>

                  <vs-button icon floating circle color="twitter">
                    <i class="bx bxl-twitter"></i>
                  </vs-button>
                  <vs-button icon floating circle color="linkedin">
                    <i class="bx bxl-linkedin"></i>
                  </vs-button>

                  <vs-button icon floating circle color="whatsapp">
                    <i class="bx bxl-whatsapp"></i>
                  </vs-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <div class="your_badge">
            <span class="title_bd">Badge</span>
            <div class="badge_wraps">

            </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "userprofile",
  data() {
    return {
      rate: 4,
    };
  },
  computed: {
    ...mapGetters({}),
  },
  methods: {
    ...mapActions({}),
  },
  mounted() {},
};
</script>
<style lang="scss">
.dashoard_user_info {
  padding-top: 20px;
  .borders {
    border-right: 1px solid gainsboro;
  }
  .img_upload {
    .img_up {
      text-align: center;
      .vs-avatar {
        border-radius: 100%;
      }
      .vs-avatar-content {
        margin: 0 auto;
      }
      .vs-avatar__badge {
        width: 15px;
        height: 15px;
        right: 22px;
        bottom: 2px;
      }
      .upload_text {
        font-size: 14px;
        padding-top: 8px;
        display: block;
        font-weight: 500;
        transition: 0.2s ease-in-out;
        cursor: pointer;
        &:hover {
          color: var(--active);
        }
      }
    }
  }
  //
  .user_info {
    .flex_verfied_date {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 13px;
      .id_num,
      .icons_verfied {
        display: inline-block;
      }
      .key {
        font-weight: 500;
        i {
          font-size: 16px;
          vertical-align: middle;
          margin-bottom: 4px;
          color: var(--gr);
          //color: black;
        }
      }
      .val {
        padding-left: 2px;
      }
      .icons_verfied {
        img {
          width: 30px;
          height: 30px;
          object-fit: contain;
          vertical-align: middle;
          margin-left: 5px;
        }
      }
    }
    .user_info_data {
      padding-top: 10px;
      .info_text {
        margin-bottom: 8px;
        .key_info {
          font-weight: 500;
          text-transform: capitalize;
          font-size: 15px;
          i {
            font-size: 16px;
            vertical-align: middle;
            margin-bottom: 4px;
            // color: var(--active);
            color: #343a40;
            //color: black;
          }
        }
        .val_info {
          font-size: 14px;
          padding-left: 4px;
          font-weight: 400;
          i {
            color: var(--active);
            font-size: 18px;
            vertical-align: middle;
            display: inline-block;
            margin-bottom: 2px;
          }
          .verfi {
            color: var(--gr);
            margin-left: 10px;
          }
        }
      }
      .email_name,
      .phone_name {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .link_change {
          text-align: left;
          flex: 0 0 140px;
          a {
            font-size: 13px;
            color: var(--active);
            color: black;
            text-transform: capitalize;
            transition: 0.2s ease-in-out;
            &:hover {
              color: var(--active);
            }
          }
        }
      }
      .share_store {
        margin-top: 14px;
        .key_info {
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 8px;
          display: block;
          i {
            //    color: var(--gr);
            color: white;
            margin-bottom: 0;
            // font-size: 25px;
            // vertical-align: middle;
            // margin-bottom: 2px;
            // padding-right: 3px;
          }
        }
      }
    }
  }
}
</style>
