<template>
   <div class="products_wraps">
    <div class="row">
        <!-- single box products -->
        <div class="col-12" v-for="prod,index in 10" :key="prod">
            <template v-if="(index)%5 == 0 && index >0">
                <div class="BannerArea_section">
      <!-- slider banners -->
   <div class="slider_banners">
      <div uk-slideshow="animation: push;max-height:200">

    <div class="uk-position-relative uk-visible-toggle uk-light" tabindex="-1">

        <ul class="uk-slideshow-items">
            <li>
                <img src="https://images.unsplash.com/photo-1543709533-c032159da7b0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80" alt="" uk-cover>
            </li>
            <li>
                <img src="https://images.unsplash.com/photo-1515350540008-a3f566782a3e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80" alt="" uk-cover>
            </li>
            <li>
                <img src="https://images.unsplash.com/photo-1567333971983-7ba18485eaad?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1548&q=80" alt="" uk-cover>
            </li>
        </ul>

        <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slideshow-item="previous"></a>
        <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slideshow-item="next"></a>

    </div>

    <!-- <ul class="uk-slideshow-nav uk-dotnav uk-flex-center uk-margin"></ul> -->

</div>
   </div>
   <!-- end slider banner -->
            </div>
            </template>
            <template v-else>
                <div class="box_productTab">
                <div class="slider_left">
                    <div class="uk-position-relative uk-visible-toggle uk-light" tabindex="-1" uk-slideshow max-height="220" min-height="220">
                    <ul class="uk-slideshow-items">
                        <li>
                            <img src="https://images.unsplash.com/photo-1551076805-e1869033e561?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2064&q=80" alt="" uk-cover>
                        </li>
                        <li>
                            <img src="https://images.unsplash.com/photo-1579165466991-467135ad3110?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1740&q=80" alt="" uk-cover>
                        </li>
                    </ul>

                    <!-- <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slideshow-item="previous"></a>
                    <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slideshow-item="next"></a> -->
                    </div>
                </div>
                <div class="prod_info">
                    <div class="title">
                        <h2>VENUS ARTAS Robotic Hair Transplant System</h2>
                    </div>
                    <!-- fob price -->
                    <div class="fob_price">
                        <div class="key_fob">
                            <span>FOB Price</span>
                        </div>
                        <div class="val_fob">10 Piece(s) US$ 10</div>
                    </div>
                     <!-- fob price -->
                     <div class="properites">
                       <div class="single_prop">
                        <div class="prob_key">
                            <span>Modal</span>
                        </div>
                        <div class="prob_val">
                            <span>zc-100</span>
                        </div>
                       </div>
                       <div class="single_prop">
                        <div class="prob_key">
                            <span>Net Weight</span>
                        </div>
                        <div class="prob_val">
                            <span>Standard</span>
                        </div>
                       </div>
                       <div class="single_prop">
                        <div class="prob_key">
                            <span>Packaging</span>
                        </div>
                        <div class="prob_val">
                            <span>Special / on demand</span>
                        </div>
                       </div>
                       <div class="single_prop">
                        <div class="prob_key">
                            <span>Trademark</span>
                        </div>
                        <div class="prob_val">
                            <span>Zoey</span>
                        </div>
                       </div>
                       <div class="single_prop">
                        <div class="prob_key">
                            <span>Certification</span>
                        </div>
                        <div class="prob_val">
                            <span>ISO</span>
                        </div>
                       </div>
                       <div class="single_prop">
                        <div class="prob_key">
                            <span>Dimension</span>
                        </div>
                        <div class="prob_val">
                            <span>Standard</span>
                        </div>
                       </div>
                       <div class="single_prop">
                        <div class="prob_key">
                            <span>Brand Name</span>
                        </div>
                        <div class="prob_val">
                            <span>Zoey</span>
                        </div>
                       </div>
                       <div class="single_prop">
                        <div class="prob_key">
                            <span>Place Of Orgin</span>
                        </div>
                        <div class="prob_val">
                            <span>Pakistan</span>
                        </div>
                       </div>
                     </div>
                </div>
                <div class="store_info">
                   <div class="up_store">
                    <div class="store_img">
                        <img src="https://www.zoey.com/wp-content/uploads/2020/05/zoey-logo-2020-blog-300x161.png" alt="">
                    </div>
                   <div class="store_name">
                    <span>ZOEY CORPORATION</span>
                   </div>
                   </div>
                   <div class="flex_member">
                    <div class="single_member">
                        <span class="img_reg"><img src="~@/assets/type1.png" alt=""></span>
                    <span class="name_reg">Gold Member</span>
                    </div>
                    <div class="single_member">
                        <span class="img_reg"><img src="~@/assets/type2.png" alt=""></span>
                    <span class="name_reg">Ddu Verified</span>
                    </div>
                   </div>
                   <div class="desc_store">
                    <p>Manufacturer</p>
                    <p>ISO 9001 , Others</p>
                    <p class="maps">
                        <i class='bx bxs-map'></i>
                        <span>Pakistan</span>
                    </p>
                   </div>
                   <!--  -->
                   <div class="flex_buttons">
                    <vs-button color="#00bfff" :active="active == 0">
                        <i class='bx bxs-conversation'></i>
                        Contact
                    </vs-button>
                    <vs-avatar badge-color="danger" badge-position="top-right">
                        <i class='bx bx-heart' ></i>
                    </vs-avatar>
                   </div>
                </div>
            </div>
            </template>
        </div>
        <div class="col-12 mt-5">
            <template>
    <div class="center">
      <vs-pagination v-model="page" :length="5" color="#00bfff" />
    </div>
  </template>
        </div>
         <!-- single box products end -->
    </div>
   </div>
    </template>
    <script>
    export default {
      name: "ProductsTabArea",
      data(){
        return{
            active:0,
            page:1
        }
      }
    }
    </script>
    <style lang="scss" scoped>
    .box_productTab{
        margin-bottom: 14px;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        background-color: white;
        padding: 10px 10px;
        border-radius: 8px;
        .slider_left{
            flex: 0 0 250px;
        }
        .prod_info{
            padding-left: 10px;
            flex: 0 0 50%;
            border-right: 1px dashed gainsboro;
            .title{
                margin-bottom: 12px;
                h2{
                    font-size: 20px;
                    color: deepskyblue;
                }
            }
            .fob_price{
                display: flex;
                font-size: 14px;
                color: black;
                .key_fob{
                    margin-right: 14px;
                    opacity: 0.6;
                }
                .val_fob{
                    font-weight: 500;
                }
            }
            .properites{
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-wrap: wrap;
                margin-top: 14px;
                .single_prop{
                    flex:0 0 50%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    font-size: 13px;
                    margin-bottom: 8px;
                    padding-right: 15px;
                    .prob_key{
                        width: 45%;
                        opacity: 0.6;
                    }
                    .prob_val{
                        font-weight: 500;
                    }
                }
            }
        }
        .store_info{
            flex: 0 0 25%;
            padding-left: 10px;
            .up_store{
                display: flex;
                align-items: center;
            }
            .store_img{
                img{
                    width: 45px;
                    height: 45px;
                    object-fit: contain;
                    vertical-align:bottom;
                    margin-top: 10px;
                    margin-right:8px;
                }
            }
            .store_name{
                font-size: 14px;
                font-weight: 500;
            }
            .flex_member{
                display: flex;
                align-items: center;
                justify-content: space-between;
                .single_member{
                    margin-bottom: 12px;
                    font-size: 14px;
                    img{
                    width: 24px;
                    height:24px;
                    object-fit: contain;
                    margin-right: 8px;
                    vertical-align: top;
                    margin-top: -1px;
                }
                }   
            }
            .desc_store{
                p{
                    font-size: 14px;
                    margin-bottom: 4px;
                    opacity: 0.8;
                }
                p.maps{
                    i{
                        vertical-align: middle;
                        margin-top: -3px;
                        padding-right:3px;
                    }
                }
            }
            .flex_buttons{
                display: flex;
                align-items: center;
                button{
                    width: 50%;
                    margin-right: 10px;
                    margin-top: 12px;
                    i{
                        margin-right: 6px;
                    }
                }
            }
        }
    }
    .BannerArea_section{
        margin: 14px 0px 25px 0px;
    }
    </style>