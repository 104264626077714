var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"products_wraps"},[_c('div',{staticClass:"row"},[_vm._l((10),function(prod,index){return _c('div',{key:prod,staticClass:"col-12"},[((index)%5 == 0 && index >0)?[_vm._m(0,true)]:[_c('div',{staticClass:"box_productTab"},[_vm._m(1,true),_vm._m(2,true),_c('div',{staticClass:"store_info"},[_vm._m(3,true),_vm._m(4,true),_vm._m(5,true),_c('div',{staticClass:"flex_buttons"},[_c('vs-button',{attrs:{"color":"#00bfff","active":_vm.active == 0}},[_c('i',{staticClass:"bx bxs-conversation"}),_vm._v(" Contact ")]),_c('vs-avatar',{attrs:{"badge-color":"danger","badge-position":"top-right"}},[_c('i',{staticClass:"bx bx-heart"})])],1)])])]],2)}),_c('div',{staticClass:"col-12 mt-5"},[[_c('div',{staticClass:"center"},[_c('vs-pagination',{attrs:{"length":5,"color":"#00bfff"},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)]],2)],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"BannerArea_section"},[_c('div',{staticClass:"slider_banners"},[_c('div',{attrs:{"uk-slideshow":"animation: push;max-height:200"}},[_c('div',{staticClass:"uk-position-relative uk-visible-toggle uk-light",attrs:{"tabindex":"-1"}},[_c('ul',{staticClass:"uk-slideshow-items"},[_c('li',[_c('img',{attrs:{"src":"https://images.unsplash.com/photo-1543709533-c032159da7b0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80","alt":"","uk-cover":""}})]),_c('li',[_c('img',{attrs:{"src":"https://images.unsplash.com/photo-1515350540008-a3f566782a3e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80","alt":"","uk-cover":""}})]),_c('li',[_c('img',{attrs:{"src":"https://images.unsplash.com/photo-1567333971983-7ba18485eaad?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1548&q=80","alt":"","uk-cover":""}})])]),_c('a',{staticClass:"uk-position-center-left uk-position-small uk-hidden-hover",attrs:{"href":"#","uk-slidenav-previous":"","uk-slideshow-item":"previous"}}),_c('a',{staticClass:"uk-position-center-right uk-position-small uk-hidden-hover",attrs:{"href":"#","uk-slidenav-next":"","uk-slideshow-item":"next"}})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"slider_left"},[_c('div',{staticClass:"uk-position-relative uk-visible-toggle uk-light",attrs:{"tabindex":"-1","uk-slideshow":"","max-height":"220","min-height":"220"}},[_c('ul',{staticClass:"uk-slideshow-items"},[_c('li',[_c('img',{attrs:{"src":"https://images.unsplash.com/photo-1551076805-e1869033e561?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2064&q=80","alt":"","uk-cover":""}})]),_c('li',[_c('img',{attrs:{"src":"https://images.unsplash.com/photo-1579165466991-467135ad3110?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1740&q=80","alt":"","uk-cover":""}})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"prod_info"},[_c('div',{staticClass:"title"},[_c('h2',[_vm._v("VENUS ARTAS Robotic Hair Transplant System")])]),_c('div',{staticClass:"fob_price"},[_c('div',{staticClass:"key_fob"},[_c('span',[_vm._v("FOB Price")])]),_c('div',{staticClass:"val_fob"},[_vm._v("10 Piece(s) US$ 10")])]),_c('div',{staticClass:"properites"},[_c('div',{staticClass:"single_prop"},[_c('div',{staticClass:"prob_key"},[_c('span',[_vm._v("Modal")])]),_c('div',{staticClass:"prob_val"},[_c('span',[_vm._v("zc-100")])])]),_c('div',{staticClass:"single_prop"},[_c('div',{staticClass:"prob_key"},[_c('span',[_vm._v("Net Weight")])]),_c('div',{staticClass:"prob_val"},[_c('span',[_vm._v("Standard")])])]),_c('div',{staticClass:"single_prop"},[_c('div',{staticClass:"prob_key"},[_c('span',[_vm._v("Packaging")])]),_c('div',{staticClass:"prob_val"},[_c('span',[_vm._v("Special / on demand")])])]),_c('div',{staticClass:"single_prop"},[_c('div',{staticClass:"prob_key"},[_c('span',[_vm._v("Trademark")])]),_c('div',{staticClass:"prob_val"},[_c('span',[_vm._v("Zoey")])])]),_c('div',{staticClass:"single_prop"},[_c('div',{staticClass:"prob_key"},[_c('span',[_vm._v("Certification")])]),_c('div',{staticClass:"prob_val"},[_c('span',[_vm._v("ISO")])])]),_c('div',{staticClass:"single_prop"},[_c('div',{staticClass:"prob_key"},[_c('span',[_vm._v("Dimension")])]),_c('div',{staticClass:"prob_val"},[_c('span',[_vm._v("Standard")])])]),_c('div',{staticClass:"single_prop"},[_c('div',{staticClass:"prob_key"},[_c('span',[_vm._v("Brand Name")])]),_c('div',{staticClass:"prob_val"},[_c('span',[_vm._v("Zoey")])])]),_c('div',{staticClass:"single_prop"},[_c('div',{staticClass:"prob_key"},[_c('span',[_vm._v("Place Of Orgin")])]),_c('div',{staticClass:"prob_val"},[_c('span',[_vm._v("Pakistan")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"up_store"},[_c('div',{staticClass:"store_img"},[_c('img',{attrs:{"src":"https://www.zoey.com/wp-content/uploads/2020/05/zoey-logo-2020-blog-300x161.png","alt":""}})]),_c('div',{staticClass:"store_name"},[_c('span',[_vm._v("ZOEY CORPORATION")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex_member"},[_c('div',{staticClass:"single_member"},[_c('span',{staticClass:"img_reg"},[_c('img',{attrs:{"src":require("@/assets/type1.png"),"alt":""}})]),_c('span',{staticClass:"name_reg"},[_vm._v("Gold Member")])]),_c('div',{staticClass:"single_member"},[_c('span',{staticClass:"img_reg"},[_c('img',{attrs:{"src":require("@/assets/type2.png"),"alt":""}})]),_c('span',{staticClass:"name_reg"},[_vm._v("Ddu Verified")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"desc_store"},[_c('p',[_vm._v("Manufacturer")]),_c('p',[_vm._v("ISO 9001 , Others")]),_c('p',{staticClass:"maps"},[_c('i',{staticClass:"bx bxs-map"}),_c('span',[_vm._v("Pakistan")])])])
}]

export { render, staticRenderFns }