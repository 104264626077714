<template>
    <div class="map_listing_wraps">
      <h3 class="tabname">My listing Stats</h3>
      <div class="account_type">
        <div class="single_data">
            <div class="ti">Account Type</div>
            <div class="va gre">Free</div>
        </div>
        <div class="single_data">
            <div class="ti">Limit of live Ads</div>
            <div class="va">7 Ads</div>
        </div>
        <div class="single_data">
            <div class="ti">Live Ads</div>
            <div class="va">1 Ads</div>
        </div>
        <div class="single_data">
            <div class="ti">Paid Ads</div>
            <div class="va">0</div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 col-md-6 col-12">
            <div class="card_status">
                <div class="title_st">Product / Equipment</div>
                <div class="body_card">
                    <div class="single_data">
                        <div class="key">Number of Ads</div>
                        <div class="val">12</div>
                    </div>
                    <div class="single_data">
                        <div class="key">Running Listings</div>
                        <div class="val">2</div>
                    </div>
                    <div class="single_data">
                        <div class="key">Expired Listings</div>
                        <div class="val">1</div>
                    </div>
                    <div class="single_data">
                        <div class="key">Deleted Listings</div>
                        <div class="val">0</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-3 col-md-6 col-12">
            <div class="card_status">
                <div class="title_st">Services</div>
                <div class="body_card">
                    <div class="single_data">
                        <div class="key">Number of Ads</div>
                        <div class="val">12</div>
                    </div>
                    <div class="single_data">
                        <div class="key">Running Listings</div>
                        <div class="val">2</div>
                    </div>
                    <div class="single_data">
                        <div class="key">Expired Listings</div>
                        <div class="val">1</div>
                    </div>
                    <div class="single_data">
                        <div class="key">Deleted Listings</div>
                        <div class="val">0</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-3 col-md-6 col-12">
            <div class="card_status">
                <div class="title_st">Parts</div>
                <div class="body_card">
                    <div class="single_data">
                        <div class="key">Number of Ads</div>
                        <div class="val">12</div>
                    </div>
                    <div class="single_data">
                        <div class="key">Running Listings</div>
                        <div class="val">2</div>
                    </div>
                    <div class="single_data">
                        <div class="key">Expired Listings</div>
                        <div class="val">1</div>
                    </div>
                    <div class="single_data">
                        <div class="key">Deleted Listings</div>
                        <div class="val">0</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-3 col-md-6 col-12">
            <div class="card_status">
                <div class="title_st">Agency</div>
                <div class="body_card">
                    <div class="single_data">
                        <div class="key">Number of Ads</div>
                        <div class="val">12</div>
                    </div>
                    <div class="single_data">
                        <div class="key">Running Listings</div>
                        <div class="val">2</div>
                    </div>
                    <div class="single_data">
                        <div class="key">Expired Listings</div>
                        <div class="val">1</div>
                    </div>
                    <div class="single_data">
                        <div class="key">Deleted Listings</div>
                        <div class="val">0</div>
                    </div>
                </div>
            </div>
        </div>
       
      </div>
    </div>
  </template>
  <script>
  import { mapActions, mapGetters } from "vuex";
  export default {
    name: "myAds",
    components: {
      },
    data() {
      return {
      }
    },
    computed: {
      ...mapGetters({
      }),
    },
    methods: {
      ...mapActions({
      }),
    },
    mounted() {},
  };
  </script>
  <style lang="scss">
  .map_listing_wraps {
    padding-top: 20px;
    .mrgb {
      margin-bottom: 14px;
      position: relative;
    }
    .mrgt {
      margin-top: 25px;
    }
    .tabname {
      font-size: 18px;
      font-weight: 600;
      margin: 0;
      padding: 0;
      margin-bottom: 20px;
    }
  }
  

  .card_status{
    border: 1px solid gainsboro;
    background-color: white;
    border-radius: 8px;
    padding: 10px;
    transition: 0.3s ease-in-out;
    &:hover{
        box-shadow: 3px 3px 0px #e3e3e3;
    }
    .title_st{
        text-align: center;
        font-size: 16px;
        font-weight: 700;
        background-color: var(--active);
        padding: 2px;
        border-radius: 4px;
        color: white;
    }
    .body_card{
        padding-top: 14px;
        .single_data{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 10px;
            .key{
                font-weight: 600;
            }
            .val{
                opacity: 0.7;
            }
        }
    }
  }
  .account_type{
    display: flex;
    align-items: center;
    border: 1px solid gainsboro;
    padding: 8px 20px;
    border-radius: 8px;
    margin-bottom: 30px;

    .single_data{
        flex: 0 0 calc(100%/4);
        position: relative;
        padding-left: 20px;
        &:first-child{
            padding-left: 0px;
        }
        &:last-child{
            &::after{
                display: none;
            }
        }
        &::after{
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            width: 2px;
            height: 100%;
            background-color: gainsboro;
        }
        .ti{
            font-weight: 700;
            font-size: 15px;
        }
        .va{
            font-size: 14px;
            font-weight: 500;
        }
        .gre{
            color: green;
        }
    }
  }
  </style>
  