<template>
    <div class="jobsArea_wraps">
    <div class="container">
        <van-tabs v-model="active" type="card">
  <van-tab :title="'Job Seekers'">
   <div class="contant_tab">
    <div class="row">
        <template v-for="index in 12">
       <div class="col-md-6 col-12" :key="index">
        <van-card
        thumb="https://plus.unsplash.com/premium_photo-1661660007558-68f36eb2f5b1?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2940&q=80">
        <template #title>
        <span class="titleCard">Software engineer</span>
        </template>
        <template #desc>
        <span class="d-block">
            <p>  Must have JavaScript in addition to the VueJs framework</p>
            <p>work address Amman, Jordan, Queen Rania Street</p>
        </span>
        </template>
        <!-- <template #tags>
            <van-tag class="tagui" type="success">Html</van-tag>
            <van-tag class="tagui" type="success">Css</van-tag>
            <van-tag class="tagui" type="success">JS</van-tag>
            <van-tag class="tagui" type="success">VueJs</van-tag>
        </template> -->
        <!-- <template #price>
        <span class="d-block addressUi"></span>
        </template> -->
        
        </van-card>
        <!-- <van-card 
        num="Job Seekers"
        tag="Job Seekers"
        price="work address Amman, Jordan, Queen Rania Street"
        title="Software engineer"
        desc="work address Amman, Jordan, Queen Rania Street"
        thumb="https://img01.yzcdn.cn/vant/ipad.jpeg"
        /> -->
       </div>
    </template>
    </div>
   </div>
  </van-tab>
  <van-tab :title="'Job Vacancies'">
    <div class="contant_tab">
    <div class="row">
        <template v-for="index in 12">
            <div class="col-xl-3 col-sm-6 mt-5" :key="index">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex align-items-center">
                        <div><img src="https://plus.unsplash.com/premium_photo-1661660007558-68f36eb2f5b1?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2940&q=80" alt="" class="avatar-md rounded-circle img-thumbnail" /></div>
                        <div class="flex-1 ms-3">
                            <h5 class="font-size-16 mb-1"><a href="#" class="text-dark">Omar Eyad</a></h5>
                            <span class="badge badge-soft-success mb-0">Software Engineer</span>
                        </div>
                    </div>
                    <div class="mt-3 pt-1">
                        <p class="text-muted mb-0"><i class="mdi mdi-phone font-size-15 align-middle pe-2 text-primary"></i>0787525XXX</p>
                        <p class="text-muted mb-0 mt-2"><i class="mdi mdi-email font-size-15 align-middle pe-2 text-primary"></i> info@medical.com</p>
                        <p class="text-muted mb-0 mt-2"><i class="mdi mdi-google-maps font-size-15 align-middle pe-2 text-primary"></i>Amman , Jordan</p>
                    </div>
                    <div class="d-flex gap-2 pt-4">
                        <button type="button" class="btn btn-sm w-100"><i class="bx bx-message-square-dots me-1"></i> Contact</button>
                    </div>
                </div>
            </div>
        </div>
    </template>
    </div>
   </div>
   </van-tab>
  <van-tab :title="'Training offerd'">
    <div class="contant_tab">
    <div class="row">
        <template v-for="index in 12">
       <div class="col-md-6 col-12" :key="index">
        <van-card
        thumb="https://images.unsplash.com/photo-1579412690850-bd41cd0af397?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2865&q=80">
        <template #title>
        <span class="titleCard">BackEnd Developer</span>
        </template>
        <template #desc>
        <span class="d-block">Must have PHP and Laravel .</span>
        </template>
        <template #tags>
            <van-tag class="tagui" type="success">PHP</van-tag>
            <van-tag class="tagui" type="success">Laravel</van-tag>
            <van-tag class="tagui" type="success">My SQL</van-tag>
        </template>
        <template #price>
        <span class="d-block addressUi">work address Amman, Jordan, Queen Rania Street</span>
        </template>
        </van-card>
       </div>
    </template>
    </div>
   </div>
  </van-tab>
  <van-tab :title="'Training Wanted'">
    <div class="contant_tab">
    <div class="row">
        <template v-for="index in 12">
       <div class="col-md-6 col-12" :key="index">
        <van-card
        thumb="https://images.unsplash.com/photo-1580894908361-967195033215?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2940&q=80">
        <template #title>
        <span class="titleCard">Content writer</span>
        </template>
        <template #desc>
        <span class="d-block">You must be fluent in English, Arabic and French</span>
        </template>
        <template #tags>
            <van-tag class="tagui" type="success">English</van-tag>
            <van-tag class="tagui" type="success">Arabic</van-tag>
            <van-tag class="tagui" type="success">French</van-tag>
        </template>
        <template #price>
        <span class="d-block addressUi">work address Amman, Jordan, Queen Rania Street</span>
        </template>
        </van-card>
       </div>
    </template>
    </div>
   </div>
  </van-tab>
        </van-tabs>
    </div>
    </div>
</template>
<script>
export default {
  name: "AllJobsArea",
  data() {
    return {
        active:0
    };
  },
  components: {},
};
</script>
<style lang="scss" scoped>
.jobsArea_wraps{
    min-height: 100vh;
    padding-top: 180px;
    .van-tab__pane, .van-tab__pane-wrapper {
    background-color: #f6f8fa;
}
.van-tabs__nav--card .van-tab.van-tab--active {
    font-weight: 700;
}
.van-card {
    color: #323233;
    font-size: 12px;
    background-color: white;
    margin-bottom: 30px;
}
.titleCard{
    font-weight: 600;
    font-size: 15px;
    display: block;
}
.tagui{
    margin-right: 5px;
    margin-top: 4px;
}
.addressUi{
    opacity: 0.5;
}
}


// 
.card {
    margin-bottom: 24px;
    box-shadow: 0 2px 3px #e4e8f0;
}
.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid #eff0f2;
    border-radius: 1rem;
    font-size: 13px;
}
.avatar-md {
    height: 4rem;
    width: 4rem;
}
.rounded-circle {
    border-radius: 50%!important;
}
.img-thumbnail {
    padding: 0.25rem;
    background-color: #f1f3f7;
    border: 1px solid #eff0f2;
    border-radius: 0.75rem;
}
.avatar-title {
    align-items: center;
    background-color: #3b76e1;
    color: #fff;
    display: flex;
    font-weight: 500;
    height: 100%;
    justify-content: center;
    width: 100%;
}
.bg-soft-primary {
    background-color: rgba(59,118,225,.25)!important;
}
a {
    text-decoration: none!important;
}
.badge-soft-danger {
    color: #f56e6e !important;
    background-color: rgba(245,110,110,.1);
}
.badge-soft-success {
    color: #63ad6f !important;
    background-color: rgba(99,173,111,.1);
}
.mb-0 {
    margin-bottom: 0!important;
}
.badge {
    display: inline-block;
    padding: 0.25em 0.6em;
    font-size: 75%;
    font-weight: 500;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.75rem;
}
i{
    color: var(--active) !important;   
}
button{
    background-color: var(--active);
    color: white !important;
    i{
        color: white !important;
    }
    &:hover{
        border: 1px solid var(--active);
        color: var(--active) !important;
        i{
            color: var(--active) !important;
        }
    }
}
</style>
