<template>
  <div class="wallets_wraps">
    <h3 class="tabname">Wallet</h3>
    <div class="wallet_wrap">
      <div class="wallet_app">
        <div class="head_wa">
          <div class="title_wa">
            <span>Your Balance: </span>
            <strong>0 $</strong>
          </div>
          <div class="add_wa">
            <vs-button circle icon floating success @click="toggle_price()">
              <i class="bx bxs-wallet-alt bx-flashing"></i>
              <span>Recharge Wallet</span>
            </vs-button>
          </div>
        </div>
        <div class="body_wa" v-show="wallet_price">
          <div class="row">
            <div class="col-sm-12 col-lg-4 col-md-4">
              <div class="pricing">
                <div class="price-header">
                  <div class="title">Basic Plan</div>
                  <div class="price">25 USD</div>
                </div>
                <div class="content">
                  <ul>
                    <li>+ 5 USD free</li>
                  </ul>
                  <vs-button square icon floating @click="show_pay_methods">
                    <i class="bx bx-paper-plane"></i>
                    <span>Select</span>
                  </vs-button>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-lg-4 col-md-4">
              <div class="pricing featured">
                <!-- <div class="listing-badges">
                        <span class="featured">Recommended</span>
                    </div> -->
                <div class="price-header price-header-2">
                  <div class="title">Standard</div>
                  <div class="price">75 USD</div>
                </div>
                <div class="content">
                  <ul>
                    <li>+ 15 USD free</li>
                  </ul>
                  <vs-button square icon floating @click="show_pay_methods">
                    <i class="bx bx-paper-plane"></i>
                    <span>Select</span>
                  </vs-button>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-lg-4 col-md-4">
              <div class="pricing">
                <div class="price-header">
                  <div class="title">Pro</div>
                  <div class="price">150 USD</div>
                </div>
                <div class="content">
                  <ul>
                    <li>+ 30 USD free</li>
                  </ul>
                  <vs-button square icon floating @click="show_pay_methods">
                    <i class="bx bx-paper-plane"></i>
                    <span>Select</span>
                  </vs-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="payment_meth_wa" v-show="payemnts_show">
          <div class="row">
            <div class="col-lg-8 payments_wrap">
             <h2> payemnt metods</h2>
              <div class="row row_pays">
                <div class="col-lg-4 col-md-6 col-12">
                <a href="#" class="box_pays">
                    <input checked  id="rad1" type="radio" name="paysmehod"/>
                    <label for="rad1">
                    <img src="https://upload.wikimedia.org/wikipedia/commons/a/a4/Paypal_2014_logo.png" alt="">
                    <!-- <p>Mada Pay</p> -->
                    </label>
                </a>
            </div>
            <!--  -->
            <div class="col-lg-4 col-md-6 col-12">
                <a href="#" class="box_pays">
                    <input checked  id="rad1" type="radio" name="paysmehod"/>
                    <label for="rad1">
                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/41/Visa_Logo.png/640px-Visa_Logo.png" alt="">
                    <!-- <p>Mada Pay</p> -->
                    </label>
                </a>
            </div>
            <div class="col-lg-4 col-md-6 col-12">
                <a href="#" class="box_pays">
                    <input checked  id="rad1" type="radio" name="paysmehod"/>
                    <label for="rad1">
                    <img src="https://upload.wikimedia.org/wikipedia/commons/7/72/MasterCard_early_1990s_logo.png" alt="">
                    <!-- <p>Mada Pay</p> -->
                    </label>
                </a>
            </div>
            <div class="col-lg-4 col-md-6 col-12">
                <a href="#" class="box_pays">
                    <input checked  id="rad1" type="radio" name="paysmehod"/>
                    <label for="rad1">
                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/ba/Stripe_Logo%2C_revised_2016.svg/2560px-Stripe_Logo%2C_revised_2016.svg.png" alt="">
                    <!-- <p>Mada Pay</p> -->
                    </label>
                </a>
            </div>
              </div>

            </div>
            <div class="col-lg-4">
                <div class="invoive_wa">
                     <div class="invoice-card">
                <div class="invoice-title">
                  <div id="main-title">
                    <h4>INVOICE</h4>
                    <span>ID:35308819</span>
                  </div>

                  <span id="date">6/02/2022</span>
                </div>

                <div class="invoice-details">
                  <table class="invoice-table">
                    <thead>
                      <tr>
                        <td colspan="2">Plans</td>
                        <!-- <td>UNIT</td> -->
                        <td>PRICE</td>
                      </tr>
                    </thead>

                    <tbody>
                      <tr class="row-data">
                        <td colspan="2">Basic <span>(Plan)</span></td>
                        <!-- <td id="unit">1</td> -->
                        <td>25 $</td>
                      </tr>
                      <tr class="calc-row">
                        <td colspan="2">Total</td>
                        <td>9.00</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- <div class="invoice-footer">
                  <button class="btn btn-secondary" id="later">LATER</button>
                  <button class="btn btn-primary">PAY NOW</button>
                </div> -->
              </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "wallet_profile",
  data() {
    return {
      wallet_price: false,
      payemnts_show:false,
    };
  },
  computed: {
    ...mapGetters({}),
  },
  methods: {
    ...mapActions({}),
    toggle_price() {
      this.wallet_price = !this.wallet_price;
      this.payemnts_show = false;
    },
    show_pay_methods(){
      this.payemnts_show = true;
      this.wallet_price = false;
    }
  },
  mounted() {},
};
</script>
<style lang="scss">
.wallets_wraps {
  padding-top: 20px;
  .mrgb {
    margin-bottom: 14px;
    position: relative;
  }
  .mrgt {
    margin-top: 25px;
  }
  .tabname {
    font-size: 18px;
    font-weight: 600;
    margin: 0;
    padding: 0;
    margin-bottom: 20px;
  }
}
.wallet_app {
  //   max-width: 500px;
  margin: 0 auto;
  background-color: white;
  border: 1px solid gainsboro;
  .head_wa {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 14px;
    background-color: #fff;
    // padding-bottom: 45px;
    position: relative;
  }
  .title_wa {
    color: black;
    font-size: 18px;
    strong {
      color: black;
    }
  }
  .add_wa {
    button {
      span {
        padding-left: 8px;
      }
    }
  }
  .body_wa {
    padding: 0px 14px;
    a,
    a:hover,
    a:focus,
    a:active {
      text-decoration: none;
      outline: none;
    }

    a,
    a:active,
    a:focus {
      color: #6f6f6f;
      text-decoration: none;
      transition-timing-function: ease-in-out;
      -ms-transition-timing-function: ease-in-out;
      -moz-transition-timing-function: ease-in-out;
      -webkit-transition-timing-function: ease-in-out;
      -o-transition-timing-function: ease-in-out;
      transition-duration: 0.2s;
      -ms-transition-duration: 0.2s;
      -moz-transition-duration: 0.2s;
      -webkit-transition-duration: 0.2s;
      -o-transition-duration: 0.2s;
    }

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
    }
    img {
      max-width: 100%;
      height: auto;
    }
    .main-title {
      margin-bottom: 50px;
      text-align: center;
    }

    .main-title h1 {
      font-size: 35px;
      font-weight: 700;
      margin-bottom: 15px;
    }

    .main-title h2 {
      font-size: 28px;
      font-weight: 600;
      margin-bottom: 5px;
    }

    .main-title p {
      font-size: 18px;
      margin-bottom: 0;
    }
    .pricing {
      text-align: center;
      margin-bottom: 30px;
      position: relative;
      z-index: 9;
      box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
    }

    .pricing .price-header {
      width: 100%;
      background: #fff;
      padding: 35px 20px;
    }

    .pricing .price-header .title {
      margin: 0 0 10px;
      font-size: 22px;
      font-weight: 700;
      color: #185bff;
    }

    .pricing .price-header .price {
      font-size: 35px;
      font-weight: 300;
      margin: 0;
      color: #37404d;
    }

    .pricing .content {
      padding: 20px 20px 15px;
      background: #eaeaea;
    }

    .pricing .content ul {
      margin: 0 0 20px;
    }

    .pricing .content ul li {
      padding: 8px 0;
      font-size: 16px;
      color: #656565;
    }
    .pricing i {
      margin-right: 8px;
    }

    .pricing.featured .listing-badges {
      position: absolute;
      top: 0;
      z-index: 999;
      right: 0;
      width: 100%;
      display: block;
      font-size: 15px;
      padding: 0;
      overflow: hidden;
      height: 100px;
    }

    .pricing.featured .featured {
      float: left;
      transform: rotate(-45deg);
      left: -67px;
      top: 17px;
      position: relative;
      text-align: center;
      width: 200px;
      font-size: 13px;
      margin: 0;
      padding: 7px 10px;
      font-weight: 500;
      color: #fff;
    }
    .pricing.featured .featured {
      background: #f0151f;
    }
    .btn-outline {
      border: solid 1px #f0151f;
      color: #f0151f;
    }

    .btn-outline:hover {
      border: solid 1px #dc141d;
      background: #dc141d;
      color: #fff;
    }
    .button-theme {
      background: #f0151f;
      color: #fff !important;
    }

    .button-theme:hover {
      background: #dc141d;
      color: #fff;
    }

    .pricing-btn {
      padding: 7px 25px 5px;
      border-radius: 2px;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 1.5px;
      text-transform: uppercase;
    }
  }
  .payment_meth_wa{
    padding: 14px 14px;
  }
  .invoive_wa{
    margin-top: 40px;
.invoice-card {
  display: flex;
  flex-direction: column;
  padding: 10px 2em;
  min-height: 250px;
  width: 100%;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 10px 30px 5px rgba(0, 0, 0, 0.15);
}

.invoice-card > div {
  margin: 5px 0;
}

.invoice-title {
  flex: 0;
}

.invoice-title #date {
  display: block;
  margin: 8px 0;
  font-size: 12px;
}

.invoice-title #main-title {
  display: flex;
  justify-content: space-between;
  margin-top: 2em;
}

.invoice-title #main-title h4 {
  letter-spacing: 2.5px;
}

.invoice-title span {
  color: rgba(0, 0, 0, 0.4);
}

.invoice-details {
  flex: 0.5;
  border-top: 0.5px dashed grey;
  display: flex;
  align-items: center;
}

.invoice-table {
  width: 100%;
  border-collapse: collapse;
}

.invoice-table thead tr td {
  font-size: 12px;
  letter-spacing: 1px;
  color: grey;
  padding: 8px 0;
}

.calc-row{
  border-top: 0.5px dashed grey;
}
.invoice-table thead tr td:nth-last-child(1),
.row-data td:nth-last-child(1),
.calc-row td:nth-last-child(1)
{
  text-align: right;
}

.invoice-table tbody tr td {
    padding: 8px 0;
    letter-spacing: 0;
}

.invoice-table .row-data #unit {
  text-align: center;
}

.invoice-table .row-data span {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.6);
}

.invoice-footer {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.invoice-footer #later {
  margin-right: 5px;
}

.btn {
  border: none;
  padding: 5px 0px;
  background: none;
  cursor: pointer;
  letter-spacing: 1px;
  outline: none;
}

.btn.btn-secondary {
  color: rgba(0, 0, 0, 0.3);
}

.btn.btn-primary {
  color: var(--primary-color);
}

.btn#later {
  margin-right: 2em;
}


  }
  .payments_wrap{
      h2{
        font-size: 1rem ;
        color: black;
        font-weight: 700;
        padding-top: 0px;
    }
     .row_pays{
        padding-top: 20px;
    }
     .box_pays{
      display: block;
        text-align: center;
    background-color: #eaeaea;
    width: 100%;
    min-height: 155px;
    border-radius: 10px;
    margin-bottom: 20px;
    border: 1px solid gainsboro;
    transition: 0.3s ease-in-out;
    }
     .box_pays label{
        display: inline-block;
    width: 100%;
    height: 100%;
    padding: 20px 20px 10px 20px;
    cursor: pointer;
    }
     .box_pays.cheked_pay{
        background-color: #cecece;
    }
     .box_pays:hover{
        background-color: #cecece;
    }
     .box_pays input{
        display: none;
    }
     .box_pays img{
        width: 100%;
        height: 120px;
        padding: 8px;
        object-fit: contain;
    }
     .box_pays p{
        font-size: 15px;
        color: black;
        font-weight: 500;
        padding-top: 15px;
        padding-bottom: 10px;
    }
  }
}
</style>
