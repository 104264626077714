<template>
    <div class="products_wraps">
     <div class="row">
         <!-- single box products -->
         <div class="col-12" v-for="prod,index in 10" :key="prod">
            <template v-if="(index)%5 == 0 && index >0">
                <div class="BannerArea_section">
      <!-- slider banners -->
   <div class="slider_banners">
      <div uk-slideshow="animation: push;max-height:200">

    <div class="uk-position-relative uk-visible-toggle uk-light" tabindex="-1">

        <ul class="uk-slideshow-items">
            <li>
                <img src="https://images.unsplash.com/photo-1543709533-c032159da7b0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80" alt="" uk-cover>
            </li>
            <li>
                <img src="https://images.unsplash.com/photo-1515350540008-a3f566782a3e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80" alt="" uk-cover>
            </li>
            <li>
                <img src="https://images.unsplash.com/photo-1567333971983-7ba18485eaad?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1548&q=80" alt="" uk-cover>
            </li>
        </ul>

        <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slideshow-item="previous"></a>
        <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slideshow-item="next"></a>

    </div>

    <!-- <ul class="uk-slideshow-nav uk-dotnav uk-flex-center uk-margin"></ul> -->

</div>
   </div>
   <!-- end slider banner -->
            </div>
            </template>
            <template v-else>
                <div class="box_productTab">
                 <div class="slider_left">
                     <div class="uk-position-relative uk-visible-toggle uk-light" tabindex="-1" uk-slideshow max-height="220" min-height="220">
                     <ul class="uk-slideshow-items">
                        <li>
                             <img src="https://plus.unsplash.com/premium_photo-1658506826316-f21670ec809e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1686&q=80" alt="" uk-cover>
                         </li>
                         <li>
                             <img src="https://images.unsplash.com/photo-1563013544-824ae1b704d3?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1740&q=80" alt="" uk-cover>
                         </li>
                     </ul>
 
                     <!-- <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slideshow-item="previous"></a>
                     <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slideshow-item="next"></a> -->
                     </div>
                     <div class="abs_buttons">
                    <vs-button
                    color="#dcdcdc"
                    animation-type="scale">
                    <i class='bx bxs-store' ></i>
                    WebStore
                </vs-button>
                <vs-button
                    color="#dcdcdc"
                    animation-type="scale">
                    <i class='bx bx-copy-alt' ></i>
                    VTS
                </vs-button>
                 </div>
                 </div>
                 <div class="prod_info">
                     <div class="title">
                         <h2> <span class="status">AUCTION</span>Trolley 4D Ultrasound Scanner Color Doppler...</h2>
                         <div class="date_title">
                            <i class='bx bxs-calendar'></i>
                            <span>December-01</span>
                         </div>
                     </div>
                     <!-- fob price -->
                     <div class="fob_price">
                        <div class="single_fob">
                            <div class="key_fob">
                             <span>Brand:</span>
                         </div>
                         <div class="val_fob">ENVITEC</div>
                        </div>
                        <!--  -->
                        <div class="single_fob">
                            <div class="key_fob">
                             <span>Model:</span>
                         </div>
                         <div class="val_fob">P/N PM30-32732</div>
                        </div>
                        <div class="single_fob">
                            <div class="key_fob">
                             <span>Category:</span>
                         </div>
                         <div class="val_fob">Ultrasound</div>
                        </div>
                        <!-- <div class="single_fob">
                            <div class="key_fob">
                             <span>See All:</span>
                         </div>
                         <div class="val_fob">Ultrasound</div>
                        </div> -->
                     </div>
                      <!-- fob price -->
                      <div class="parg">
                        <p>PHILIPS Clearvue 650 –(MFG:12/2014 ) – New Option: (4D Imaging - Abdominal – Abdominal Vascular – Adult Echo- Cerebro Vascular – CW- GrayScale Freehand 3D – Musculoskeletal – OB/Gyn....</p>
                      </div>
                      <div class="properites">
                        <div class="single_prop">
                         <div class="prob_key">
                             <span>Bid Now:</span>
                         </div>
                         <div class="prob_val">
                             <span > US $21,000</span>
                         </div>
                        </div>
                        <div class="single_prop">
                         <div class="prob_key">
                             <span>Condition:</span>
                         </div>
                         <div class="prob_val">
                             <span > New</span>
                         </div>
                        </div>
                        <div class="single_prop">
                         <div class="prob_key">
                             <span>Bids:</span>
                         </div>
                         <div class="prob_val">
                             <span >1</span>
                         </div>
                        </div>
                        <div class="single_prop">
                         <div class="prob_key">
                             <span>Year:</span>
                         </div>
                         <div class="prob_val">
                             <span >2022</span>
                         </div>
                        </div>
                        <div class="single_prop">
                         <div class="prob_key">
                             <span>Shipping From:</span>
                         </div>
                         <div class="prob_val">
                             <span class="prob_flag"><img src="~@/assets/flag/flag13.png" alt=""></span>
                         </div>
                        </div>
                        <div class="single_prop">
                         <div class="prob_key">
                             <span>In Stock:</span>
                         </div>
                         <div class="prob_val">
                             <span >Yes</span>
                         </div>
                        </div>
                        <div class="single_prop">
                         <div class="prob_key">
                             <span>Time left:</span>
                         </div>
                         <div class="prob_val">
                             <span ><van-count-down :time="time" format="DD Day, HH:mm:ss" /></span>
                         </div>
                        </div>
                      </div>
                      <!--  -->
                 <div class="badage_users">
                   <div class="badge_up">
                     <div class="user-bad">
                        <i class='bx bx-user' ></i>
                        <span>Mr. Yazan AbuFarha</span>
                    </div>
                    <div class="user-bad">
                        <i class='bx bx-building'></i>
                        <span>AbuFarha Medical</span>
                        <span><img width="18" height="18" src="~@/assets/flag/flag13.png" alt=""></span>
                    </div>
                    <div class="user-bad ratings">
                        <span>{{ rate_val }}/5</span>
                        <van-rate
                    v-model="rate_val"
                    :size="14"
                    readonly
                    color="#ffd21e"
                    void-icon="star"
                    void-color="#eee"
                    />
                    </div>
                   </div>
                   <div class="badge_down">
                    <!-- gradient -->
                   <vs-button
                    color="#e63026"
                    animation-type="scale">
                   <i class='bx bx-heart'></i>
                    <template #animate >
                    Add to Favorites
                    </template>
                </vs-button>
                <vs-button
                    color="#45c93a"
                    animation-type="scale">
                <i class='bx bxs-conversation' ></i>
                    <template #animate >
                    Chat Now
                    </template>
                </vs-button>
                <vs-button
                    color="#00bfff"
                    animation-type="scale">
                    Send Inquiry
                </vs-button>
                <vs-button
                    color="#00bfff"
                    animation-type="scale">
                    <i class='bx bxs-user-plus'></i>
                    Follow
                </vs-button>
                   </div>
                 </div>
                 </div>
             </div>
            </template>
         </div>
         <div class="col-12 mt-5">
             <template>
     <div class="center">
       <vs-pagination v-model="page" :length="5" color="#00bfff" />
     </div>
   </template>
         </div>
          <!-- single box products end -->
     </div>
    </div>
     </template>
     <script>
     export default {
       name: "ProductsTabArea",
       data(){
         return{
             active:0,
             page:1,
             rate_val:4,
             time: 120 * 60 * 60 * 1000,
         }
       }
     }
     </script>
     <style lang="scss" scoped>
     .box_productTab{
         margin-bottom: 14px;
         display: flex;
         align-items: flex-start;
         justify-content: space-between;
         background-color: white;
         padding: 10px 10px;
         border-radius: 8px;
         flex-wrap: wrap;
         .slider_left{
             flex: 0 0 250px;
         }
         .prod_info{
             padding-left: 10px;
             flex: 0 0 calc(100% - 250px);
            //  border-right: 1px dashed gainsboro;
             .title{
                display: flex;
                align-items: center;
                justify-content: space-between;
                 margin-bottom: 12px;
                 h2{
                     font-size: 20px;
                     color: deepskyblue;
                     .status{
                        font-size: 15px;
                        border: 1px solid #666666;
                        color: #666666;
                        padding: 2px 18px;
                        border-radius: 3px;
                        margin-right: 10px;
                        display: inline-block;

                     }
                 }
                 .date_title{
                    font-size: 14px;
                    color: deepskyblue;
                    i{
                        padding-right: 4px;
                        vertical-align: middle;
                        margin-top: -4px;
                       
                    }
                 }
             }
             .fob_price{
                 display: flex;
                 font-size: 14px;
                 color: black;
                 .single_fob{
                    display: flex;
                    align-items: center;
                    &:last-child{
                        &::after{
                            display: none;
                        }
                    }
                    &::after{
                        content: '|';
                        padding: 0px 8px;
                        opacity: 0.5;
                    }
                 }
                 .key_fob{
                     margin-right: 10px;
                     opacity: 0.6;
                 }
                 .val_fob{
                     font-weight: 500;
                 }
             }
             .parg{
                p{
                    font-size: 14px;
                    opacity: 0.8;
                    margin-top: 4px;
                }
             }
             .properites{
                 display: flex;
                 align-items: center;
                 justify-content: space-between;
                 flex-wrap: wrap;
                 margin-top: 14px;
                 border-bottom: 1px dashed gainsboro;
                 padding-bottom: 5px;
                 .single_prop{
                     flex:0 0 50%;
                     display: flex;
                     align-items: center;
                     justify-content: space-between;
                     font-size: 13px;
                     margin-bottom: 8px;
                     padding-right: 15px;
                     .prob_key{
                         width: 45%;
                         opacity: 0.6;
                     }
                     .prob_val{
                         font-weight: 500;
                         .prob_flag{
                            img{
                                width: 24px;
                                height: 24px;
                                object-fit: contain;
                            }
                         }
                     }
                 }
             }
            //  
            
            .badage_users{
                .badge_up{
                padding-top: 16px;
                display: flex;
                align-items: center;
                }
                .badge_down{
                    padding: 14px 0px;
                    display: flex;
                    align-items: center;
                }
                .user-bad{
                    font-size: 14px;
                    font-weight: 600;
                    margin-right: 14px;
                    i{
                        vertical-align: middle;
                        margin-top: -5px;
                        margin-right: 4px;
                        font-size: 17px;
                    }
                    img{
                        vertical-align: middle;
                        object-fit: contain;
                        margin-left: 8px;
                        margin-top: -4px;
                    }
                }
                .ratings{
                    span{
                        padding-right: 5px;
                    }
                }
         }
         }
       
     }
     .abs_buttons{
                button{
                    color: black;
                    display: flex;
                    justify-content: space-between;
                    font-size: 13px;
                    i{
                        vertical-align: middle;
                        margin-right: 6px;
                        font-size: 16px;
                    }
                }
            }
    .BannerArea_section{
        margin: 14px 0px 25px 0px;
    }
     </style>