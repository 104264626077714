<template>
  <div class="side_filter_wrap" v-if="loadPage">
      <div class="bords">
        <router-link to="/">Home</router-link> 
        <span class="chev-ri"><i class='bx bx-chevron-right'></i></span>
        <router-link class="active" to="">Filter</router-link>
      </div>
      <!-- collapse filter -->
      <div class="collapse_filter">
          <van-collapse  v-model="active_collapse" :border="false" type="default">
          <div class="box_check1">
        <van-collapse-item  name="1" title=" Category">
         <div class="flex_checkbox">
          <van-checkbox-group v-model="categoryChecked" @change.native="onChangeCategory">
            <div class="single_check" v-for="category in get_category_list" :key="category.id">
              <van-checkbox shape="square" checked-color="#00bfff"  :name="category.id">
                <div class="flex_data">
              <span class="name">{{ category.name }}</span>
            </div>
              </van-checkbox>
            </div>
        </van-checkbox-group>
         </div>
       </van-collapse-item>
      </div>
       <!-- range price -->
       <div class="box_check1 pd2">
       <van-collapse-item  name="2" title="Budget">
         <div class="flex_checkbox">
          <div class="single_check">
            <div class="slider_price">
              <div class="flex_bud">
                <span>{{ budget[0] }} USD</span>
                <span>{{ budget[1] }} USD</span>
              </div>
      <!-- <vs-slider :step='1' @mouseup="onchangeBudget" @touchend="onchangeBudget" v-model="budget" color="#00bfff" text-fixed='SAR' :max="10000" /> -->
      <!-- <vue-slider style="margin-top:35px" v-bind="options"  :order="false" v-model="budget"
      ></vue-slider>  -->
      <van-slider style="margin-top:30px" v-model="budget" :max="50000" range @change="onChange" />



    </div>
         </div>
         </div>
       </van-collapse-item>
      </div>
      <!-- period time -->
      <div class="box_check1 pd2">
       <van-collapse-item  name="3" title="Business Type">
         <div class="flex_checkbox">
          <van-checkbox-group v-model="projectPeriodChecked" @change.native="onchangePeriod">
            <!-- v-for="period in periodList" -->
            <div class="single_check" v-for="period in periodList" :key="period.id">
              <van-checkbox shape="square" checked-color="#00bfff"  :name="period.id">
                <div class="flex_data">
              <span class="name">  {{ period.text }}</span>
            </div>
              </van-checkbox>
            </div>
        </van-checkbox-group>
         </div>
       </van-collapse-item>
      </div>
      <!--  -->
      <div class="box_check1 pd2">
       <van-collapse-item  name="5"  title="Brand">
         <div class="flex_checkbox">
          <van-radio-group v-model="brandList">
            <div class="single_check" >
              <van-radio shape="square" checked-color="#00bfff"  :name="1" label-position="right">
                <div class="flex_data">
              <span class="name">Philips</span>
            </div>
              </van-radio>
            </div>
            <div class="single_check">
              <van-radio shape="square" checked-color="#00bfff"  :name="2" label-position="right">
                <div class="flex_data">
              <span class="name"> Toshiba</span>
            </div>
              </van-radio>
            </div>
            <div class="single_check">
              <van-radio shape="square" checked-color="#00bfff"  :name="3" label-position="right">
                <div class="flex_data">
              <span class="name">LG</span>
            </div>
              </van-radio>
            </div>
            <div class="single_check">
              <van-radio shape="square" checked-color="#00bfff"  :name="4" label-position="right">
                <div class="flex_data">
              <span class="name">Others</span>
            </div>
              </van-radio>
            </div>
        </van-radio-group>
         </div>
       </van-collapse-item>
      </div>
      <!--  -->
      <div class="box_check1 pd2">
       <van-collapse-item  name="6"  title=" Supplier Country">
         <div class="flex_checkbox">
          <van-radio-group v-model="brandList">
            <div class="single_check" >
              <van-radio shape="square" checked-color="#00bfff"  :name="1" label-position="right">
                <div class="flex_data">
              <span class="name">USA</span>
            </div>
              </van-radio>
            </div>
            <div class="single_check">
              <van-radio shape="square" checked-color="#00bfff"  :name="2" label-position="right">
                <div class="flex_data">
              <span class="name"> Japan</span>
            </div>
              </van-radio>
            </div>
            <div class="single_check">
              <van-radio shape="square" checked-color="#00bfff"  :name="3" label-position="right">
                <div class="flex_data">
              <span class="name">Russia</span>
            </div>
              </van-radio>
            </div>
            <div class="single_check">
              <van-radio shape="square" checked-color="#00bfff"  :name="4" label-position="right">
                <div class="flex_data">
              <span class="name">Jordan</span>
            </div>
              </van-radio>
            </div>
        </van-radio-group>
         </div>
       </van-collapse-item>
      </div>
      <div class="box_check1 pd2">
       <van-collapse-item  name="7"  title="Job Type">
         <div class="flex_checkbox">
          <van-radio-group v-model="jopType">
            <div class="single_check" >
              <van-radio shape="square" checked-color="#00bfff"  :name="1" label-position="right">
                <div class="flex_data">
              <span class="name">ALL</span>
            </div>
              </van-radio>
            </div>
            <div class="single_check">
              <van-radio shape="square" checked-color="#00bfff"  :name="2" label-position="right">
                <div class="flex_data">
              <span class="name">Job Seekers</span>
            </div>
              </van-radio>
            </div>
            <div class="single_check">
              <van-radio shape="square" checked-color="#00bfff"  :name="3" label-position="right">
                <div class="flex_data">
              <span class="name">Job Vacancies</span>
            </div>
              </van-radio>
            </div>
        </van-radio-group>
         </div>
       </van-collapse-item>
      </div>
       <!-- Search by -->
       <div class="box_check1 pd2 last">
       <van-collapse-item  name="4"  title="Sort by">
         <div class="flex_checkbox">
          <van-radio-group v-model="projectSortChecked">
            <div class="single_check" >
              <van-radio shape="square" checked-color="#00bfff"  :name="1" label-position="right">
                <div class="flex_data">
              <span class="name"> Ascending Order</span>
            </div>
              </van-radio>
            </div>
            <div class="single_check">
              <van-radio shape="square" checked-color="#00bfff"  :name="2" label-position="right">
                <div class="flex_data">
              <span class="name"> Descending Order</span>
            </div>
              </van-radio>
            </div>
        </van-radio-group>
         </div>
       </van-collapse-item>
      </div>
      </van-collapse>
      </div>
    </div>
    </template>
    <script>
    export default {
      name: "AboutArea",
      data(){
        return{
          get_category_list:     [
    {
        "id": 2,
        "name": "Ultrasound",
        "slug": "smart-home",
        "image": "https://dashboard.boardfreelancers.com/public/uploads/categories/OSzzxQWX78IpxY22JzZdIwrbXLFWzC6LfTZF5ip5.jpg"
    },
    {
        "id": 3,
        "name": "Veterinary",
        "slug": "watches",
        "image": "https://dashboard.boardfreelancers.com/public/uploads/categories/oCHQlTnAthf7DLisgYjiFe2kzS99VmmaCUa7YC4X.jpg"
    },
    {
        "id": 12,
        "name": "Catgeory Name 3",
        "slug": "test",
        "image": "https://dashboard.boardfreelancers.com/public/uploads/categories/Oil0Pg7BQQpmYgdGDXIvRL91dfNqXMXtbuMr2kih.jpg"
    },
    {
        "id": 13,
        "name": "Catgeory Name 4",
        "slug": "mobile-apps",
        "image": "https://dashboard.boardfreelancers.com/public/uploads/categories/DJ3rg4gBGaz5kOvV40bIZL86SQeZEoiNUddU0IJy.jpg"
    }],
    categoryChecked:[],
    budget:[1000,25000],
     value: 15,
       options: {
        dotSize: 14,
        width: 'auto',
        height: 4,
        contained: false,
        direction: localStorage.getItem("base_lang") == "Ar" ? 'rtl' : 'ltr' ,
        reverse: localStorage.getItem("base_lang") == "Ar" ? true : false,
	      data: null,
        dataLabel: 'label',
        dataValue: 'value',
        min: 0,
        max: 5000,
        interval: 1,
        disabled: false,
        clickable: true,
        duration: 0.5,
        adsorb: false,
        lazy: false,
        tooltip: 'active',
        tooltipPlacement: 'top',
        useKeyboard: false,
        keydownHook: null,
        dragOnClick: false,
        enableCross: true,
        fixed: false,
        minRange: void 0,
        maxRange: void 0,
        order: true,
        marks: false,
        dotOptions: void 0,
        dotAttrs: void 0,
        process: true,
        dotStyle: void 0,
        railStyle: void 0,
        processStyle: void 0,
        tooltipStyle: void 0,
        stepStyle: void 0,
        stepActiveStyle: void 0,
        labelStyle: void 0,
        labelActiveStyle: void 0,
      },
      periodList:[
          {'id':1 , text: 'Trade Company'},
          {'id':2 , text:'Manufacturer'},
          {'id':3 , text:'Broker'},
          {'id':4 , text:'Agency'},
          {'id':5 , text:'Hospital'},
          {'id':6 , text:'Others'},
        ],
        projectPeriodChecked:[],
        brandList:[],
        active_collapse:['1' , '2' , '3' , '4' , '5', '6' , '7'],
        projectSortChecked:1,
        loadPage:true,
        jopType:1
        }
      },
      components:{
      },
      methods:{
        onchangeBudget(){
        },
        onchangePeriod(){
        },
        onChange(value) {
          console.log(value)
      // Toast('current value：' + value);
    },
      }
    }
    </script>
     <style lang="scss" scoped>
     .side_filter_wrap{
       background-color: white;
       // border: 1px solid gainsboro;
       min-height: 100vh;
       padding: 25px 20px 20px 20px;
       border-radius: 20px;
       .chev-ri{
        i{
          vertical-align: middle;
          opacity: 0.4;
        }
       }
       .bords{
         display: flex;
         align-items: center;
         color: black;
         span{
           font-style: normal;
           font-weight: 400;
           font-size: 24px;
           line-height: 24px;
           margin: 0px 14px;
         }
         a{
           font-style: normal;
           font-weight: 400;
           font-size: 16px;
           line-height: 24px;
           color: #000;
         }
         a.active{
           font-weight: 600;
         }
       }
       // collpase
       
       .collapse_filter{
         padding-top: 20px;
         .box_check1{
           position: relative;
           padding-bottom: 20px;
          &::after{
           content: '';
           position: absolute;
           left: 0;
           bottom: 0;
           border: 1px solid rgba(161, 166, 173, 0.18);
     box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
     filter: blur(2px);
     width: 100%;
     height: 2px;
          }
         }
         .last{
           &::after{
             display: none;
           }
         }
         .titleCollapse{
         font-style: normal;
     font-weight: 700;
     font-size: 15px;
     line-height: 24px;
       }
       .flex_data{
         width: 100%;
         display: flex;
         align-items: center;
         justify-content: space-between;
         // 
         font-style: normal;
     font-weight: 400;
     font-size: 14px;
     line-height: 21px;
       }
       .pd2{
         padding-top: 12px;
         padding-bottom: 30px;
       }
       // price
       .slider_price{
         .flex_bud{
           display: flex;
           align-items: center;
           justify-content: space-between;
           span{
             font-style: normal;
             font-weight: 400;
             font-size: 14px;
             line-height: 21px;
             color: #A1A6AD;
           }
         }
       }
       .single_check{
        margin-bottom: 14px;
       }
       // colors
       .colors_check{
         display: flex;
         align-items: center;
         justify-content: space-between;
         flex-wrap: wrap;
         .single_check{
           flex: 0 0 22%;
           margin-bottom: 8px;
         }
       }
       .check-color {
       visibility: hidden;
       position: absolute;
       right: 0;
       appearance: none;
       -webkit-appearance: none;
       -moz-appearance: none;
     }
     .circle_color{
       width: 45px;
         height: 45px;
         border-radius: 100%;
         background-color: #FAD9D4;
         display: flex;
         align-items: center;
         justify-content: center;
         font-size: 23px;
         .main_colors{
           width: 35px;
           height: 35px;
            border-radius: 100%;
            display: flex;
           justify-content: center;
           align-items: center;
           color: white;
           i{
             transition: 0.2s ease-in-out;
           }
         }
     }
       }
     }
     </style>
     