<template>
  <div class="map_company_wraps">
    <h3 class="tabname">Trademarks</h3>
    <div class="branches_wrap">
      <div class="center examplex social_share">
        <vs-table striped>
          <template #thead>
            <vs-tr>
              <vs-th> Id </vs-th>
              <vs-th> Name </vs-th>
              <vs-th class="just_center"> Action </vs-th>
            </vs-tr>
          </template>
          <template #tbody>
            <vs-tr :key="i" v-for="(tr, i) in users" :data="tr">
              <vs-td>
                {{ tr.id }}
              </vs-td>
              <vs-td expand>
                {{ tr.name }}
              </vs-td>
              <vs-td class="just_center">
                <vs-button icon relief danger circle animation-type="rotate">
                  <i class="bx bx-trash"></i>
                  <template #animate>
                    <i class="bx bxs-trash"></i>
                  </template>
                </vs-button>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </div>
      <div class="row">
        <div class="col-md-4 col-12 mr-auto ml-auto mrgt">
          <vs-button gradient v-show="!toggle_active" @click="toggle_form()"
            ><i class="bx bxs-folder-plus bx-tada mr-2"></i> Add Trademarks
            Information
          </vs-button>
          <vs-button
            gradient
            danger
            v-show="toggle_active"
            @click="toggle_form()"
            ><i class="bx bx-window-close bx-tada mr-2"></i> Close
          </vs-button>
        </div>
      </div>
      <!-- input form  -->
      <div class="form_wraps" v-show="toggle_active">
        <div class="row">
          <div class="col-md-12 mrgb">
            <b-field label="Registration/Filing No.">
              <vs-input placeholder="" />
            </b-field>
          </div>
          <div class="col-md-12 mrgb">
            <b-field label="Trademark Name">
              <vs-input placeholder="" />
            </b-field>
          </div>
          <div class="col-md-12 mrgb">
            <b-field label="Start Date">
              <b-datepicker
                placeholder="(ex) 2006/12/31"
                icon="calendar-today"
                trap-focus
                required
              >
              </b-datepicker>
            </b-field>
          </div>
          <div class="col-md-12 mrgb">
            <b-field label="End Date (validity period)">
              <b-datepicker
                placeholder="(ex) 2022/12/31"
                icon="calendar-today"
                trap-focus
                required
              >
              </b-datepicker>
            </b-field>
          </div>
          <div class="col-md-12 mrgb">
            <b-field label="Description">
              <b-input type="textarea" maxlength="1024" placeholder=""></b-input>
            </b-field>
          </div>
          <div class="col-md-12 mrgb2">
            <b-field label="Trademark Image" class="upload_img">
              <b-field>
                <!--  -->
                <b-upload v-model="dropFiles" drag-drop expanded required>
                  <section class="section">
                    <div class="content has-text-centered">
                      <p class="uploadIco">
                        <i class="bx bxs-cloud-upload"></i>
                      </p>
                      <p>Upload Trademark Image</p>
                    </div>
                  </section>
                </b-upload>
              </b-field>
              <p class="sp_desc danger danger_c">
                Recommended image size: 000000 X 00000 pixel.
              </p>
              <div class="tags">
                <span v-if="dropFiles" class="tag is-primary">
                  {{ dropFiles.name }}
                  <button
                    class="delete is-small"
                    type="button"
                    @click="deleteDropFile()"
                  ></button>
                </span>
              </div>
            </b-field>
          </div>
          <div class="col-md-4 col-12 mr-auto ml-auto mrgt">
            <vs-button gradient>Save My Data</vs-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "certificates_profile",
  data() {
    return {
      users: [
        {
          id: 1,
          name: "Trademark Name 1",
        },
        {
          id: 2,
          name: "Trademark Name 2",
        },
      ],
      dropFiles: null,
      toggle_active: false,
    };
  },
  computed: {
    ...mapGetters({}),
  },
  methods: {
    ...mapActions({}),
    deleteDropFile() {
      this.dropFiles = null;
    },
    toggle_form() {
      this.toggle_active = !this.toggle_active;
    },
  },
  mounted() {},
};
</script>
<style lang="scss">
.map_company_wraps {
  padding-top: 20px;
  .mrgb {
    margin-bottom: 14px;
    position: relative;
  }
  .mrgt {
    margin-top: 25px;
  }
  .tabname {
    font-size: 18px;
    font-weight: 600;
    margin: 0;
    padding: 0;
    margin-bottom: 20px;
  }
  .just_center {
    text-align: center;
    .vs-table__th__content {
      justify-content: center;
    }
  }
}
</style>
