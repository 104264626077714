<template>
    <footer>
        <div class="row pd_row">
            <div class="col-lg-3">
                <div class="logo_desc">
                    <img src="https://upload.wikimedia.org/wikipedia/commons/8/85/Logo-Test.png" alt="">
                    <p>Medical.com is the world's leading andfastest
                        growing online business-to-business(B2B)
                        marketplace that connects small and medium
                        businesses across the globe for international trade.</p>
                </div>
            </div>
            <div class="col-lg-2">
                <div class="box_list">
                    <h2>Premium Services</h2>
                     <div class="list_link">
                    <a href="#">Membership Pricing</a>
                     <a href="#">Trade Show</a>
                      <a href="#">Companies</a>
                       <a href="#">Tenders</a>
                        <a href="#">Auction</a>
                </div>
                </div>
            </div>
            <div class="col-lg-2">
                  <div class="box_list">
                    <h2>Featured Services</h2>
                <div class="list_link">
                    <a href="#">Seller Guide</a>
                     <a href="#">Buyer Guide</a>
                      <a href="#">Policies & rules</a>
                       <a href="#">FAQ</a>
                </div>
                  </div>
            </div>
            <div class="col-lg-2">
                  <div class="box_list">
                     <h2>Customer services</h2>
                <div class="list_link">
                    <a href="#">Help Center</a>
                     <a href="#">RFQ</a>
                      <a href="#">Trade Alert</a>
                       <a href="#">Advertise with us</a>
                        <a href="#">Forum</a>
                </div>
                  </div>
            </div>
            <div class="col-lg-3">
                <div class="subscripe">
                    <h2>Subsicripe Now</h2>
                    <div class="row mtt_sub">
                        <div class="col-lg-8">
                             <vs-input  placeholder="Your Email" />
                        </div>
                        <div class="col-lg-4">
                            <vs-button gradient>Subscribe</vs-button>
                        </div>
                    </div>
                    <p>We’ll never share your email address with a third-party.</p>
                </div>
            </div>
            <!-- new row -->
            <div class="col-lg-5"></div>
            <div class="col-lg-2">
                   <div class="box_list">
                     <h2>Follow Us</h2>
                <div class="list_link social_foot">
                    <a href="#"><i class='bx bxl-facebook-circle' ></i>facebook</a>
                     <a href="#"><i class='bx bxl-instagram'></i>instagram</a>
                      <a href="#"><i class='bx bxl-linkedin' ></i>linkedin</a>
                       <a href="#"><i class='bx bxl-twitter' ></i>twitter</a>
                        <a href="#"><i class='bx bxl-blogger' ></i>blogger</a>
                </div>
                  </div>
            </div>
             <div class="col-lg-2">
                   <div class="box_list">
                     <h2>Contact Us</h2>
                <div class="list_link social_foot">
                    <a href="#"><i class='bx bxl-whatsapp' ></i>whatsapp</a>
                     <a href="#"><i class='bx bxl-telegram' ></i>telegram</a>
                     <a href="#"><i class='bx bx-mail-send' ></i>e-Mail</a>
                </div>
                  </div>
            </div>
            <div class="col-lg-3">
                  <div class="box_list">
                     <h2>Download</h2>
                    <div class="flex_apps">
                        <a class="google" href="#">
                            <img src="https://hf-files-oregon.s3.amazonaws.com/hdpconcord_kb_attachments/2020/09-18/9501d8c2-babe-4f54-8df8-92692dfbc657/google-play-badge.png" alt="">
                        </a>
                         <a class="apple" href="#">
                            <img src="https://www.freepngimg.com/download/iphone/68600-play-google-apple-app-iphone-store.png" alt="">
                        </a>
                    </div>
                  </div>
            </div>
            <div class="col-12">
                <div class="flex_link_foot">
                    <a href="#">Home</a>
                    <a href="#">Join us for free</a>
                    <a href="#">Statistics</a>
                    <a href="#">Category</a>
                    <a href="#">Blog</a>
                    <a href="#">Career</a>
                    <a href="#">Suppliers by Region</a>
                    <a href="#">Sitemap</a>
                    <a href="#">Logistics services</a>
                    <a href="#">Parts</a>
                    <a href="#">Services</a>
                    <a href="#">About Us</a>
                </div>
                <div class="lang_foot">
                    <span>Languages:</span>
                    <a href="#">English</a>
                    <a href="#">العربية</a>
                    <a href="#">Español</a>
                    <a href="#">한국어</a>
                    <a href="#">Pусский</a>
                    <a href="#">中文</a>
                    <a href="#">日本語</a>
                </div>
            </div>
        </div>
        <div class="row">
             <div class="col-12">
                <div class="copy">
                    <p>Copyright © 2023 med.com</p>
                </div>
            </div>
        </div>
    </footer>
</template>
<script>


export default {
  name: "footerApp",
  components: {},
  data(){
    return{}
  },
  methods:{}
};
</script>

<style scoped lang="scss">
footer{
       padding: 40px 0px 0px 0px;
        background-color: #ededed;
    margin-top: 80px;
    .pd_row{
        padding: 0px 10px;
    }
.logo_desc{
    img{
        height: 100px;
        object-fit: contain;
    }
    p{
        margin-top: -14px;
        font-size: 14px;
        // opacity: 0.8;
    }
}
.box_list{
    margin-top: 35px;
    h2{
        font-size: 1rem;
        font-weight: 500;
        position: relative;
        margin-bottom: 20px;
        &::after{
            content: "";
            position: absolute;
            left: 0;
            bottom: -4px;
            width: 14%;
            height: 1.5px;
            background-color: blue;
        }
    }
.list_link{
        display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    a{
        color: black;
        opacity: 0.8;
        font-size: 14px;
        margin-bottom: 8px;
    }
}
}
.subscripe{
    margin-top: 35px;
     h2{
        font-size: 1rem;
        font-weight: 500;
        position: relative;
        margin-bottom: 12px;
        &::after{
            content: "";
            position: absolute;
            left: 0;
            bottom: -4px;
            width: 14%;
            height: 1.5px;
            background-color: blue;
        }
    }
    p{
        font-size: 12px;
        margin-top: 10px;
    }
.mtt_sub{
    padding-top: 10px;
}
}
.social_foot{
    a{
        margin-bottom: 8px;
            display: flex;
    align-items: center;
    text-transform: capitalize;
    }
    i{
       font-size: 20px;
    background-color: #3357fe;
    color: white;
    border-radius: 100px;
    width: 30px;
    height: 30px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-right: 6px;
    }
}
.flex_apps{
    display: flex;
    align-items: center;
    a{
        flex: 0 0 50%;
    }
    a.google{
        img{
               scale: 1.1;
        }
    }
    a.apple{
        img{
            //   height: 120px;
        }
    }
    img{
    object-fit: contain;
    }
}
.flex_link_foot{
       display: flex;
    width: 84%;
    margin: 0 auto;
    border-top: 1px solid #c8c8c8;
    margin-top: 20px;
    padding-top: 14px;
    justify-content: space-between;

    a{
           color: black;
    display: inline-block;
    margin-right: 8px;
    opacity: 0.5;
    font-size: 14px;
    }
}
}
.lang_foot{
    text-align: center;
    margin-top: 14px;
    text-align: center;
     span{
         color: black;
    display: inline-block;
    margin-right: 14px;
    opacity: 0.5;
    font-size: 14px;
     }
     a{
           color: black;
    display: inline-block;
    margin-right: 8px;
    opacity: 0.5;
    font-size: 14px;
    }
}
.copy{
        background: linear-gradient(45deg, black, #525358);
    color: white;
    padding: 14px;
    font-size: 14px;
    text-align: center;
    margin-top: 30px;
}
</style>