<template>
    <div class="jobs_wraps">
      <h3 class="tabname">My Career Page</h3>
      <van-tabs v-model="active" type="card">
  <van-tab :title="'Job Seekers'">
   <div class="contant_tab">
    
      <van-checkbox-group v-model="result1" ref="checkboxGroup">
        <div class="row controll_up">
          <div class="col-6">
           <div class="all_wrap">
            <van-checkbox v-model="selectAll" @click="changeStatus()" checked-color="#1d9bf0">Select All</van-checkbox>
           </div>
          </div>
          <div class="col-6">
            <div class="delete_wrap">
              <van-button type="danger" round>Delete</van-button>
            </div>
          </div>
        </div>
        <div class="row">
          <template v-for="index in 12">
            <div class="col-md-6 col-12 mt-5" :key="index">
          <van-checkbox checked-color="#1d9bf0" :name="index">
        <van-card
        thumb="https://plus.unsplash.com/premium_photo-1661660007558-68f36eb2f5b1?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2940&q=80">
        <template #title>
        <span class="titleCard">Software engineer</span>
        </template>
        <template #desc>
        <span class="d-block">
            <p>  Must have JavaScript in addition to the VueJs framework</p>
            <p>work address Amman, Jordan, Queen Rania Street</p>
        </span>
        </template>
        <!-- <template #tags>
            <van-tag class="tagui" type="success">Html</van-tag>
            <van-tag class="tagui" type="success">Css</van-tag>
            <van-tag class="tagui" type="success">JS</van-tag>
            <van-tag class="tagui" type="success">VueJs</van-tag>
        </template> -->
        <!-- <template #price>
        <span class="d-block addressUi"></span>
        </template> -->
        
        </van-card>
        <!-- <van-card 
        num="Job Seekers"
        tag="Job Seekers"
        price="work address Amman, Jordan, Queen Rania Street"
        title="Software engineer"
        desc="work address Amman, Jordan, Queen Rania Street"
        thumb="https://img01.yzcdn.cn/vant/ipad.jpeg"
        /> -->
          </van-checkbox>
          </div>
    </template>
        </div>
  </van-checkbox-group>
   </div>
  </van-tab>
  <van-tab :title="'Job Vacancies'">
   <div class="contant_tab">
    
      <van-checkbox-group v-model="result1" ref="checkboxGroup">
        <div class="row controll_up">
          <div class="col-6">
           <div class="all_wrap">
            <van-checkbox v-model="selectAll" @click="changeStatus()" checked-color="#1d9bf0">Select All</van-checkbox>
           </div>
          </div>
          <div class="col-6">
            <div class="delete_wrap">
              <van-button type="danger" round>Delete</van-button>
            </div>
          </div>
        </div>
        <div class="row">
          <template v-for="index in 12">
            <div class="col-md-6 col-12 mt-5" :key="index">
          <van-checkbox checked-color="#1d9bf0" :name="index">
        <van-card
        thumb="https://images.unsplash.com/photo-1593720213428-28a5b9e94613?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2940&q=80">
        <template #title>
        <span class="titleCard">Web Developer</span>
        </template>
        <template #desc>
        <span class="d-block">
            <p>  Must have JavaScript in addition to the VueJs framework</p>
            <p>work address Amman, Jordan, Queen Rania Street</p>
        </span>
        </template>
        </van-card>
          </van-checkbox>
          </div>
    </template>
        </div>
  </van-checkbox-group>
   </div>
  </van-tab>
  <van-tab :title="'Training offerd'">
   <div class="contant_tab">   
      <van-checkbox-group v-model="result1" ref="checkboxGroup">
        <div class="row controll_up">
          <div class="col-6">
           <div class="all_wrap">
            <van-checkbox v-model="selectAll" @click="changeStatus()" checked-color="#1d9bf0">Select All</van-checkbox>
           </div>
          </div>
          <div class="col-6">
            <div class="delete_wrap">
              <van-button type="danger" round>Delete</van-button>
            </div>
          </div>
        </div>
        <div class="row">
          <template v-for="index in 12">
            <div class="col-md-6 col-12 mt-5" :key="index">
          <van-checkbox checked-color="#1d9bf0" :name="index">
        <van-card
        thumb="https://images.unsplash.com/photo-1579412690850-bd41cd0af397?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2865&q=80">
        <template #title>
        <span class="titleCard">Content writer</span>
        </template>
        <template #desc>
        <span class="d-block">
            <p>  Must have JavaScript in addition to the VueJs framework</p>
            <p>work address Amman, Jordan, Queen Rania Street</p>
        </span>
        </template>
        </van-card>
          </van-checkbox>
          </div>
    </template>
        </div>
  </van-checkbox-group>
   </div>
  </van-tab>
  <van-tab :title="'Training Wanted'">
   <div class="contant_tab">   
      <van-checkbox-group v-model="result1" ref="checkboxGroup">
        <div class="row controll_up">
          <div class="col-6">
           <div class="all_wrap">
            <van-checkbox v-model="selectAll" @click="changeStatus()" checked-color="#1d9bf0">Select All</van-checkbox>
           </div>
          </div>
          <div class="col-6">
            <div class="delete_wrap">
              <van-button type="danger" round>Delete</van-button>
            </div>
          </div>
        </div>
        <div class="row">
          <template v-for="index in 12">
            <div class="col-md-6 col-12 mt-5" :key="index">
          <van-checkbox checked-color="#1d9bf0" :name="index">
        <van-card
        thumb="https://images.unsplash.com/photo-1580894908361-967195033215?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2940&q=80">
        <template #title>
        <span class="titleCard">Content writer</span>
        </template>
        <template #desc>
        <span class="d-block">
            <p>  Must have JavaScript in addition to the VueJs framework</p>
            <p>work address Amman, Jordan, Queen Rania Street</p>
        </span>
        </template>
        </van-card>
          </van-checkbox>
          </div>
    </template>
        </div>
  </van-checkbox-group>
   </div>
  </van-tab>

        </van-tabs>
    </div>
  </template>
  <script>
  export default {
    name: "jobsProfile",
    data() {
      return {
        active:0,
        result1:[],
        selectAll:false,
      }
    },
    computed: {
    
    },
    watch: {
    },
    methods: {
      changeStatus(){
        if(this.selectAll == false){
          this.$refs.checkboxGroup.toggleAll(true);
        }else{
          this.$refs.checkboxGroup.toggleAll(false);
        }
        this.selectAll= !this.selectAll;
      }
    },
    mounted() {},
  };
  </script>
  <style lang="scss">
  .jobs_wraps {
    padding-top: 20px;
    .mrgb {
      margin-bottom: 14px;
      position: relative;
    }
    .mrgt {
      margin-top: 25px;
    }
    .tabname {
      font-size: 18px;
      font-weight: 600;
      margin: 0;
      padding: 0;
      margin-bottom: 20px;
    }
    
  }
  .jobsArea_wraps{
    min-height: 100vh;
    padding-top: 180px;
    .van-tab__pane, .van-tab__pane-wrapper {
    background-color: #f6f8fa;
}
.van-tabs__nav--card .van-tab.van-tab--active {
    font-weight: 700;
}
.van-card {
    color: #323233;
    font-size: 12px;
    background-color: white;
    margin-bottom: 30px;
}
.titleCard{
    font-weight: 600;
    font-size: 15px;
    display: block;
}
.tagui{
    margin-right: 5px;
    margin-top: 4px;
}
.addressUi{
    opacity: 0.5;
}
}
.controll_up{
  font-size: 14px;
  align-items: center;
  .delete_wrap{
    text-align: right;
    button{
      min-width: 200px;
    }
  }
}
  </style>
  