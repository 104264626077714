<template>
  <div class="dashoard_user_info">
   <h3 class="tabname">Business Information</h3>
   <div class="personal_wraps">
    <!-- input form  -->
      <div class="form_wraps">
        <div class="row">
             <div class="col-md-12 mrgb">
                 <b-field label="Company Name">
           <vs-input placeholder="Google , Apple , etc.." />
                </b-field>
            </div>
            <div class="col-md-6 mrgb">
                <b-field label="Business Type *">
             <vs-select placeholder="Select" v-model="value">
        <vs-option label="Type 1" value="1">
          Type 1
        </vs-option>
        <vs-option label="Type 2" value="2">
          Type 2
        </vs-option>
        <vs-option label="Type 3" value="3">
          Type 3
        </vs-option>
        <vs-option  label="Type 4" value="4">
          Type 4
        </vs-option>
        <vs-option label="Type 5" value="5">
          Type 5
        </vs-option>
        <vs-option label="Type 6" value="6">
          Type 6
        </vs-option>
        <vs-option label="Type 7" value="7">
          Type 7
        </vs-option>
      </vs-select>
        </b-field>
            </div>
            <div class="col-md-6 mrgb">
                <div class="center center_flex">
        <vs-checkbox  v-model="option1">
         OFFICIAL DISTRIBUTOR
      </vs-checkbox>
      <vs-checkbox class="last_check" success v-model="option2">
         Brand
      </vs-checkbox>
                </div>
            </div>
           <div class="col-md-12 mrgb">
                <b-field label="Select / Enter Brand">
             <vs-select placeholder="Select / Enter Brand" v-model="value">
        <vs-option label="Type 1" value="1">
          Type 1
        </vs-option>
        <vs-option label="Type 2" value="2">
          Type 2
        </vs-option>
        <vs-option label="Type 3" value="3">
          Type 3
        </vs-option>
        <vs-option  label="Type 4" value="4">
          Type 4
        </vs-option>
        <vs-option label="Type 5" value="5">
          Type 5
        </vs-option>
        <vs-option label="Type 6" value="6">
          Type 6
        </vs-option>
        <vs-option label="Type 7" value="7">
          Type 7
        </vs-option>
      </vs-select>
        </b-field>
            </div> 
            <div class="col-md-12 mrgb">
                 <b-field label="Main Product Keywords">
          <vs-input placeholder="Products Name" />
        </b-field>
        <span class="change_span">+ Add main product <span style="color:#000;display:inline-block;padding-left:10px">(Up to 20)</span></span>
            </div>
             <div class="col-md-12 mrgb">
                 <b-field label="Year Established *	">
           <vs-input placeholder="ex: 1982..." />
                </b-field>
            </div>
            <div class="col-md-12 mrgb">
                 <b-field label="Employees Total *">
           <vs-input placeholder="ex: 25" />
                </b-field>
            </div>
             <div class="col-md-12 mrgb">
                 <b-field label="Annual Revenue *">
           <vs-input placeholder="ex: 25900" />
                </b-field>
            </div>
             <div class="col-md-12 mrgb">
                 <b-field label="Export Market">
                      <div class="center center_flex">
                        <vs-checkbox  v-model="option3">
                        South America
                    </vs-checkbox>
                    <vs-checkbox class="last_check" v-model="option4">
                        North America
                    </vs-checkbox>
                     <vs-checkbox class="last_check" v-model="option5">
                        China
                    </vs-checkbox>
                     <vs-checkbox class="last_check" v-model="option6">
                        Europe
                    </vs-checkbox>
                     <vs-checkbox class="last_check" v-model="option7">
                        Asia
                    </vs-checkbox>
                     <vs-checkbox class="last_check" v-model="option8">
                       Australia
                    </vs-checkbox>
                    <vs-checkbox class="last_check" v-model="option9">
                       Africa
                    </vs-checkbox>
                </div>
                </b-field>
            </div>
             <div class="col-md-12 mrgb">
                 <b-field label="Import Market">
                      <div class="center center_flex">
                        <vs-checkbox  v-model="option3">
                        South America
                    </vs-checkbox>
                    <vs-checkbox class="last_check" v-model="option4">
                        North America
                    </vs-checkbox>
                     <vs-checkbox class="last_check" v-model="option5">
                        China
                    </vs-checkbox>
                     <vs-checkbox class="last_check" v-model="option6">
                        Europe
                    </vs-checkbox>
                     <vs-checkbox class="last_check" v-model="option7">
                        Asia
                    </vs-checkbox>
                     <vs-checkbox class="last_check" v-model="option8">
                       Australia
                    </vs-checkbox>
                    <vs-checkbox class="last_check" v-model="option9">
                       Africa
                    </vs-checkbox>
                </div>
                </b-field>
            </div>
            <div class="col-md-6 mrgb">
                <b-field label="Company Website URL">
                     <vs-input placeholder="https://google.com" />
                    </b-field>
            </div>
            <div class="col-md-6 mrgb">
                <div class="center center_flex">
                   <span class="or_span">OR</span> 
        <vs-checkbox  v-model="option1">
         I don’t have website
      </vs-checkbox>
                </div>
            </div>
            <!-- title busniess location -->
            <div class="col-12 mrgb">
                <h2 class="tabname">Business Location</h2>
            </div>
            <div class="col-md-6 mrgb">
                <b-field label="Country">
             <vs-select placeholder="Select" v-model="value">
        <vs-option label="Jordan" value="1">
          Jordan
        </vs-option>
        <vs-option label="Qatar" value="2">
          Qatar
        </vs-option>
        <vs-option label="China" value="3">
          China
        </vs-option>
        <vs-option  label="Spain" value="4">
          Spain
        </vs-option>
        <vs-option label="Turkey" value="5">
          Turkey
        </vs-option>
        <vs-option label="Oman" value="6">
          Oman
        </vs-option>
        <vs-option label="Saudi Arabia" value="7">
          Saudi Arabia
        </vs-option>
      </vs-select>
        </b-field>
            </div>
            <div class="col-md-6 mrgb">
                 <b-field label="City">
           <vs-input placeholder="Amman ..." />
                </b-field>
            </div>
             <div class="col-md-6 mrgb">
                 <b-field label="State/Province">
           <vs-input placeholder="Jo" />
                </b-field>
            </div>
            <div class="col-md-6 mrgb">
                 <b-field label="Postal Code">
           <vs-input placeholder="9721" />
                </b-field>
            </div>
            <div class="col-md-6 mrgb">
                 <b-field label="Street Address">
           <vs-input placeholder="Mekkah St.." />
                </b-field>
            </div>
             <div class="col-md-6 mrgb">
                 <b-field label="Address 1">
           <vs-input placeholder="Mekkah St.." />
                </b-field>
            </div>
            <!-- phone -->
            <div class="col-md-12 mrgb">
                 <b-field label="Phone Landline">
                  <vs-input placeholder="+962787525719" />
                </b-field>
            </div>
             <div class="col-md-12 mrgb">
                 <b-field label="Fax">
                  <vs-input placeholder="98890" />
                </b-field>
            </div>
            <div class="col-md-12 mrgb">
                 <b-field label="Mobile ">
                  <vs-input placeholder="+962787525719" />
                </b-field>
            </div>
            <div class="col-md-12 mrgb">
                 <b-field label="WhatsApp">
                  <vs-input placeholder="+962787525719" />
                </b-field>
            </div>
            <div class="col-md-12 mrgb">
                 <b-field label="Contact Persone Name">
                  <vs-input placeholder="Mohammad" />
                </b-field>
            </div>
            <div class="col-md-12 mrgb">
                 <b-field label="Contact Email">
                  <vs-input placeholder="example@email.com" />
                </b-field>
            </div>
            <div class="col-md-12 mrgb">
                 <b-field label="Position Title">
                  <vs-input placeholder="Hr" />
                </b-field>
            </div>
            <div class="col-md-12 mrgb">
                 <b-field label="Selling Categories buyer / seller ??">
                  <vs-input placeholder="Categories" />
                </b-field>
            </div>
            <!-- editor div -->
            <div class="col-md-12 mrgb2">
                 <b-field label="Company Description">
                 <!-- <b-input maxlength="10000" type="textarea"></b-input> -->
                     <vue-editor id="editor"
                        :useCustomImageHandler="true"
                        @image-added="handleImageAdded" v-model="htmlForEditor" >
                        </vue-editor>
                </b-field>
            </div>
            <!-- end editor div -->
            <!-- upload divs -->
             <div class="col-md-6 mrgb2">
                 <b-field label="Company Logo" class="upload_img">
                   <b-field>
                      <!--  -->
                      <b-upload v-model="dropFiles" drag-drop expanded required>
                        <section class="section">
                          <div class="content has-text-centered">
                            <p class="uploadIco">
                              <i class="bx bxs-cloud-upload"></i>
                            </p>
                            <p>Upload Company Logo</p>
                          </div>
                        </section>
                      </b-upload>
                    </b-field>
                    <p class="sp_desc">This logo image will appear with your company name at the top of your Webstore main.</p>
                    <p class="sp_desc danger danger_c">Recommended image size: 000000 X 00000 pixel.</p>
                     <div class="tags">
                      <span
                       v-if="dropFiles"
                        class="tag is-primary"
                      >
                        {{ dropFiles.name }}
                        <button
                          class="delete is-small"
                          type="button"
                          @click="deleteDropFile()"
                        ></button>
                      </span>
                    </div>
                </b-field>
            </div>
             <div class="col-md-6 mrgb2">
                 <b-field label="Company License" class="upload_img">
                   <b-field>
                      <!--  -->
                      <b-upload v-model="dropFiles" drag-drop expanded required>
                        <section class="section">
                          <div class="content has-text-centered">
                            <p class="uploadIco">
                              <i class="bx bxs-cloud-upload"></i>
                            </p>
                            <p>Upload Company License</p>
                          </div>
                        </section>
                      </b-upload>
                    </b-field>
                    <p class="sp_desc">This License image <strong class="danger_c">will not appear</strong> on any place of the website.</p>
                    <p class="sp_desc danger danger_c">Recommended image size: 000000 X 00000 pixel.</p>
                     <div class="tags">
                      <span
                       v-if="dropFiles"
                        class="tag is-primary"
                      >
                        {{ dropFiles.name }}
                        <button
                          class="delete is-small"
                          type="button"
                          @click="deleteDropFile()"
                        ></button>
                      </span>
                    </div>
                </b-field>
            </div>
             <div class="col-md-12 mrgb2">
                 <b-field label="Company Image" class="upload_img">
                   <b-field>
                      <!--  -->
                      <b-upload v-model="dropFiles_arr" drag-drop multiple expanded required>
                        <section class="section">
                          <div class="content has-text-centered">
                            <p class="uploadIco">
                              <i class="bx bxs-cloud-upload"></i>
                            </p>
                            <p>Upload Company Image</p>
                          </div>
                        </section>
                      </b-upload>
                    </b-field>
                    <p class="sp_desc">(T. ex) Office building, Staffs, Convention, etc.</p>
                    <p class="sp_desc danger danger_c">Recommended image size: 000000 X 00000 pixel.</p>
                    <p class="sp_desc danger">The First image will be displayed on the main page of your Storefront.</p>
                    <div class="tags">
                      <span v-for="(file, index) in dropFiles_arr"
                          :key="index"
                          class="tag is-primary" >
                          {{file.name}}
                          <button class="delete is-small"
                              type="button"
                              @click="deleteDropFileMulti(index)">
                          </button>
                      </span>
                  </div>
                </b-field>
            </div>
              <div class="col-md-12 mrgb2">
                 <b-field label="Company Brochure" class="upload_img">
                   <b-field>
                      <!--  -->
                      <b-upload v-model="dropFiles_arr" drag-drop multiple expanded required>
                        <section class="section">
                          <div class="content has-text-centered">
                            <p class="uploadIco">
                              <i class="bx bxs-cloud-upload"></i>
                            </p>
                            <p>Upload Company Brochure</p>
                          </div>
                        </section>
                      </b-upload>
                    </b-field>
                    <p class="sp_desc">- You can upload Max. 5 brochures</p>
                    <p class="sp_desc danger danger_c">PDF file only, total uploadable size : 10mb</p>
                    <p class="sp_desc danger">  (Ex) Company brochure, product line, etc.</p>
                    <div class="tags">
                      <span v-for="(file, index) in dropFiles_arr"
                          :key="index"
                          class="tag is-primary" >
                          {{file.name}}
                          <button class="delete is-small"
                              type="button"
                              @click="deleteDropFileMulti(index)">
                          </button>
                      </span>
                  </div>
                </b-field>
            </div>
              <!-- text area iframe -->
               <div class="col-md-12 mrgb2">
                 <b-field label="Video (embed code)">
                 <b-input type="textarea" placeholder="Embed code Example<iframe width='560' height='315'
src='https://www.youtube.com/embed/EXamMPle'>.....</iframe>"></b-input>
                </b-field>
            </div>
            <!-- end iframe -->
              <!-- end upload divs -->
                <div class="col-md-4 col-12 mr-auto ml-auto mrgt">
                  <vs-button gradient>Save My Data</vs-button>
            </div>
        </div>
      </div>
    
   </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
  import { VueEditor } from 'vue2-editor'
//  import axios from 'axios'

export default {
  name: "userprofile",
  data() {
    return {
        n_name:1,
        im:1,
        picked:1,
        hasVisiblePassword: false,
        password:'password',
         htmlForEditor: "",
         dropFiles: null,
         dropFiles_arr:[],
        // 
        value:1,
         option1: false,
         option2: false,
          option3: false,
           option4: false,
            option5: false,
             option6: false,
              option7: false,
               option8: false,
                option9: false,
                 option10: false,
    };
  },
  components: {
    VueEditor
  },
  computed: {
    ...mapGetters({}),
  },
  methods: {
    ...mapActions({}),
     handleImageAdded: function(file, Editor, cursorLocation) {
        // An example of using FormData
        // NOTE: Your key could be different such as:
        // formData.append('file', file)
        console.log(file);
        Editor.insertEmbed(cursorLocation, 'image', 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSTB-pULN4tWIgabMt9ooDqzE6mD-tQ3VfkzbHDRqPhTrR3lwS4T1tkU7DMz35OBHl3NVE&usqp=CAU');
        // var formData = new FormData();
        // formData.append('image', file)
 
        // axios({
        //   url: 'https://fakeapi.yoursite.com/images',
        //   method: 'POST',
        //   data: formData
        // })
        // .then((result) => {
        //   let url = result.data.url // Get url from response
        //   Editor.insertEmbed(cursorLocation, 'image', url);
          
        // })
        // .catch((err) => {
        //   console.log(err);
        // })
      },
       deleteDropFile() {
      this.dropFiles=null;
    },
     deleteDropFileMulti(index) {
                this.dropFiles.splice(index, 1)
            }
  },
  mounted() {},
};
</script>
<style lang="scss">
.dashoard_user_info{
    padding-top: 20px;
    .mrgb{
        margin-bottom: 14px;
        position: relative;
    }
    .mrgb2{
        margin-bottom: 20px;
        position: relative;
    }
    .mrgt{
        margin-top: 25px;
    }
    .or_span{
            margin-bottom: 6px;
    display: inline-block;
    padding-right: 15px;
    font-size: 15px;
    font-weight: 500;
    }
    .center_flex{
            display: flex;
    height: 100%;
    justify-content: flex-start;
    align-items: flex-end;
    .last_check{
        margin-left: 15px;
    }
    }
    .change_span{
       font-size: 13px;
    color: var(--active);
    cursor: pointer;
    position: absolute;
    right: 20px;
    bottom: -14px;
    font-weight: 400;
    text-transform: capitalize;
    }
    .tabname{
        font-size: 18px;
        font-weight: 600;
        margin: 0;
        padding: 0;
    }
    .personal_wraps{
    padding-top: 20px;
    .type_user{
    display: flex;
    align-items: center;
    .key_span{
        display: inline-block;
    margin-right: 20px;
    font-size: 16px;
    font-weight: 500;
    }
    .radio_wrap{
        display: flex;
        align-items: center;
        .vs-radio-content{
            margin-right: 10px;
        }
    }
    }
    .form_wraps{
        padding-top: 0px;
        // max-width: 700px;
    }
    }
}
</style>
