<template>
  <div class="map_listing_wraps">
    <h3 class="tabname">My Ads</h3>
   <div class="search_bar">
     <div class="row align-items-center">
        <div class="col-10">
              <b-field>
            <p class="control">
                <b-dropdown>
                    <template #trigger>
                        <b-button label="Filters" icon-right="menu-down" />
                    </template>

                    <b-dropdown-item value="open_issues">Open Issues and Pull Requests</b-dropdown-item>
                    <b-dropdown-item value="your_issues">Your Issues</b-dropdown-item>
                    <b-dropdown-item value="pull_requests">Your Pull Requests</b-dropdown-item>
                    <b-dropdown-item value="everything">Everything</b-dropdown-item>
                </b-dropdown>
            </p>
            <b-input class="w-100" icon="magnify" type="search" placeholder="Search..."></b-input>
        </b-field>
        </div>
        <div class="col-2 p-0">
              <vs-button success square relief>Search</vs-button>
        </div>
     </div>
   </div>
   <div class="filter_bar">
    <div class="row align-items-center">
        <div class="col-md-2 col-6 mb-4">
             <div class="center">
      <vs-select placeholder="Status" v-model="val1">
        <vs-option label="Active" value="1">
          Active
        </vs-option>
        <vs-option label="Rejected" value="2">
          Rejected
        </vs-option>
      </vs-select>
    </div>
        </div>
        <div class="col-md-2 col-6 mb-4">
                  <div class="center">
      <vs-select placeholder="Type" v-model="val2">
        <vs-option label="All Ads" value="1">
          All Ads
        </vs-option>
        <vs-option label="Sell Ads" value="2">
          Sell Ads
        </vs-option>
        <vs-option label="Buy Ads" value="3">
          Buy Ads
        </vs-option>
      </vs-select>
    </div>
        </div>
        <div class="col-md-2 col-6 mb-4">
                  <div class="center">
      <vs-select placeholder="Brand" v-model="val3">
        <vs-option label="Brand 1" value="1">
          Brand 1
        </vs-option>
        <vs-option label="Brand 2" value="2">
          Brand 2
        </vs-option>
        <vs-option label="Brand 3" value="3">
          Brand 3
        </vs-option>
        <vs-option disabled label="Brand 4" value="4">
          Brand 4
        </vs-option>
        <vs-option label="Brand 5" value="5">
          Brand 5
        </vs-option>
      </vs-select>
    </div>
        </div>
        <div class="col-md-2 col-6 mb-4">
                  <div class="center">
      <vs-select placeholder="Category" v-model="val4">
        <vs-option label="Category 1" value="1">
          Category 1
        </vs-option>
        <vs-option label=" Category 2" value="2">
           Category 2
        </vs-option>
        <vs-option label=" Category 3" value="3">
           Category 3
        </vs-option>
      </vs-select>
    </div>
        </div>
        <div class="col-md-2 col-6 mb-4">
                  <div class="center">
     <vs-select
        filter
        multiple
        collapse-chips
        placeholder="Model"
        v-model="val5"
      >
        <vs-option label="Model 1" value="1">
          Model 1
        </vs-option>
        <vs-option label="Model 2" value="2">
          Model 2
        </vs-option>
        <vs-option label="Model 3" value="3">
          Model 3
        </vs-option>
      </vs-select>
    </div>
        </div>
        <div class="col-md-2 col-6 mb-4">
              <vs-checkbox label-before v-model="option1">
       In Stock
      </vs-checkbox>
        </div>
    </div>
   </div>
   <div class="box_listing_wrap">
    <div class="single_listing">
        <div class="flex_up">
            <div class="flex_image">
              <div class="sec1">
                  <div class="abs_status gr">
                    <span>Live</span>
                </div>
               <div class="img_div">
                 <img src="https://images.unsplash.com/photo-1595500381966-eee2034aae48?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2825&q=80" alt="">
               </div>
               <div class="text_desc">
                <h1>Medical Disinfectant Wipes</h1>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
                <div class="price_abs">
                    <span class="pr">150,000.00</span>
                    <span class="cr">USD</span>
                </div>
                <div class="shipping">
                    <span>Jordan </span> |
                    <span>Sub Category</span>
                </div>
               </div>
              </div>
               <div class="flex_infos">
                <div class="date1 singleinfo">
                    <i class='bx bx-calendar bx-flashing' ></i>
                    <span class="vals">2022-09-01</span>
                </div>
                <div class="date2 singleinfo">
                    <span class="keys">online for : </span>
                    <span class="vals">20 Days</span>
                </div>
                <div class="date3 singleinfo">
                    <span class="keys">Expires in : </span>
                    <span class="vals">9 Days</span>
                </div>
               </div>
                <div class="flex_infos2">
                <div class="method">
                  <div class="keys">
                     <i class='bx bxs-edit-alt' ></i>
                  </div>
                    <span class="vals">Edit</span>
                </div>
                <div class="method">
                    <span class="keys">
                         <vs-switch v-model="active1">
                            <template #on>
                            ON
                            </template>
                            <template #off>
                            OFF
                            </template>
                        </vs-switch>
                    </span>
                    <span class="vals">Deactivate</span>
                </div>
                <div class="method">
                    <span class="keys">
                        <i class='bx bx-chat' ></i>
                         </span>
                    <span class="vals">Chat</span>
                </div>
                 <div class="method">
                    <span class="keys">
                      <i class='bx bx-share-alt' ></i>
                         </span>
                    <span class="vals">Share</span>
                </div>
                <div class="method">
                    <span class="keys">
                       <i class='bx bx-line-chart' ></i>
                         </span>
                    <span class="vals">Statistics</span>
                </div>
               </div>
            </div>
            <div class="flex_bost">
                <div class="flex_ups">
                  <div class="flex_cirlce">
                   <vue-circle
      ref="myUniqueID"
      :progress="60"
      :size="140"
      :reverse="false"
      line-cap="round"
      :fill="fill"
      empty-fill="rgba(0, 0, 0, .1)"
      :animation-start-value="0.0"
      :start-angle="0"
      insert-mode="append"
      :thickness="8"
      :show-percent="true"
      @vue-circle-progress="progress"
      @vue-circle-end="progress_end"
    >
      <p>Slot!</p>
               </vue-circle>
                </div>
               <div class="circle_text">
                 <p class="title_1">Post Quality</p>
                <p>Add more information to your Post, achieve 100% and get more views!</p>
               </div>
                </div>
             <div class="inf0_bost2">
                <p>Get <strong>more views</strong> and sell faster:</p>
                <div class="row align-items-center">
                  <div class="col-8">
                    <div class="progress_div">
                      <p class="label_prog"><strong>1500</strong> people saw your Ad</p>
                    <b-progress 
                    :rounded="true" 
                    :value="40" 
                    type="is-success" 
                    format="percent"
                    show-value
                >
                <!-- Custom display with <span style="color: red">HTML</span> -->
                </b-progress>
                  </div>
                </div>
                <div class="col-4">
                     <vs-button success  relief>Boost</vs-button>
                  </div>
                </div>
             </div>
            </div>
        </div>
    </div>
     <div class="single_listing">
        <div class="flex_up">
            <div class="flex_image">
              <div class="sec1">
                  <div class="abs_status dn">
                    <span>Expired</span>
                </div>
               <div class="img_div">
                 <img src="https://images.unsplash.com/photo-1532094349884-543bc11b234d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2940&q=80" alt="">
               </div>
               <div class="text_desc">
                <h1>Medical Disinfectant Wipes</h1>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
                <div class="price_abs">
                    <span class="pr">150,000.00</span>
                    <span class="cr">USD</span>
                </div>
                <div class="shipping">
                    <span>Jordan </span> |
                    <span>Sub Category</span>
                </div>
               </div>
              </div>
               <div class="flex_infos">
                <div class="date1 singleinfo">
                    <i class='bx bx-calendar bx-flashing' ></i>
                    <span class="vals">2022-09-01</span>
                </div>
                <div class="date2 singleinfo">
                    <span class="keys">online for : </span>
                    <span class="vals">20 Days</span>
                </div>
                <div class="date3 singleinfo">
                    <span class="keys">Expires in : </span>
                    <span class="vals">9 Days</span>
                </div>
               </div>
                <div class="flex_infos2">
                <div class="method">
                  <div class="keys">
                     <i class='bx bxs-edit-alt' ></i>
                  </div>
                    <span class="vals">Edit</span>
                </div>
                <div class="method">
                    <span class="keys">
                         <vs-switch v-model="active1">
                            <template #on>
                            ON
                            </template>
                            <template #off>
                            OFF
                            </template>
                        </vs-switch>
                    </span>
                    <span class="vals">Deactivate</span>
                </div>
                <div class="method">
                    <span class="keys">
                        <i class='bx bx-chat' ></i>
                         </span>
                    <span class="vals">Chat</span>
                </div>
                 <div class="method">
                    <span class="keys">
                      <i class='bx bx-share-alt' ></i>
                         </span>
                    <span class="vals">Share</span>
                </div>
                <div class="method">
                    <span class="keys">
                       <i class='bx bx-line-chart' ></i>
                         </span>
                    <span class="vals">Statistics</span>
                </div>
               </div>
            </div>
            <div class="flex_bost">
                <div class="flex_ups">
                  <div class="flex_cirlce">
                   <vue-circle
      ref="myUniqueID"
      :progress="24"
      :size="140"
      :reverse="false"
      line-cap="round"
      :fill="fill"
      empty-fill="rgba(0, 0, 0, .1)"
      :animation-start-value="0.0"
      :start-angle="0"
      insert-mode="append"
      :thickness="8"
      :show-percent="true"
      @vue-circle-progress="progress"
      @vue-circle-end="progress_end"
    >
      <p>Slot!</p>
               </vue-circle>
                </div>
               <div class="circle_text">
                 <p class="title_1">Post Quality</p>
                <p>Add more information to your Post, achieve 100% and get more views!</p>
               </div>
                </div>
             <div class="inf0_bost2">
                <p>Get <strong>more views</strong> and sell faster:</p>
                <div class="row align-items-center">
                  <div class="col-8">
                    <div class="progress_div">
                      <p class="label_prog"><strong>300</strong> people saw your Ad</p>
                    <b-progress 
                    :rounded="true" 
                    :value="20" 
                    type="is-danger" 
                    format="percent"
                    show-value
                >
                <!-- Custom display with <span style="color: red">HTML</span> -->
                </b-progress>
                  </div>
                </div>
                <div class="col-4">
                     <vs-button success  relief>Boost</vs-button>
                  </div>
                </div>
             </div>
            </div>
        </div>
    </div>
   </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import VueCircle from 'vue2-circle-progress'

export default {
  name: "MylistingStats",
  components: {
      VueCircle
    },
  data() {
    return {
        val1:'',
         val2:'',
          val3:'',
           val4:'',
            val5:'',
            option1:false,
            active1:false,
            // 
             fill: { color:"#45c93a" }
    }
  },
  computed: {
    ...mapGetters({
    }),
  },
  methods: {
    ...mapActions({
    }),
     progress(_,progress,stepValue){
        console.log('prog=>' , progress,  'stepval=>',stepValue);
        // if(stepValue>=50){
        //    this.$refs.myUniqueID.updateFill("#45c93a");
        // }
        
      },
      progress_end(event){
        console.log("Circle progress end" , event);
      }
  },
  mounted() {},
};
</script>
<style lang="scss">
.map_listing_wraps {
  padding-top: 20px;
  .mrgb {
    margin-bottom: 14px;
    position: relative;
  }
  .mrgt {
    margin-top: 25px;
  }
  .tabname {
    font-size: 18px;
    font-weight: 600;
    margin: 0;
    padding: 0;
    margin-bottom: 20px;
  }
  .filter_bar{
    margin-top: 20px;
    .vs-select__input  , .vs-select__chips{
        background-color: white;
    }
  }
 
}
.abs_status{
    position: absolute;
    right: 30px;
    top: -6px;
    padding: 0px 22px;
    font-weight: 500;
}
.price_abs{
    position: absolute;
    top: 30%;
    right: 30px;
}
.gr{
    color: #45c93a;
    border: 1px solid;
}
.dn{
  color: #ff4658;
  border: 1px solid;
}
.box_listing_wrap{
  margin-top: 20px;
.single_listing{
    background-color: white;
    padding: 20px 10px;
    border-radius: 20px;
    box-shadow: 0px 0px 10px #dcdcdca3;
    margin-bottom: 40px;
    transition: 0.3s ease-in-out;
    &:hover{
      background-color: rgb(247, 247, 247);
      transform: scale(0.97);
    }
 .flex_up{
        display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    .flex_image{
         border-right: 1px solid #E9E9E9;
        .sec1{
            display: flex;
        align-items: flex-start;
            flex: 0 0 60%;
             padding-right: 150px;
            position: relative;
        }
        .img_div{
            max-width: 150px;
            width: 150px;
           img{
             height: 180px;
            width: 100%;
            object-fit: contain;
            object-position: top;
           }
        }
        .text_desc{
            padding-top: 0px;
            padding-left: 10px;
                max-width: 250px;
                width: 250px;
            h1{
            font-size: 20px;
            font-weight: 500;
            margin-bottom: 4px;
            }
            p{
                font-size: 14px;
                font-weight: 400;
                opacity: 0.8;
            }
            .shipping{
                font-size: 14px;
                font-weight: 400;
                padding-top: 10px;
                color: var(--active);
            }
        }
    }
    .flex_infos{
        display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px 20px;
    background-color: rgb(251, 251, 251);
    border-radius: 4px;
    font-size: 14px;
     border-top: 1px solid #e2dede;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 10px;
    div{
        flex: 0 0 calc(100% / 3);
        position: relative;
    }
    .date2 , .date3{
        .keys{
            font-weight: 600;
            color: black;
        }
        .vals{
            color: #45c93a;
            padding-left: 4px;
            font-weight: 500;
        }
    }
    .date1{
        i{
            padding-right: 10px;
            font-size: 18px;
            vertical-align: middle;
            margin-top: -4px;
            color: #45c93a;
        }
    }
    .date1 , .date2{
        &::after{
            padding-left: 10px;
            content: '|';
            color: rgb(81, 81, 81);
            position: absolute;
            right: 20px;
        }
    }
    }
    .flex_infos2{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #e2dede;
    padding-top: 10px;
    .method{
        display: flex;
        align-items: center;
        flex-direction: column;
        flex: 0 0 calc(100% / 5);
        cursor: pointer;
        &:hover{
            color: var(--active);
        }
        .keys{
            margin-bottom: 4px;
            i{
                transition: 0.2s ease-in-out;
                font-size: 28px;
            }
        }
        .vals{
            transition: 0.2s ease-in-out;
            font-size: 14px;
            font-weight: 400;
        }
    }
    }
    .flex_bost{
       flex: 0 0 40%;
       padding-left: 14px;
      .flex_ups{
         border-bottom: 1px solid #E9E9E9;
          padding-bottom: 14px;
           display: flex;
          align-items: center;
         .flex_cirlce{
        flex: 0 0 140px;
      }
      .circle_text{
        padding-left: 20px;
        flex: 0 0 calc(100% - 140px);
        p.title_1{
          font-size: 16px;
          color: black;
          font-weight: 500;
          opacity: 1;
        }
        p{
          font-size: 14px;
          opacity: 0.8;
          font-weight: 400;
        }
      }
      }
      .inf0_bost2{
        padding-top: 14px;
        .row{
          padding-top: 20px;
          button{
            margin-bottom: -10px;
          }
        }
        .progress_div{
          .label_prog{
            font-size: 13px;
            padding-bottom: 8px;
          }
        }
      }
    }
 }   
}
}

</style>
