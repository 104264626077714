<template>
    <div class="dashoard_user_info add_adds_wraps">
      <div class="container">
        <h3 class="tabname">Post Details</h3>
     <div class="personal_wraps">
      <!-- input form  -->
        <div class="form_wraps">
          <div class="row">
               <div class="col-md-6 mrgb">
                   <b-field label="Brand Name">
                   <vs-input placeholder="name as shown after publish your ads.." />
                  </b-field>
              </div>
              <div class="col-md-6 mrgb">
                  <b-field label="Choose Model">
               <vs-select placeholder="Select" v-model="value">
          <vs-option label="Model 1" value="1">
           Model 1
          </vs-option>
          <vs-option label="Model 2" value="2">
           Model 2
          </vs-option>
          <vs-option label="Model 3" value="3">
            Model 3
          </vs-option>
          <vs-option  label="Model 4" value="4">
          Model 4
          </vs-option>
          <vs-option label="Model 5" value="5">
          Model 5
          </vs-option>
          <vs-option label="Model 6" value="6">
           Model 5
          </vs-option>
          <vs-option label="Model 7" value="7">
           Model 7
          </vs-option>
        </vs-select>
          </b-field>
              </div> 
              <div class="col-md-12 mrgb">
                <div class="row">
                  <b-field label="Ad title">
                  <div class="col-md-2">
                    <vs-select placeholder="Ads Type" v-model="value2">
                    <vs-option label="For Sall" value="1">
                    For Sall
                    </vs-option>
                    <vs-option label="Wanted" value="2">
                      Wanted
                    </vs-option>
                    <vs-option label="Jop Offer" value="3">
                      Jop Offer
                    </vs-option>
                    <vs-option  label="RFQ" value="4">
                    RFQ 
                    </vs-option>
                    <vs-option label="Others" value="5">
                      Others
                    </vs-option>
                   </vs-select>
                  </div>
                  <div class="col-md-10">
                 <vs-input placeholder="Ad Title ..." />
                  </div>
                  </b-field>
                </div>
              </div>
              <div class="col-md-12 mrgb2">
                   <b-field label="Ad Description">
                       <vue-editor id="editor"
                          :useCustomImageHandler="true"
                          @image-added="handleImageAdded" v-model="htmlForEditor" >
                          </vue-editor>
                  </b-field>
              </div>
              <div class="col-md-6 mrgb">
                   <b-field label="Qty Available (Unit)">
                  <vs-input placeholder="ex: 25" />
                  </b-field>
              </div>
              <div class="col-md-6 mrgb">
                   <b-field label="Min Order (Unit)">
                  <vs-input placeholder="ex: minmum 3 unit to order ..." />
                  </b-field>
              </div>
              <div class="col-md-6 mrgb">
                   <b-field label="Country of Origin">
                  <vs-input placeholder="ex: Jordan" />
                  </b-field>
              </div>
              <div class="col-md-6 mrgb">
                   <b-field label="Shipping From">
                  <vs-input placeholder="ex: India" />
                  </b-field>
              </div>
              <div class="col-md-6 mrgb">
                   <b-field label="Price">
                  <vs-input placeholder="10,000 $" />
                  </b-field>
              </div>
              <div class="col-md-6 mrgb">
                   <b-field label="Flexble price">
                    <div class="slider_price">
              <div class="flex_bud">
                <span>{{ budget[0] }} USD</span>
                <span>{{ budget[1] }} USD</span>
              </div>
      <van-slider style="margin-top:30px" v-model="budget" :max="50000" range @change="onChange" />
    </div>
                  </b-field>
              </div>
              <!--  -->
               <div class="col-md-12 mrgb">
                   <b-field label="In Stock">
                        <div class="center center_flex">
                          <vs-radio  v-model="option1" val="yes">
                         Yes
                      </vs-radio>
                      <vs-radio class="last_check" v-model="option1" val="no">
                        No
                      </vs-radio>
                  </div>
                  </b-field>
              </div>
              <div class="col-md-6 mrgb">
                  <b-field label="Available only in">
                    <vs-select
                  filter
                  multiple
                  collapse-chips
                  placeholder="Choose countries"
                  v-model="value3"
                >
        <vs-option label="Jordan" value="1">
          Jordan
        </vs-option>
        <vs-option label="India" value="2">
          India
        </vs-option>
        <vs-option label="Spain" value="3">
          Spain
        </vs-option>
        <vs-option label="USA" value="4">
          USA
        </vs-option>
        <vs-option label="Italy" value="5">
          Italy
        </vs-option>
        <vs-option label="Russia" value="6">
          Russia
        </vs-option>
        <vs-option label="Brazil" value="7">
          Brazil
        </vs-option>
      </vs-select>
                      </b-field>
              </div>
              <div class="col-md-6 mrgb">
                <b-field label="Condition">
                    <vs-select placeholder="Select Condition" v-model="value4">
                    <vs-option label="New " value="1">
                      New 
                    </vs-option>
                    <vs-option label="Refurbished" value="2">
                      Refurbished 
                    </vs-option>
                    <vs-option label="Used" value="3">
                      Used 
                    </vs-option>
                    <vs-option  label="OEM " value="4">
                      OEM  
                    </vs-option>
                   </vs-select>
                  </b-field>
              </div>
              <!-- Add Spesifications -->
              <div class="col-12 mrgb">
                  <h2 class="tabname">Add Spesifications</h2>
              </div>
              <div class="col-12 mrgb">
                <div class="inputs">
                  <!-- <label for="">{{get_strings[77].name}}</label> -->
                  <!-- {{ mySkills}}  -->
                  <ul class="list-group">
                  <li class="list-group-item mb-5" v-for="(single_spes , index) in mySpesification" :key="index">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="flex-row">
                          <a class="deleteicon" href="#" v-on:click.prevent="removeSkills(index)"><i class='bx bxs-trash'></i></a>
                          <div class="row">
                            <div class="col-md-3 mrgb">
                   <b-field label="Feature Name">
                   <vs-input v-model="single_spes.key" placeholder="ex : S/N or Part No etc..." />
                  </b-field>
              </div>
              <div class="col-md-9 mrgb">
                   <b-field label="Feature Name">
                   <vs-input v-model="single_spes.value" placeholder="" />
                  </b-field>
              </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
                <p @click='addSkills' class="newFetureBtn">New Feature <i class='bx bx-plus'  ></i></p>
                </div>
              </div>
              <!--  -->
              <div class="col-12 mrgb">
                  <h2 class="tabname">Add Photos</h2>
              </div>
              <div class="col-12 mrgb">
                <vs-alert color="dark">
      Uploading photos will increase views over your Ad. Use real photos that describes your item.
    </vs-alert>
              </div>
              <div class="col-12 mrgb">
                <van-uploader v-model="fileList" multiple :max-count="max_cont" preview-size="150"	 />
              </div>
                <!-- end upload divs -->
                  <div class="col-md-4 col-12 mr-auto ml-auto mrgt">
                    <vs-button gradient>Publish Ad</vs-button>
              </div>
          </div>
        </div>
      
     </div>
      </div>
    </div>
  </template>
  <script>
  import { mapActions, mapGetters } from "vuex";
    import { VueEditor } from 'vue2-editor'
  //  import axios from 'axios'
  
  export default {
    name: "userprofile",
    data() {
      return {
          n_name:1,
          im:1,
          picked:1,
          hasVisiblePassword: false,
          password:'password',
           htmlForEditor: "",
           dropFiles: null,
           dropFiles_arr:[],
          // 
          value:1,
          value2:1,
          value3:1,
          value4:1,
          budget:[1000,25000],
          mySpesification:[],
          fileList: [{ url: 'https://img01.yzcdn.cn/vant/leaf.jpg' }, { url: 'https://img01.yzcdn.cn/vant/leaf.jpg' }],
           option1: '',
           max_cont:4
      };
    },
    components: {
      VueEditor
    },
    computed: {
      ...mapGetters({}),
    },
    methods: {
      ...mapActions({}),
       handleImageAdded: function(file, Editor, cursorLocation) {
          // An example of using FormData
          // NOTE: Your key could be different such as:
          // formData.append('file', file)
          console.log(file);
          Editor.insertEmbed(cursorLocation, 'image', 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSTB-pULN4tWIgabMt9ooDqzE6mD-tQ3VfkzbHDRqPhTrR3lwS4T1tkU7DMz35OBHl3NVE&usqp=CAU');
          // var formData = new FormData();
          // formData.append('image', file)
   
          // axios({
          //   url: 'https://fakeapi.yoursite.com/images',
          //   method: 'POST',
          //   data: formData
          // })
          // .then((result) => {
          //   let url = result.data.url // Get url from response
          //   Editor.insertEmbed(cursorLocation, 'image', url);
            
          // })
          // .catch((err) => {
          //   console.log(err);
          // })
        },
         deleteDropFile() {
        this.dropFiles=null;
      },
       deleteDropFileMulti(index) {
                  this.dropFiles.splice(index, 1)
              },
              onchangeBudget(){
        },
        onchangePeriod(){
        },
        onChange(value) {
          console.log(value)
      // Toast('current value：' + value);
    },
    addSkills() {
      this.mySpesification.push({key:'',value:''});
    },
    removeSkills(index) {
      this.mySpesification.splice(index, 1);
    },
    },
    mounted() {},
  };
  </script>
  <style lang="scss" scoped>
  .dashoard_user_info{
      padding-top: 200px;
      .mrgb{
          margin-bottom: 20px;
          position: relative;
      }
      .mrgb2{
          margin-bottom: 20px;
          position: relative;
      }
      .mrgt{
          margin-top: 25px;
      }
      .or_span{
              margin-bottom: 6px;
      display: inline-block;
      padding-right: 15px;
      font-size: 15px;
      font-weight: 500;
      }
      .center_flex{
              display: flex;
      height: 100%;
      justify-content: flex-start;
      align-items: flex-end;
      .last_check{
          margin-left: 15px;
      }
      }
      .change_span{
         font-size: 13px;
      color: var(--active);
      cursor: pointer;
      position: absolute;
      right: 20px;
      bottom: -14px;
      font-weight: 400;
      text-transform: capitalize;
      }
      .tabname{
        font-size: 16px;
    margin: 0;
    padding: 0;
    background-color: gainsboro;
    display: inline-block;
    padding: 6px 20px;
    border-radius: 5px;
    font-weight: 600;
    color: black;
    min-width: 200px;
    text-align: center;
    margin-top: 20px;
      }
      .personal_wraps{
      padding-top: 20px;
      .type_user{
      display: flex;
      align-items: center;
      .key_span{
          display: inline-block;
      margin-right: 20px;
      font-size: 16px;
      font-weight: 500;
      }
      .radio_wrap{
          display: flex;
          align-items: center;
          .vs-radio-content{
              margin-right: 10px;
          }
      }
      }
      .form_wraps{
          padding-top: 0px;
          // max-width: 700px;
      }
      }
  }
  .slider_price{
         .flex_bud{
           display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    right: 0;
    top: 0;
    width: 200px;
           span{
             font-style: normal;
             font-weight: 400;
             font-size: 14px;
             line-height: 21px;
             color: #A1A6AD;
           }
         }
       }
       .flex-row{
  padding: 10px 0px;
  position: relative;
  .vuesax-app-is-ltr .vs-select--input{
    height: 48px !important;
  }
}
.con-vs-slider{
  min-width: auto;
}
.deleteicon{
  width: 35px;
  height: 35px;
  background-color: red;
  color: white;
  border-radius: 100%;
  position: absolute;
  right: -10px;
  top: -10px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s ease-in-out;
  &:hover{
    transform: scale(1.1);
  }
}
.newSkillsBtn{
  height: 52px;
  i{
    vertical-align: middle;
    margin-top: -3px;
  }
}
.newFetureBtn{
    display: block;
    text-align: center;
    background-color: #45c93a;
    color: white;
    padding: 6px 34px;
    border-radius: 5px;
    margin-left: 4px;
    transition: 0.3s ease-in-out;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    // &:hover{
    //   transform: scale(1.1);
    // }
  i{
    vertical-align: middle;
    margin-top: -1px;
  }
}
  </style>
  