<template>
  <div id="app">
    <headerApp></headerApp>
    <div class="container-fluid">
     <router-view></router-view>
  </div>
  <footerApp></footerApp>
  </div>
</template>

<script>
import headerApp from "./components/global/headerApp.vue";
import footerApp from "./components/global/footerApp.vue";
import $ from 'jquery';
export default {
  name: "App",
  components: {
    headerApp,
    footerApp
  },
  data(){
    return{}
  },
  methods:{
    add_fav(id){
      if(document.querySelector('#fav'+id).getElementsByTagName('i')[0].classList.contains('bxs-heart')){
        document.querySelector('#fav'+id).getElementsByTagName('i')[0].classList.add('bx-heart')
     document.querySelector('#fav'+id).getElementsByTagName('i')[0].classList.remove('bxs-heart')
      }else{
      document.querySelector('#fav'+id).getElementsByTagName('i')[0].classList.remove('bx-heart')
     document.querySelector('#fav'+id).getElementsByTagName('i')[0].classList.add('bxs-heart')
      }
     
      //<i class='bx bxs-heart' ></i>
    },
    sidebar(){
    $('.cat-toggle-btn').on('click', function () {
			$('.cat__menu').slideToggle(500);
		});
    }
  },
  mounted(){
    this.sidebar();
  }
};
</script>

<style  lang="scss">
</style>