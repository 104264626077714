<template>
    <div class="following_wrap">
    <div class="wrap_follow">
        <!-- start following -->
        <div class="following_w">
          <!-- <h3 class="tabname">Following</h3> -->
          <section>
        <!-- <p class="content"><b>Selected:</b> {{ selected }}</p> -->
        <!--  label="Find a movie" -->
        <b-field label="followers">
            <b-autocomplete
                :data="data"
                placeholder="Find member or company"
                field="title"
                :loading="isFetching"
                @typing="getAsyncData"
                @select="option => selected = option">

                <template slot-scope="props">
                    <div class="media">
                        <div class="media-left">
                            <img width="32" :src="`https://image.tmdb.org/t/p/w500/${props.option.poster_path}`">
                        </div>
                        <div class="media-content">
                            {{ props.option.title }}
                            <br>
                            <small>
                                Released at {{ props.option.release_date }},
                                rated <b>{{ props.option.vote_average }}</b>
                            </small>
                        </div>
                    </div>
                </template>
            </b-autocomplete>
        </b-field>
    </section>
        <div class="row mrgt">
          <div class="col-12">
            <div class="box_follow">
              <div class="image_foll">
                <img src="http://gooby.net/storage/2763/625fd71f8834e_business-logo-design-template-78655edda18bc1196ab28760f1535baa_screen.jpg" alt="">
              </div>
              <div class="name_foll">
                <span class="co_name">AbuFarha Medical  <i class="bx bxs-badge-check"></i></span>
                <span class="user_name">Mr. Yazan AbuFarha</span>
                <span class="address_name">Queen Rania Str, Amman Jordan</span>
                <span class="foll_num">1099 Followers</span>
              </div>
              <div class="acc_type">
                <div class="flex_type">
                 <div class="sing_flex">
                  <span class="key_t">Account:</span>
                  <span class="val_t">Gold</span>
                 </div>
                 <div class="sing_flex">
                  <span class="key_t">Type:</span>
                  <span class="val_t">Trade Company</span>
                 </div>
                 <div class="sing_flex">
                  <span class="key_t">Member Since:</span>
                  <span class="val_t">5 Years</span>
                 </div>
                 <div class="your_rate info_text">
              <b-rate
                icon-pack="fas"
                v-model="rate"
                :disabled="true"
                custom-text="Rated by 99 Users"
              ></b-rate>
            </div>
                </div>
              </div>
              <div class="acc_tools">
                <span class="bs"> 
                  <vs-button><i class='bx bxs-id-card' ></i>
                    Business Card
                    </vs-button>
                 </span>
                <span class="webs">
                  <vs-button danger><i class='bx bx-store' ></i> 
                    Web Store
                    </vs-button>
                  </span>
                <span class="smsg">
                  <vs-button success><i class='bx bx-chat' ></i> 
                    Send a Message
                    </vs-button>
                  </span>
              </div>
              <div class="acc_badage">
                <img src="https://www.freeiconspng.com/thumbs/badge-icon-png/badge-icon-png-22.png" alt="">
                <img src="https://www.freeiconspng.com/thumbs/badge-icon-png/badge-icon-png-22.png" alt="">
              </div>
            </div>
            <div class="box_follow">
              <div class="image_foll">
                <img src="http://gooby.net/storage/2763/625fd71f8834e_business-logo-design-template-78655edda18bc1196ab28760f1535baa_screen.jpg" alt="">
              </div>
              <div class="name_foll">
                <span class="co_name">AbuFarha Medical  <i class="bx bxs-badge-check"></i></span>
                <span class="user_name">Mr. Yazan AbuFarha</span>
                <span class="address_name">Queen Rania Str, Amman Jordan</span>
                <span class="foll_num">1099 Followers</span>
              </div>
              <div class="acc_type">
                <div class="flex_type">
                 <div class="sing_flex">
                  <span class="key_t">Account:</span>
                  <span class="val_t">Gold</span>
                 </div>
                 <div class="sing_flex">
                  <span class="key_t">Type:</span>
                  <span class="val_t">Trade Company</span>
                 </div>
                 <div class="sing_flex">
                  <span class="key_t">Member Since:</span>
                  <span class="val_t">5 Years</span>
                 </div>
                 <div class="your_rate info_text">
              <b-rate
                icon-pack="fas"
                v-model="rate"
                :disabled="true"
                custom-text="Rated by 99 Users"
              ></b-rate>
            </div>
                </div>
              </div>
              <div class="acc_tools">
                <span class="bs"> 
                  <vs-button><i class='bx bxs-id-card' ></i>
                    Business Card
                    </vs-button>
                 </span>
                <span class="webs">
                  <vs-button danger><i class='bx bx-store' ></i> 
                    Web Store
                    </vs-button>
                  </span>
                <span class="smsg">
                  <vs-button success><i class='bx bx-chat' ></i> 
                    Send a Message
                    </vs-button>
                  </span>
              </div>
              <div class="acc_badage">
                <img src="https://www.freeiconspng.com/thumbs/badge-icon-png/badge-icon-png-22.png" alt="">
                <img src="https://www.freeiconspng.com/thumbs/badge-icon-png/badge-icon-png-22.png" alt="">
              </div>
            </div>
            <div class="box_follow">
              <div class="image_foll">
                <img src="http://gooby.net/storage/2763/625fd71f8834e_business-logo-design-template-78655edda18bc1196ab28760f1535baa_screen.jpg" alt="">
              </div>
              <div class="name_foll">
                <span class="co_name">AbuFarha Medical  <i class="bx bxs-badge-check"></i></span>
                <span class="user_name">Mr. Yazan AbuFarha</span>
                <span class="address_name">Queen Rania Str, Amman Jordan</span>
                <span class="foll_num">1099 Followers</span>
              </div>
              <div class="acc_type">
                <div class="flex_type">
                 <div class="sing_flex">
                  <span class="key_t">Account:</span>
                  <span class="val_t">Gold</span>
                 </div>
                 <div class="sing_flex">
                  <span class="key_t">Type:</span>
                  <span class="val_t">Trade Company</span>
                 </div>
                 <div class="sing_flex">
                  <span class="key_t">Member Since:</span>
                  <span class="val_t">5 Years</span>
                 </div>
                 <div class="your_rate info_text">
              <b-rate
                icon-pack="fas"
                v-model="rate"
                :disabled="true"
                custom-text="Rated by 99 Users"
              ></b-rate>
            </div>
                </div>
              </div>
              <div class="acc_tools">
                <span class="bs"> 
                  <vs-button><i class='bx bxs-id-card' ></i>
                    Business Card
                    </vs-button>
                 </span>
                <span class="webs">
                  <vs-button danger><i class='bx bx-store' ></i> 
                    Web Store
                    </vs-button>
                  </span>
                <span class="smsg">
                  <vs-button success><i class='bx bx-chat' ></i> 
                    Send a Message
                    </vs-button>
                  </span>
              </div>
              <div class="acc_badage">
                <img src="https://www.freeiconspng.com/thumbs/badge-icon-png/badge-icon-png-22.png" alt="">
                <img src="https://www.freeiconspng.com/thumbs/badge-icon-png/badge-icon-png-22.png" alt="">
              </div>
            </div>
          </div>
        </div>
        </div>
    </div>
    </div>
  </template>
  <script>
  import { mapActions, mapGetters } from "vuex";
  import debounce from 'lodash/debounce'
  export default {
    name: "followers_profile",
    data() {
      return {
        data: [],
        selected: null,
        isFetching: false,
        rate: 4,
      };
    },
    computed: {
      ...mapGetters({
      }),
    },
    methods: {
      ...mapActions({
      }),
      getAsyncData: debounce(function (name) {
                if (!name.length) {
                    this.data = []
                    return
                }
                this.isFetching = true
                this.$http.get(`https://api.themoviedb.org/3/search/movie?api_key=bb6f51bef07465653c3e553d6ab161a8&query=${name}`)
                    .then(({ data }) => {
                        this.data = []
                        data.results.forEach((item) => this.data.push(item))
                    })
                    .catch((error) => {
                        this.data = []
                        throw error
                    })
                    .finally(() => {
                        this.isFetching = false
                    })
            }, 500)
    },
    mounted() {},
  };
  </script>
  <style lang="scss">
  .following_wrap {
    padding-top: 20px;
    .mrgb {
      margin-bottom: 14px;
      position: relative;
    }
    .mrgt {
      margin-top: 25px;
    }
    .tabname {
      font-size: 18px;
      font-weight: 600;
      margin: 0;
      padding: 0;
      margin-bottom: 20px;
    }
    .box_follow{
      display: flex;
      align-items: flex-start;
      background-color: white;
      box-shadow: 0px 1px 2px #cbcbcb;
      margin-bottom: 25px;
      justify-content: space-between;
      padding-top: 10px;
      padding-bottom: 20px;
      .image_foll{
        // flex: 0 0 120px;
        padding-top: 14px;
        flex: 0 0 18%;
        img{
          width: 100%;
          height: 110px;
          object-fit: cover;
        }
      }
      .name_foll{
        padding-top: 14px;
        // flex: 0 0 260px;
        flex: 0 0 24%;
        i{
          vertical-align: middle;
          padding-left: 10px;
          margin-bottom: 4px;
        }
        span{
          display: block;
          font-size: 14px;
          font-weight: 500;
          margin-bottom: 2px;
        }
        .co_name{
          font-size: 1.1rem;
          font-weight: 500;
          color: var(--active);
        }
      }
      .acc_type{
        flex: 0 0 25%;
        padding-top: 14px;
        .sing_flex{
          display: flex;
          justify-content: space-between;
          font-size: 14px;
        }
        .info_text {
        margin-top: 8px;
        .key_info {
          font-weight: 500;
          text-transform: capitalize;
          font-size: 15px;
          i {
            font-size: 16px;
            vertical-align: middle;
            margin-bottom: 4px;
            // color: var(--active);
            color: #343a40;
            //color: black;
          }
        }
        .val_info {
          font-size: 14px;
          padding-left: 4px;
          font-weight: 400;
          i {
            color: var(--active);
            font-size: 18px;
            vertical-align: middle;
            display: inline-block;
            margin-bottom: 2px;
          }
          .verfi {
            color: var(--gr);
            margin-left: 10px;
          }
        }
      }
      }
      .acc_tools{
        // flex: 0 0 150px;
        flex: 0 0 18%;
        padding-top: 4px;
        padding-left: 8px;
        span{
          display: block;
          font-size: 14px;
        }
        i{
          margin-right: 4px;
          font-size: 18px;
        }
      }
      .acc_badage{
        // flex: 0 0 100px;
        flex: 0 0 10%;
        padding-top: 14px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        img{
          width: 50px;
        }
      }
    }
  }
  </style>
  