<template>
  <div class="map_company_wraps">
    <h3 class="tabname">Webstore</h3>
    <div class="branches_wrap">
      <!-- input form  -->
      <div class="form_wraps">
        <div class="row">
          <div class="col-md-12 mrgb2">
            <b-field label="Webstore Slider Image" class="upload_img">
                 <b-upload v-model="dropFiles_arr" drag-drop multiple expanded required>
                        <section class="section">
                          <div class="content has-text-centered">
                            <p class="uploadIco">
                              <i class="bx bxs-cloud-upload"></i>
                            </p>
                            <p>Upload Company Image</p>
                          </div>
                        </section>
                      </b-upload>
                    <p class="sp_desc">(T. ex) Office building, Staffs, Convention, etc.</p>
                    <p class="sp_desc danger danger_c">Recommended image size: 000000 X 00000 pixel.</p>
                    <p class="sp_desc danger">The First image will be displayed on the main page of your Storefront.</p>
                    <div class="tags">
                      <span v-for="(file, index) in dropFiles_arr"
                          :key="index"
                          class="tag is-primary" >
                          {{file.name}}
                          <button class="delete is-small"
                              type="button"
                              @click="deleteDropFileMulti(index)">
                          </button>
                      </span>
                  </div>
            </b-field>
          </div>
          <div class="col-md-4 col-12 mr-auto ml-auto mrgt">
            <vs-button gradient>Save My Data</vs-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "webstore_profile",
  data() {
    return {
      dropFiles_arr: [],
    };
  },
  computed: {
    ...mapGetters({}),
  },
  methods: {
    ...mapActions({}),
    deleteDropFileMulti(index) {
                this.dropFiles_arr.splice(index, 1)
            }
  },
  mounted() {},
};
</script>
<style lang="scss">
.map_company_wraps {
  padding-top: 20px;
  .mrgb {
    margin-bottom: 14px;
    position: relative;
  }
  .mrgt {
    margin-top: 25px;
  }
  .tabname {
    font-size: 18px;
    font-weight: 600;
    margin: 0;
    padding: 0;
    margin-bottom: 20px;
  }
}
</style>
