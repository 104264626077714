<template>
 <div class="container">
     <!-- Main Slider -->
     <div class="main_Slider">
      <div uk-slideshow="animation: push;autoplay-interval: 6000;autoplay: true;pause-on-hover: false;min-height:440;max-height:450">

    <div class="uk-position-relative uk-visible-toggle uk-light" tabindex="-1">

        <ul class="uk-slideshow-items">
            <li>
                <img src="https://images.unsplash.com/photo-1537498425277-c283d32ef9db?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2956&q=80" alt="" uk-cover>
            </li>
            <li>
                <img src="https://images.unsplash.com/photo-1483058712412-4245e9b90334?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2940&q=80" alt="" uk-cover>
            </li>
             <li>
                <img src="https://images.unsplash.com/photo-1482440308425-276ad0f28b19?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2940&q=80" alt="" uk-cover>
            </li>
            
        </ul>

        <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slideshow-item="previous"></a>
        <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slideshow-item="next"></a>

    </div>

    <!-- <ul class="uk-slideshow-nav uk-dotnav uk-flex-center uk-margin"></ul> -->

</div>
    </div>
    <!-- end slider -->
    <div class="latest_products">
      <div class="title_sec">
        <h2>Latest Listings & Products From AbuFarha</h2>
        <router-link to="/">See All <i class='bx bx-chevron-right'></i></router-link>
      </div>
      <div class="prod_list_wraps">
        <div class="uk-position-relative uk-visible-toggle uk-light" tabindex="-1" uk-slider="autoplay: 3000">
        <ul class="uk-slider-items uk-child-width-1-2 uk-child-width-1-5@l uk-grid">
            <li v-for="prod in 10" :key="prod">
              <div class="box_products">
           <div class="fav_box" :id="`fav${index+8}`" @click="add_fav(index+8)">
            <vs-avatar  badge-color="danger" badge-position="top-right">
              <i class='bx bx-heart' ></i>
            </vs-avatar>
           </div>
          <div class="imagebox">
            <img src="https://www.3erp.com/wp-content/uploads/2016/12/medical.png" alt="">
          </div>
          <div class="description">
            <h3>Product Name device</h3>
             <p>Lorem Ipsum is simply dummy  </p>
            <p class="shipping">Shipping from: USA <img src="~@/assets/flag1.png" alt=""> </p>
          </div>
         <div class="btn_contact">
            <vs-button gradient> Contact supplier</vs-button>
         </div>
             </div>
            </li>
        </ul>
        <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slider-item="previous"></a>
        <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slider-item="next"></a>
      </div>
      </div>
    </div>
    <!-- products sidebar -->
    <div class="row">
      <div class="col-12">
        <div class="title_sec mb-5">
        <h2>Hot Categores</h2>
        <router-link to="/">See All <i class='bx bx-chevron-right'></i></router-link>
      </div>
      </div>
      <div class="col-xl-3">
        <div class="hidden">
      <vs-sidebar
        v-model="active"
        open
        >
        <template #logo>
          <!-- ...img logo -->
        </template>
        <vs-sidebar-item id="home">
          <template #icon>
            <i class='bx bx-home'></i>
          </template>
         Ultrasound Machine
        </vs-sidebar-item>
       
        <vs-sidebar-item id="Music">
          <template #icon>
            <i class='bx bxs-music'></i>
          </template>
          Ultrasound Machine
        </vs-sidebar-item>
        <vs-sidebar-group>
          <template #header>
            <vs-sidebar-item arrow>
              <template #icon>
                <i class='bx bx-group'></i>
              </template>
              Ultrasound Machine
            </vs-sidebar-item>
          </template>

          <vs-sidebar-item id="Instagram">
            <template #icon>
              <i class='bx bxl-instagram'></i>
            </template>
            Ultrasound Machine
          </vs-sidebar-item>
          <vs-sidebar-item id="twitter">
            <template #icon>
              <i class='bx bxl-twitter' ></i>
            </template>
            Ultrasound Machine
          </vs-sidebar-item>
          <vs-sidebar-item id="Facebook">
            <template #icon>
              <i class='bx bxl-facebook' ></i>
            </template>
            Ultrasound Machine
          </vs-sidebar-item>
        </vs-sidebar-group>
        <vs-sidebar-group>
          <template #header>
            <vs-sidebar-item arrow>
              <template #icon>
                <i class='bx bx-code-alt' ></i>
              </template>
              Ultrasound Machine
            </vs-sidebar-item>
          </template>

          <!-- start -->
              <vs-sidebar-group>
          <template #header>
            <vs-sidebar-item arrow>
              <template #icon>
                <i class='bx bx-group'></i>
              </template>
              Ultrasound Machine
            </vs-sidebar-item>
          </template>

          <vs-sidebar-item id="Instagram">
            <template #icon>
              <i class='bx bxl-instagram'></i>
            </template>
            Ultrasound Machine
          </vs-sidebar-item>
          <vs-sidebar-item id="twitter">
            <template #icon>
              <i class='bx bxl-twitter' ></i>
            </template>
            Ultrasound Machine
          </vs-sidebar-item>
          <vs-sidebar-item id="Facebook">
            <template #icon>
              <i class='bx bxl-facebook' ></i>
            </template>
            Ultrasound Machine
          </vs-sidebar-item>
        </vs-sidebar-group>
          <!-- end -->
          <vs-sidebar-item id="codepen">
            <template #icon>
              <i class='bx bxl-codepen'></i>
            </template>
            Ultrasound Machine
          </vs-sidebar-item>
          <vs-sidebar-item id="discord">
            <template #icon>
              <i class='bx bxl-discord'></i>
            </template>
            Ultrasound Machine
          </vs-sidebar-item>
          <vs-sidebar-item id="Javascript">
            <template #icon>
              <i class='bx bxl-javascript' ></i>
            </template>
            Ultrasound Machine
          </vs-sidebar-item>
          <vs-sidebar-item id="git">
            <template #icon>
              <i class='bx bxl-git' ></i>
            </template>
            Ultrasound Machine
          </vs-sidebar-item>
        </vs-sidebar-group>
        <vs-sidebar-item id="donate">
          <template #icon>
            <i class='bx bxs-donate-heart' ></i>
          </template>
          Ultrasound Machine
        </vs-sidebar-item>
        <vs-sidebar-item id="drink">
          <template #icon>
            <i class='bx bx-drink'></i>
          </template>
          Ultrasound Machine
        </vs-sidebar-item>
        <vs-sidebar-item id="shopping">
          <template #icon>
            <i class='bx bxs-shopping-bags'></i>
          </template>
          Ultrasound Machine
        </vs-sidebar-item>
        <vs-sidebar-item id="chat">
          <template #icon>
            <i class='bx bx-chat' ></i>
          </template>
          Ultrasound Machine
        </vs-sidebar-item>
      </vs-sidebar>
    </div>
      </div>
      <div class="col-xl-9">
        <div class="row">
          <div class="col-xl-3"  v-for="prod in 10" :key="prod">
            <div class="box_products">
           <div class="fav_box" :id="`fav${index+8}`" @click="add_fav(index+8)">
            <vs-avatar  badge-color="danger" badge-position="top-right">
              <i class='bx bx-heart' ></i>
            </vs-avatar>
           </div>
          <div class="imagebox">
            <img src="https://www.3erp.com/wp-content/uploads/2016/12/medical.png" alt="">
          </div>
          <div class="description">
            <h3>Product Name device</h3>
             <p>Lorem Ipsum is simply dummy  </p>
            <p class="shipping">Shipping from: USA <img src="~@/assets/flag1.png" alt=""> </p>
          </div>
         <div class="btn_contact">
            <vs-button gradient> Contact supplier</vs-button>
         </div>
             </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="text-center">
       <vs-pagination style="z-index: 0;" v-model="page" :length="5" color="#00bfff" />
     </div>
      </div>
    </div>
    <!-- end -->
    <!-- certification -->
    <div class="latest_products">
      <div class="title_sec justify-content-center">
        <h2>Certification & Honors</h2>
        <!-- <router-link to="/">See All <i class='bx bx-chevron-right'></i></router-link> -->
      </div>
      <div class="prod_list_wraps">
        <div class="uk-position-relative uk-visible-toggle uk-light" tabindex="-1" uk-slider="autoplay: 3000">
        <ul class="uk-slider-items uk-child-width-1-2 uk-child-width-1-3@l uk-grid">
            <li v-for="prod in 8" :key="prod">
              <img src="https://marketplace.canva.com/EAFmXHnvaeQ/1/0/1600w/canva-black-and-gold-luxury-achievement-certificate-qsAlK5vJSf4.jpg" alt="">
            </li>
        </ul>
        <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slider-item="previous"></a>
        <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slider-item="next"></a>
      </div>
      </div>
    </div>
    <!-- end -->
    <!-- certification -->
    <div class="sendMessage">
     <div class="text_send">
      <h2>Send Message To Supplier</h2>
      <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's </p>
     </div>
     <form action="#" class="form_contact">
               <textarea class="uk-input"  placeholder="Enter Your Message" ></textarea>
          <van-button color="#1d9bf0">Submit</van-button> 
        </form>
    </div>
    <!-- end -->
 </div>
</template>
<script>
export default {
  name: "HomeStoreArea",
  data() {
    return {
        page:1
    };
  },
  components: {},
};
</script>
<style lang="scss" scoped>
.title_sec{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--active);
    margin-top: 40px;
    padding: 12px 10px;
    border-radius: 8px;
    h2{
      font-size: 18px;
      font-weight: 600;
    }
    h2,a{
      color: white;
      i{
        vertical-align: middle;
        margin-top: -3px;
        font-size: 20px;
      }
    }
  }
.prod_list_wraps{
  padding-top: 40px;
  .row_prod{
  padding-top: 40px;
}
}
.box_products{
  border: 1px solid gainsboro;
  background-color: white;
  border-radius: 10px;
  position: relative;
  margin-bottom: 40px;
  transition: filter 300ms;
  color: #000;
  &:hover{
    filter: drop-shadow(0 0 1em #00bfff);
    .imagebox{
    background-color: #00bfff;
    border-radius: 10px;
    img{
      transform: scale(1.1) translateY(-25px);
    }
  }
  }
  .imagebox{
    background-color: rgb(240, 240, 240);
    border-radius: 10px;
     transition: 0.3s linear;
    img{
      height: 120px;
      width: 100%;
      object-fit: contain;
      transition: 0.3s linear;
    }
  }
  .description{
    padding: 14px 8px;
    h3{
      font-size: 0.9rem;
      font-weight: 500;
      color: #000;
    }
    p{
      font-size: 13px;
      opacity: 0.8;
    }
    p.shipping{
      opacity: 1;
      color: #000;
      img{
        height: 18px;
           vertical-align: top;
           padding-left: 10px;
      }
    }
  }
  .fav_box{
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 2;
    cursor: pointer;
  }
  .bxs-heart{
    color: red;
  }
  .btn_contact{
  padding: 10px 20px;
  }
}
.sendMessage{
  background-color: white;
  padding: 50px;
  text-align: center;
  margin-top: 50px;
  .text_send{
    margin-bottom: 30px;
    h2{
      font-size: 18px;
      font-weight: 600;
    }
    p{
      font-size: 14px;
    }
  }
  .form_contact{
    textarea{
      height: 200px;
      font-size: 14px;
      border: 1px solid gainsboro !important;
      border-radius: 4px;
    }
    button{
      width: 200px;
      margin-top: 20px;
      border-radius: 4px;
    }
  }
}
</style>
