<template>
    <div class="dashoard_user_info add_adds_wraps">
      <div class="container">
        <h3 class="tabname">buyer Offers</h3>
     <div class="personal_wraps">
      <!-- input form  -->
        <div class="form_wraps">
            <div class="row">
                <div class="col-md-12 mrgb">
                  <b-field label="Choose">
               <vs-select placeholder="Select" v-model="value">
          <vs-option label="Choose 1" value="1">
            Choose 1 
          </vs-option>
          <vs-option label="Choose 2" value="2">
            Choose 2 
          </vs-option>
          <vs-option label="Choose 3" value="3">
            Choose 3 
          </vs-option>
        </vs-select>
          </b-field>
              </div>
              <div class="col-md-6 mrgb">
                   <b-field label="Product Name">
                  <vs-input placeholder="" />
                  </b-field>
              </div>
              <div class="col-md-6 mrgb">
                  <b-field label="Category">
               <vs-select placeholder="Select" v-model="value2">
          <vs-option label="Category 1" value="1">
            Category 1 
          </vs-option>
          <vs-option label="Category 2" value="2">
            Category 2 
          </vs-option>
          <vs-option label="Category 3" value="3">
            Category 3 
          </vs-option>
        </vs-select>
          </b-field>
              </div>
              <div class="col-md-12 mrgb">
                  <b-field label="Conditions">
               <vs-select placeholder="Conditions" v-model="value3">
          <vs-option label="New" value="1">
            New 
          </vs-option>
          <vs-option label="Used" value="2">
            Used
          </vs-option>
        </vs-select>
          </b-field>
              </div>
              <div class="col-md-12 mrgb">
                  <b-field label="Country Where Product/Service Will Be Used">
               <vs-select placeholder="Select" v-model="value3">
                    <vs-option label="Jordan" value="1">
                        Jordan
                    </vs-option>
                    <vs-option label="India" value="2">
                    India
                    </vs-option>
                    <vs-option label="Spain" value="3">
                        Spain
                    </vs-option>
                    <vs-option  label="Usa" value="4">
                    Usa
                    </vs-option>
                    <vs-option label="Russia" value="6">
                    Russia
                    </vs-option>
                    <vs-option label="Qatar" value="7">
                    Qatar
                    </vs-option>
                    <vs-option label="Egypt" value="8">
                    Egypt
                    </vs-option>
                    </vs-select>
                    </b-field>
            </div>
            <div class="col-md-12 mrgb mt-2">
                   <b-field label="Budget">
                    <div class="slider_price">
              <div class="flex_bud">
                <span>{{ budget[0] }} USD</span>
                <span>{{ budget[1] }} USD</span>
              </div>
      <van-slider style="margin-top:30px" v-model="budget" :max="50000" range @change="onChange" />
    </div>
                  </b-field>
              </div>
              <div class="col-md-12 mrgb">
                   <b-field label="Quantity">
                   <vs-input placeholder="" />
                  </b-field>
              </div>
              <div class="col-md-12 mrgb">
                  <b-field label="Looking To Purchase Within (Timeframe)">
                   <vs-select placeholder="" v-model="value">
                  <vs-option label=" option 1" value="1">
                    option 1
          </vs-option>
          <vs-option label=" option 2" value="2">
            option 2
          </vs-option>
          <vs-option label=" option 3" value="3">
            option 3
          </vs-option>
        </vs-select>
          </b-field>
              </div>             
              <div class="col-md-12 mrgb">
                   <b-field label="Details">
                   <textarea class="textarea"></textarea>
                  </b-field>
              </div>
              <!--  -->
              <div class="col-12 mrgb">
                  <h2 class="tabname">Attachment</h2>
              </div>
              <div class="col-12 mrgb">
                <van-uploader v-model="fileList" multiple :max-count="max_cont" preview-size="150"	 />
              </div>
              <div class="col-md-12 mrgb">
                   <b-field label="Shipping method">
                   <vs-input placeholder="" />
                  </b-field>
              </div>
              <div class="col-md-12 mrgb">
                   <b-field label="Destination">
                   <vs-input placeholder="" />
                  </b-field>
              </div>
              <div class="col-md-12 mrgb">
                   <b-field label="Lead time">
                    <div class="d-flex align-items-center flex_inpp">
                        <span> Ship in</span> <vs-input placeholder="" /> <span>day(s) after supplier receives the initial payment</span>
                    </div>
                  </b-field>
              </div>
              <div class="col-md-12 mrgb">
                  <b-field label="Payment method">
               <vs-select placeholder="Select" v-model="value3">
                    <vs-option label="Value 1" value="1">
                       Value 1
                    </vs-option>
                    <vs-option label="Value 2" value="2">
                        Value 2
                    </vs-option>
                    <vs-option label="Value 3" value="3">
                        Value 3
                    </vs-option>
                    <vs-option  label="Value 4" value="4">
                        Value 4
                    </vs-option>
                    <vs-option label="Value 5" value="6">
                        Value 5
                    </vs-option>
                    </vs-select>
                    </b-field>
            </div>
              <!--  -->
                <!-- end upload divs -->
                  <div class="col-md-4 col-12 mr-auto ml-auto mrgt">
                    <vs-button gradient>Publish Offers</vs-button>
              </div>
                </div>
        </div>
      
     </div>
      </div>
    </div>
  </template>
  <script>
  import { mapActions, mapGetters } from "vuex";
  //  import axios from 'axios'
  
  export default {
    name: "sellerOffers",
    data() {
      return {
          n_name:1,
          im:1,
          picked:1,
          hasVisiblePassword: false,
          password:'password',
           htmlForEditor: "",
           dropFiles: null,
           dropFiles_arr:[],
          // 
          value:1,
          value2:1,
          value3:1,
          value4:1,
          budget:[1000,25000],
          mySpesification:[],
          fileList: [{ url: 'https://img01.yzcdn.cn/vant/leaf.jpg' }, { url: 'https://img01.yzcdn.cn/vant/leaf.jpg' }],
           option1: 1,
           option2:'',
           max_cont:4,
           payterms:[],
           certification:[],
           dateTimes:'',
           active:0,
      };
    },
    components: {
    },
    computed: {
      ...mapGetters({}),
    },
    methods: {
      ...mapActions({}),
       handleImageAdded: function(file, Editor, cursorLocation) {
          // An example of using FormData
          // NOTE: Your key could be different such as:
          // formData.append('file', file)
          console.log(file);
          Editor.insertEmbed(cursorLocation, 'image', 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSTB-pULN4tWIgabMt9ooDqzE6mD-tQ3VfkzbHDRqPhTrR3lwS4T1tkU7DMz35OBHl3NVE&usqp=CAU');
          // var formData = new FormData();
          // formData.append('image', file)
   
          // axios({
          //   url: 'https://fakeapi.yoursite.com/images',
          //   method: 'POST',
          //   data: formData
          // })
          // .then((result) => {
          //   let url = result.data.url // Get url from response
          //   Editor.insertEmbed(cursorLocation, 'image', url);
            
          // })
          // .catch((err) => {
          //   console.log(err);
          // })
        },
         deleteDropFile() {
        this.dropFiles=null;
      },
       deleteDropFileMulti(index) {
                  this.dropFiles.splice(index, 1)
              },
              onchangeBudget(){
        },
        onchangePeriod(){
        },
        onChange(value) {
          console.log(value)
      // Toast('current value：' + value);
    },
    addSkills() {
      this.mySpesification.push({key:'',value:''});
    },
    removeSkills(index) {
      this.mySpesification.splice(index, 1);
    },
    avalibaleSample(){
        console.log(this.option1)
    }
    },
    mounted() {},
  };
  </script>
  <style lang="scss" scoped>
  .dashoard_user_info{
      padding-top: 200px;
      .mrgb{
          margin-bottom: 20px;
          position: relative;
      }
      .mrgb2{
          margin-bottom: 20px;
          position: relative;
      }
      .mrgt{
          margin-top: 25px;
      }
      .or_span{
              margin-bottom: 6px;
      display: inline-block;
      padding-right: 15px;
      font-size: 15px;
      font-weight: 500;
      }
      .center_flex , .van-checkbox-group  , .van-radio-group{
        display: flex;
      height: 100%;
      justify-content: flex-start;
      align-items: flex-end;
      .last_check{
          margin-left: 15px;
      }
      .van-checkbox , .van-radio{
        margin-right: 20px;
      }
      }
      .change_span{
         font-size: 13px;
      color: var(--active);
      cursor: pointer;
      position: absolute;
      right: 20px;
      bottom: -14px;
      font-weight: 400;
      text-transform: capitalize;
      }
      .tabname{
        font-size: 16px;
    margin: 0;
    padding: 0;
    background-color: gainsboro;
    display: inline-block;
    padding: 6px 20px;
    border-radius: 5px;
    font-weight: 600;
    color: black;
    min-width: 200px;
    text-align: center;
    margin-top: 20px;
      }
      .personal_wraps{
      padding-top: 20px;
      .type_user{
      display: flex;
      align-items: center;
      .key_span{
          display: inline-block;
      margin-right: 20px;
      font-size: 16px;
      font-weight: 500;
      }
      .radio_wrap{
          display: flex;
          align-items: center;
          .vs-radio-content{
              margin-right: 10px;
          }
      }
      }
      .form_wraps{
          padding-top: 0px;
          // max-width: 700px;
      }
      }
  }
.data_keys{
    .single_data{
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        border-bottom: 1px solid rgba(220, 220, 220, 0.532);
        padding: 8px 0px;
        &:last-child{
            border-bottom: none;
        }
        .key1{
            font-weight: 600;
        }
        .val1{
            opacity: 0.5;
        }
    }
}
.textarea{
    background-color: white !important;
    font-size: 14px !important;
}
.slider_price{
         .flex_bud{
           display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    right: 0;
    top: 0;
    width: 200px;
           span{
             font-style: normal;
             font-weight: 400;
             font-size: 14px;
             line-height: 21px;
             color: #A1A6AD;
           }
         }
       }
       .flex_inpp{
        .vs-input-parent{
        margin: 0px 20px;
    }
}
  </style>
  