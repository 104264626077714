<template>
    <div class="forumDetails_wraps">
        <div class="container">
            <div class="details_topic">
                <div class="user">
                    <div class="image">
                        <img src="https://i.pinimg.com/736x/ee/50/de/ee50dec983d90280a90a40afbe5be29d.jpg" alt="">
                    </div>
                    <div class="text_user">
                      <div class="username">
                        Mohammad Abu Alsamen
                      </div>
                      <div class="title_topic">
                        How do I find the software and BT version of a Voluson Expert
                      </div>
                      <div class="date">
                        August 18, 2010 10:34
                      </div>
                    </div>
                </div>
                <div class="description_topic">
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,</p>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,</p>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,</p>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,</p>
                </div>
                <div class="add_new_comment">
                    <div class="addArea_comment">
                        <h2>Add Comment</h2>
                      <div class="form">
                        <b-field label="">
                            <vs-select placeholder="Select" v-model="usernameSelect">
                                <vs-option label="Yazan Abu Farha" value="1">
                                    Yazan Abu Farha
                                </vs-option>
                                <vs-option label="Yazan Abu Farha 2" value="2">
                                    Yazan Abu Farha 2
                                </vs-option>
                                <vs-option label="Yazan Abu Farha 3" value="3">
                                    Yazan Abu Farha 3
                                </vs-option>
                                </vs-select>
                         </b-field>
                        <textarea name="" id="" v-model="comment" class="textAreaComment"  placeholder="Comment Now"></textarea>
                        <van-button color="#1d9bf0">
                            Send
                        </van-button>
                      </div>
                    </div>
                    <div class="list_comment">
                        <h2 class="list_comm">Comments</h2>
                        <div v-for="(item, index) in comments" :key="index">
                        <Comment
                    v-bind="{
                    avatar: item.image,
                    author: item.author,
                    comment: item.comment,
                    replies: item.replies,
                    is_liked: item.is_liked,
                    is_disliked: item.is_disliked,
                    id: item.id,
                    votes: item.votes,
                    date: item.date,
                    }"
                    ></Comment>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Comment from "./Comment.vue";
export default {
  name: "forumDetails",
  data() {
    return {
        comments: [
        {
          author: "Omar Eyad",
          id: 1,
          image: "https://picsum.photos/50",
          comment: "I love this product",
          date: "2023-10-01",
          votes: 10,
          is_liked: true,
          is_disliked: false,
          replies: [],
        },
        {
          author: "Mohammad Abu Alsamen",
          id: 2,
          comment:
            "I don't like this product",
          image: "https://picsum.photos/50",
          date: "2023-10-01",
          votes: -10,
          is_liked: false,
          is_disliked: false,
          replies: [
            {
              author: "Mohammad Hasab Allah",
              id: 3,
              comment:
                "Why not?",
              image: "https://picsum.photos/50",
              date: "2023-10-01",
              votes: 2,
              is_liked: false,
              is_disliked: false,
              replies: [],
            },
            {
              author: "Omar Eyad",
              id: 32,
              comment: "Amazing Products !! .",
              image: "https://picsum.photos/50",
              date: "2023-10-01",
              votes: 2,
              is_liked: true,
              is_disliked: false,
              replies: [
                {
                 author: "Mohammad Hasab Allah",
                  id: 32,
                  comment: "Nested Comment 1",
                  image: "https://picsum.photos/50",
                  date: "2023-10-01",
                  votes: 2,
                  is_liked: false,
                  is_disliked: false,
                  replies: [
                  {
                  author: "Omar  Eyad",
                  id: 32,
                  comment: "yes in Nested worked",
                  image: "https://picsum.photos/50",
                  date: "2019-01-01",
                  votes: 2,
                  is_liked: false,
                  is_disliked: false,
                  replies: [
                    {author: "Mohammad Abu Alsamen",
                  id: 32,
                  comment: "Nested Comment 2",
                  image: "https://picsum.photos/50",
                  date: "2019-01-01",
                  votes: 2,
                  is_liked: false,
                  is_disliked: false,

                    }
                  ],
                },
                  ],
                },
                {
                  author: "Yazan Abu Farha",
                  id: 32,
                  comment: "Good Comment !!",
                  image: "https://picsum.photos/50",
                  date: "2019-01-01",
                  votes: 2,
                  is_liked: false,
                  is_disliked: false,
                  replies: [],
                },
              ],
            },
            {
                author: "Yazan Abu Farha",
              id: 343,
              comment: "Thank You ..",
              image: "https://picsum.photos/50",
              date: "2019-01-01",
              votes: 2,
              is_liked: false,
              is_disliked: false,
              replies: [],
            },
            {
              author: "Mohammad Hasab Allah",
              id: 13,
              comment: "Wow , Its Great .",
              image: "https://picsum.photos/50",
              date: "2019-01-01",
              votes: 2,
              is_liked: false,
              is_disliked: false,
              replies: [
                {
                  author: "Yazan Abu Farha",
                  id: 321,
                  comment: "Yes Thx .",
                  image: "https://picsum.photos/50",
                  date: "2019-01-01",
                  votes: 2,
                  is_liked: false,
                  is_disliked: false,
                  replies: [],
                },
              ],
            },
            {
              author: "Omar Eyad",
              id: 3,
              comment: "Good Job .",
              image: "https://picsum.photos/50",
              date: "2019-01-01",
              votes: 2,
              is_liked: false,
              is_disliked: false,
              replies: [],
            },
          ],
        },
      ],
      comment:'',
      usernameSelect:'1'
    };
  },
  components: {
    Comment
  },
  methods:{
    addCommentFunc(){
      let myComment = this.comment;
      this.comments[0].replies.push(
        {
          author: "Omar Eyad",
          id: 1,
          image: "https://picsum.photos/50",
          comment:myComment,
          date: "2019-01-01",
          votes: 10,
          is_liked: true,
          is_disliked: false,
          replies: [],
        },
      )
    }
  }
};
</script>
<style lang="scss" scoped>
.forumDetails_wraps{
    min-height: 100vh;
    padding-top: 200px;
    background-color: white;
    border-radius: 14px;
    padding-bottom: 50px;
    .details_topic{
    .user{
        display: flex;
        align-items: flex-start;
        .image{
            img{
                width: 80px;
                height: 80px;
                object-fit: cover;
                border-radius: 100%;
                margin-top: -2px;
            }
        }
        .text_user{
            padding-left: 10px;
            .username{
                font-weight: 600;
                font-size: 15px;
                color: var(--active);
            }
            .title_topic{
                font-size: 16px;
                font-weight: 700;
            }
            .date{
                font-size: 13px;
                opacity: 0.5;
                color: #000;
            }
        }
    }
    .description_topic{
        padding-left: 90px;
        width: 80%;
        p{
        font-size: 14px;
        font-weight: 500;
        opacity: 0.7;
        margin-bottom: 12px;
        }

    }
    }
    .add_new_comment{
        padding-left: 90px;
        width: 80%;
        .textAreaComment{
            font-size: 13px;
            width: 100%;
            height: 120px;
            padding: 10px;
            border: 1px solid gainsboro !important;
            border-radius: 10px;
        }
    }
    .addArea_comment{
        margin-top: 50px;
        h2{
            font-size: 18px;
            font-weight: 600;
            color: #000;
            margin-bottom: 10px;
        }
        .form{
            button{
                width: 200px;
                border-radius: 10px;
            }
        }
    }
    .list_comment{
        margin-top: 50px;
        .list_comm{
            font-size: 18px;
            font-weight: 600;
            color: #000;
            margin-bottom: 10px;
        }
    }
}
</style>
