<template>
 <div class="rfq_section">
      <div class="form_order">
        <h2>One Request, Multiple Proposals</h2>
        <p>Ultrasound, from <img src="~@/assets/flag1.png" alt=""> Y******, received 10 Proposal(s)</p>
        <div class="row">
          <div class="col-12 input_parents">
            <vs-input  placeholder="Whar are you looking for..." />
          </div>
           <div class="col-xl-6 input_parents">
             <vs-input  placeholder="Quantity" />
           </div>
            <div class="col-xl-6 input_parents">
              <vs-input  placeholder="Piece / Pieces" />
            </div>
            <div class="col-xl-12">
       <vs-button color="rgb(59,222,200)" gradient>  Request for Quotation</vs-button>
            </div>
            <div class="col-xl-12">
 <vs-button gradient> Request for Services</vs-button>
             
            </div>
        </div>
      </div>
    </div>
</template>
<script>
export default {
  name: "RfqFormArea",
}
</script>
<style lang="scss" scoped>
.form_order{
      // box-shadow: 0px 2px 5px gainsboro;
 padding: 20px 10px;
    margin: 0 auto;
    height: 440px;
    border: 1px solid rgba(214, 214, 214, 0.461);
    // border-radius: 8px;
    background-color: white;
  h2{
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 10px;
  }
  p{
    font-size: 12px;
    display: block;
    margin-bottom: 20px;
    img{
      height: 20px;
      object-fit: contain;
    }
  }
  .vs-input-content , input{
    width: 100%;
  }
  .input_parents{
    margin-bottom: 20px;
  }
  .vs-button{
    width: 100%;
  }
}
</style>