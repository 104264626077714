<template>
  <div class="dashoard_user_info">
   <h3 class="tabname">Pesonal Information</h3>
   <div class="personal_wraps">
    <!-- <div class="type_user">
        <span class="key_span">I'm a :</span>
        <div class="radio_wrap">
      <vs-radio v-model="picked" val="1">
        Buyer
      </vs-radio>
      <vs-radio v-model="picked" success val="2">
        Seller
      </vs-radio>
      <vs-radio v-model="picked" danger val="3">
        Both
      </vs-radio>
      
        </div>
    </div>
     <div class="type_user">
        <span class="key_span">nickname :</span>
        <div class="radio_wrap">
      <vs-radio v-model="picked" val="1">
        Mr.
      </vs-radio>
      <vs-radio v-model="picked" success val="2">
        Ms.
      </vs-radio>
      <vs-radio v-model="picked" danger val="3">
        none
      </vs-radio>
      
        </div>
    </div> -->
    <!-- input form  -->
      <div class="form_wraps">
        <div class="row">
            <div class="col-12 mrgb">
                <b-field label="I'm a">
             <vs-select placeholder="Select" v-model="im">
        <vs-option label="Buyer" value="1">
          Buyer
        </vs-option>
        <vs-option label="Seller" value="2">
          Seller
        </vs-option>
        <vs-option label="Both" value="3">
          Both
        </vs-option>
      </vs-select>
        </b-field>
            </div>
            <div class="col-md-4 mrgb">
            <b-field label="nickname">
             <vs-select placeholder="Select" v-model="n_name">
        <vs-option label="Mr." value="1">
          Mr.
        </vs-option>
        <vs-option label="Ms." value="2">
          Ms.
        </vs-option>
        <vs-option label="none" value="3">
          none
        </vs-option>
      </vs-select>
        </b-field>
            </div>
             <div class="col-md-4 mrgb">
                 <b-field label="First Name">
          <vs-input placeholder="First Name" />
        </b-field>
            </div>
             <div class="col-md-4 mrgb">
                 <b-field label="Last Name">
           <vs-input placeholder="Last Name" />
                </b-field>
            </div>
            <div class="col-12 mrgb">
                <b-field label="Country">
             <vs-select placeholder="Select" v-model="value">
        <vs-option label="Jordan" value="1">
          Jordan
        </vs-option>
        <vs-option label="Qatar" value="2">
          Qatar
        </vs-option>
        <vs-option label="China" value="3">
          China
        </vs-option>
        <vs-option  label="Spain" value="4">
          Spain
        </vs-option>
        <vs-option label="Turkey" value="5">
          Turkey
        </vs-option>
        <vs-option label="Oman" value="6">
          Oman
        </vs-option>
        <vs-option label="Saudi Arabia" value="7">
          Saudi Arabia
        </vs-option>
      </vs-select>
        </b-field>
            </div>
            <div class="col-md-12 mrgb">
                 <b-field label="Email">
          <vs-input placeholder="First Name" value="omareyad@gmail.com" :disabled="true" />
        </b-field>
        <span class="change_span">change Email Address</span>
            </div>
            <div class="col-md-12 mrgb">
                 <b-field label="Mobile">
          <vs-input value="+962787525719" :disabled="true" />
        </b-field>
        <span class="change_span">change Mobile Number</span>
            </div>
             <div class="col-md-12 mrgb">
                 <b-field label="Whatsapp">
          <vs-input value="+962787525719" :disabled="true" />
        </b-field>
        <span class="change_span">change Whatsapp Number</span>
            </div>
            <!-- pass -->
              <div class="col-md-12 mrgb">
                 <b-field label="Password">
          <vs-input
        type="password"
        v-model="password"
        :visiblePassword="hasVisiblePassword"
        icon-after
        @click-icon="hasVisiblePassword = !hasVisiblePassword">
        <template #icon>
          <i v-if="!hasVisiblePassword" class='bx bx-show-alt'></i>
          <i v-else class='bx bx-hide'></i>
        </template>
      </vs-input>
          <template #icon>
          <i v-if="!hasVisiblePassword" class='bx bx-show-alt'></i>
          <i v-else class='bx bx-hide'></i>
        </template>
        </b-field>
        <span class="change_span">change Password</span>
            </div>
            <div class="col-md-4 col-12 mr-auto ml-auto mrgt">
                  <vs-button gradient>Save My Data</vs-button>
            </div>
        </div>
      </div>
    
   </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "userprofile",
  data() {
    return {
        n_name:1,
        im:1,
        picked:1,
        value:1,
        hasVisiblePassword: false,
        password:'password'
    };
  },
  computed: {
    ...mapGetters({}),
  },
  methods: {
    ...mapActions({}),
  },
  mounted() {},
};
</script>
<style lang="scss">
.dashoard_user_info{
    padding-top: 20px;
    .mrgb{
        margin-bottom: 14px;
        position: relative;
    }
    .mrgt{
        margin-top: 25px;
    }
    .change_span{
       font-size: 13px;
    color: var(--active);
    cursor: pointer;
    position: absolute;
    right: 20px;
    bottom: -14px;
    font-weight: 400;
    text-transform: capitalize;
    }
    .tabname{
        font-size: 18px;
        font-weight: 600;
        margin: 0;
        padding: 0;
    }
    .personal_wraps{
    padding-top: 20px;
    .type_user{
    display: flex;
    align-items: center;
    .key_span{
        display: inline-block;
    margin-right: 20px;
    font-size: 16px;
    font-weight: 500;
    }
    .radio_wrap{
        display: flex;
        align-items: center;
        .vs-radio-content{
            margin-right: 10px;
        }
    }
    }
    .form_wraps{
        padding-top: 0px;
        // max-width: 700px;
    }
    }
}
</style>
