<template>
  <div class="heroArea_section">
   <div class="row home1">
    <div class="col-lg-3">
       <sidebarhome/>
    </div>
    <div class="col-lg-6">
    <mainSlider></mainSlider>
    </div>
    <div class="col-lg-3">
   <RfqFormArea></RfqFormArea>
    </div>
   </div>
    </div>
</template>
<script>
import sidebarhome from "@/components/global/sidebarHome.vue";
import RfqFormArea from "@/components/home/rfqformhome.vue";
import mainSlider from "@/components/home/mainsliderhome.vue";
export default {
  name: "HeroArea",
    components: {
    sidebarhome,
    RfqFormArea,
    mainSlider
  },
}
</script>
<style lang="scss" scoped>
// home sec
.home1{
  padding-top: 190px;
}

// 
</style>