<template>
  <main>
     <!--  slider -->
    <div
      class="uk-position-relative uk-visible-toggle uk-light"
      tabindex="-1"
      uk-slideshow="animation: fade;min-height: 400;max-height:400"
    >
    <section class="slider__area fix">
          <div class="container">
            <div class="slider-active">
      <ul class="uk-slideshow-items">
        <li>
              <div class="slider__item">
                <div class="row align-items-center">
                  <div class="col-xl-5 col-lg-6">
                    <div class="slider__content">
                      <ul
                        class="tgbanner__content-meta list-wrap animate__animated animate__fadeInUp animate__delay-0.2s"
                      >
                        <li class="category">
                          <a href="categories.html">Medical</a>
                        </li>
                        <li>
                          <span class="by">by</span>
                          <a href="categories.html">Yazan</a>
                        </li>
                        <li>Nov 21, 2023</li>
                      </ul>
                      <h2
                        class="title  animate__animated animate__fadeInUp animate__delay-1s"
                      >
                      Lorem Ipsum is simply dummy text of the printing
                      </h2>
                      <a
                        href="#"
                        class="btn"
                        data-animation-in="tg-fadeInUp"
                        data-delay-in="1"
                        ><span class="btn-text">Read More</span>
                        <i class="far fa-long-arrow-right"></i
                      ></a>
                    </div>
                  </div>
                  <div class="col-xl-7 col-lg-6">
                    <div class="slider__img-wrap">
                      <img
                        src="https://images.unsplash.com/photo-1576091160399-112ba8d25d1d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2940&q=80"
                        class="main-img"
                        alt="img"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div class="slider__item">
                <div class="row align-items-center">
                  <div class="col-xl-5 col-lg-6">
                    <div class="slider__content">
                      <ul
                        class="tgbanner__content-meta list-wrap animate__animated animate__fadeInUp animate__delay-1s"
                      >
                        <li class="category">
                          <a href="categories.html">Medical</a>
                        </li>
                        <li>
                          <span class="by">by</span>
                          <a href="categories.html">Yazan</a>
                        </li>
                        <li>Nov 21, 2023</li>
                      </ul>
                      <h2
                        class="title  animate__animated animate__fadeInUp animate__delay-1s"
                      >
                      Lorem Ipsum is simply dummy text of the printing
                      </h2>
                      <a
                        href="#"
                        class="btn"
                        data-animation-in="tg-fadeInUp"
                        data-delay-in="1"
                        ><span class="btn-text">Read More</span>
                        <i class="far fa-long-arrow-right"></i
                      ></a>
                    </div>
                  </div>
                  <div class="col-xl-7 col-lg-6">
                    <div class="slider__img-wrap">
                      <img
                        src="https://images.unsplash.com/photo-1576671081837-49000212a370?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2898&q=80"
                        class="main-img"
                        alt="img"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </li>
        
      </ul>
      </div>
      </div>
      </section>
      <a
        class="uk-position-center-left uk-position-small uk-hidden-hover"
        href="#"
        uk-slidenav-previous
        uk-slideshow-item="previous"
      ></a>
      <a
        class="uk-position-center-right uk-position-small uk-hidden-hover"
        href="#"
        uk-slidenav-next
        uk-slideshow-item="next"
      ></a>
    </div>
    <!-- end slider -->
      <!-- popular-area -->
      <section class="popular__post-area white-bg section__hover-line pt-75 pb-75">
            <div class="container">
                <div class="section__title-wrap mb-40">
                    <div class="row align-items-end">
                        <div class="col-sm-6">
                            <div class="section__title">
                                <span class="section__sub-title">Trending</span>
                                <h3 class="section__main-title">Trending news</h3>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="section__read-more text-start text-sm-end">
                                <a href="categories.html">show more <i class='bx bx-right-arrow-alt'></i></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="trending__slider">
                    <div class="swiper-container popular-active">
                        <div class="uk-position-relative uk-visible-toggle uk-light" tabindex="-1" uk-slider>
                    <ul class="uk-slider-items uk-child-width-1-2 uk-child-width-1-3@m uk-grid">
                        <li>
                            <div class="swiper-slide">
                                <div class="trending__post">
                                    <div class="trending__post-thumb tgImage__hover">
                                        <a href="#"><img src="https://images.unsplash.com/photo-1471864190281-a93a3070b6de?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2940&q=80" alt="img"></a>
                                        <!-- icon is trend -->
                                        <span class="is_trend"><i class="fas fa-bolt"></i></span>
                                    </div>
                                    <div class="trending__post-content">
                                        <ul class="tgbanner__content-meta list-wrap">
                                            <li class="category"><a href="categories.html">Medical</a></li>
                                            <li><span class="by">By</span> <a href="categories.html">Yazan</a></li>
                                            <li>Nov 21, 2023</li>
                                        </ul>
                                        <h4 class="title tgcommon__hover"><a href="#">
                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                        </a></h4>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="swiper-slide">
                                <div class="trending__post">
                                    <div class="trending__post-thumb tgImage__hover">
                                        <a href="#"><img src="https://images.unsplash.com/photo-1516549655169-df83a0774514?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2940&q=80" alt="img"></a>
                                        <!-- icon is trend -->
                                        <span class="is_trend"><i class="fas fa-bolt"></i></span>
                                    </div>
                                    <div class="trending__post-content">
                                        <ul class="tgbanner__content-meta list-wrap">
                                            <li class="category"><a href="categories.html">Medical</a></li>
                                            <li><span class="by">By</span> <a href="categories.html">Yazan</a></li>
                                            <li>Nov 21, 2023</li>
                                        </ul>
                                        <h4 class="title tgcommon__hover"><a href="#">
                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                        </a></h4>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="swiper-slide">
                                <div class="trending__post">
                                    <div class="trending__post-thumb tgImage__hover">
                                        <a href="#"><img src="https://images.unsplash.com/photo-1584017911766-d451b3d0e843?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2938&q=80" alt="img"></a>
                                        <!-- icon is trend -->
                                        <span class="is_trend"><i class="fas fa-bolt"></i></span>
                                    </div>
                                    <div class="trending__post-content">
                                        <ul class="tgbanner__content-meta list-wrap">
                                            <li class="category"><a href="categories.html">Medical</a></li>
                                            <li><span class="by">By</span> <a href="categories.html">Yazan</a></li>
                                            <li>Nov 21, 2023</li>
                                        </ul>
                                        <h4 class="title tgcommon__hover"><a href="#">
                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                        </a></h4>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="swiper-slide">
                                <div class="trending__post">
                                    <div class="trending__post-thumb tgImage__hover">
                                        <a href="#"><img src="https://images.unsplash.com/photo-1551190822-a9333d879b1f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2940&q=80" alt="img"></a>
                                        <!-- icon is trend -->
                                        <span class="is_trend"><i class="fas fa-bolt"></i></span>
                                    </div>
                                    <div class="trending__post-content">
                                        <ul class="tgbanner__content-meta list-wrap">
                                            <li class="category"><a href="categories.html">Medical</a></li>
                                            <li><span class="by">By</span> <a href="categories.html">Yazan</a></li>
                                            <li>Nov 21, 2023</li>
                                        </ul>
                                        <h4 class="title tgcommon__hover"><a href="#">
                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                        </a></h4>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>

                    <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slider-item="previous"></a>
                    <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slider-item="next"></a>

                    </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- popular-area-end -->

         <!-- category-area -->
         <section class="category__area section__hover-line pt-75">
            <div class="container">
                <div class="section__title-wrap mb-40">
                    <div class="row align-items-end">
                        <div class="col-sm-6">
                            <div class="section__title">
                                <span class="section__sub-title">categories</span>
                                <h3 class="section__main-title">Best categories</h3>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="section__read-more text-start text-sm-end">
                                <a href="categories.html">show more <i class='bx bx-right-arrow-alt'></i></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="category__wrapper">
                    <div class="category__item">
                        <a href="categories.html">
                            <img src="~@/assets/newImage/cat1.jpeg" alt="img">
                            <span class="cat-name">Category 1</span>
                        </a>
                    </div>
                    <div class="category__item">
                        <a href="categories.html">
                            <img src="~@/assets/newImage/cat2.jpeg" alt="img">
                            <span class="cat-name">Category 2</span>
                        </a>
                    </div>
                    <div class="category__item">
                        <a href="categories.html">
                            <img src="~@/assets/newImage/cat3.jpeg" alt="img">
                            <span class="cat-name">Category 3</span>
                        </a>
                    </div>
                    <div class="category__item">
                        <a href="categories.html">
                            <img src="~@/assets/newImage/cat4.jpeg" alt="img">
                            <span class="cat-name">Category 4</span>
                        </a>
                    </div>
                    <div class="category__item">
                        <a href="categories.html">
                            <img src="~@/assets/newImage/cat5.jpeg" alt="img">
                            <span class="cat-name">Category 5</span>
                        </a>
                    </div>
                    <div class="category__item">
                        <a href="categories.html">
                            <img src="https://images.unsplash.com/photo-1651186857332-5dbda6b94dda?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2940&q=80" alt="img">
                            <span class="cat-name">Category 6</span>
                        </a>
                    </div>
                    <div class="category__item">
                        <a href="categories.html">
                            <img src="https://images.unsplash.com/photo-1615486511484-92e172cc4fe0?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2942&q=80" alt="img">
                            <span class="cat-name">Category 7</span>
                        </a>
                    </div>
                    <div class="category__item">
                        <a href="categories.html">
                            <img src="https://images.unsplash.com/photo-1504439468489-c8920d796a29?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2942&q=80" alt="img">
                            <span class="cat-name">Category 8</span>
                        </a>
                    </div>
                    <div class="category__item">
                        <a href="categories.html">
                            <img src="https://images.unsplash.com/photo-1582719298866-977ee81c87d7?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2940&q=80" alt="img">
                            <span class="cat-name">Category 9</span>
                        </a>
                    </div>
                    <div class="category__item">
                        <a href="categories.html">
                            <img src="https://images.unsplash.com/photo-1579165466991-467135ad3110?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2940&q=80" alt="img">
                            <span class="cat-name">Category 10</span>
                        </a>
                    </div>
                </div>
            </div>
        </section>
        <!-- category-area-end -->

          <!-- advertisement-area -->
          <div class="advertisement pt-80">
            <div class="container">
                <div class="col-12">
                    <div class="advertisement__image text-center">
                        <a href="#"><img src="~@/assets/newImage/ads1.png" alt="advertisement"></a>
                    </div>
                </div>
            </div>
        </div>
        <!-- advertisement-area-end -->
         <!-- popular-area -->
      <section class="popular__post-area white-bg section__hover-line pt-75 pb-75">
            <div class="container">
                <div class="section__title-wrap mb-40">
                    <div class="row align-items-end">
                        <div class="col-sm-6">
                            <div class="section__title">
                                <span class="section__sub-title">Special</span>
                                <h3 class="section__main-title">Special news</h3>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="section__read-more text-start text-sm-end">
                                <a href="categories.html">show more <i class='bx bx-right-arrow-alt'></i></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="trending__slider">
                    <div class="swiper-container popular-active">
                        <div class="uk-position-relative uk-visible-toggle uk-light" tabindex="-1" uk-slider>
                    <ul class="uk-slider-items uk-child-width-1-2 uk-child-width-1-3@m uk-grid">
                        <li>
                            <div class="swiper-slide">
                                <div class="trending__post">
                                    <div class="trending__post-thumb tgImage__hover">
                                        <a href="#"><img src="https://images.unsplash.com/photo-1579684288402-e3e337bcc7af?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2940&q=80" alt="img"></a>
                                        <!-- icon is trend -->
                                        <span class="is_trend"><i class="fas fa-bolt"></i></span>
                                    </div>
                                    <div class="trending__post-content">
                                        <ul class="tgbanner__content-meta list-wrap">
                                            <li class="category"><a href="categories.html">Medical</a></li>
                                            <li><span class="by">By</span> <a href="categories.html">Yazan</a></li>
                                            <li>Nov 21, 2023</li>
                                        </ul>
                                        <h4 class="title tgcommon__hover"><a href="#">
                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                        </a></h4>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="swiper-slide">
                                <div class="trending__post">
                                    <div class="trending__post-thumb tgImage__hover">
                                        <a href="#"><img src="https://images.unsplash.com/photo-1585435557343-3b092031a831?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2940&q=80" alt="img"></a>
                                        <!-- icon is trend -->
                                        <span class="is_trend"><i class="fas fa-bolt"></i></span>
                                    </div>
                                    <div class="trending__post-content">
                                        <ul class="tgbanner__content-meta list-wrap">
                                            <li class="category"><a href="categories.html">Medical</a></li>
                                            <li><span class="by">By</span> <a href="categories.html">Yazan</a></li>
                                            <li>Nov 21, 2023</li>
                                        </ul>
                                        <h4 class="title tgcommon__hover"><a href="#">
                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                        </a></h4>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="swiper-slide">
                                <div class="trending__post">
                                    <div class="trending__post-thumb tgImage__hover">
                                        <a href="#"><img src="https://images.unsplash.com/photo-1611689102192-1f6e0e52df0a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=3033&q=80" alt="img"></a>
                                        <!-- icon is trend -->
                                        <span class="is_trend"><i class="fas fa-bolt"></i></span>
                                    </div>
                                    <div class="trending__post-content">
                                        <ul class="tgbanner__content-meta list-wrap">
                                            <li class="category"><a href="categories.html">Medical</a></li>
                                            <li><span class="by">By</span> <a href="categories.html">Yazan</a></li>
                                            <li>Nov 21, 2023</li>
                                        </ul>
                                        <h4 class="title tgcommon__hover"><a href="#">
                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                        </a></h4>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="swiper-slide">
                                <div class="trending__post">
                                    <div class="trending__post-thumb tgImage__hover">
                                        <a href="#"><img src="https://images.unsplash.com/photo-1599814516324-66aa0bf16425?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2874&q=80" alt="img"></a>
                                        <!-- icon is trend -->
                                        <span class="is_trend"><i class="fas fa-bolt"></i></span>
                                    </div>
                                    <div class="trending__post-content">
                                        <ul class="tgbanner__content-meta list-wrap">
                                            <li class="category"><a href="categories.html">Medical</a></li>
                                            <li><span class="by">By</span> <a href="categories.html">Yazan</a></li>
                                            <li>Nov 21, 2023</li>
                                        </ul>
                                        <h4 class="title tgcommon__hover"><a href="#">
                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                        </a></h4>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>

                    <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slider-item="previous"></a>
                    <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slider-item="next"></a>

                    </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- popular-area-end -->
          <!-- handpicked-post-area -->
          <section class="handpicked-post-area section__hover-line pt-75 pb-50">
            <div class="container">
                <div class="section__title-wrap mb-40">
                    <div class="row align-items-end">
                        <div class="col-sm-6">
                            <div class="section__title">
                                <span class="section__sub-title">News</span>
                                <h3 class="section__main-title">Last News</h3>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="section__read-more text-start text-sm-end">
                                <a href="categories.html">show more <i class='bx bx-right-arrow-alt'></i></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="col-xl-6 col-lg-8">
                        <div class="handpicked__item big-post">
                            <div class="handpicked__thumb tgImage__hover">
                                <a href="#"><img src="https://images.unsplash.com/photo-1463367620918-d4824d05ce0e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2944&q=80" alt="img"></a>
                            </div>
                            <div class="handpicked__content">
                                <ul class="tgbanner__content-meta list-wrap">
                                    <li class="category"><a href="categories.html">Medical</a></li>
                                    <li><span class="by">By</span> <a href="categories.html">Yazan</a></li>
                                    <li>Nov 21, 2023</li>
                                </ul>
                                <h2 class="title tgcommon__hover"><a href="#">
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                </a></h2>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6">
                        <div class="handpicked__sidebar-post">
                            <div class="row">
                                <div class="col-xl-6 col-lg-4 col-md-6">
                                    <div class="handpicked__item small-post">
                                        <div class="handpicked__thumb tgImage__hover">
                                            <a href="#"><img src="https://images.unsplash.com/photo-1561328399-f94d2ce78679?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2940&q=80" alt="img"></a>
                                        </div>
                                        <div class="handpicked__content">
                                <ul class="tgbanner__content-meta list-wrap">
                                    <li class="category"><a href="categories.html">Medical</a></li>
                                    <li><span class="by">By</span> <a href="categories.html">Yazan</a></li>
                                    <li>Nov 21, 2023</li>
                                </ul>
                                <h2 class="title tgcommon__hover"><a href="#">
                                    Lorem Ipsum is simply dummy text of the printing.
                                </a></h2>
                            </div>
                                    </div>
                                </div>
                                <div class="col-xl-6 col-lg-4 col-md-6">
                                    <div class="handpicked__item small-post">
                                        <div class="handpicked__thumb tgImage__hover">
                                            <a href="#"><img src="https://images.unsplash.com/photo-1532187863486-abf9dbad1b69?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2940&q=80" alt="img"></a>
                                        </div>
                                        <div class="handpicked__content">
                                <ul class="tgbanner__content-meta list-wrap">
                                    <li class="category"><a href="categories.html">Medical</a></li>
                                    <li><span class="by">By</span> <a href="categories.html">Yazan</a></li>
                                    <li>Nov 21, 2023</li>
                                </ul>
                                <h2 class="title tgcommon__hover"><a href="#">
                                    Lorem Ipsum is simply dummy text of the printing.
                                </a></h2>
                            </div>
                                    </div>
                                </div>
                                <div class="col-xl-6 col-lg-4 col-md-6">
                                    <div class="handpicked__item small-post">
                                        <div class="handpicked__thumb tgImage__hover">
                                            <a href="#"><img src="https://images.unsplash.com/photo-1532187643603-ba119ca4109e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2940&q=80" alt="img"></a>
                                        </div>
                                        <div class="handpicked__content">
                                <ul class="tgbanner__content-meta list-wrap">
                                    <li class="category"><a href="categories.html">Medical</a></li>
                                    <li><span class="by">By</span> <a href="categories.html">Yazan</a></li>
                                    <li>Nov 21, 2023</li>
                                </ul>
                                <h2 class="title tgcommon__hover"><a href="#">
                                    Lorem Ipsum is simply dummy text of the printing.
                                </a></h2>
                            </div>
                                    </div>
                                </div>
                                <div class="col-xl-6 col-lg-4 col-md-6">
                                    <div class="handpicked__item small-post">
                                        <div class="handpicked__thumb tgImage__hover">
                                            <a href="#"><img src="https://images.unsplash.com/photo-1532634993-15f421e42ec0?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2940&q=80" alt="img"></a>
                                        </div>
                                        <div class="handpicked__content">
                                <ul class="tgbanner__content-meta list-wrap">
                                    <li class="category"><a href="categories.html">Medical</a></li>
                                    <li><span class="by">By</span> <a href="categories.html">Yazan</a></li>
                                    <li>Nov 21, 2023</li>
                                </ul>
                                <h2 class="title tgcommon__hover"><a href="#">
                                    Lorem Ipsum is simply dummy text of the printing.
                                </a></h2>
                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- handpicked-post-area-end -->

         <!-- advertisement-area -->
         <div class="advertisement pt-80 pb-80">
            <div class="container">
                <div class="col-12">
                    <div class="advertisement__image text-center">
                        <a href="#"><img src="~@/assets/newImage/ads2.gif" alt="advertisement"></a>
                    </div>
                </div>
            </div>
        </div>
        <!-- advertisement-area-end -->
  </main>
</template>
<script>
import '../../scss/newsStyle.scss';
export default {
  name: "newsHome",
  data() {
    return {};
  },
  components: {},
};
</script>
<style>
</style>