import Vue from "vue";
import App from "./App.vue";
import store from "./store/store.js";
import "./registerServiceWorker";
Vue.config.ignoredElements = [/^ion-/];

// Style Lib Dev BY Omar Eyad ##
import "vuesax/dist/vuesax.css";
import Vuesax from "vuesax";
Vue.use(Vuesax, {
  // options here
});
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap-grid.min.css";
import uk from "uikit";
import "uikit/dist/css/uikit.min.css";
import "uikit/dist/js/uikit.min.js";
Vue.mixin({
  data: function () {
    return {
      get uk() {
        return uk;
      },
    };
  },
});
import Buefy from "buefy";
import "buefy/dist/buefy.css";
Vue.use(Buefy);

// phone tel
import VueTelInput from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
Vue.use(VueTelInput, {
  mode: "international",
  showSearchBox: true,
  showFlags: false,
});
// end tel

import Vant from 'vant';
import 'vant/lib/index.css';
// Just import this module, then Vant works in PC browser
import '@vant/touch-emulator';

Vue.use(Vant);

// google map
import * as VueGoogleMaps from "vue2-google-maps";
import Geocoder from "@pderas/vue2-geocoder";
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyAoOQA_ac1dbUgyXKxal0q3L9M4CtKjq5s",
    libraries: "places",
  },
});

Vue.use(Geocoder, {
  defaultCountryCode: null, // e.g. 'CA'
  defaultLanguage: "no", // e.g. 'en'
  defaultMode: "lat-lng", // or 'lat-lng'
  googleMapsApiKey: "AIzaSyAoOQA_ac1dbUgyXKxal0q3L9M4CtKjq5s",
});

import "./scss/main.scss";

// End Style Lib Dev BY Omar Eyad ##

// imports Views ....
import Home from "@/views/Home.vue";
import Login from "@/views/Login.vue";
import Register from "@/views/Register.vue";
import Plans from "@/views/Plans.vue";
import Categories from "@/views/Categories.vue";
import ItemAdsFinal from "@/views/ItemAdsFinal.vue";
import AddAds from "@/views/AddAds.vue";
import News from "@/views/News.vue";
// 
import newsDeatilsArea from "@/components/news/newsDeatilsArea";
import addNewNewsArea from "@/components/news/addNewNewsArea";
// Middle Pages
import productTabArea from "@/components/categories/productTabArea";
import SupplierTabArea from "@/components/categories/SupplierTabArea";
import AgencyTabArea from "@/components/categories/AgencyTabArea";
import PartsTabArea from "@/components/categories/PartsTabArea";
import ServiceProviderTabArea from "@/components/categories/ServiceProviderTabArea";
import CareersTabArea from "@/components/categories/CareersTabArea";
import TendersTabArea from "@/components/categories/TendersTabArea";
import RFQTabArea from "@/components/categories/RFQTabArea";
import AuctionsTabArea from "@/components/categories/AuctionsTabArea";
import ForumsTabArea from "@/components/categories/ForumsTabArea";
// profile pages
import Profile from "@/views/Profile.vue";
// profile blocks
import dashboardprofile from "@/components/profile/profile_block/dashboard_profile";
import pesonalprofile from "@/components/profile/profile_block/pesonal_profile";
import businessprofile from "@/components/profile/profile_block/business_profile";
import mapprofile from "@/components/profile/profile_block/map_profile";
import branchesprofile from "@/components/profile/profile_block/branches_profile";
import certificatesprofile from "@/components/profile/profile_block/certificates_profile";
import trademarksprofile from "@/components/profile/profile_block/trademarks_profile";
import webstoreprofile from "@/components/profile/profile_block/webstore_profile";
import myAdsprofile from "@/components/profile/profile_block/myAd_profile";
import listingprofile from "@/components/profile/profile_block/listing_profile";
import walletprofile from "@/components/profile/profile_block/wallet_profile";
import followingprofile from "@/components/profile/profile_block/following_profile";
import followersprofile from "@/components/profile/profile_block/followers_profile";
import jobsAndTraning from "@/components/profile/profile_block/jobsAndTraning";
// categories page

// Jops Pages
import allJobsArea from "@/components/jobs/allJobsArea";
import addTraningArea from "@/components/jobs/addTraningArea";
import addJobArea from "@/components/jobs/addJobArea";
// RFQ Pages
import rfqListArea from "@/components/rfq/rfqListArea";
import compareArea from "@/components/rfq/compareArea";
import sellerOfferArea from "@/components/rfq/sellerOfferArea";
import buyerOfferArea from "@/components/rfq/buyerOfferArea";
import rfqDetailsArea from "@/components/rfq/rfqDetailsArea";
// webStore
import homeStore from "@/components/webStore/homeStore";
import homeStoreArea from "@/components/webStore/homeStoreArea";
import storeCategoresArea from "@/components/webStore/storeCategoresArea";
import profileStore from "@/components/webStore/profileStore";
import aboutStore from "@/components/webStore/aboutStore";
import contactStoreArea from "@/components/webStore/contactStoreArea";
// Chats
import chatsArea from "@/components/chats/chatsArea";
// General pages 
import aboutArea from "@/components/generalPage/aboutArea";
import contactArea from "@/components/generalPage/contactArea";
// Forums
import homeForm from "@/components/forum/homeForm";
import singleCategoryForum from "@/components/forum/singleCategoryForum";
import fourmDetails from "@/components/forum/fourmDetails";
import addTopics from "@/components/forum/addTopics";



// Import Routes #----
import VueRouter from "vue-router";
import Router from "vue-router";
// not push if url same router link ##
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
Vue.use(VueRouter);
const router = new VueRouter({
  mode: "history",
  scrollBehavior() {
    document.querySelector("body").scrollIntoView({ behavior: "instant" });
  },
  routes: [
    { path: "/", component: Home },
    { path: "/home", component: Home, name: "Home" },
    { path: "/login", component: Login, name: "login" },
    { path: "/register", component: Register, name: "Register" },
    { path: "/plans", component: Plans, name: "Plans" },

    { path: "/categories", component: Categories, name: "Categories" ,
    children:[
      {
        path: '/categories/product',
        name: 'productTabArea',
        component: productTabArea
    },
    {
      path: '/categories/supplier',
      name: 'SupplierTabArea',
      component: SupplierTabArea
  },
  {
    path: '/categories/agency',
    name: 'AgencyTabArea',
    component: AgencyTabArea
},
{
  path: '/categories/parts',
  name: 'PartsTabArea',
  component: PartsTabArea
},
{
  path: '/categories/service',
  name: 'ServiceProviderTabArea',
  component: ServiceProviderTabArea
},
{
  path: '/categories/careers',
  name: 'CareersTabArea',
  component: CareersTabArea
},
{
  path: '/categories/tenders',
  name: 'TendersTabArea',
  component: TendersTabArea
},
{
  path: '/categories/rfq',
  name: 'RFQTabArea',
  component: RFQTabArea
},
{
  path: '/categories/auctions',
  name: 'AuctionsTabArea',
  component: AuctionsTabArea
},
{
  path: '/categories/forums',
  name: 'ForumsTabArea',
  component: ForumsTabArea
},



  ] 
    },
    {
      path: "/profile/dashboard",
      component: Profile,
      name: "Profile",
      children: [
        {
          path: "/profile",
          component: dashboardprofile,
        },
        {
          path: "/profile/dashboard",
          name: "dashboard",
          component: dashboardprofile,
        },
        {
          path: "/profile/pesonal",
          name: "pesonal",
          component: pesonalprofile,
        },
        {
          path: "/profile/business",
          name: "business",
          component: businessprofile,
        },
        {
          path: "/profile/map",
          name: "map",
          component: mapprofile,
        },
        {
          path: "/profile/branches",
          name: "branches",
          component: branchesprofile,
        },
        {
          path: "/profile/certificates",
          name: "certificates",
          component: certificatesprofile,
        },
        {
          path: "/profile/trademarks",
          name: "trademarks",
          component: trademarksprofile,
        },
        {
          path: "/profile/webstore",
          name: "webstore",
          component: webstoreprofile,
        },
        {
          path: "/profile/listing",
          name: "myAds",
          component: myAdsprofile,
        },
        {
          path: "/profile/my-ads",
          name: "listing",
          component: listingprofile,
        },

        {
          path: "/profile/wallet",
          name: "wallet",
          component: walletprofile,
        },
        {
          path: "/profile/following",
          name: "following",
          component: followingprofile,
        },
        {
          path: "/profile/followers",
          name: "followers",
          component: followersprofile,
        },
        {
          path: "/profile/jobs",
          name: "jobs",
          component: jobsAndTraning,
        },
        
        
        
        
        
      ],
    },
    { path: "/product-sell", component: ItemAdsFinal, name: "ItemAdsFinal" },
    { path: "/add-adds", component: AddAds, name: "AddAds" },
    { path: "/news", component: News, name: "News" },
    { path: "/news/new", component: addNewNewsArea, name: "addNewNewsArea" },
    { path: "/news/details/:id", component: newsDeatilsArea, name: "newsDeatilsArea" },
    { path: "/jobs/all", component: allJobsArea, name: "allJobsArea" },
    { path: "/jobs/traning/new", component: addTraningArea, name: "addTraningArea" },
    { path: "/jobs/new", component: addJobArea, name: "addJobArea" },
    { path: "/rfq/list", component: rfqListArea, name: "rfqListArea" },
    { path: "/rfq/compare", component: compareArea, name: "compareArea" },
    { path: "/rfq/seller-offer", component: sellerOfferArea, name: "sellerOfferArea" },
    { path: "/rfq/buyer-offer", component: buyerOfferArea, name: "buyerOfferArea" },
    { path: "/rfq/details", component: rfqDetailsArea, name: "rfqDetailsArea" },
    { path: "/webstore/home", component: homeStore, name: "homeStore",
    children: [
      { 
        path: "/webstore/home",
        component: homeStoreArea,
      },
      { 
        path: "/webstore/categories",
        component: storeCategoresArea,
      },
      { 
        path: "/webstore/profile",
        component: profileStore,
      },
      { 
        path: "/webstore/about",
        component: aboutStore,
      },
      { 
        path: "/webstore/contact",
        component: contactStoreArea,
      },
      
    ]
  },
  { path: "/chats/:id?", component: chatsArea, name: "chatsArea" },
  { path: "/about", component: aboutArea, name: "aboutArea" },
  { path: "/contact", component: contactArea, name: "contactArea" },
  // Forum
  { path: "/forum", component: homeForm, name: "homeForm" },
  { path: "/forum/:categoryName", component:singleCategoryForum , name: "singleCategoryForum" },
  { path: "/forum/:categoryName/:topicId", component: fourmDetails, name: "fourmDetails" },
  { path: "/new-topic/", component: addTopics, name: "addTopics" },
    
  
    
  ],
});

// End Routes
import Vue2Editor from "vue2-editor";
Vue.use(Vue2Editor);


Vue.config.productionTip = false;
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
