<template>
<div class="forum_home_area">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <b-field label="Search Forums">
                   <vs-input placeholder="Search for related topic or person ..." />
                  </b-field>
            </div>
            <div class="col-lg-9">
                <div class="content_topic">
                  <div class="single_subjects" v-for="category , index in 4" :key=" category">
                    <div class="title_content">
                        <div class="show_more">
                            <van-button to="/forum/dealer" color="#1d9bf0" plain>  Click here to view more</van-button>
                        </div>
                        <div class="name_topic">
                            Dealer/Reseller
                        </div>
                        <div class="options_topics">
                            <van-button plain color="#1d9bf0">Post a Topic</van-button>
                            <div class="notification_icons">
                                <template v-if="index == 0">
                                    <i class='bx bxs-bell-ring bx-tada'></i>
                                </template>
                                <template v-else>
                                    <i class='bx bxs-bell'></i>
                                </template>
                               
                            </div>
                        </div>
                    </div>
                    <div class="data-list-topics">
                        <div class="single-list" v-for="data in 5" :key="data">
                            <!-- username -->
                            <div class="username_title">
                                <div class="image">
                                    <img src="https://wallpapers.com/images/hd/nature-landscape-hd-usqznq19dscdjkf8.jpg" alt="">
                                </div>
                                <div class="txt_user">
                                    <div class="title_us">Already planning for 2023</div>
                                    <div class="user_date">
                                        <span class="name">Anonymous</span>
                                        <span class="date">Jul 23, 2021</span>
                                    </div>
                                </div>
                            </div>
                            <!-- end username -->
                            <!-- tags -->
                            <div class="tags_post">
                                <span>tags Name 1</span>
                                <span>tags Name 2</span>
                                <span>tags Name 3</span>
                                <span>tags Name 4</span>
                            </div>
                            <!-- tags -->
                            <!-- replay views -->
                            <div class="repliesView">
                              <div class="single_rep">
                                <div class="keyr">Replies</div>
                                <div class="valr"> <i class='bx bxs-comment-detail'></i> 12</div>
                              </div>
                              <div class="single_rep">
                                <div class="keyr">Views</div>
                                <div class="valr"><i class='bx bxs-show'></i> 6K</div>
                              </div>
                            </div>
                            <!--  -->
                            <!-- user2 -->
                            <div class="date-2">
                               <div class="txt2">
                                <div class="dateval">Oct 6, 2022</div>
                                <span>Islam</span>
                               </div>
                               <div class="image2">
                                <img src="https://e0.pxfuel.com/wallpapers/269/10/desktop-wallpaper-norway-36-norway-gallery-of-top4themes-norway-landscape.jpg" alt="">
                               </div>
                            </div>
                            <!--  -->
                        </div>
                    </div>
                  </div>
                </div>
            </div>
            <div class="col-lg-3">
                <div class="box_user_rates">
                    <div class="title_box">
                        <span>Recently Rated</span> <span><img src="https://cdn.pixabay.com/photo/2019/11/22/10/02/rate-4644521_1280.png" alt=""></span>
                    </div>
                    <div class="user-list">
                        <div class="single_user">
                            <div class="user_name">
                                Omar Eyad
                            </div>
                            <div class="user_rate">
                                <van-rate
                                v-model="star_rate"
                                :size="18"
                                color="#ffd21e"
                                void-icon="star"
                                void-color="#eee"
                                />
                            </div>
                        </div>
                        <div class="single_user">
                            <div class="user_name">
                               Mohammad Abu Alsamen
                            </div>
                            <div class="user_rate">
                                <van-rate
                                v-model="star_rate"
                                :size="18"
                                color="#ffd21e"
                                void-icon="star"
                                void-color="#eee"
                                />
                            </div>
                        </div>
                        <div class="single_user">
                            <div class="user_name">
                                Mohammad Abu Hasab Allah
                            </div>
                            <div class="user_rate">
                                <van-rate
                                v-model="star_rate"
                                :size="18"
                                color="#ffd21e"
                                void-icon="star"
                                void-color="#eee"
                                />
                            </div>
                        </div>
                        <div class="single_user">
                            <div class="user_name">
                                Yazan Abu Farha
                            </div>
                            <div class="user_rate">
                                <van-rate
                                v-model="star_rate"
                                :size="18"
                                color="#ffd21e"
                                void-icon="star"
                                void-color="#eee"
                                />
                            </div>
                        </div>
                        <div class="single_user">
                            <div class="user_name">
                                Alexander Joun
                            </div>
                            <div class="user_rate">
                                <van-rate
                                v-model="star_rate"
                                :size="18"
                                color="#ffd21e"
                                void-icon="star"
                                void-color="#eee"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <!-- box user 2 -->
                <div class="box_user_rates">
                    <div class="title_box">
                        <span>Recently Certified</span> <span><img src="https://www.iconpacks.net/icons/1/free-certificate-icon-1356-thumb.png" alt=""></span>
                    </div>
                    <div class="user-list">
                        <div class="single_user">
                            <div class="user_name">
                                Omar Eyad
                            </div>
                            <div class="user_rate">
                                <van-rate
                                v-model="star_rate"
                                :size="18"
                                color="#ffd21e"
                                void-icon="star"
                                void-color="#eee"
                                />
                            </div>
                        </div>
                        <div class="single_user">
                            <div class="user_name">
                               Mohammad Abu Alsamen
                            </div>
                            <div class="user_rate">
                                <van-rate
                                v-model="star_rate"
                                :size="18"
                                color="#ffd21e"
                                void-icon="star"
                                void-color="#eee"
                                />
                            </div>
                        </div>
                        <div class="single_user">
                            <div class="user_name">
                                Mohammad Abu Hasab Allah
                            </div>
                            <div class="user_rate">
                                <van-rate
                                v-model="star_rate"
                                :size="18"
                                color="#ffd21e"
                                void-icon="star"
                                void-color="#eee"
                                />
                            </div>
                        </div>
                        <div class="single_user">
                            <div class="user_name">
                                Yazan Abu Farha
                            </div>
                            <div class="user_rate">
                                <van-rate
                                v-model="star_rate"
                                :size="18"
                                color="#ffd21e"
                                void-icon="star"
                                void-color="#eee"
                                />
                            </div>
                        </div>
                        <div class="single_user">
                            <div class="user_name">
                                Alexander Joun
                            </div>
                            <div class="user_rate">
                                <van-rate
                                v-model="star_rate"
                                :size="18"
                                color="#ffd21e"
                                void-icon="star"
                                void-color="#eee"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <!-- box 3 -->
                <div class="box_user_rates">
                    <div class="title_box">
                        <span>Top 100 users</span> <span><img src="https://www.exeter.ac.uk/v8media/recruitmentsites/ksp-icons/greenicons/T4_Top_100_transparent.png" alt=""></span>
                    </div>
                    <div class="user-list">
                        <div class="single_user">
                            <div class="user_name">
                                Omar Eyad
                            </div>
                            <div class="user_rate">
                                <van-rate
                                v-model="star_rate"
                                :size="18"
                                color="#ffd21e"
                                void-icon="star"
                                void-color="#eee"
                                />
                            </div>
                        </div>
                        <div class="single_user">
                            <div class="user_name">
                               Mohammad Abu Alsamen
                            </div>
                            <div class="user_rate">
                                <van-rate
                                v-model="star_rate"
                                :size="18"
                                color="#ffd21e"
                                void-icon="star"
                                void-color="#eee"
                                />
                            </div>
                        </div>
                        <div class="single_user">
                            <div class="user_name">
                                Mohammad Abu Hasab Allah
                            </div>
                            <div class="user_rate">
                                <van-rate
                                v-model="star_rate"
                                :size="18"
                                color="#ffd21e"
                                void-icon="star"
                                void-color="#eee"
                                />
                            </div>
                        </div>
                        <div class="single_user">
                            <div class="user_name">
                                Yazan Abu Farha
                            </div>
                            <div class="user_rate">
                                <van-rate
                                v-model="star_rate"
                                :size="18"
                                color="#ffd21e"
                                void-icon="star"
                                void-color="#eee"
                                />
                            </div>
                        </div>
                        <div class="single_user">
                            <div class="user_name">
                                Alexander Joun
                            </div>
                            <div class="user_rate">
                                <van-rate
                                v-model="star_rate"
                                :size="18"
                                color="#ffd21e"
                                void-icon="star"
                                void-color="#eee"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
export default {
  name: "HomeForumArea",
  data() {
    return {
        star_rate:4
    };
  },
  components: {},
};
</script>
<style lang="scss" scoped>
.forum_home_area{
    min-height: 100vh;
    // margin-top: 180px;
    padding-top: 200px;
    background-color: white;
    border-radius: 14px;
    .content_topic{
        margin-top: 30px;
        .single_subjects{
            margin-bottom: 50px;
            border: 1px solid rgba(220, 220, 220, 0.579);
            border-radius: 10px;
            padding-bottom: 10px;
        }
        .title_content{
            display: flex;
            align-items: center;
            background-color: #1d9bf0;
            justify-content: space-between;
            padding: 14px 14px;
            border-radius: 10px;
            margin-bottom: 15px;
            .name_topic{
                font-size: 20px;
                font-weight: 500;
                color: white;
            }
            button{
                border-radius: 30px;
                padding-left: 24px;
                padding-right: 24px;
            }
            .options_topics{
                display: flex;
                align-items: center;
                .notification_icons{
                    width: 40px;
                    height: 40px;
                    border-radius: 100%;
                    background-color: white;
                    color: var(--active);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-left: 10px;
                    cursor: pointer;
                }
            }
        }
        // 
        .data-list-topics{
            .single-list{
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 4px 10px;
                transition: 0.3s ease-in-out;
                &:nth-child(even) {
                    background-color: rgba(220, 220, 220, 0.303);
                    }
                &:hover{
                    background-color: rgba(220, 220, 220, 0.303);
                }
                .username_title{
                    display: flex;
                    align-items: flex-start;
                   flex: 0 0 calc(100%/4);
                    .image{
                        width: 35px;
                        height: 35px;
                        flex: 0 0 35px;
                        margin-top: -5px;
                        img{
                        width: 35px;
                        height: 35px;
                        object-fit: cover;
                        border-radius: 100%;
                    }
                    }
                    .txt_user{
                        flex: 0 0 calc(100% - 35px);
                        display: flex;
                        align-items: flex-start;
                        flex-direction: column;
                        padding-left: 10px;
                        .title_us{
                            font-size: 15px;
                            line-height: 10px;
                            color: var(--active);
                        }
                        .user_date{
                            .date{
                                font-size: 13px;
                            }
                            .name{
                                font-size: 12px;
                                padding-right: 5px;
                                opacity: 0.6;
                            }
                        }
                    }
                }
                .repliesView{
                    flex: 0 0 calc(100%/6);
                    .single_rep{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        border-bottom: 1px dashed gainsboro;
                        padding-bottom: 2px;
                        &:last-child{
                            border-bottom: none;
                        }
                        .keyr{
                            font-size: 14px;
                            font-weight: 600;
                        }
                        .valr{
                            font-size: 13px;
                            font-weight: 400;
                            opacity: 0.5;
                            i{
                                vertical-align: middle;
                                margin-top: -1px;
                                font-size: 12px;
                            }
                        }
                    }
                }
                .tags_post{
                    display: flex;
                    align-items: center;
                  span{
                    font-size: 13px;
                    border: 1px solid gainsboro;
                    margin: 2px;
                    padding: 4px;
                    border-radius: 4px;

                  }
                }
                .date-2{
                    display: flex;
                    align-items: flex-start;
                    flex-direction: row-reverse;
                    .image2{
                        img{
                            width: 35px;
                            height: 35px;
                            border-radius: 100%;
                            margin-top: -5px;
                        }
                    }
                    .txt2{
                        padding-left: 10px;
                        .dateval{
                            font-size: 13px;
                            line-height: 10px;
                        }
                        span{
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }
    // 
    .box_user_rates{
        margin-top: 30px;
        border: 1px solid rgba(220, 220, 220, 0.374);
        border-radius: 10px;
        .title_box{
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: var(--active);
            padding: 4px 12px;
            color: white;
            border-radius: 10px;
            margin-bottom: 8px;
            span{
                font-size: 18px;
                font-weight: 500;
            }
            img{
                width: 35px;
                height: 35px;
                object-fit: contain;
            }
        }
        .user-list{
            padding: 4px 12px;
            .single_user{
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 10px;
                border-bottom: 1px solid rgba(220, 220, 220, 0.374);
                padding-bottom: 10px;
                &:last-child{
                    border-bottom: none;
                    padding-bottom: 0px;
                }
                .user_name{
                    font-size: 13px;
                }
            }
        }
    }
}
</style>
