<template>
   <!-- flag -->
    <div class="flag_slider">
      <div class="sp">
      <span>Suppliers by country or region</span>
    </div>
      <div class="uk-position-relative uk-visible-toggle uk-light" tabindex="-1" uk-slider="autoplay:true;autoplay-interval: 5000">

    <ul class="uk-slider-items uk-child-width-1-2 uk-child-width-1-3@m uk-child-width-1-4@m uk-child-width-1-6@l uk-grid">
        <li>
            <div class="uk-panel">
                <div class="flag_box">
                  <img src="~@/assets/f1.png" alt="">
                  <span class="flag_naem">Brazil</span>
                </div>
            </div>
        </li>
         <li>
            <div class="uk-panel">
                <div class="flag_box">
                  <img src="~@/assets/f2.png" alt="">
                  <span class="flag_naem">China</span>
                </div>
            </div>
        </li>
        <li>
            <div class="uk-panel">
                <div class="flag_box">
                  <img src="~@/assets/f3.png" alt="">
                  <span class="flag_naem">France</span>
                </div>
            </div>
        </li>
         <li>
            <div class="uk-panel">
                <div class="flag_box">
                  <img src="~@/assets/f4.png" alt="">
                  <span class="flag_naem">Japan</span>
                </div>
            </div>
        </li>
         <li>
            <div class="uk-panel">
                <div class="flag_box">
                  <img src="~@/assets/f5.png" alt="">
                  <span class="flag_naem">Russia</span>
                </div>
            </div>
        </li>
        <li>
            <div class="uk-panel">
                <div class="flag_box">
                  <img src="~@/assets/f6.png" alt="">
                  <span class="flag_naem">Ksa</span>
                </div>
            </div>
        </li>
        <li>
            <div class="uk-panel">
                <div class="flag_box">
                  <img src="~@/assets/f7.png" alt="">
                  <span class="flag_naem">Jordan</span>
                </div>
            </div>
        </li>
         <li>
            <div class="uk-panel">
                <div class="flag_box">
                  <img src="~@/assets/f8.png" alt="">
                  <span class="flag_naem">Turkey</span>
                </div>
            </div>
        </li>
        
    </ul>

    <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slider-item="previous"></a>
    <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slider-item="next"></a>

</div>
    </div>
   <!-- end flag -->
</template>
<script>
export default {
  name: "SupplierArea",
}
</script>
<style lang="scss" scoped>
// flag
.flag_slider{
  padding-top: 50px;
  .sp{
    margin-bottom: 25px;
  }
}
.flag_box{
  text-align: center;
  img{
    height: 60px;
    object-fit: contain;
  }
  span{
        color: black;
    font-size: 14px;
    padding-left: 10px;
  }
}
// 
</style>