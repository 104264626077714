<template>
    <div class="forum_home_area">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <b-field label="Search Forums">
                       <vs-input placeholder="Search for related topic or person ..." />
                      </b-field>
                </div>
                <div class="col-lg-12">
                    <div class="content_topic">
                      <div class="single_subjects">
                        <div class="title_content">
                            <div class="show_more" style="opacity: 0;">
                                <van-button color="#1d9bf0" plain>  Click here to view more</van-button>
                            </div>
                            <div class="name_topic">
                                Dealer/Reseller
                            </div>
                            <div class="options_topics">
                                <van-button plain color="#1d9bf0">Post a Topic</van-button>
                                <div class="notification_icons">
                                    <template v-if="index == 0">
                                        <i class='bx bxs-bell-ring bx-tada'></i>
                                    </template>
                                    <template v-else>
                                        <i class='bx bxs-bell'></i>
                                    </template>
                                   
                                </div>
                            </div>
                        </div>
                        <template  v-for="data,index in 20" >
                            <div class="data-list-topics" :key="data">
                            <template v-if="(index)%5 == 0 && index > 0">
                                 <div class="BannerArea_section">
                                <!-- slider banners -->
                            <div class="slider_banners">
                                    <div uk-slideshow="animation: push;max-height:200">

                                <div class="uk-position-relative uk-visible-toggle uk-light" tabindex="-1">

                                    <ul class="uk-slideshow-items">
                                        <li>
                                            <img src="https://images.unsplash.com/photo-1543709533-c032159da7b0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80" alt="" uk-cover>
                                        </li>
                                        <li>
                                            <img src="https://images.unsplash.com/photo-1515350540008-a3f566782a3e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80" alt="" uk-cover>
                                        </li>
                                        <li>
                                            <img src="https://images.unsplash.com/photo-1567333971983-7ba18485eaad?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1548&q=80" alt="" uk-cover>
                                        </li>
                                    </ul>

                                    <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slideshow-item="previous"></a>
                                    <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slideshow-item="next"></a>

                                </div>

                                <!-- <ul class="uk-slideshow-nav uk-dotnav uk-flex-center uk-margin"></ul> -->

                            </div>
                            </div>
                            <!-- end slider banner -->
                                            </div>
                             </template>
                             <template v-else>
                                <div class="single-list">
                                <!-- username -->
                                <div class="username_title">
                                    <div class="image">
                                        <img src="https://wallpapers.com/images/hd/nature-landscape-hd-usqznq19dscdjkf8.jpg" alt="">
                                    </div>
                                    <div class="txt_user">
                                        <div class="title_us">Already planning for 2023</div>
                                        <div class="user_date">
                                            <span class="name">Anonymous</span>
                                            <span class="date">Jul 23, 2021</span>
                                        </div>
                                    </div>
                                </div>
                                <!-- end username -->
                                <!-- tags -->
                                <div class="tags_post">
                                    <span>tags Name 1</span>
                                    <span>tags Name 2</span>
                                    <span>tags Name 3</span>
                                    <span>tags Name 4</span>
                                </div>
                                <!-- tags -->
                                <!-- replay views -->
                                <div class="repliesView">
                                  <div class="single_rep">
                                    <div class="keyr">Replies</div>
                                    <div class="valr"> <i class='bx bxs-comment-detail'></i> 12</div>
                                  </div>
                                  <div class="single_rep">
                                    <div class="keyr">Views</div>
                                    <div class="valr"><i class='bx bxs-show'></i> 6K</div>
                                  </div>
                                </div>
                                <!--  -->
                                <!-- user2 -->
                                <div class="date-2">
                                   <div class="txt2">
                                    <div class="dateval">Oct 6, 2022</div>
                                    <span>Islam</span>
                                   </div>
                                   <div class="image2">
                                    <img src="https://e0.pxfuel.com/wallpapers/269/10/desktop-wallpaper-norway-36-norway-gallery-of-top4themes-norway-landscape.jpg" alt="">
                                   </div>
                                </div>
                                <!--  -->
                            </div>
                             </template>
                        </div>
                        </template>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </template>
    <script>
    export default {
      name: "HomeForumArea",
      data() {
        return {
            star_rate:4
        };
      },
      components: {},
    };
    </script>
    <style lang="scss" scoped>
    .forum_home_area{
        min-height: 100vh;
        padding-top: 200px;
        background-color: white;
        border-radius: 14px;
        .content_topic{
            margin-top: 30px;
            .single_subjects{
                margin-bottom: 50px;
                border: 1px solid rgba(220, 220, 220, 0.579);
                border-radius: 10px;
                padding-bottom: 10px;
            }
            .title_content{
                display: flex;
                align-items: center;
                background-color: #1d9bf0;
                justify-content: space-between;
                padding: 14px 14px;
                border-radius: 10px;
                margin-bottom: 15px;
                .name_topic{
                    font-size: 20px;
                    font-weight: 500;
                    color: white;
                }
                button{
                    border-radius: 30px;
                    padding-left: 24px;
                    padding-right: 24px;
                }
                .options_topics{
                    display: flex;
                    align-items: center;
                    .notification_icons{
                        width: 40px;
                        height: 40px;
                        border-radius: 100%;
                        background-color: white;
                        color: var(--active);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-left: 10px;
                        cursor: pointer;
                    }
                }
            }
            // 
            .data-list-topics{
                .single-list{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 4px 10px;
                    transition: 0.3s ease-in-out;
                    &:nth-child(even) {
                        background-color: rgba(220, 220, 220, 0.303);
                        }
                    &:hover{
                        background-color: rgba(220, 220, 220, 0.303);
                    }
                    .username_title{
                        display: flex;
                        align-items: flex-start;
                       flex: 0 0 calc(100%/4);
                        .image{
                            width: 35px;
                            height: 35px;
                            flex: 0 0 35px;
                            margin-top: -5px;
                            img{
                            width: 35px;
                            height: 35px;
                            object-fit: cover;
                            border-radius: 100%;
                        }
                        }
                        .txt_user{
                            flex: 0 0 calc(100% - 35px);
                            display: flex;
                            align-items: flex-start;
                            flex-direction: column;
                            padding-left: 10px;
                            .title_us{
                                font-size: 15px;
                                line-height: 10px;
                                color: var(--active);
                            }
                            .user_date{
                                .date{
                                    font-size: 13px;
                                }
                                .name{
                                    font-size: 12px;
                                    padding-right: 5px;
                                    opacity: 0.6;
                                }
                            }
                        }
                    }
                    .repliesView{
                        flex: 0 0 calc(100%/6);
                        .single_rep{
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            border-bottom: 1px dashed gainsboro;
                            padding-bottom: 2px;
                            &:last-child{
                                border-bottom: none;
                            }
                            .keyr{
                                font-size: 14px;
                                font-weight: 600;
                            }
                            .valr{
                                font-size: 13px;
                                font-weight: 400;
                                opacity: 0.5;
                                i{
                                    vertical-align: middle;
                                    margin-top: -1px;
                                    font-size: 12px;
                                }
                            }
                        }
                    }
                    .tags_post{
                        display: flex;
                        align-items: center;
                      span{
                        font-size: 13px;
                        border: 1px solid gainsboro;
                        margin: 2px;
                        padding: 4px;
                        border-radius: 4px;
    
                      }
                    }
                    .date-2{
                        display: flex;
                        align-items: flex-start;
                        flex-direction: row-reverse;
                        .image2{
                            img{
                                width: 35px;
                                height: 35px;
                                border-radius: 100%;
                                margin-top: -5px;
                            }
                        }
                        .txt2{
                            padding-left: 10px;
                            .dateval{
                                font-size: 13px;
                                line-height: 10px;
                            }
                            span{
                                font-size: 12px;
                            }
                        }
                    }
                }
            }
        }
        // 
        .BannerArea_section{
        margin: 14px 0px 25px 0px;
            }
    }
    </style>
    