<template>
<div class="chatsArea_wraps">
    <div class="container-xl container-fluid">
            <div class="row">
            <div class="col-lg-4">
                <div class="chat_side_wrap">
        <div class="title_chat">
            <h1>Chats</h1>
            <router-link to="/chats" class="d-none">
                <span><i class='bx bx-chevron-right' ></i></span>
            </router-link>
        </div>
        <div class="search_user">
            <input class="vs-input"  />
            <i class='bx bx-search' ></i>
        </div>
        <div class="list_chat">
            <router-link :to="`/chats/1`"  class="single_chat">
                <div class="image">
                    <img src="https://scontent.famm13-1.fna.fbcdn.net/v/t39.30808-1/348638904_1214976155889063_2014289421906887103_n.jpg?stp=dst-jpg_p480x480&_nc_cat=105&ccb=1-7&_nc_sid=fe8171&_nc_ohc=NPFclu1HrBIAX8M3m4l&_nc_ht=scontent.famm13-1.fna&oh=00_AfAELo3HGbEGYSA0YOBdVJdlLh-Pn9kweKgzlHf5mwntfA&oe=650A70ED" alt="userimg">
                </div>
                <div class="user_msg">
                    <h1>Mohammad Abu Alsamen</h1>
                    <div class="flex_msg">
                        <p>hi , How are U ?</p>
                        <p>1 days ago</p>
                    </div>
                </div>
            </router-link>
            <router-link :to="`/chats/2`"  class="single_chat">
                <div class="image">
                    <img src="https://e1.pxfuel.com/desktop-wallpaper/501/938/desktop-wallpaper-best-5-trt-on-hip-noyan.jpg" alt="userimg">
                </div>
                <div class="user_msg">
                    <h1>Omar Eyad</h1>
                    <div class="flex_msg">
                        <p>How much is the price ?</p>
                        <p>1 second ago</p>
                    </div>
                </div>
            </router-link>
            <router-link :to="`/chats/3`"  class="single_chat">
                <div class="image">
                    <img src="https://scontent.famm13-1.fna.fbcdn.net/v/t39.30808-1/306504553_5431355586960488_495552846758177587_n.jpg?stp=c0.94.480.480a_dst-jpg_p480x480&_nc_cat=101&ccb=1-7&_nc_sid=fe8171&_nc_ohc=FHJFw1IGJ9YAX_zF66l&_nc_ht=scontent.famm13-1.fna&oh=00_AfC56jPOIj0OrsIDntsZzgme6ZiGZJ0NWoigwjDR5JOvnA&oe=650A912A" alt="userimg">
                </div>
                <div class="user_msg">
                    <h1>Mohammad Abo HasabAllah</h1>
                    <div class="flex_msg">
                        <p>I'm Good And U ?</p>
                        <p>8 second ago</p>
                    </div>
                </div>
            </router-link>
            <router-link :to="`/chats/4`"  class="single_chat">
                <div class="image">
                    <img src="https://wallpapers.com/images/hd/nature-landscape-hd-usqznq19dscdjkf8.jpg" alt="userimg">
                </div>
                <div class="user_msg">
                    <h1>Yazan Abo Farha</h1>
                    <div class="flex_msg">
                        <p>Good Job Thank U.</p>
                        <p>12 Hours ago</p>
                    </div>
                </div>
            </router-link>
            <!-- <div class="no_chats">
                    <div class="images">
                        <img src="~@/assets/nochat.svg" alt="No Chats">
                    </div>
                    <p>
                        {{get_strings[22].name}}
                    </p>
                </div> -->
        </div>
    </div>
            </div>
            <div class="col-lg-8">
                <div class="chat_main_wraps">
                    <div class="--dark-theme" id="chat">
        <!-- All Chat Messages  -->
        <div class="user_title_chating">
          <div class="left_tit">
                <div class="image">
                    <img src="https://scontent.famm13-1.fna.fbcdn.net/v/t39.30808-1/348638904_1214976155889063_2014289421906887103_n.jpg?stp=dst-jpg_p480x480&_nc_cat=105&ccb=1-7&_nc_sid=fe8171&_nc_ohc=NPFclu1HrBIAX8M3m4l&_nc_ht=scontent.famm13-1.fna&oh=00_AfAELo3HGbEGYSA0YOBdVJdlLh-Pn9kweKgzlHf5mwntfA&oe=650A70ED" alt="userIcon">
                </div>
                <div class="user_name">
                <span class="name">Mohammad Abu Alsamen   <i class='bx bxs-badge-check' ></i></span>
                <div class="status">Last seen 1 Day ago</div>
            </div>
          </div>
          <div class="right_tit">
            <div class="info_card" @click="infoChat = true">
                <vs-tooltip>
                    <i class='bx bxs-info-circle bx-tada'></i>
                <template #tooltip>
                    Show Supplier Info
                </template>
            </vs-tooltip>
                
            </div>
          </div>
        </div>
      <div class="chat__conversation-board" id="chatWraps">
         <!-- single Msg ########  2 -->
         <div class="chat__conversation-board__message-container reversed myMsgBg uk-animation-slide-bottom">
          <div class="chat__conversation-board__message__person">
            <!-- img user -->
            <div class="chat__conversation-board__message__person__avatar">
                <img
                src="https://scontent.famm13-1.fna.fbcdn.net/v/t39.30808-1/348638904_1214976155889063_2014289421906887103_n.jpg?stp=dst-jpg_p480x480&_nc_cat=105&ccb=1-7&_nc_sid=fe8171&_nc_ohc=NPFclu1HrBIAX8M3m4l&_nc_ht=scontent.famm13-1.fna&oh=00_AfAELo3HGbEGYSA0YOBdVJdlLh-Pn9kweKgzlHf5mwntfA&oe=650A70ED" alt="user.naem"
              />
            </div>
          </div>
          <!-- msg text -->
          <div class="chat__conversation-board__message__context">
            <div class="chat__conversation-board__message__bubble">
              <span>
               <p class="mb-0">Hi Omar How Are U ?</p>
               <!-- <template v-if="msg.attachment">
                <p class="mb-0">
                  <a class="d-block" :href="msg.attachment" target="_blank">
                  <div class="single_attach_show"><i class='bx bxs-file-blank'></i>
                    <div class="filename">{{ msg.attachment_old_name }}</div>
                  </div>
                </a></p>
               </template> -->
              </span>
            </div>
            <span class="msg_time">30 second ago <i class='bx bx-check-double'></i></span>
          </div>
          <!-- msg opt -->
          <div class="chat__conversation-board__message__options">
            <button
              class="btn-icon chat__conversation-board__message__option-button option-item emoji-button"
            >
              <svg
                class="feather feather-smile sc-dnqmqq jxshSx"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                aria-hidden="true"
              >
                <circle cx="12" cy="12" r="10"></circle>
                <path d="M8 14s1.5 2 4 2 4-2 4-2"></path>
                <line x1="9" y1="9" x2="9.01" y2="9"></line>
                <line x1="15" y1="9" x2="15.01" y2="9"></line>
              </svg>
            </button>
            <button
              class="btn-icon chat__conversation-board__message__option-button option-item more-button"
            >
              <svg
                class="feather feather-more-horizontal sc-dnqmqq jxshSx"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                aria-hidden="true"
              >
                <circle cx="12" cy="12" r="1"></circle>
                <circle cx="19" cy="12" r="1"></circle>
                <circle cx="5" cy="12" r="1"></circle>
              </svg>
            </button>
          </div>
        </div>
          <!-- else reseved -->
          <div class="chat__conversation-board__message-container uk-animation-slide-bottom">
          <div class="chat__conversation-board__message__person">
            <div class="chat__conversation-board__message__person__avatar">
                <img
                src="https://e1.pxfuel.com/desktop-wallpaper/501/938/desktop-wallpaper-best-5-trt-on-hip-noyan.jpg"
                alt="userIcons"
              />
            </div>
          </div>
          <div class="chat__conversation-board__message__context ">
            <div class="chat__conversation-board__message__bubble">
              <span>I'm Good Thank U .</span>
            </div>
            <!-- <div class="chat__conversation-board__message__bubble">
              <span>Ara you here ?!</span>
            </div> -->
            <!-- <div class="chat__conversation-board__message__bubble">
              <span>Ara you here ?!</span>
            </div> -->
            <span class="msg_time">10 second ago <i class='bx bx-check-double'></i></span>
          </div>
          <!-- msg Options Dots -->
          <div class="chat__conversation-board__message__options">
            <button
              class="btn-icon chat__conversation-board__message__option-button option-item emoji-button"
            >
              <svg
                class="feather feather-smile sc-dnqmqq jxshSx"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                aria-hidden="true"
              >
                <circle cx="12" cy="12" r="10"></circle>
                <path d="M8 14s1.5 2 4 2 4-2 4-2"></path>
                <line x1="9" y1="9" x2="9.01" y2="9"></line>
                <line x1="15" y1="9" x2="15.01" y2="9"></line>
              </svg>
            </button>
            <button
              class="btn-icon chat__conversation-board__message__option-button option-item more-button"
            >
              <svg
                class="feather feather-more-horizontal sc-dnqmqq jxshSx"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                aria-hidden="true"
              >
                <circle cx="12" cy="12" r="1"></circle>
                <circle cx="19" cy="12" r="1"></circle>
                <circle cx="5" cy="12" r="1"></circle>
              </svg>
            </button>
          </div>
        </div>

      </div>
      <!--  -->
      <!-- text Msg Area  -->
      <div class="files_attach_panel">
        <span class="single_attach"><i class='bx bxs-file-blank'></i><span class="filename">Medical.pdf</span>
         <span class="removefile" ><i class='bx bx-x '></i></span>
      </span>
      </div>
      <div class="chat__conversation-panel">
        <div class="chat__conversation-panel__container">
         <div class="flex_tooltips">
            <vs-tooltip class="mr-2">
            <label for="file_chat" class="chat__conversation-panel__button panel-item btn-icon add-file-button">
            <svg
              class="feather feather-plus sc-dnqmqq jxshSx"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              aria-hidden="true"
            >
              <line x1="12" y1="5" x2="12" y2="19"></line>
              <line x1="5" y1="12" x2="19" y2="12"></line>
            </svg>
            <input type="file"  id="file_chat" style="display:none">
          </label>
                <template #tooltip>
                    Add attachment
                </template>
            </vs-tooltip>
          <vs-tooltip class="mr-2">
            <button
            class="chat__conversation-panel__button panel-item btn-icon emoji-button "
          >
          <i class='bx bxs-smile' ></i>
          </button>
                <template #tooltip>
                    Show emoji
                </template>
            </vs-tooltip>
            <!--  -->
            <van-popover v-model="ready_reposes" placement="top">
                <div class="row">
                    <div class="col-xl-3" v-for="msg in 12" :key="msg">
                        <span @click="ready_reposes = false">Hello how are U ?</span>
                    </div>
                </div>
            <template #reference>
                <vs-tooltip class="mr-2">
            <button  @click="ready_reposes = true"
            class="chat__conversation-panel__button panel-item btn-icon emoji-button "
          >
          <i class='bx bxs-comment-edit'></i>
          </button>
                <template #tooltip>
                    Ready responses
                </template>
            </vs-tooltip>
            </template>
            </van-popover>
            <!--  -->
         </div>
          <!--  -->
          <textarea
            class="chat__conversation-panel__input panel-item"
            placeholder="Enter Your Messgae" 
          >
          </textarea>
          <button
            class="chat__conversation-panel__button panel-item btn-icon send-message-button"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              aria-hidden="true"
              data-reactid="1036"
            >
              <line x1="22" y1="2" x2="11" y2="13"></line>
              <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
            </svg>
          </button>
        </div>
      </div>
     <!-- End text Msg Area  -->
    </div>
                </div>
            </div>
        </div>

        <van-popup v-model="infoChat" class="info_chatModel" closeable>
            <div class="content_chat_info">
                <div class="card">
                <div class="card-body">
                    <div class="d-flex align-items-center">
                        <div><img src="https://scontent.famm13-1.fna.fbcdn.net/v/t39.30808-1/348638904_1214976155889063_2014289421906887103_n.jpg?stp=dst-jpg_p480x480&_nc_cat=105&ccb=1-7&_nc_sid=fe8171&_nc_ohc=NPFclu1HrBIAX8M3m4l&_nc_ht=scontent.famm13-1.fna&oh=00_AfAELo3HGbEGYSA0YOBdVJdlLh-Pn9kweKgzlHf5mwntfA&oe=650A70ED" alt="" class="avatar-md rounded-circle img-thumbnail" /></div>
                        <div class="flex-1 ms-3">
                            <h5 class="font-size-16 mb-1"><a href="#" class="text-dark">Mohammad Abu Alsamen</a></h5>
                            <span class="badge badge-soft-success mb-0">CEO</span>
                        </div>
                    </div>
                    <div class="mt-3 pt-1">
                        <p class="text-muted mb-0"><i class="mdi mdi-phone font-size-15 align-middle pe-2 text-primary"></i>0787525XXX</p>
                        <p class="text-muted mb-0 mt-2"><i class="mdi mdi-email font-size-15 align-middle pe-2 text-primary"></i> info@medical.com</p>
                        <p class="text-muted mb-0 mt-2"><i class="mdi mdi-google-maps font-size-15 align-middle pe-2 text-primary"></i>Amman , Jordan</p>
                    </div>
                    <div class="d-flex gap-2 pt-4">
                        <button type="button" class="btn btn-sm w-100"><i class='bx bxs-store' ></i> Vist Web Store</button>
                    </div>
                </div>
            </div>
            </div>
        </van-popup>
    </div>
</div>
</template>
<script>
export default {
  name: "chatsArea",
  data() {
    return {
        infoChat:false,
        ready_reposes:false
    };
  },
  components: {},
};
</script>
<style lang="scss">
.user_title_chating{
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    left: 0;
    width: 100%;
    padding: 0px 10px 0px 8px;
    background-color: rgb(255 255 255);
    z-index: 9;
    top: 0px;
    padding-top: 14px;
    padding-bottom: 6px;
    .left_tit{
        display: flex;
    align-items: center;
        .image{
    flex:  0 0 50px;
    margin-right: 10px;
    img{
        width: 50px;
        height: 50px;
        object-fit: cover;
        border-radius: 100%;
        border: 1px solid var(--active);
        padding: 2px;
    }
            }
        .user_name{
            .name{
                font-size: 14px;
                font-weight: 500;
                i{
                    font-size: 20px;
                    vertical-align: middle;
                    color: #16a1f0;
                    margin-top: -2px;
                }
            }
            .status{
                font-size: 12px;
                color: var(--active);
            }
        }
    }
    .right_tit{
        display: flex;
        align-items: center;
       i{
        color: var(--active);
        font-size: 24px;
        margin-top: -25px;
        cursor: pointer;
        transition: 0.2s ease-in-out;
       }
       i:hover{
        transform: scale(1.2);
       }
    }
}
.info_chatModel{
    width: 400px;
    min-height: 400px;
    background-color: transparent;
    .content_chat_info{
        padding-top: 20px;
    }

// 
.card {
    width: 100%;
    margin: 0 auto;
    margin-top: 30px;
}
.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid #eff0f2;
    border-radius: 1rem;
    font-size: 15px;
}
.avatar-md {
    height: 5rem;
    width: 5rem;
    object-fit: cover;
}
.rounded-circle {
    border-radius: 50%!important;
}
.img-thumbnail {
    padding: 0.25rem;
    background-color: #f1f3f7;
    border: 1px solid #eff0f2;
    border-radius: 0.75rem;
}
.avatar-title {
    align-items: center;
    background-color: #3b76e1;
    color: #fff;
    display: flex;
    font-weight: 600;
    height: 100%;
    justify-content: center;
    width: 100%;
    font-size: 16px;
}
.bg-soft-primary {
    background-color: rgba(59,118,225,.25)!important;
}
a {
    text-decoration: none!important;
}
.badge-soft-danger {
    color: #f56e6e !important;
    background-color: rgba(245,110,110,.1);
}
.badge-soft-success {
    color: #63ad6f !important;
    background-color: rgba(99,173,111,.1);
}
.mb-0 {
    margin-bottom: 0!important;
}
.badge {
    display: inline-block;
    padding: 0.25em 0.6em;
    font-size: 75%;
    font-weight: 500;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.75rem;
}
i{
    color: var(--active) !important;   
}
button{
    background-color: var(--active);
    color: white !important;
    i{
        color: white !important;
        vertical-align: middle;
        margin-top: -2px;
    }
    &:hover{
        border: 1px solid var(--active);
        color: var(--active) !important;
        i{
            color: var(--active) !important;
        }
    }
}
}
.msg_time{
    font-size: 12px;
    display: block;
    margin-top: -2px;
    opacity: 0.6;
    i{
        font-size: 22px;
        vertical-align: middle;
        margin-left: 10px;
        color: var(--active);
    }
    }
    .flex_tooltips{
        padding-top: 5px;
        display: flex;
        align-items: normal;
        margin-right: 10px;
        label{
            margin-top: 1px;
        }
    }
.van-popover{
    width: 600px;
    position: relative;
        z-index: 2;
    .row{
        padding: 20px;
        span{
            display: block;
            font-size: 13px;
            margin-bottom: 10px;
            background-color: var(--active);
            color: white;
            padding: 4px 12px;
            border-radius: 30px;
            cursor: pointer;
        }
    }
}
</style>
<style lang="scss" scoped>
.chatsArea_wraps{
    min-height: 100vh;
    padding-top: 200px;
}
.chat_side_wrap.hideAfter{
    &::after{
        z-index: -1;
        opacity: 0;
    }
}
.chat_side_wrap{
    background-color: #fff;
    border-radius: 20px;
    padding: 20px 20px;
    position: relative;
    min-height: 300px;
    .search_user{
        position: relative;
       input{
        background-color: whitesmoke;
        width: 100%;
        height: 32px;
        border-radius: 30px;
        padding-left: 50px;
       }
       i{
        position: absolute;
        left: 10px;
        top: 6px;
        font-size: 20px;
        color: #ababab;
        border-right: 0.01px solid #ababab;
        padding-right: 6px;
       }
    }
    &::after{
        content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 90px;
    transition: all .25s ease-in-out;
    opacity: 1;
    background: linear-gradient(1deg, #ffffff, #ffffff91);
    display: none;
    }
    .title_chat{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 4px 0px;
        margin-bottom: 10px;
        h1{
            font-size: 15px;
            font-weight: 500;
            color: #000;
        }
        a{
            color: var(--active);
        }
    }
    .list_chat{
        max-height: 300px;
    overflow-y: auto;
        padding-top: 18px;
        .single_chat{
            display: flex;
            align-items: flex-start;
            margin-bottom: 10px;
            padding-bottom: 6px;
            border-bottom: 1px solid rgba(220, 220, 220, 0.291);
            cursor: pointer;
            .image{
                flex:  0 0 50px;
                margin-right: 10px;
                img{
                    width: 50px;
                    height: 50px;
                    object-fit: cover;
                    border-radius: 100%;
                    border: 1px solid var(--active);
                    padding: 2px;
                }
            }
            .user_msg{
                flex: 0 0 calc(100% - 60px);
                h1{
                    font-size: 15px;
                    font-weight: 500;
                    color: #000;
                    margin: 0;
                }
                .flex_msg{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding-top: 4px;
                    opacity: 0.7;
                    color: #000;
                    p{
                        font-size: 13px;
                    }
                }
                .flex_msg.not_seen{
                  p{
                    font-weight: 700;
                    &:first-child{
                        position: relative;
                        &::after{
                            font-family: boxicons !important;
                            font-weight: 400;
                            content: "\ed47";
                            right: -25px;
                            position: absolute;
                            top: 1px;
                            color: var(--active);
                            animation: flashing 1.5s infinite linear;
                        }
                    }
                  }
                }
            }
        }
    }
}
.no_chats{
  text-align: center;
  padding-top: 30px;
  .images{
    img{
      width: 100%;
      height: 150px;
      object-fit: contain;
    }
  }
  p{
    margin-top: 20px;
    font-size: 14px;
    font-weight: 500;
  }
}
// 
.nochat_user{
  text-align: center;
  background-color: white;
    padding: 50px 0px;
    border-radius: 14px;
  .images{
    img{
      width: 100%;
      height: 350px;
      object-fit: contain;
    }
  }
  p{
    margin-top: 40px;
    font-size: 16px;
    font-weight: 500;
  }
}
.--dark-theme {
  --chat-background: #fff;
  --chat-panel-background: whitesmoke;
  // --chat-bubble-background: #42d9c6;
  --chat-bubble-background: #3e4042;
  --chat-bubble-active-background: #171a1b;
  --chat-add-button-background: var(--active);
  --chat-send-button-background: var(--active);
  --chat-text-color: #fff;
  --chat-options-svg: #a3a3a3;
}

body {
  background: url(https://images.unsplash.com/photo-1495808985667-ba4ce2ef31b3?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80);
  background-size: cover;
}

#chat {
  background: var(--chat-background);
  max-width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 1em;
  border-radius: 12px;
  position: relative;
  overflow: hidden;
}
#chat::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(https://images.unsplash.com/photo-1495808985667-ba4ce2ef31b3?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80)
    fixed;
  z-index: -1;
}
#chat .btn-icon {
  position: relative;
  cursor: pointer;
}
#chat .btn-icon svg {
  stroke: #fff;
  fill: #fff;
  width: 50%;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
#chat .chat__conversation-board {
  padding: 1em 0 2em;
  height: calc(90vh - 55px - 2em - 25px * 2 - 2.5em - 3em);
  overflow: auto;
  padding-top: 115px;
}
#chat .chat__conversation-board__message-container.reversed {
  flex-direction: row-reverse;
}
#chat
  .chat__conversation-board__message-container.reversed
  .chat__conversation-board__message__bubble {
  position: relative;
}
#chat
  .chat__conversation-board__message-container.reversed
  .chat__conversation-board__message__bubble
  span:not(:last-child) {
  margin: 0 0 2em 0;
}
#chat
  .chat__conversation-board__message-container.reversed
  .chat__conversation-board__message__person {
  margin: 0 0 0 1.2em;
}
#chat
  .chat__conversation-board__message-container.reversed
  .chat__conversation-board__message__options {
  align-self: center;
  position: absolute;
  left: 0;
}
#chat .chat__conversation-board__message-container {
  position: relative;
  display: flex;
  flex-direction: row;
}
#chat
  .chat__conversation-board__message-container:hover
  .chat__conversation-board__message__options {
  display: flex;
  align-items: center;
}
#chat
  .chat__conversation-board__message-container:hover
  .option-item:not(:last-child) {
  margin: 0 0.5em 0 0;
}
#chat .chat__conversation-board__message-container:not(:last-child) {
  margin: 0 0 2em 0;
}
#chat .chat__conversation-board__message__person {
  text-align: center;
  margin: 0 1.2em 0 0;
}
#chat .chat__conversation-board__message__person__avatar {
  height: 35px;
  width: 35px;
  overflow: hidden;
  border-radius: 50%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-user-select: none;
  position: relative;
}
#chat .chat__conversation-board__message__person__avatar::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
}
#chat .chat__conversation-board__message__person__avatar img {
  height: 100%;
  width: auto;
}
#chat .chat__conversation-board__message__person__nickname {
  font-size: 9px;
  color: #484848;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: none;
}
#chat .chat__conversation-board__message__context {
  max-width: 55%;
  align-self: flex-end;
}
#chat .chat__conversation-board__message__options {
  align-self: center;
  position: absolute;
  right: 0;
  display: none;
}
#chat .chat__conversation-board__message__options .option-item {
  border: 0;
  background: 0;
  padding: 0;
  margin: 0;
  height: 16px;
  width: 16px;
  outline: none;
}
#chat .chat__conversation-board__message__options .more-button svg {
  stroke: var(--chat-options-svg);
  fill: transparent;
  width: 100%;
}
.myMsgBg .chat__conversation-board__message__bubble span{
background:#42d9c6 !important;
}
#chat .chat__conversation-board__message__bubble span {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: inline-table;
  word-wrap: break-word;
  background: var(--chat-bubble-background);
  font-size: 13px;
  color: var(--chat-text-color);
  padding: 0.5em 0.8em;
  line-height: 1.5;
  border-radius: 6px;
}
#chat .chat__conversation-board__message__bubble:not(:last-child) {
  margin: 0 0 0.3em;
}
#chat .chat__conversation-board__message__bubble:active {
  background: var(--chat-bubble-active-background);
}
#chat .chat__conversation-panel {
  background: var(--chat-panel-background);
  border-radius: 12px;
  padding: 0 1em;
  height: 55px;
  margin: 0.5em 0 0;
}
#chat .chat__conversation-panel__container {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
}
#chat .chat__conversation-panel__container .panel-item:not(:last-child) {
  margin: 0 0.5em 0 0;
}
#chat .chat__conversation-panel__button {
  background: grey;
  height: 20px;
  width: 30px;
  border: 0;
  padding: 0;
  outline: none;
  cursor: pointer;
}
#chat .chat__conversation-panel .add-file-button {
  height: 23px;
  min-width: 23px;
  width: 23px;
  background: var(--chat-add-button-background);
  border-radius: 50%;
}
#chat .chat__conversation-panel .add-file-button svg {
  width: 70%;
  stroke: #fff;
}
#chat .chat__conversation-panel .emoji-button {
  min-width: 23px;
  width: 23px;
  height: 23px;
  background: transparent;
  border-radius: 50%;
  font-size: 26px;
  vertical-align: middle;
  i{
    vertical-align:top;
    margin-top: -3px;
    color: var(--active);
  }
}
#chat .chat__conversation-panel .emoji-button svg {
  width: 100%;
  fill: transparent;
  stroke: #54575c;
}
#chat .chat__conversation-panel .send-message-button {
  background: var(--chat-send-button-background);
  height: 30px;
  min-width: 30px;
  border-radius: 50%;
  transition: 0.3s ease;
}
#chat .chat__conversation-panel .send-message-button:active {
  transform: scale(0.97);
}
#chat .chat__conversation-panel .send-message-button svg {
  margin: 1px -1px;
}
#chat .chat__conversation-panel__input {
  width: 100%;
  height: 100%;
  outline: none;
  position: relative;
  color: black;
  font-size: 13px;
  background: transparent;
  border: 0;
  resize: none;
  border: none !important;
  padding-top: 18px;
}

.files_attach_panel{
  padding-top: 10px;
  padding-left: 10px;
  overflow-x: auto;
  min-height: 40px;
  .single_attach{
    color: var(--active);
    font-size: 13px;
    background-color: #fff;
    border: 1px solid rgba(220, 220, 220, 0.474);
    padding: 4px 10px;
    border-radius: 4px;
    margin-right: 8px;
   .filename{
    margin: 0px 4px;
    display: inline-block;
   }
   .removefile{
    background-color: red;
    color: white;
    width: 18px;
    height: 18px;
    border-radius: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-left: 5px;
   }
  }
}
.single_attach_show{
  display: inline-flex;
  align-items: center;
    color: var(--cw);
    font-size: 14px;
    opacity: 0.7;
    // background-color: #3e4042;
    padding: 1px 0px;
    border-radius: 4px;
    margin: 0px;
   .filename{
    margin: 0px 4px;
    display: inline-block;
   }
   i{
    font-size: 18px;
   }
  }

@media only screen and (max-width: 600px) {
  #chat {
    margin: 0;
    border-radius: 0;
  }
  #chat .chat__conversation-board {
    // height: calc(100vh - 55px - 2em - 0.5em - 3em);
    height: calc(100vh - 100px - 5em - 3.5em - 3em);
  }
  #chat .chat__conversation-board__message__options {
    display: none !important;
  }
}
</style>
