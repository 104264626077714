<template>
 <div class="pd_fix">
  <loginApp></loginApp>
 </div>
</template>

<script>
import loginApp from '@/components/login/loginApp.vue';

export default {
   name:'Login',
   data(){
    return{
    }
   },
   components:{
       loginApp,
      
   },
   methods:{},
    mounted(){
         document.body.classList.add('loginPage');
    },
    destroyed(){
      document.body.classList.remove('loginPage');
    }
}
</script>
