<template>
   <!-- setting icons -->
  <div class="setting_icons">
    <div class="sp">
      <span>Settlement Process</span>
    </div>
    <div class="flex_icons_set">
      <div class="single_setting_icon">
        <div class="image_icos">
          <i class='bx bxs-user-account'></i>
        </div>
        <div class="text_icos">
          Register
        </div>
      </div>
      <div class="single_setting_icon">
        <div class="image_icos">
         <i class='bx bxs-edit-alt' ></i>
        </div>
        <div class="text_icos">
          Edit company information and product information
        </div>
      </div>
      <div class="single_setting_icon">
        <div class="image_icos">
        <i class='bx bx-file-find' ></i>
        </div>
        <div class="text_icos">
          Platform Review
        </div>
      </div>
       <div class="single_setting_icon">
        <div class="image_icos">
      <i class='bx bxs-user-check' ></i>
        </div>
        <div class="text_icos">
         Determine membership level and sign a contract
        </div>
      </div>
          <div class="single_setting_icon">
        <div class="image_icos">
       <i class='bx bx-map-pin' ></i>
        </div>
        <div class="text_icos">
         start global promotion
        </div>
      </div>
     
    </div>
  </div>
   <!-- end Setting icons -->
</template>
<script>
export default {
  name: "processArea",
}
</script>
<style lang="scss" scoped>
// start setting 
.setting_icons{
  padding-bottom: 70px;
  padding-top: 60px;
  .sp{
    margin-bottom: 40px;
  }
  .flex_icons_set{
        display: flex;
    align-items: center;
    justify-content: space-between;
    .single_setting_icon:last-child{
    &::after{
      display: none;
    }
    }
    .single_setting_icon{
      min-height: 200px;
      position: relative;
    background-color: white;
    padding: 20px 8px;
    flex: 0 0 calc(100% / 5.6);
        box-shadow: 0px 2px 5px #e6e6e6;
    justify-content: center;
    .image_icos{
      text-align: center;
       i{
    font-size: 5.5rem;
    color:deepskyblue;
  }
    }
  .text_icos{
    font-size: 0.9rem;
    color: black;
    font-weight: 500;
    text-align: center;
    padding-top: 20px;
  }
  &::after{
    content: '\ee91';
    position: absolute;
    right: -23px;
    top: 40%;
    z-index: 1;
    font-family: 'boxicons';
    font-size: 45px;
    color: orange;

  }
  
    }
  }
} 
// end setting
</style>