<template>
    <div class="itemFinalAds">
      <itemAdsFinalArea></itemAdsFinalArea>
    </div>
  </template>
  
  <script>
  import itemAdsFinalArea from "@/components/itemAdsFinal/itemAdsFinalArea.vue";
  export default {
    name: "ItemAdsFinal",
    data() {
      return {};
    },
    components: {
        itemAdsFinalArea,
    },
    methods: {},
    mounted() {
      document.body.classList.add("itemFinalAds");
    },
    destroyed() {
      document.body.classList.remove("itemFinalAds");
    },
  };
  </script>
  