<template>
    <div class="itemFinalAds">
      <addAdsArea></addAdsArea>
    </div>
  </template>
  
  <script>
  import addAdsArea from "@/components/addAds/addAdsArea.vue";
  export default {
    name: "addAds",
    data() {
      return {};
    },
    components: {
        addAdsArea,
    },
    methods: {},
    mounted() {
      document.body.classList.add("addAdsArea");
    },
    destroyed() {
      document.body.classList.remove("addAdsArea");
    },
  };
  </script>
  