<template>
  <div class="testmonial">
    <div class="uk-position-relative uk-visible-toggle uk-light" tabindex="-1" uk-slider="center: false;finite:false">

    <ul class="uk-slider-items uk-grid uk-grid-match" >
      <li class="uk-width-3-4@l">
            <div class="">
                <div class="flex_testm">
                  <div class="image_testm">
                    <img src="https://images4.alphacoders.com/123/1230945.jpg" alt="">
                  </div>
                  <div class="desc_testm">
                    <h2>Instgram Company</h2>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, </p>
                    <p> but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets </p>
                  </div>
                </div>
            </div>
        </li>
        <li class="uk-width-3-4@l">
            <div class="">
                <div class="flex_testm">
                  <div class="image_testm">
                    <img src="https://images3.alphacoders.com/689/689598.jpg" alt="">
                  </div>
                  <div class="desc_testm">
                    <h2>Facebook Company</h2>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, </p>
                    <p> but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets </p>
                  </div>
                </div>
            </div>
        </li>
        <li class="uk-width-3-4@l">
            <div class="">
                <div class="flex_testm">
                  <div class="image_testm">
                    <img src="https://downloadr2.apkmirror.com/wp-content/uploads/2022/11/18/636c2391c550d.png" alt="">
                  </div>
                  <div class="desc_testm">
                    <h2>Google Company</h2>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, </p>
                    <p> but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets </p>
                  </div>
                </div>
            </div>
        </li>
    </ul>
      <ul class="uk-slider-nav uk-dotnav uk-flex-center uk-margin"></ul>
    <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slider-item="previous"></a>
    <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slider-item="next"></a>

</div>
   </div>
   <!-- end -->
</template>
<script>
export default {
  name: "TestimonailArea",
}
</script>
<style lang="scss" scoped>
// testmionail
.testmonial{
  padding-top: 70px;
}
.flex_testm{
    display: flex;
    align-items: flex-start;
    color: black;
    border: 1px solid gainsboro;
    padding: 20px 14px;
    border-radius: 10px;
    box-shadow: 0px 1px 2px gainsboro;
     transition: 0.2s ease-in-out;
    &:hover{
          background-color: #dcdcdc9c;
      .image_testm{
        img{
              filter: drop-shadow(2px 4px 6px gray);
        }
      }
    }
  .image_testm{
    flex: 0 0 300px;
    img{
      object-fit: contain;
      transition: 0.2s ease-in-out;
          height: 200px;
          // border-radius: 10px;
    }
  }
  .desc_testm{
      flex: 0 0 calc(100% - 300px);
    padding-left: 20px;
    h2{    
    color: black;
    font-size: 1.6rem;
    margin-bottom: 10px;
    font-weight: 500;
    }
    p{
      font-size: 14px;
    opacity: 0.6;
    }
    }
}
// end test
</style>