<template>
 <div class="whichCategoryArea">
    <div class="alert_category">
     <vs-alert color="red"  >
      <div class="flex_choise">
        <div class="radio_wrap">
        <span class="title_radio">Which category do you prefer</span>
            <vs-radio  v-model="picked" success val="1">
              Pharmaceutical
            </vs-radio>
             <vs-radio  v-model="picked" danger val="2">
              Medical Equipment
            </vs-radio>
            <vs-radio v-model="picked"  val="3">
              Both
            </vs-radio>
        </div>
      </div>
    </vs-alert>
   </div>
    </div>
</template>
<script>
export default {
  name: "whichCategoryArea",
   data(){
    return{
       picked: 1,
    }
  },
}
</script>
<style lang="scss" scoped>
.alert_category{
  margin-top: 25px;
}
.flex_choise{
   display: flex;
  align-items: center;
  span.title_radio{
    display: inline-block;
    // margin-right: 80px;
    font-size: 15px;
    text-transform: capitalize;
    font-weight: 500;
  }
.radio_wrap{
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 0 0 100%;
}
.vs-radio__effect{
  border: 1px solid white;
}
}
</style>