<template>
    <div class="dashoard_user_info add_adds_wraps">
      <div class="container">
        <h3 class="tabname">Add New Training Ads</h3>
     <div class="personal_wraps">
      <!-- input form  -->
        <div class="form_wraps">
          <div class="row">
            <div class="col-md-6 mrgb">
                  <b-field label="Related to">
                    <vs-input readonly  @click="relatedpop = true" :placeholder="`Sub Category - ${result1}`" />
          </b-field>
              </div>
              <div class="col-md-6 mrgb">
                   <b-field label="Course topic">
                   <vs-input placeholder="Learn VueJs ..." />
                  </b-field>
              </div>
              <div class="col-md-6 mrgb">
                  <b-field label="Training Method">
               <vs-select placeholder="Select" v-model="value2">
          <vs-option label="Offline (In Location)" value="1">
            Offline (In Location)
          </vs-option>
          <vs-option label="Online" value="2">
          Online
          </vs-option>
        </vs-select>
          </b-field>
              </div>
              <div class="col-md-6 mrgb">
                   <b-field label="Course period (Days)">
                   <vs-input placeholder="90 Days" />
                  </b-field>
              </div>
              <div class="col-md-6 mrgb">
                   <b-field label="Trainer Experience">
                   <vs-input placeholder="+1 Years" />
                  </b-field>
              </div>
              <div class="col-md-6 mrgb">
                   <b-field label="Course Cost ($)">
                   <vs-input placeholder="90 $" />
                  </b-field>
              </div>
              <div class="col-md-6 mrgb">
                  <b-field label="Country">
               <vs-select placeholder="Select" v-model="value3">
                    <vs-option label="Jordan" value="1">
                        Jordan
                    </vs-option>
                    <vs-option label="India" value="2">
                    India
                    </vs-option>
                    <vs-option label="Spain" value="3">
                        Spain
                    </vs-option>
                    <vs-option  label="Usa" value="4">
                    Usa
                    </vs-option>
                    <vs-option label="Russia" value="6">
                    Russia
                    </vs-option>
                    <vs-option label="Qatar" value="7">
                    Qatar
                    </vs-option>
                    <vs-option label="Egypt" value="8">
                    Egypt
                    </vs-option>
                    </vs-select>
                    </b-field>
                </div>
                <div class="col-md-6 mrgb">
                  <b-field label="State">
               <vs-select placeholder="Select" v-model="value3">
                    <vs-option label="Amman" value="1">
                        Amman
                    </vs-option>
                    <vs-option label="Irbid" value="2">
                    Irbid
                    </vs-option>
                    <vs-option label="Zarqa" value="3">
                    Zarqa
                    </vs-option>
                    <vs-option  label="Jarash" value="4">
                    Jarash
                    </vs-option>
                    <vs-option label="Karak" value="6">
                    Karak
                    </vs-option>
                    <vs-option label=" Tafila" value="7">
                    Tafila
                    </vs-option>
                    <vs-option label="Al-Salt" value="8">
                    Al-Salt
                    </vs-option>
                    </vs-select>
                    </b-field>
                </div>
              <div class="col-md-12 mrgb2">
                   <b-field label="Description">
                    <textarea class="txtArea"></textarea>
                  </b-field>
              </div>
                  <div class="col-md-4 col-12 mr-auto ml-auto mrgt">
                    <vs-button gradient>Publish Training</vs-button>
              </div>
          </div>
        </div>
        <van-popup v-model="relatedpop" class="realted_wraps_pop" closeable>
        <div class="realted_wraps">
         <h2 class="tit">Related to</h2>
         <van-checkbox-group v-model="result1" ref="checkboxGroup">
        <div class="row mt-5">
          <h3 class="mb-4">Catgeory Name</h3>
          <template v-for="index in 20">
            <div class="col-lg-3 col-12 mb-4" :key="index">
          <van-checkbox checked-color="#1d9bf0" :name="index">
            <span class="titleCard">Sub Category - {{ index }}</span>
          </van-checkbox>
          </div>
    </template>
        </div>
  </van-checkbox-group>
        </div>
        </van-popup>
     </div>
      </div>
    </div>
  </template>
  <script>
  import { mapActions, mapGetters } from "vuex";
  
  export default {
    name: "AddJobs",
    data() {
      return {
          n_name:1,
          im:1,
          picked:1,
          hasVisiblePassword: false,
          password:'password',
           htmlForEditor: "",
           dropFiles: null,
           dropFiles_arr:[],
          // 
          value:1,
          value2:1,
          value3:1,
          value4:1,
          budget:[1000,25000],
          mySpesification:[],
          fileList: [{ url: 'https://img01.yzcdn.cn/vant/leaf.jpg' }, { url: 'https://img01.yzcdn.cn/vant/leaf.jpg' }],
           option1: '',
           max_cont:4,
            //  
            relatedpop:false,
           result1:[]
      };
    },
    components: {
    },
    computed: {
      ...mapGetters({}),
    },
    methods: {
      ...mapActions({}),
       handleImageAdded: function(file, Editor, cursorLocation) {
          // An example of using FormData
          // NOTE: Your key could be different such as:
          // formData.append('file', file)
          console.log(file);
          Editor.insertEmbed(cursorLocation, 'image', 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSTB-pULN4tWIgabMt9ooDqzE6mD-tQ3VfkzbHDRqPhTrR3lwS4T1tkU7DMz35OBHl3NVE&usqp=CAU');
          // var formData = new FormData();
          // formData.append('image', file)
   
          // axios({
          //   url: 'https://fakeapi.yoursite.com/images',
          //   method: 'POST',
          //   data: formData
          // })
          // .then((result) => {
          //   let url = result.data.url // Get url from response
          //   Editor.insertEmbed(cursorLocation, 'image', url);
            
          // })
          // .catch((err) => {
          //   console.log(err);
          // })
        },
         deleteDropFile() {
        this.dropFiles=null;
      },
       deleteDropFileMulti(index) {
                  this.dropFiles.splice(index, 1)
              },
              onchangeBudget(){
        },
        onchangePeriod(){
        },
        onChange(value) {
          console.log(value)
      // Toast('current value：' + value);
    },
    addSkills() {
      this.mySpesification.push({key:'',value:''});
    },
    removeSkills(index) {
      this.mySpesification.splice(index, 1);
    },
    },
    mounted() {},
  };
  </script>
  <style lang="scss" scoped>
  .dashoard_user_info{
      padding-top: 200px;
      .mrgb{
          margin-bottom: 20px;
          position: relative;
      }
      .mrgb2{
          margin-bottom: 20px;
          position: relative;
      }
      .mrgt{
          margin-top: 25px;
      }
      .or_span{
              margin-bottom: 6px;
      display: inline-block;
      padding-right: 15px;
      font-size: 15px;
      font-weight: 500;
      }
      .center_flex{
              display: flex;
      height: 100%;
      justify-content: flex-start;
      align-items: flex-end;
      .last_check{
          margin-left: 15px;
      }
      }
      .change_span{
         font-size: 13px;
      color: var(--active);
      cursor: pointer;
      position: absolute;
      right: 20px;
      bottom: -14px;
      font-weight: 400;
      text-transform: capitalize;
      }
      .tabname{
        font-size: 16px;
    margin: 0;
    padding: 0;
    background-color: gainsboro;
    display: inline-block;
    padding: 6px 20px;
    border-radius: 5px;
    font-weight: 600;
    color: black;
    min-width: 200px;
    text-align: center;
    margin-top: 20px;
      }
      .personal_wraps{
      padding-top: 20px;
      .type_user{
      display: flex;
      align-items: center;
      .key_span{
          display: inline-block;
      margin-right: 20px;
      font-size: 16px;
      font-weight: 500;
      }
      .radio_wrap{
          display: flex;
          align-items: center;
          .vs-radio-content{
              margin-right: 10px;
          }
      }
      }
      .form_wraps{
          padding-top: 0px;
          // max-width: 700px;
      }
      }
  }
  .slider_price{
         .flex_bud{
           display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    right: 0;
    top: 0;
    width: 200px;
           span{
             font-style: normal;
             font-weight: 400;
             font-size: 14px;
             line-height: 21px;
             color: #A1A6AD;
           }
         }
       }
       .flex-row{
  padding: 10px 0px;
  position: relative;
  .vuesax-app-is-ltr .vs-select--input{
    height: 48px !important;
  }
}
.con-vs-slider{
  min-width: auto;
}
.deleteicon{
  width: 35px;
  height: 35px;
  background-color: red;
  color: white;
  border-radius: 100%;
  position: absolute;
  right: -10px;
  top: -10px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s ease-in-out;
  &:hover{
    transform: scale(1.1);
  }
}
.newSkillsBtn{
  height: 52px;
  i{
    vertical-align: middle;
    margin-top: -3px;
  }
}
.newFetureBtn{
    display: block;
    text-align: center;
    background-color: #45c93a;
    color: white;
    padding: 6px 34px;
    border-radius: 5px;
    margin-left: 4px;
    transition: 0.3s ease-in-out;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    // &:hover{
    //   transform: scale(1.1);
    // }
  i{
    vertical-align: middle;
    margin-top: -1px;
  }
}
.sp_enter{
    font-size: 13px;
    margin-top: -9px;
    margin-bottom: 15px;
 }  
.vue-tags-input{
    max-width: 100%;
}
.txtArea{
    width: 100%;
    height: 150px;
    background-color: white !important;
    border: none !important;
    padding: 10px;
    font-size: 14px;
}

// 
.realted_wraps_pop{
  width: 90%;
  height: 80vh;
  padding: 20px;
  border-radius: 10px;
  h2{
    font-size: 20px;
  }
}
  </style>
  