<template>
   <div class="itemAds_final_wraps">
    <div class="container">
       <div class="row">
        <div class="col-xl-9">
            <div class="row">
                <div class="col-xl-6">
                    <div class="slider_item">
                    <div class="slider_prod">
            <div
              class="uk-position-relative uk-visible-toggle uk-light main_slider_info"
              tabindex="-1"
              uk-slideshow="min-height:340;
              max-width:375"
            >
              <div class="flex_grid">
                <ul class="uk-slideshow-items main_slider1">
                  <li v-for="image, index in images" :key="index">
                    <img :src="image" alt="slideshow" uk-contain />
                  </li>
                </ul>

                <ul class="uk-thumbnav uk-thumbnav-vertical">
                  <li :uk-slideshow-item="index" :class="[(index==0)?'uk-active':'']"  v-for="image,index in images" :key="index">
                    <a href="#"
                      ><img
                        :src="image"
                        width="100"
                        height="154"
                        alt="thumbnav"
                    /></a>
                  </li>
                </ul>
              </div>
              <!-- <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slideshow-item="previous"></a>
                <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slideshow-item="next"></a> -->
            </div>
          </div> 
                </div>
            </div>
            <div class="col-xl-6">
                <div class="prod_info">
                     <div class="title">
                         <h2><span class="status">Wanted</span> ENVITEC EnviteC Power Supply </h2>
                     </div>
                      <!-- fob price -->
                      <div class="properites">
                        <div class="single_prop">
                         <div class="prob_key">
                             <span>FOB Price: </span>
                         </div>
                         <div class="prob_val">
                             <span>US $145 - 200 / Unit</span>
                         </div>
                        </div>
                        <div class="single_prop">
                         <div class="prob_key">
                             <span>Condition:</span>
                         </div>
                         <div class="prob_val">
                             <span>New</span>
                         </div>
                        </div>
                        <div class="single_prop">
                         <div class="prob_key">
                             <span>Quantity needed:</span>
                         </div>
                         <div class="prob_val">
                             <span>112 Unit</span>
                         </div>
                        </div>
                        <div class="single_prop">
                         <div class="prob_key">
                             <span>Shipping To:</span>
                         </div>
                         <div class="prob_val">
                             <span class="prob_flag"><img src="~@/assets/flag/flag5.png" alt=""></span>
                         </div>
                        </div>
                        <div class="single_prop">
                         <div class="prob_key">
                             <span>Shipping From:</span>
                         </div>
                         <div class="prob_val">
                             <span class="prob_flag"><img src="~@/assets/flag/flag9.png" alt=""></span>
                         </div>
                        </div>
                        <div class="single_prop">
                         <div class="prob_key">
                             <span>Country of Origin:</span>
                         </div>
                         <div class="prob_val">
                             <span class="prob_flag"><img src="~@/assets/flag/flag2.png" alt=""></span>
                         </div>
                        </div>
                        <div class="single_prop">
                         <div class="prob_key">
                             <span>In Stock:</span>
                         </div>
                         <div class="prob_val">
                             <span>Yes</span>
                         </div>
                        </div>
                      </div>
                      <!--  -->
                 <div class="badage_users">
                   <div class="badge_down">
                    <!-- gradient -->
                   <vs-button
                    color="#e63026"
                    animation-type="scale">
                   <i class='bx bx-heart'></i>
                    <template #animate >
                    Add to Favorites
                    </template>
                </vs-button>
                <vs-button
                    color="#45c93a"
                    animation-type="scale">
                <i class='bx bxs-conversation' ></i>
                    <template #animate >
                    Chat Now
                    </template>
                </vs-button>
                <vs-button
                    color="#00bfff"
                    animation-type="scale">
                    <i class='bx bxs-user-plus'></i>
                    Follow Supplier
                </vs-button>
                   </div>
                   <div class="slice_button">
                    <vs-button
                    @click="modalf1=true"
                    color="#394f86"
                    animation-type="scale">
                    Get Financing
                </vs-button>
                <vs-button
                @click="modalf2=true"
                    color="#394f86"
                    animation-type="scale">
                     Forward Listing
                </vs-button>
                   </div>
                 </div>
                 </div>
            </div>
            <!-- item description -->
            <div class="col-12">
                <div class="title_desc">
                    <h2>Item Information</h2>
                    <p>Seller assumes all responsibility for this listing.</p>
                    <div class="prod_info">
                        <div class="properites prop2">
                        <div class="single_prop">
                         <div class="prob_key">
                             <span>Condition</span>
                         </div>
                         <div class="prob_val">
                             <span>Used - Exellent</span>
                         </div>
                        </div>
                        <div class="single_prop">
                         <div class="prob_key">
                             <span>Brand:</span>
                         </div>
                         <div class="prob_val">
                             <span>Philps</span>
                         </div>
                        </div>
                        <div class="single_prop">
                         <div class="prob_key">
                             <span>Fluoroscopic</span>
                         </div>
                         <div class="prob_val">
                             <span>0.1-7.2w/auto HIP</span>
                         </div>
                        </div>
                        <div class="single_prop">
                         <div class="prob_key">
                             <span>Model</span>
                         </div>
                         <div class="prob_val">
                             <span>BV Libra</span>
                         </div>
                        </div>
                        <div class="single_prop">
                         <div class="prob_key">
                             <span>Body Smart</span>
                         </div>
                         <div class="prob_val">
                             <span>Yes</span>
                         </div>
                        </div>
                        <div class="single_prop">
                         <div class="prob_key">
                             <span>Cooling</span>
                         </div>
                         <div class="prob_val">
                             <span>30,600</span>
                         </div>
                        </div>
                        <div class="single_prop">
                         <div class="prob_key">
                             <span>Maximum Output</span>
                         </div>
                         <div class="prob_val">
                             <span>7.2 mA @ 110 Kvp</span>
                         </div>
                        </div>
                        <div class="single_prop">
                         <div class="prob_key">
                             <span>Monitors</span>
                         </div>
                         <div class="prob_val">
                             <span>Dual Hi Resolution</span>
                         </div>
                        </div>
                        <div class="single_prop">
                         <div class="prob_key">
                             <span>Radio Graphic</span>
                         </div>
                         <div class="prob_val">
                             <span>40 - 105</span>
                         </div>
                        </div>
                        <div class="single_prop">
                         <div class="prob_key">
                             <span>Heat</span>
                         </div>
                         <div class="prob_val">
                             <span>50,000 / 1,200,000</span>
                         </div>
                        </div>
                        <div class="single_prop">
                         <div class="prob_key">
                             <span>Size</span>
                         </div>
                         <div class="prob_val">
                             <span>512 X 512 HZ</span>
                         </div>
                        </div>
                        <div class="single_prop">
                         <div class="prob_key">
                             <span>X-Ray</span>
                         </div>
                         <div class="prob_val">
                             <span>Stationary</span>
                         </div>
                        </div>
                        
                      </div>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="title_desc">
                    <h2>Item Description</h2>
                    <p>Full description of the product</p>
                    <div class="wrap_p">
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages,</p>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages,</p>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages,</p>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="BannerArea_section">
                    <!-- slider banners -->
                <div class="slider_banners">
                    <div uk-slideshow="animation: push;max-height:200">

                    <div class="uk-position-relative uk-visible-toggle uk-light" tabindex="-1">

                        <ul class="uk-slideshow-items">
                            <li>
                                <img src="https://images.unsplash.com/photo-1543709533-c032159da7b0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80" alt="" uk-cover>
                            </li>
                            <li>
                                <img src="https://images.unsplash.com/photo-1515350540008-a3f566782a3e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80" alt="" uk-cover>
                            </li>
                            <li>
                                <img src="https://images.unsplash.com/photo-1567333971983-7ba18485eaad?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1548&q=80" alt="" uk-cover>
                            </li>
                        </ul>

                        <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slideshow-item="previous"></a>
                        <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slideshow-item="next"></a>

                    </div>

                    <!-- <ul class="uk-slideshow-nav uk-dotnav uk-flex-center uk-margin"></ul> -->

                </div>
                </div>
                <!-- end slider banner -->
            </div>
            </div>
            <div class="col-12">
                <div class="title_desc">
                    <h2>Related Products</h2>
                    <p>Seller assumes all responsibility for this listing.</p>
                    </div>
                <div class="row">
      <template v-for="(prod,index) in 8" >
       <div class="col-xl-3" :key="prod.id">
        <div class="box_products">
           <div class="fav_box" :id="`fav${index}`" @click="add_fav(index)">
            <vs-avatar  badge-color="danger" badge-position="top-right">
              <i class='bx bx-heart' ></i>
            </vs-avatar>
           </div>
          <div class="imagebox">
            <img src="http://userscontent2.emaze.com/images/486f275c-cece-4f32-a497-f97fd4701eea/6173aa33-b388-4454-9906-7e9cdecea457.png" alt="">
          </div>
          <div class="description">
            <h3>Product Name device</h3>
            <p>Lorem Ipsum is simply dummy  </p>
            <p class="shipping">Shipping from: USA <img src="~@/assets/flag1.png" alt=""> </p>
          </div>
         <div class="btn_contact">
            <vs-button gradient> Contact supplier</vs-button>
         </div>
        </div>
      </div>
      </template>
    </div>
            </div>
            </div>
        </div>
        <div class="col-xl-3">
            <div class="company_card">
              <div class="title_cards">
                <span>Company Cards</span>
              </div>
              <div class="wraps_cards">
                <div class="company_logo">
                    <img src="https://c1.wallpaperflare.com/preview/150/478/521/boardroom-city-office-business-room-conference.jpg" alt="">
                </div>
                <div class="company_info">
                    <div class="name_c">AbuFarha Medical Supplies</div>
                    <div class="role_c">Trader Company</div>
                    <div class="location">Location : Jordan <img src="~@/assets/flag/flag13.png" alt=""></div>
                </div>
              </div>
              <div class="user-bad ratings">
                <span>Rating :</span>    
                <div class="rating_s">
                    <span>{{ rate_val }}/5</span>
                        <van-rate
                    v-model="rate_val"
                    :size="14"
                    readonly
                    color="#ffd21e"
                    void-icon="star"
                    void-color="#eee"
                    />
                </div>
                </div>
                <div class="sales_m">
                    <p class="roleid">Contact Name (Sales Manager)</p>
                    <p class="nameid">Mr. Yazan AbuFarha</p>
                </div>
                <div class="button_phones">
                    <vs-button color="#6495ed" animation-type="scale" @click="showMobile(1)">
                        <i class='bx bxs-phone-call bx-tada'></i>
                        <div class="hidden_phon">
                            <span>{{ phone1 }}</span>
                        </div>
                     </vs-button>
                     <vs-button color="#6495ed" animation-type="scale" @click="showMobile(2)">
                        <i class='bx bxs-phone-call bx-tada'></i>
                        <div class="hidden_phon">
                            <span>{{ phone2 }}</span>
                        </div>
                     </vs-button>
                     <vs-button color="#6495ed" animation-type="scale" @click="showMobile(3)">
                        <i class='bx bxs-phone-call bx-tada'></i>
                        <div class="hidden_phon">
                            <span>{{ phone3 }}</span>
                        </div>
                     </vs-button>
                     <vs-button color="#6495ed" animation-type="scale" >
                        <i class='bx bxs-send'></i>
                        <div class="hidden_phon">
                            <span>Contact Supplier</span>
                        </div>
                     </vs-button>
                     <vs-button color="#6495ed" animation-type="scale" >
                        <i class='bx bxs-conversation'></i>
                        <div class="hidden_phon">
                            <span>Chat Now</span>
                        </div>
                     </vs-button>
                </div>
            </div>
            <!--  -->
            <div class="company_card">
              <div class="title_cards">
                <span>Shop with confidence</span>
              </div>
                <div class="button_phones">
                    <vs-button color="#6495ed" animation-type="scale" @click="showMobile(1)">
                        <i class='bx bxs-store-alt'></i>
                        <div class="hidden_phon">
                            <span>WebStore</span>
                        </div>
                     </vs-button>
                     <vs-button color="#6495ed" animation-type="scale" @click="showMobile(2)">
                        <i class='bx bxs-store-alt'></i>
                        <div class="hidden_phon">
                            <span>Virtual Trade Show</span>
                        </div>
                     </vs-button>
                </div>
            </div>
            <!--  -->
            <div class="company_card">
              <div class="title_cards">
                <span>See Other Items on WebStore</span>
              </div>
                <div class="flex_category_store">
                    <div class="single_category">
                        <div class="image">
                            <img src="https://plus.unsplash.com/premium_photo-1676325101744-ce4a45a331c7?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2940&q=80" alt="">
                        </div>
                        <p>Category Name</p>
                    </div>
                    <div class="single_category">
                        <div class="image">
                            <img src="https://images.unsplash.com/photo-1582719471863-f4c7006280cd?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2893&q=80" alt="">
                        </div>
                        <p>Category Name</p>
                    </div>
                    <div class="single_category">
                        <div class="image">
                            <img src=" https://images.unsplash.com/photo-1576671081837-49000212a370?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2898&q=80" alt="">
                        </div>
                        <p>Category Name</p>
                    </div>
                    <div class="single_category">
                        <div class="image">
                            <img src="https://images.unsplash.com/photo-1532187863486-abf9dbad1b69?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2940&q=80" alt="">
                        </div>
                        <p>Category Name</p>
                    </div>
                    <div class="single_category">
                        <div class="image">
                            <img src="https://images.unsplash.com/photo-1576669802218-d535933f897c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2940&q=80" alt="">
                        </div>
                        <p>Category Name</p>
                    </div>
                    <div class="single_category">
                        <div class="image">
                            <img src="https://images.unsplash.com/photo-1532187643603-ba119ca4109e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2940&q=80" alt="">
                        </div>
                        <p>Category Name</p>
                    </div>
                    
                   
                </div>
            </div>
        </div>
       </div>
       <!--  -->
    </div>
   <van-popup class="modal_f1" v-model="modalf1" closeable>
   <div class="title_modal">
    <h2>Get Financing</h2>
   </div>
   <form class="form_f1" action="#">
    <div class="row">
        <div class="col-md-12 mrgb">
            <b-field label="First Name">
             <vs-input placeholder="Join ..." />
             </b-field>
            </div>
            <div class="col-md-12 mrgb">
            <b-field label="Last Name">
             <vs-input placeholder="Alex ..." />
             </b-field>
            </div>
            <div class="col-md-12 mrgb">
            <b-field label="Company Name">
             <vs-input placeholder="Example Company ..." />
             </b-field>
            </div>
            <div class="col-md-12 mrgb">
            <b-field label="Email">
             <vs-input placeholder="example@gmail.com" />
             </b-field>
            </div>
            <div class="col-md-12 mrgb">
            <b-field label="Phone">
             <vs-input placeholder="078-XXXXXXXX" />
             </b-field>
            </div>
            <div class="col-md-12 mrgb">
            <b-field label="Amount to Finance (Min 8,000 $)">
             <vs-input placeholder="Amount Finance." />
             </b-field>
            </div>
            <div class="col-md-12 mrgb">
            <b-field label="Time in Bussiness">
             <vs-input placeholder="Time in Bussiness" />
             </b-field>
            </div>
            <div class="col-md-12 mrgb">
            <b-field label="How Can We Help You ?">
             <textarea placeholder="Please Share a bit of your Financal Needs ." class="txt_area uk-input"></textarea>
             </b-field>
            </div>
            <div class="col-12">
                <vs-button
                    color="#394f86"
                    animation-type="scale">
                    Start Approval Process
                </vs-button>
            </div>

    </div>
   </form>
   </van-popup>
   <van-popup class="modal_f1" v-model="modalf2" closeable>
   <div class="title_modal">
    <h2>Forward Listing</h2>
   </div>
   <form class="form_f1" action="#">
    <div class="row">
            <div class="col-md-12 mrgb">
            <b-field label="Send To">
             <vs-input placeholder="example@gmail.com" />
             </b-field>
            </div>
            <div class="col-md-12 mrgb">
            <b-field label="Subject">
             <vs-input placeholder="example subjects ..." />
             </b-field>
            </div>
            <div class="col-md-12 mrgb">
            <b-field label="Body">
             <textarea placeholder="Your Message ..." class="txt_area uk-input"></textarea>
             </b-field>
            </div>
            <div class="col-12">
                <vs-button
                    color="#394f86"
                    animation-type="scale">
                    Start Approval Process
                </vs-button>
            </div>

    </div>
   </form>
   </van-popup>
       <!--  -->
    </div>
</template>
<script>
export default {
  name: "itemAdsArea",
  components: {},
  data(){
    return{
        images: [
        'https://placekitten.com/801/800',
      'https://placekitten.com/802/800',
      'https://placekitten.com/803/800',
      'https://placekitten.com/804/800',
      'https://placekitten.com/805/800',
    ],
    rate_val:4,
    phone1:'+962-7XXXXXXX',
    phone2:'+962-7XXXXXXX',
    phone3:'+962-7XXXXXXX',
    modalf1:false,
    modalf2:false
    }
  },
  methods:{
    showMobile(index){
        if(index ==1){
            this.phone1 = '+962-787525719';
              window.open("tel:+962-787525719","_self");
        }else if(index ==2){
            this.phone2 = '+962-787525718';
              window.open("tel:+962-787525718","_self");
        }else{
            this.phone3 = '+962-787525717';
              window.open("tel:+962-787525717","_self");
        }
    }
  }
};
</script>
<style lang="scss" scoped>
.itemAds_final_wraps{
min-height: 100vh;
padding-top: 200px;
.prod_info{
            //  border-right: 1px dashed gainsboro;
             .title{
                display: flex;
                align-items: center;
                justify-content: space-between;
                 margin-bottom: 12px;
                 h2{
                     font-size: 20px;
                     color: deepskyblue;
                     .status{
                        font-size: 15px;
                        border: 1px solid #666666;
                        color: #666666;
                        padding: 2px 18px;
                        border-radius: 3px;
                        margin-right: 10px;
                        display: inline-block;

                     }
                 }
                 .date_title{
                    font-size: 14px;
                    color: deepskyblue;
                    i{
                        padding-right: 4px;
                        vertical-align: middle;
                        margin-top: -4px;
                       
                    }
                 }
             }
             .fob_price{
                 display: flex;
                 font-size: 14px;
                 color: black;
                 .single_fob{
                    display: flex;
                    align-items: center;
                    &:last-child{
                        &::after{
                            display: none;
                        }
                    }
                    &::after{
                        content: '|';
                        padding: 0px 8px;
                        opacity: 0.5;
                    }
                 }
                 .key_fob{
                     margin-right: 10px;
                     opacity: 0.6;
                 }
                 .val_fob{
                     font-weight: 500;
                 }
             }
             .parg{
                p{
                    font-size: 14px;
                    opacity: 0.8;
                    margin-top: 4px;
                }
             }
             .properites{
                 display: flex;
                 align-items: center;
                 justify-content: space-between;
                 flex-wrap: wrap;
                 margin-top: 14px;
                 border-bottom: 1px dashed gainsboro;
                 padding-bottom: 5px;
                 .single_prop{
                     flex:0 0 100%;
                     display: flex;
                     align-items: center;
                     justify-content: space-between;
                     font-size: 13px;
                     margin-bottom: 8px;
                     padding-right: 15px;
                     border-bottom: 1px dashed gainsboro;
                     padding-bottom: 4px;
                     &:last-child{
                        border: none;
                        padding-bottom:0px;
                     }
                     .prob_key{
                         width: 45%;
                         opacity: 0.6;
                     }
                     .prob_val{
                         font-weight: 500;
                         .prob_flag{
                            img{
                                width: 24px;
                                height: 24px;
                                object-fit: contain;
                            }
                         }
                     }
                 }
             }
             
            //  
            
            .badage_users{
                .badge_up{
                padding-top: 16px;
                display: flex;
                align-items: center;
                }
                .badge_down{
                    padding: 14px 0px;
                    display: flex;
                    align-items: center;
                }
                .user-bad{
                    font-size: 14px;
                    font-weight: 600;
                    margin-right: 14px;
                    i{
                        vertical-align: middle;
                        margin-top: -5px;
                        margin-right: 4px;
                        font-size: 17px;
                    }
                    img{
                        vertical-align: middle;
                        object-fit: contain;
                        margin-left: 8px;
                        margin-top: -4px;
                    }
                }
                .ratings{
                    span{
                        padding-right: 5px;
                    }
                }
         }
         }

.title_desc{
padding: 20px 0px;
h2{
    font-size: 18px;
    font-weight: 600;
}
p{
    font-size: 14px;
    font-weight: 400;
}
    .properites.prop2{
    border-bottom: none;
    border: 1px solid gainsboro;
    padding: 14px;
    border-radius: 8px;
    .single_prop{
            flex:0 0 calc(100%/3.3);
            border: none;
    }
    }
    }
    .wrap_p{
    border: 1px solid gainsboro;
    padding: 14px;
    border-radius: 8px;
    margin-top: 10px;
    P{
        margin-bottom: 15px;
    }
    }
}
.uk-thumbnav{
    margin-top: -4px;
    display: flex;
    align-items: center;
    flex-direction: row;;
    flex-wrap: nowrap;
    // overflow-x: auto;
    justify-content: space-between;
    li{
        margin: 0px 4px;
    }
    // li{
    //     flex: 0 0 120px;
    // }
}
.row_prod{
  padding-top: 40px;
}
.box_products{
  border: 1px solid gainsboro;
  background-color: white;
  border-radius: 10px;
  position: relative;
  margin-bottom: 40px;
  transition: filter 300ms;
  &:hover{
    filter: drop-shadow(0 0 1em #00bfff);
    .imagebox{
    background-color: #00bfff;
    border-radius: 10px;
    img{
      transform: scale(1.1) translateY(-25px);
    }
  }
  }
  .imagebox{
    background-color: rgb(240, 240, 240);
    border-radius: 10px;
     transition: 0.3s linear;
    img{
      height: 120px;
      width: 100%;
      object-fit: contain;
      transition: 0.3s linear;
    }
  }
  .description{
    padding: 14px 8px;
    h3{
      font-size: 0.9rem;
      font-weight: 500;
    }
    p{
      font-size: 13px;
      opacity: 0.8;
    }
    p.shipping{
      opacity: 1;
      color: #000;
      img{
        height: 18px;
           vertical-align: top;
           padding-left: 10px;
      }
    }
  }
  .fav_box{
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 2;
    cursor: pointer;
  }
  .bxs-heart{
    color: red;
  }
  .btn_contact{
  padding: 10px 20px;
  }
}
.company_card{
    background-color: white;
    padding: 0px 14px 14px 14px;
    border: 1px solid gainsboro;
    border-radius: 5px;
    margin-bottom: 25px;
    .title_cards{
        background-color: #D9D9D9;
        border-radius: 0px 0px 5px 5px;
        text-align: center;
        width: 80%;
        margin: 0 auto 20px auto;
        font-size: 15px;
        font-weight: 600;
        padding: 4px 0px;
    }
    .wraps_cards{
        display: flex;
        align-items: flex-start;
        .company_logo{
            flex: 0 0 100px;
            img{
                width: 100px;
                height: 80px;
                object-fit: cover;
                border-radius: 5px;
            }

        }
        .company_info{
            font-size: 13px;
            font-weight: 600;
            padding-left: 10px;
            .name_c{
               margin-bottom: 2px;
            }
            .location{
                margin-top: 5px;
                img{
                    width: 20px;
                    height: 20px;
                    object-fit: contain;
                    vertical-align: middle;
                    margin-top: -3px;
                    margin-left: 4px;
                    
                }
            }
        }
    }
}
.ratings{
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding: 0px 2px;
    .rating_s{
        span{
            padding-right: 10px;
        }
    }
}
.sales_m{
    font-size: 14px;
    padding: 0px 2px;
    margin-top: 10px;
    .roleid{
        font-weight: 600;  
    }
    .nameid{
        font-size: 14px;
        font-weight: 400;
    }
}
.button_phones{
    margin-top: 15px;
    button{
        margin-bottom: 12px;
    }
    .vs-button__content{
        // display: flex;
        // justify-content: space-between;
        // align-items: center;
        font-size: 14px;
        padding-left: 10px;
        i{
            font-size: 20px;
            vertical-align: middle;
            margin-right: 10px;
        }
    }
}
.flex_category_store{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    .single_category{
        flex: 0 0 48%;
        margin-bottom: 12px;
        text-align: center;
        display: block;
        cursor: pointer;
        &:hover{
            img{
                border-radius: 5px;
                transform: scale(1.2);
            }
        }
        .image{
            overflow: hidden;
            img{
            width: 100%;
            height: 100px;
            border-radius: 5px;
            object-fit: cover;
            transition: 0.2s ease-in-out;
        }
        }
        p{
            font-size: 13px;
            font-weight: 600;
            padding-top: 3px;
        }
    }
}
.modal_f1{
    width: 400px;
    min-height: 400px;
    max-height: 96vh;
    overflow-y: auto;
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    .title_modal{
        h2{
            font-size: 18px;
            font-weight: 600;
        }
    }
    .form_f1{
        margin-top: 25px;
        label{
            font-size: 14px;
            opacity: 0.7;
        }
        .mrgb{
            margin-bottom: 15px;
        }
        .txt_area{
            width: 100%;
            height: 120px;
            font-size: 13px;
        }
    }
}
</style>