<template>
 <div class="plans_section">
    <div class="row">
      <div class="col-md-12 mb-5">
         <h2 class="main-head">Medical Plans</h2>
      </div>
      <!-- Purple Table -->
      <div class="col-xl-3">
         <div class="pricing-table purple">
            <!-- Table Head -->
            <div class="pricing-label">Fixed Price</div>
            <h2>Free Plans</h2>
            <h5>Made for starters</h5>
            <!-- Features -->
            <div class="pricing-features">
               <div class="feature">Title<span>value</span></div>
               <div class="feature">Title<span>value</span></div>
                <div class="feature">Title<span>value</span></div>
                 <div class="feature">Title<span>value</span></div>
                  <div class="feature">Title<span>value</span></div>
            </div>
            <!-- Price -->
            <div class="price-tag">
               <!-- <span class="symbol">$</span> -->
               <span class="amount">Free</span>
               <!-- <span class="after">/Year</span> -->
            </div>
            <!-- Button -->
            <a class="price-button" href="#">Get Started</a>
         </div>
      </div>
      <!-- Turquoise Table -->
      <div class="col-xl-3">
         <div class="pricing-table turquoise">
            <!-- Table Head -->
            <div class="pricing-label">Fixed Price</div>
            <h2>Gold Plan</h2>
            <h5>Made for experienced users</h5>
            <!-- Features -->
            <div class="pricing-features">
             <div class="feature">Title<span>value</span></div>
               <div class="feature">Title<span>value</span></div>
                <div class="feature">Title<span>value</span></div>
                 <div class="feature">Title<span>value</span></div>
                  <div class="feature">Title<span>value</span></div>
            </div>
            <!-- Price -->
            <div class="price-tag">
               <span class="symbol">$</span>
               <span class="amount">10.99</span>
               <span class="after">/month</span>
            </div>
            <!-- Button -->
            <a class="price-button" href="#">Get Started</a>
         </div>
      </div>
       <!-- Turquoise Table -->
      <div class="col-xl-3">
         <div class="pricing-table black">
            <!-- Table Head -->
            <div class="pricing-label">Fixed Price</div>
            <h2>Premuim Plans</h2>
            <h5>Made for experienced users</h5>
            <!-- Features -->
            <div class="pricing-features">
               <div class="feature">Title<span>value</span></div>
               <div class="feature">Title<span>value</span></div>
                <div class="feature">Title<span>value</span></div>
                 <div class="feature">Title<span>value</span></div>
                  <div class="feature">Title<span>value</span></div>
            </div>
            <!-- Price -->
            <div class="price-tag">
               <span class="symbol">$</span>
               <span class="amount">100.00</span>
               <span class="after">/year</span>
            </div>
            <!-- Button -->
            <a class="price-button" href="#">Get Started</a>
         </div>
      </div>
      <!-- Red Table -->
      <div class="col-md-3">
         <div class="pricing-table red">
            <!-- Table Head -->
            <div class="pricing-label">Recommanded</div>
            <h2>Unlimited Plans</h2>
            <h5>Made for professionals/agencies</h5>
            <!-- Features -->
            <div class="pricing-features">
                 <div class="feature">Title<span>value</span></div>
               <div class="feature">Title<span>value</span></div>
                <div class="feature">Title<span>value</span></div>
                 <div class="feature">Title<span>value</span></div>
                  <div class="feature">Title<span>value</span></div>
            </div>
            <!-- Price -->
            <div class="price-tag">
               <span class="symbol">$</span>
               <span class="amount">149.99</span>
               <span class="after">/year</span>
            </div>
            <!-- Button -->
            <a class="price-button" href="#">Get Started</a>
         </div>
      </div>
   </div>
   <!-- style 2 plans -->
   <div id="pricing-tables" class="">
      <div class="container-fluid">
         <div class="row">
       <div class="col-md-3 col-sm-6 col-xs-12 color-1">
                <div class="single-table text-center">
                    <div class="plan-header">
                        <h3>basic</h3>
                        <p>plan for basic user</p>
                        <h4 class="plan-price">$30<span>/mo</span></h4>
                    </div>


                    <ul class="text-center">
                        <li>10 Free PSD files</li>
                        <li>10 Free PSD files</li>
                        <li>10 Free PSD files</li>
                        <li>10 Free PSD files</li>
                        <li>10 Free PSD files</li>
                        <li>10 Free PSD files</li>
                    </ul>
                    <a href="#" class="plan-submit hvr-bubble-float-right">buy now</a>
                </div>
            </div>
            <div class="col-md-3 col-sm-6 col-xs-12 color-2">
                <div class="single-table text-center">
                    <div class="plan-header">
                        <h3>basic</h3>
                        <p>plan for basic user</p>
                        <h4 class="plan-price">$30<span>/mo</span></h4>
                    </div>


                    <ul class="text-center">
                        <li>10 Free PSD files</li>
                        <li>10 Free PSD files</li>
                        <li>10 Free PSD files</li>
                        <li>10 Free PSD files</li>
                        <li>10 Free PSD files</li>
                        <li>10 Free PSD files</li>
                    </ul>
                    <a href="#" class="plan-submit hvr-bubble-float-right">buy now</a>
                </div>
            </div>
            <div class="col-md-3 col-sm-6 col-xs-12 color-3">
                <div class="single-table text-center">
                    <div class="plan-header">
                        <h3>basic</h3>
                        <p>plan for basic user</p>
                        <h4 class="plan-price">$30<span>/mo</span></h4>
                    </div>


                    <ul class="text-center">
                        <li>10 Free PSD files</li>
                        <li>10 Free PSD files</li>
                        <li>10 Free PSD files</li>
                        <li>10 Free PSD files</li>
                        <li>10 Free PSD files</li>
                        <li>10 Free PSD files</li>
                    </ul>
                    <a href="#" class="plan-submit hvr-bubble-float-right">buy now</a>
                </div>
            </div>
            <div class="col-md-3 col-sm-6 col-xs-12 color-4">
                <div class="single-table text-center">
                    <div class="plan-header">
                        <h3>basic</h3>
                        <p>plan for basic user</p>
                        <h4 class="plan-price">$30<span>/mo</span></h4>
                    </div>


                    <ul class="text-center">
                        <li>10 Free PSD files</li>
                        <li>10 Free PSD files</li>
                        <li>10 Free PSD files</li>
                        <li>10 Free PSD files</li>
                        <li>10 Free PSD files</li>
                        <li>10 Free PSD files</li>
                    </ul>
                    <a href="#" class="plan-submit hvr-bubble-float-right">buy now</a>
                </div>
            </div>
   </div>
      </div>
   </div>

    </div>
</template>
<script>
export default {
  name: "PlansArea",
}
</script>
<style lang="scss" scoped>

.main-head {
	background: #0D1440;
	box-shadow: 0px 1px 10px -6px rgba(0, 0, 0, .15);
	padding: 1rem;
	margin-bottom: 0;
	margin-top: 1rem;
	color: #fff;
	font-weight: 500;
	text-transform: uppercase;
	border-radius: 4px;
	font-size: 16px;
    text-align: center;
}

.pricing-table {
	background: #fff;
	box-shadow: 0px 1px 10px -6px rgba(0, 0, 0, .15);
	padding: 2rem;
	border-radius: 4px;
	transition: .3s;
}

.pricing-table:hover {
	box-shadow: 0px 1px 10px -4px rgba(0, 0, 0, .15);
}

.pricing-table .pricing-label {
	border-radius: 2px;
	padding: .25rem .5rem;
	margin-bottom: 1rem;
	display: inline-block;
	font-size: 12px;
	font-weight: 500;
}

.pricing-table h2 {
	color: #3b3b3b;
	font-size: 24px;
	font-weight: 500;
}

.pricing-table h5 {
	color: #B3B3B3;
	font-size: 14px;
	font-weight: 400;
}

.pricing-table .pricing-features {
	margin-top: 2rem;
}

.pricing-table .pricing-features .feature {
	font-size: 14px;
	margin: .5rem 0;
	color: #B3B3B3;
}

.pricing-table .pricing-features .feature span {
	display: inline-block;
	float: right;
	color: #3b3b3b;
	font-weight: 500;
}

.pricing-table 	.price-tag {
	margin-top: 2rem;
	text-align: center;
	font-weight: 500;
}

.pricing-table .price-tag .symbol {
	font-size: 24px;
}

.pricing-table .price-tag .amount {
	letter-spacing: -2px;
	font-size: 64px;
}

.pricing-table .price-tag .after {
	color: #3b3b3b;
	font-weight: 500;
}

.pricing-table .price-button {
	display: block;
	color: #fff;
	margin-top: 2rem;
	padding: .75rem;
	border-radius: 2px;
	text-align: center;
	font-weight: 500;
	transition: .3s;
}

.pricing-table .price-button:hover {
	text-decoration: none;
}

.purple .pricing-label {
	background: #cad2ff;
	color: #627afe;
}

.purple .price-tag {
	color: #627afe;
}

.purple .price-button {
	background: #627afe;
}

.purple .price-button:hover {
	background: #546dfe;
}

.turquoise .pricing-label {
	background: #b9edee;
	color: #44cdd2;
}

.turquoise .price-tag {
	color: #44cdd2;
}

.turquoise .price-button {
	background: #44cdd2;
}

.turquoise .price-button:hover {
	background: #2dbcc4;
}

.red .pricing-label {
	background: #ffc4c4;
	color: #ff5e5e;
}

.red .price-tag {
	color: #ff5e5e;
}

.red .price-button {
	background: #ff5e5e;
}

.red .price-button:hover {
	background: #f23c3c;
}
// 
.black .pricing-label {
	background: #464646;
	color: #fff;
}

.black .price-tag {
	color: #000;
}

.black .price-button {
	background: #464646;
}

.black .price-button:hover {
	background: #000;
}

// style 2 scss =====================
// Table colors
.text-center{
   text-align: center;
}
$table-bg:#eeeeee;
$plan-header-bg:#e67e22; //change
$plan-header-bg-2:#3498db; //change
$plan-header-bg-3:#2ecc71; //change
$plan-header-bg-4:#9b59b6; //change

$single-table-bg:#fff;
$table-header:#fff;
$table-border-color:lighten($plan-header-bg, 60%);
$plan-price-color: #fff;
$plan-price-month-color: #8394AE;
$plan-price-border: lighten($plan-header-bg, 20%);
@import url('https://fonts.googleapis.com/css?family=Lato');
body {
    padding-top: 70px;
}

#pricing-tables {
    background-color: $table-bg;
    padding: 30px 0;
    position: relative;
    font-family: 'Lato', sans-serif;
    & .col-md-3,.col-sm-6,.col-xs-12 {
        padding-right: 0;
        padding-left: 0;
        transition: 0.5s linear;
        &:hover {
            box-shadow: 0px 11px 30px 0px rgba(0, 0, 0, 0.75);
            z-index: 2;
            -webkit-transform: scale(1.06);
            -ms-transform: scale(1.06);
            -moz-transform: scale(1.06);
            transform: scale(1.06);
            border: 0;
            -webkit-transition: 0.5s all;
            transition: 0.5s all;
            border: none;
        }
    }
}

.single-table {
    background: $single-table-bg;
    -webkit-transition: all 0.20s linear;
    transition: all 0.20s linear;
    z-index: 1;
    & .plan-header {
        background: $plan-header-bg;
        color: $table-header;
        text-transform: capitalize;
        padding: 2px 0;
        & h3 {
            margin: 0;
            padding: 20px 0 5px 0;
            text-transform: uppercase;
            color: white;
        }
        p{
         padding-bottom: 10px;
        }
    }

    & .plan-price {
        display: inline-block;
        color: $plan-header-bg;
        margin: 0 0 10px 0;
        font-size: 25px;
        font-weight: bold;
        background: #fff;
        border-radius: 50%;
        color: $plan-header-bg;
        padding: 33px 15px;
        & span {
            font-size: 14px;
            font-weight: normal;
        }
    }
    & ul {
        margin: 0;
        padding: 20px 0;
        list-style: none;
        & li {
            padding: 8px 0;
            margin: 0 20px;
            border-bottom: 1px solid $table-border-color;
            font-size: 15px;
        }
    }
    & .plan-submit {
        display: inline-block;
        text-decoration: none;
        margin: 20px 0 30px 0;
        padding: 10px 40px;
        border: 1px solid $plan-header-bg;
        color: $plan-header-bg;
        font-size: 15px;
        text-transform: uppercase;
        border-radius: 3px;
        -webkit-transition: all .25s linear;
        -moz-transition: all .25s linear;
        -ms-transition: all .25s linear;
        -o-transition: all .25s linear;
        transition: all .25s linear;
        &:hover {
            background: $plan-header-bg;
            color: #FFF;
            -webkit-transition: all .25s linear;
            -moz-transition: all .25s linear;
            -ms-transition: all .25s linear;
            -o-transition: all .25s linear;
            transition: all .25s linear;
        }
    }

    /* Bubble Float Right */
    .hvr-bubble-float-right {
        display: inline-block;
        vertical-align: middle;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        box-shadow: 0 0 1px rgba(0, 0, 0, 0);
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -moz-osx-font-smoothing: grayscale;
        position: relative;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        -webkit-transition-property: transform;
        transition-property: transform;
    }
    .hvr-bubble-float-right:before {
        position: absolute;
        z-index: -1;
        top: calc(50% - 10px);
        right: 0;
        content: '';
        border-style: solid;
        border-width: 10px 0 10px 10px;
        border-color: transparent transparent transparent transparent;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        -webkit-transition-property: transform;
        transition-property: transform;
    }
    .hvr-bubble-float-right:hover,
    .hvr-bubble-float-right:focus,
    .hvr-bubble-float-right:active {
        -webkit-transform: translateX(-10px);
        transform: translateX(-10px);
    }
    .hvr-bubble-float-right:hover:before,
    .hvr-bubble-float-right:focus:before,
    .hvr-bubble-float-right:active:before {
        -webkit-transform: translateX(10px);
        transform: translateX(10px);
        border-color: transparent transparent transparent $plan-header-bg;
    }
    
}

.color-2 {
    & .single-table .plan-header{
        background: $plan-header-bg-2;
        color: $table-header;
        & .plan-price{
            color:$plan-header-bg-2;
            background: #fff;
        }

        
        }
    & .single-table .plan-submit{
        border: 1px solid $plan-header-bg-2;
        color: $plan-header-bg-2; 
        &:hover{
            background: $plan-header-bg-2;
            color: #FFF;
        }       
    }


    .hvr-bubble-float-right:hover:before,
    .hvr-bubble-float-right:focus:before,
    .hvr-bubble-float-right:active:before {
        -webkit-transform: translateX(10px);
        transform: translateX(10px);
        border-color: transparent transparent transparent $plan-header-bg-2;
    }

}


.color-3 {
    & .single-table .plan-header{
        background: $plan-header-bg-3;
        color: $table-header;
        & .plan-price{
            color:$plan-header-bg-3;
            background: #fff;
        }

        
        }
    & .single-table .plan-submit{
        border: 1px solid $plan-header-bg-3;
        color: $plan-header-bg-3; 
        &:hover{
            background: $plan-header-bg-3;
            color: #FFF;
        }       
    }

  
    .hvr-bubble-float-right:hover:before,
    .hvr-bubble-float-right:focus:before,
    .hvr-bubble-float-right:active:before {
        -webkit-transform: translateX(10px);
        transform: translateX(10px);
        border-color: transparent transparent transparent $plan-header-bg-3;
    }

}



.color-4 {
    & .single-table .plan-header{
        background: $plan-header-bg-4;
        color: $table-header;

        & .plan-price{
            color:$plan-header-bg-4;
            background: #fff;
        }

        
        }
    & .single-table .plan-submit{
        border: 1px solid $plan-header-bg-4;
        color: $plan-header-bg-4; 
        &:hover{
            background: $plan-header-bg-4;
            color: #FFF;
        }       
    }

 
    .hvr-bubble-float-right:hover:before,
    .hvr-bubble-float-right:focus:before,
    .hvr-bubble-float-right:active:before {
        -webkit-transform: translateX(10px);
        transform: translateX(10px);
        border-color: transparent transparent transparent $plan-header-bg-4;
    }

}



</style>