<template>
    <div class="pd_fix" style="min-height: 100vh;">
        <div class="container">
            <div class="row">
            <div class="col-xl-3">
              <filterSideArea></filterSideArea>
            </div>
            <div class="col-xl-9">
                <div class="filter_up_products">
                    <!-- <van-tabs type="card">
                    <van-tab to="/categories/product" title="Product"></van-tab>
                    <van-tab to="/categories/supplier" title="Supplier"></van-tab>
                    <van-tab to="/categories/agency" title="Agency Service"></van-tab>
                    <van-tab title="Sourcing Request"></van-tab>
                    <van-tab title="Service Provider"></van-tab>
                    </van-tabs> -->
                    <!--  -->
                    <!-- tags -->
                    <!-- @click="toggleActive(0,index)"  :id="`tags_up_${0}${index}`"-->
                    <div class="flex_filter_tags">
                                <router-link :to="tag.to" :class="['single_tags']"  v-for="tag,index in tags_list" :key="index">
                                <span class="icons">
                                    <i :class='tag.icons'></i>
                                </span>
                                <span>{{ tag.title }}</span>
                            </router-link>
                        </div>
                    <!-- regions  -->
                    <div class="regions_wraps">
                        <div class="title_reg">
                            Country/Region :
                        </div>
                        <div class="check_regions">
                            <van-checkbox-group v-model="regions" checked-color="#00bfff" icon-size="16px">
                                <van-checkbox name="all">
                                <span class="img_reg"><img src="~@/assets/flag/gl.png" alt=""></span>
                                <span class="name_reg">All</span>
                            </van-checkbox>
                            <van-checkbox name="ch">
                                <span class="img_reg"><img src="~@/assets/flag/flag4.png" alt=""></span>
                                <span class="name_reg">China</span>
                            </van-checkbox>
                            <van-checkbox name="aus">
                                <span class="img_reg"><img src="~@/assets/flag/flag1.png" alt=""></span>
                                <span class="name_reg">Austiralia</span>
                            </van-checkbox>
                            <van-checkbox name="br">
                                <span class="img_reg"><img src="~@/assets/flag/flag2.png" alt=""></span>
                                <span class="name_reg">Brazil</span>
                            </van-checkbox>
                            <van-checkbox name="can">
                                <span class="img_reg"><img src="~@/assets/flag/flag3.png" alt=""></span>
                                <span class="name_reg">Canada</span>
                            </van-checkbox>
                            <van-checkbox name="fr">
                                <span class="img_reg"><img src="~@/assets/flag/flag6.png" alt=""></span>
                                <span class="name_reg">France</span>
                            </van-checkbox>
                            <span class="more_reg" @click="show = true">More <i class='bx bx-chevron-down bx-tada' ></i></span>
                            
                            </van-checkbox-group>
                            <!-- pop up -->
                            <van-popup v-model="show" round position="bottom" style="min-height: 400px;padding: 20px 10px;" closeable>
                                <h1>More Country/Region :</h1>
                                <van-checkbox-group v-model="regions" checked-color="#00bfff" icon-size="16px">
                            <van-checkbox name="gr">
                                <span class="img_reg"><img src="~@/assets/flag/flag7.png" alt=""></span>
                                <span class="name_reg">Germany</span>
                            </van-checkbox>
                            <van-checkbox name="in">
                                <span class="img_reg"><img src="~@/assets/flag/flag5.png" alt=""></span>
                                <span class="name_reg">India</span>
                            </van-checkbox>
                            <van-checkbox name="it">
                                <span class="img_reg"><img src="~@/assets/flag/flag9.png" alt=""></span>
                                <span class="name_reg">Italy</span>
                            </van-checkbox>
                            <van-checkbox name="kor">
                                <span class="img_reg"><img src="~@/assets/flag/flag10.png" alt=""></span>
                                <span class="name_reg">Koria</span>
                            </van-checkbox>
                            <van-checkbox name="jo">
                                <span class="img_reg"><img src="~@/assets/flag/flag13.png" alt=""></span>
                                <span class="name_reg">Jordan</span>
                            </van-checkbox>
                            <van-checkbox name="jp">
                                <span class="img_reg"><img src="~@/assets/flag/flag12.png" alt=""></span>
                                <span class="name_reg">Japan</span>
                            </van-checkbox>
                            <van-checkbox name="eg">
                                <span class="img_reg"><img src="~@/assets/flag/flag11.png" alt=""></span>
                                <span class="name_reg">Egypt</span>
                            </van-checkbox>
                            <van-checkbox name="ru">
                                <span class="img_reg"><img src="~@/assets/flag/flag14.png" alt=""></span>
                                <span class="name_reg">Russia</span>
                            </van-checkbox>
                            </van-checkbox-group>
                            
                            </van-popup>
                        </div>
                    </div>
                    <!-- user -->
                    <div class="member_wraps">
                        <div class="title_reg">
                            Supplier Types: :
                        </div>
                        <div class="check_member">
                            <van-checkbox-group v-model="user_type" checked-color="#00bfff" icon-size="16px">
                                <van-checkbox name="all">
                                <span class="img_reg"><img src="~@/assets/all.png" alt=""></span>
                                <span class="name_reg">All</span>
                            </van-checkbox>
                            <van-checkbox name="t1">
                                <span class="img_reg"><img src="~@/assets/type1.png" alt=""></span>
                                <span class="name_reg">Gold Member</span>
                            </van-checkbox>
                            <van-checkbox name="t2">
                                <span class="img_reg"><img src="~@/assets/type2.png" alt=""></span>
                                <span class="name_reg">Verified Member</span>
                            </van-checkbox>
                            </van-checkbox-group>
                        </div>
                    </div>
                </div>
                <!-- inner view router -->
                <div class="inner_view">
                    <router-view></router-view>
                </div>
            </div>
            <div class="col-xl-3 d-none">
                ads
            </div>
        </div>
        </div>
    </div>
   </template>
   
   <script>
   import filterSideArea from '@/components/categories/filterSideArea.vue';
   export default {
      name:'category',
      data(){
       return{
        tags_list:[
            {
                icons:'bx bx-cube-alt ',
                title:'Products / Equipment',
                to:'/categories/product'
            },
            {
                icons:'bx bxs-color ',
                title:'Parts',
                to:'/categories/parts'
            },
            {
                icons:'bx bx-money-withdraw ',
                title:'Service Provider',
                to:'/categories/service'
            },
            {
                icons:'bx bx-user-voice ',
                title:'Supplier / Manufacturer',
                to:'/categories/supplier'
            },
            {
                icons:'bx bxs-castle ',
                title:'Agencies',
                to:'/categories/agency'
            },
            {
                icons:'bx bxs-brightness ',
                title:'Forums',
                to:'/categories/forums'
            },
            {
                icons:'bx bx-briefcase-alt-2 ',
                title:'RFQ / RFS Request',
                to:'/categories/rfq'
            },
            {
                icons:'bx bx-wallet-alt ',
                title:'Tenders',
                to:'/categories/tenders'
            },
            {
                icons:'bx bx-calculator ',
                title:'Auctions',
                to:'/categories/auctions'
            },
            {
                icons:'bx bx-id-card ',
                title:'Careers',
                to:'/categories/careers'
            },
        ],
        regions:[],
        user_type:[],
        show: false,
       }
      },
      components:{
        filterSideArea
      },
      methods:{
        toggleActive(parentIndex,index){
        document.getElementById(`tags_up_${parentIndex}${index}`).classList.toggle('active');
        },
      },
       mounted(){
            document.body.classList.add('categoryPage');
            let myRoute = this.$route.path.split('/categories');
            if(myRoute[1]==''){
                this.$router.push('/categories/product');
            }
       },
       destroyed(){
         document.body.classList.remove('categoryPage');
       }
   }
   </script>
   <style lang="scss">
.van-tab__pane, .van-tab__pane-wrapper {
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    width: 100%;
    background-color: white;
    padding: 18px 20px;
}
.van-tabs--card>.van-tabs__wrap {
    height: 44px;
}
.van-tabs__nav--card {
    box-sizing: border-box;
    height: 44px;
    margin: 0 0px;
    border: 1px solid #ececec;
    border-radius: 2px;
}
.van-tabs__nav--card .van-tab.van-tab--active {
    color: deepskyblue;
    background-color: #fff;
    &::before{
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 1px;
        background-color: deepskyblue;
    }
}
.van-tabs__nav--card .van-tab {
    color: deepskyblue;
    border-right: 1px solid #ececec9e;
    background-color: #f4f4f4;
}
// 
.flex_filter_tags{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 8px;
    .single_tags{
        flex: 0 0 calc(100% / 5.2);
        padding: 12px 8px;
        background-color: #f4f4f4;
        color: black;
        font-size: 14px;
        font-weight: 500;
        min-height: 40px;
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        border-radius: 4px;
        cursor: pointer;
        i{
            vertical-align: middle;
            margin-top: -3px;
            margin-right: 6px;
            font-size: 20px;
        }
    }
    .single_tags.router-link-exact-active{
        background-color: deepskyblue;
        color: white;
        i{
            animation: flashing 1.5s infinite linear;
        }
    }
}
.regions_wraps{
    padding: 14px;
    background-color: white;
    margin: 14px 0px;
    border-radius: 8px;
    // .title_reg{}
    .check_regions{
        .van-checkbox-group{
            padding-top: 15px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
        }
        .img_reg{
           img{
            width: 24px;
            height: 24px;
            object-fit: contain;
            margin-right: 6px;
           }
        }
        .name_reg{
            font-size: 14px;
        }
    }
    .more_reg{
        font-size: 14px;
        cursor: pointer;
        i{
            vertical-align: middle;
        }
    }
    .van-tab__pane, .van-tab__pane-wrapper{
        padding: 18px 0px;
        cursor: pointer;
    }
}
// 
.member_wraps{
    padding: 14px;
    background-color: white;
    margin: 14px 0px;
    border-radius: 8px;
    .check_member{
    .van-checkbox{
        margin-right: 50px;
    }
    .van-checkbox-group{
            padding-top: 15px;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
        }
        .img_reg{
           img{
            width: 24px;
            height: 24px;
            object-fit: contain;
            margin-right: 6px;
           }
        }
        .name_reg{
            font-size: 14px;
        }
}
}

</style>