<template>
  <!-- products sidebar -->
  <div class="row mt-20">
      <div class="col-xl-3">
        <div class="hidden">
      <vs-sidebar
        v-model="active" open >
        <template #logo>
          <!-- ...img logo -->
        </template>
        <vs-sidebar-item id="home">
          <template #icon>
            <i class='bx bx-home'></i>
          </template>
         Ultrasound Machine
        </vs-sidebar-item>
       
        <vs-sidebar-item id="Music">
          <template #icon>
            <i class='bx bxs-music'></i>
          </template>
          Ultrasound Machine
        </vs-sidebar-item>
        <vs-sidebar-group>
          <template #header>
            <vs-sidebar-item arrow>
              <template #icon>
                <i class='bx bx-group'></i>
              </template>
              Ultrasound Machine
            </vs-sidebar-item>
          </template>

          <vs-sidebar-item id="Instagram">
            <template #icon>
              <i class='bx bxl-instagram'></i>
            </template>
            Ultrasound Machine
          </vs-sidebar-item>
          <vs-sidebar-item id="twitter">
            <template #icon>
              <i class='bx bxl-twitter' ></i>
            </template>
            Ultrasound Machine
          </vs-sidebar-item>
          <vs-sidebar-item id="Facebook">
            <template #icon>
              <i class='bx bxl-facebook' ></i>
            </template>
            Ultrasound Machine
          </vs-sidebar-item>
        </vs-sidebar-group>
        <vs-sidebar-group>
          <template #header>
            <vs-sidebar-item arrow>
              <template #icon>
                <i class='bx bx-code-alt' ></i>
              </template>
              Ultrasound Machine
            </vs-sidebar-item>
          </template>

          <!-- start -->
              <vs-sidebar-group>
          <template #header>
            <vs-sidebar-item arrow>
              <template #icon>
                <i class='bx bx-group'></i>
              </template>
              Ultrasound Machine
            </vs-sidebar-item>
          </template>

          <vs-sidebar-item id="Instagram">
            <template #icon>
              <i class='bx bxl-instagram'></i>
            </template>
            Ultrasound Machine
          </vs-sidebar-item>
          <vs-sidebar-item id="twitter">
            <template #icon>
              <i class='bx bxl-twitter' ></i>
            </template>
            Ultrasound Machine
          </vs-sidebar-item>
          <vs-sidebar-item id="Facebook">
            <template #icon>
              <i class='bx bxl-facebook' ></i>
            </template>
            Ultrasound Machine
          </vs-sidebar-item>
        </vs-sidebar-group>
          <!-- end -->
          <vs-sidebar-item id="codepen">
            <template #icon>
              <i class='bx bxl-codepen'></i>
            </template>
            Ultrasound Machine
          </vs-sidebar-item>
          <vs-sidebar-item id="discord">
            <template #icon>
              <i class='bx bxl-discord'></i>
            </template>
            Ultrasound Machine
          </vs-sidebar-item>
          <vs-sidebar-item id="Javascript">
            <template #icon>
              <i class='bx bxl-javascript' ></i>
            </template>
            Ultrasound Machine
          </vs-sidebar-item>
          <vs-sidebar-item id="git">
            <template #icon>
              <i class='bx bxl-git' ></i>
            </template>
            Ultrasound Machine
          </vs-sidebar-item>
        </vs-sidebar-group>
        <vs-sidebar-item id="donate">
          <template #icon>
            <i class='bx bxs-donate-heart' ></i>
          </template>
          Ultrasound Machine
        </vs-sidebar-item>
        <vs-sidebar-item id="drink">
          <template #icon>
            <i class='bx bx-drink'></i>
          </template>
          Ultrasound Machine
        </vs-sidebar-item>
        <vs-sidebar-item id="shopping">
          <template #icon>
            <i class='bx bxs-shopping-bags'></i>
          </template>
          Ultrasound Machine
        </vs-sidebar-item>
        <vs-sidebar-item id="chat">
          <template #icon>
            <i class='bx bx-chat' ></i>
          </template>
          Ultrasound Machine
        </vs-sidebar-item>
      </vs-sidebar>
    </div>
      </div>
      <div class="col-xl-9">
        <div class="row">
          <div class="col-xl-3"  v-for="prod in 10" :key="prod">
            <div class="box_products">
           <div class="fav_box" :id="`fav${index+8}`" @click="add_fav(index+8)">
            <vs-avatar  badge-color="danger" badge-position="top-right">
              <i class='bx bx-heart' ></i>
            </vs-avatar>
           </div>
          <div class="imagebox">
            <img src="https://www.3erp.com/wp-content/uploads/2016/12/medical.png" alt="">
          </div>
          <div class="description">
            <h3>Product Name device</h3>
             <p>Lorem Ipsum is simply dummy  </p>
            <p class="shipping">Shipping from: USA <img src="~@/assets/flag1.png" alt=""> </p>
          </div>
         <div class="btn_contact">
            <vs-button gradient> Contact supplier</vs-button>
         </div>
             </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="text-center">
       <vs-pagination style="z-index: 0;" v-model="page" :length="5" color="#00bfff" />
     </div>
      </div>
    </div>
    <!-- end -->
</template>
<script>
export default {
  name: "storeCategories",
  data() {
    return {
    page:1
  };
  },
  components: {},
};
</script>
<style lang="scss" scoped>
.mt-20{
  margin-top: 50px;
}
.box_products{
  border: 1px solid gainsboro;
  background-color: white;
  border-radius: 10px;
  position: relative;
  margin-bottom: 40px;
  transition: filter 300ms;
  color: #000;
  &:hover{
    filter: drop-shadow(0 0 1em #00bfff);
    .imagebox{
    background-color: #00bfff;
    border-radius: 10px;
    img{
      transform: scale(1.1) translateY(-25px);
    }
  }
  }
  .imagebox{
    background-color: rgb(240, 240, 240);
    border-radius: 10px;
     transition: 0.3s linear;
    img{
      height: 120px;
      width: 100%;
      object-fit: contain;
      transition: 0.3s linear;
    }
  }
  .description{
    padding: 14px 8px;
    h3{
      font-size: 0.9rem;
      font-weight: 500;
      color: #000;
    }
    p{
      font-size: 13px;
      opacity: 0.8;
    }
    p.shipping{
      opacity: 1;
      color: #000;
      img{
        height: 18px;
           vertical-align: top;
           padding-left: 10px;
      }
    }
  }
  .fav_box{
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 2;
    cursor: pointer;
  }
  .bxs-heart{
    color: red;
  }
  .btn_contact{
  padding: 10px 20px;
  }
}
</style>
