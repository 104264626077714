<template>
  <header class="header_main">
     <div class="small_nav">
      <div class="parent_sm_nav">
        <div class="info_1">
          <a href="#"><i class='bx bx-phone'></i>+962799909090</a>
          <a href="#"><i class='bx bx-mail-send' ></i>companyname@mail.com</a>
        </div>
        <div class="info_2">
          <div class="ddl_up">
         <b-dropdown :triggers="['hover']" aria-role="list">
            <template #trigger>
                <b-button
                    label="Help & Community"
                    type="is-light"
                    icon-right="chevron-down" />
            </template>
            <b-dropdown-item aria-role="listitem">Link1 </b-dropdown-item>
            <b-dropdown-item aria-role="listitem">Link2</b-dropdown-item>
        </b-dropdown>
        <b-dropdown :triggers="['hover']" aria-role="list">
            <template #trigger>
                <b-button
                    label="currency"
                    type="is-light"
                    icon-right="chevron-down" />
            </template>
            <b-dropdown-item aria-role="listitem">Usd</b-dropdown-item>
            <b-dropdown-item aria-role="listitem">JD</b-dropdown-item>
        </b-dropdown>
        <b-dropdown :triggers="['hover']" aria-role="list">
            <template #trigger>
                <b-button
                    label="Language"
                    type="is-light"
                    icon-right="chevron-down" />
            </template>
            <b-dropdown-item aria-role="listitem">English</b-dropdown-item>
            <b-dropdown-item aria-role="listitem">Arabic</b-dropdown-item>
        </b-dropdown>
        </div>
        </div>
      </div>
     </div>
      <div class="navbar-main">
       <div class="logo">
        <router-link to="/"> <img src="https://upload.wikimedia.org/wikipedia/commons/8/85/Logo-Test.png" alt=""></router-link>
       </div>
       <div class="search_nav">
         <vs-input v-model="value" placeholder="What Are You Looking For ..." />
       </div>
       <div class="icons_nav_main">
        <router-link to="/login">
           <vs-avatar badge-color="info" badge-position="top-right">
              <i class='bx bxs-user-circle' ></i>
            </vs-avatar>
        </router-link>
        <router-link to="/profile/dashboard">
           <vs-avatar badge-color="success" badge-position="top-right">
              <i class='bx bxs-message-dots' ></i>

              <template #badge>
                4
              </template>
            </vs-avatar>
          </router-link>
        <a href="#">
           <vs-avatar badge-color="danger" badge-position="top-right">
              <i class='bx bx-heart' ></i>

              <template #badge>
                9
              </template>
            </vs-avatar>
        </a>
         <a href="#">
           <vs-avatar badge-color="danger" badge-position="top-right">
              <i class='bx bx-bell' ></i>

              <template #badge>
                12
              </template>
            </vs-avatar>
        </a>
        
       </div>
      </div>
      <div class="navbar_down">
        <div class="flex_nav_down">
           <!-- <button type="button" class="side-menu-btn offcanvas-toggle-btn"><i class="fas fa-bars"></i></button> -->
          <a href="#">EQUIPMENT</a>
           <a href="#">PARTS</a>
            <a href="#">PARTS</a>
            <a href="#">SERVICES</a>
              <a href="#">SERVICES</a>
              <a href="#">Agencies</a>
               <a href="#">PRICING</a>
                <a href="#">FORUMS</a>
                 <a href="#">AUCTION</a>
                 <a href="#">Blog</a>
                  <a href="#">Blog</a>
                   <a href="#">Blog</a>
                    <a href="#">Blog</a>
        </div>
      </div>
     </header>
</template>
<script>


export default {
  name: "headerApp",
  components: {},
   data(){
    return{
       value:'',
    }
  },
  methods:{}
};
</script>

<style  lang="scss">
.header_main{
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  .small_nav{
  background-color: whitesmoke;
  // border-bottom: 1px solid #d9d6d6;
  padding: 4px 10px;
}
.parent_sm_nav{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.info_1{
  a{
    color: black;
    font-size: 14px;
    font-weight: 400;
    margin-right: 20px;
    i{
        font-size: 24px;
    vertical-align: middle;
    padding-right: 8px;
    }
  }
}
.info_2{
  display: flex;
}
.dropdown-trigger{
span{
  font-size: 13px;
}
}
:focus-visible {
    outline: none !important;
}
// navbar
.navbar-main{
  // background-color: #20c997;
  // box-shadow: 0px 2px 5px gainsboro;
  background-color: white;
 display: flex !important;
    align-items: center !important;
    justify-content: space-between;
  .logo{
  img{
    height: 80px;
    object-fit: contain;
    // filter: invert(1);
  }
  }
  .search_nav{
    display: flex;
        flex: 0 0 calc(100% - 450px);
    .vs-input-parent , input{
      width: 100%;
    }
  }
  .icons_nav_main{
    display: flex;
    align-items: center;
    a{
      margin-right: 10px;
    }
  }
}
.flex_nav_down{
      display: flex;
    justify-content: center;
    padding: 10px 14px;
    background-color: whitesmoke;
        box-shadow: 0px 2px 5px gainsboro;
  a{
    font-size: 14px;
    font-weight: 400;
    color: #858585;
    margin: 0px 10px;
    text-transform: uppercase;
    transition: 0.3s ease-in-out;
    &:hover{
      color: deepskyblue;
    }
  }
}
}

</style>