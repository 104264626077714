<template>
  <div class="map_company_wraps">
    <h3 class="tabname">Company Map</h3>
    <div class="maps_wrap">
      <div class="google_map_wrap">
        <b-message type="is-warning" v-model="isActiveAlert" has-icon>
          {{ error }}
          <br />
          please allow permission to get your Address
        </b-message>
        <div class="search_googlemap">
          <i class="fas fa-map-marker-alt leftIcons"></i>
          <GmapAutocomplete
            class="uk-input"
            ref="autocomplete"
            @place_changed="setPlace"
            :options="{
              fields: ['geometry', 'formatted_address', 'address_components'],
            }"
          >
          </GmapAutocomplete>
          <b-button
            class="getMylocation"
            @click="getMyLocation"
            :loading="loadbtn"
            type="is-danger"
            icon-right="crosshairs-gps"
          ></b-button>
        </div>
        <br />
        <div class="row">
          <div class="col-md-12">
            <GmapMap
              ref="mapss"
              :center="center"
              :zoom="17"
              style="width: 100%; height: 400px"
            >
              <GmapMarker
                :position="posmarker"
                :draggable="true"
                @dragend="updateCoordinates"
              />
              <!-- icon= "https://24order.no/storage/app/uploads/public/612/3fb/004/6123fb004e37b780670542.png" -->
            </GmapMap>
          </div>
        </div>

        <!-- saveNewAddress() -->
        <!-- <b-button
          @click="saveNewAddress()"
          class="mt-5"
          type="is-success"
          icon-left="map-marker-plus"
          >Save address</b-button
        > -->
      </div>
      <div class="row">
        <div class="col-md-4 col-12 mr-auto ml-auto mrgt">
          <vs-button gradient>Save My Data</vs-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
//  import $ from 'jquery'
import { gmapApi } from "vue2-google-maps";
export default {
  name: "googlePlacescomp",
  data() {
    return {
      center: { lat: 0, lng: 0 },
      posmarker: { lat: 0, lng: 0 },
      currentPlace: null,
      loadbtn: false,
      error: "",
      isActiveAlert: false,
      google: gmapApi,
      newAddress: {
        address_text: "",
        lat: "",
        lng: "",
      },
      errorMsg: "",
    };
  },
  computed: {
    ...mapGetters({
      get_address_by_latlng: "get_address_by_latlng",
      get_response_addAddress: "get_response_addAddress",
    }),
  },
  watch: {
    get_address_by_latlng: function (newVal, oldVal) {
      //  console.log( 'new value = ',newVal);
      // console.log( 'old value =', oldVal , 'from watcher');
      console.log(oldVal);
      this.$refs["autocomplete"].$el.value = newVal;
      this.newAddress.address_text = newVal;
    },
  },
  methods: {
    ...mapActions({
      getAddressFrom: "getAddressFrom",
      add_new_address: "add_new_address",
    }),
    setPlace(place) {
      this.currentPlace = place;
      const marker = {
        lat: this.currentPlace.geometry.location.lat(),
        lng: this.currentPlace.geometry.location.lng(),
      };
      this.posmarker = marker;
      this.center = marker;
      this.newAddress.lat = this.currentPlace.geometry.location.lat();
      this.newAddress.lng = this.currentPlace.geometry.location.lng();
      this.getAddressFrom(this.posmarker);
    },
    updateCoordinates(location) {
      this.posmarker = {
        lat: location.latLng.lat(),
        lng: location.latLng.lng(),
      };
      this.center = {
        lat: location.latLng.lat(),
        lng: location.latLng.lng(),
      };
      this.newAddress.lat = location.latLng.lat();
      this.newAddress.lng = location.latLng.lng();
      this.getAddressFrom(this.posmarker);
    },
    getMyLocation() {
      (this.loadbtn = true),
        navigator.geolocation.getCurrentPosition(
          (position) => {
            this.center = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            this.posmarker = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            this.loadbtn = false;
            this.isActiveAlert = false;
            this.newAddress.lat = position.coords.latitude;
            this.newAddress.lng = position.coords.longitude;
            this.getAddressFrom(this.posmarker);
          },
          (error) => {
            this.error =
              "Locator is unable to find your address. Please type your address manually. or Move Marker";
            (this.loadbtn = false), (this.isActiveAlert = true);
            console.log("error", error.message);
          }
        );
    },
    saveNewAddress() {
      //    this.add_new_address(this.newAddress).then(()=>{
      //      if(this.get_response_addAddress.status == 402)
      //      {
      //         this.errorMsg = this.get_response_addAddress.message;
      //         this.danger();
      //      }
      //      else if(this.get_response_addAddress.status == 200)
      //      {
      //       this.errorMsg = 'A new address has been added successfully';
      //       this.success();
      //       this.uk.modal($('#modal-container')).hide();
      //      }
      //    });
    },
    success() {
      const notif = this.$buefy.notification.open({
        duration: 4000,
        message: this.errorMsg,
        position: "is-bottom-right",
        type: "is-success",
        hasIcon: true,
      });
      notif.$on("close", () => {
        // this.$buefy.notification.open('Custom notification closed!')
      });
    },
    danger() {
      const notif = this.$buefy.notification.open({
        duration: 4000,
        message: this.errorMsg,
        position: "is-bottom-right",
        type: "is-danger",
        hasIcon: true,
      });
      notif.$on("close", () => {
        // this.$buefy.notification.open('Custom notification closed!')
      });
    },
  },
  mounted() {},
};
</script>
<style lang="scss">
.map_company_wraps {
  padding-top: 20px;
  .mrgb {
    margin-bottom: 14px;
    position: relative;
  }
  .mrgt {
    margin-top: 25px;
  }
  .tabname {
    font-size: 18px;
    font-weight: 600;
    margin: 0;
    padding: 0;
    margin-bottom: 20px;
  }
  .search_googlemap {
    position: relative;
    .getMylocation {
      position: absolute;
      right: 0px;
      border-radius: 0;
      width: 50px;
    }
    .leftIcons {
      position: absolute;
      font-size: 26px;
      top: 6px;
      left: 8px;
      color: #0b1518;
    }
    .uk-input {
      padding-left: 40px;
    }
  }
}
</style>
