<template>
 <div class="pd_fix">
  <plansApp></plansApp>
 </div>
</template>

<script>
import plansApp from '@/components/plans/plansApp.vue';

export default {
   name:'Plans',
   data(){
    return{
    }
   },
   components:{
       plansApp,
   },
   methods:{},
    mounted(){
         document.body.classList.add('PlansPage');
    },
    destroyed(){
      document.body.classList.remove('PlansPage');
    }
}
</script>
