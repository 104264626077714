<template>
<div class="rfqDetails_wraps">
<div class="container">
    <div class="row">
    <div class="col-lg-8">
        <div class="box_productTab">
                 <div class="slider_left">
                     <div class="uk-position-relative uk-visible-toggle uk-light" tabindex="-1" uk-slideshow max-height="220" min-height="220">
                     <ul class="uk-slideshow-items">
                        <li>
                             <img src="https://images.unsplash.com/photo-1539846484870-1a68e3aa8e74?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1740&q=80" alt="" uk-cover>
                         </li>
                        <li>
                             <img src="https://images.unsplash.com/photo-1648582712570-f3cf51d4d2aa?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1740&q=80" alt="" uk-cover>
                         </li>
                     </ul>
 
                     <!-- <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slideshow-item="previous"></a>
                     <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slideshow-item="next"></a> -->
                     </div>
                 </div>
                 <div class="prod_info">
                     <div class="title">
                         <h2> <span class="status">RFS</span> Blood syetem fix kit</h2>
                         <div class="date_title">
                            <i class='bx bxs-calendar'></i>
                            <span>October-22</span>
                         </div>
                     </div>
                     <!-- fob price -->
                     <div class="fob_price">
                        <div class="single_fob">
                            <div class="key_fob">
                             <span>Brand:</span>
                         </div>
                         <div class="val_fob">ENVITEC</div>
                        </div>
                        <!--  -->
                        <div class="single_fob">
                            <div class="key_fob">
                             <span>Model:</span>
                         </div>
                         <div class="val_fob">P/N PM30-32732</div>
                        </div>
                        <div class="single_fob">
                            <div class="key_fob">
                             <span>Category:</span>
                         </div>
                         <div class="val_fob">Power Supply</div>
                        </div>
                        <!-- <div class="single_fob">
                            <div class="key_fob">
                             <span>See All:</span>
                         </div>
                         <div class="val_fob">Ultrasound</div>
                        </div> -->
                     </div>
                      <!-- fob price -->
                      <div class="parg">
                        <p>Need Crude Sunflower and Rapeseed Oil on a monthly basis. Destination: CIF China Qty: 15000 mt x12 (For both Items) Target Price: 950usd/ton Payment Method: 100 % irrevocable, transferable (1 time) DLC Contact.</p>
                      </div>
                      <div class="properites">
                        <div class="single_prop">
                         <div class="prob_key">
                             <span>Qty:</span>
                         </div>
                         <div class="prob_val">
                             <span > 1000 / Unit</span>
                         </div>
                        </div>
                        <div class="single_prop">
                         <div class="prob_key">
                             <span>Quote left:</span>
                         </div>
                         <div class="prob_val">
                             <span > 10</span>
                         </div>
                        </div>
                        <div class="single_prop">
                         <div class="prob_key">
                             <span>Condition:</span>
                         </div>
                         <div class="prob_val">
                             <span > New</span>
                         </div>
                        </div>
                        <div class="single_prop">
                         <div class="prob_key">
                             <span>Budget:</span>
                         </div>
                         <div class="prob_val">
                             <span > 1000 - 2000 / Unit</span>
                         </div>
                        </div>
                        
                        <div class="single_prop">
                         <div class="prob_key">
                             <span>Posted in:</span>
                         </div>
                         <div class="prob_val">
                             <span class="prob_flag"><img src="~@/assets/flag/flag14.png" alt=""></span>
                         </div>
                        </div>
                        <div class="single_prop">
                         <div class="prob_key">
                             <span>Time left:</span>
                         </div>
                         <div class="prob_val">
                            5 Days Before
                             <!-- <span ><van-count-down :time="time" format="DD Day, HH:mm:ss" /></span> -->
                         </div>
                        </div>
                      </div>
                      <!--  -->
                 <div class="badage_users">
                   <div class="badge_down">
                    <!-- gradient -->
                   <vs-button
                   style="max-width: 140px;"
                    color="#e63026"
                    animation-type="scale">
                   <i class='bx bx-heart'></i>
                    <template #animate >
                    Add to Favorites
                    </template>
                </vs-button>
                <vs-button
                style="max-width: 140px;"
                    color="#00bfff"
                    animation-type="scale">
                    Send Quote
                </vs-button>
                   </div>
                 </div>
                 </div>
        </div>
        <div class="banner_qouteNow">
            <vs-button
                style="max-width: 140px;"
                    color="#00bfff"
                    animation-type="scale">
                     Quote Now
                </vs-button>
                <div class="avalible">
                    <span class="key1">Availability</span>
                    <span class="bold">11</span>
                </div>
                <div class="time_left">
                    <span  class="key1">Time Left</span>
                     <span class="counterTime"><van-count-down :time="time" format="DD Day, HH:mm:ss" /></span>
                </div>
        </div>
        <div class="box_seller">
                    <van-tabs v-model="active" type="card">
                    <van-tab :title="'RFQ Information'">
                    <div class="contant_tab">
                        <div class="data_keys">
                            <div class="single_data">
                                <div class="key1">
                                    Product Name
                                </div>
                                <div class="val1">
                                  Medical Parts 1
                                </div>
                            </div>
                            <div class="single_data">
                                <div class="key1">
                                    Category
                                </div>
                                <div class="val1">
                                    Medmed
                                </div>
                            </div>
                            <div class="single_data">
                                <div class="key1">
                                    Quantity
                                </div>
                                <div class="val1">
                                    10000/set
                                </div>
                            </div>
                            <div class="single_data">
                                <div class="key1">
                                    Details
                                </div>
                                <div class="val1">
                                    Merhaba, bu ürünler ilgimi çekti. Type:Soybean Oil Product Type:Fruit Oil Processing Type:Refined Refined Type:Fractionated Oil Cultivation Type:COMMON Use:Animal Feed and Oleochemicals Packaging:Bulk Packaging:Can (Tinned) Packaging:Drum Purity (%):99.9 Volume (L):100 Grade:A
                                </div>
                            </div>
                        </div>
                    </div>
                    </van-tab>
                    <!--  -->
                    <van-tab :title="'Buyer Proﬁle'">
                        <div class="contant_tab">
                            <div class="text-center card-box">
                    <div class="member-card pt-2 pb-2">
                        <div class="thumb-lg member-thumb mx-auto"><img src="https://cdn1.vectorstock.com/i/1000x1000/36/45/medical-logo-medical-center-logo-vector-26443645.jpg" class="rounded-circle img-thumbnail" alt="profile-image"></div>
                        <div class="">
                            <h4>Mr. Yazan #########</h4>
                            <p class="text-muted mb-4">General Manager | <img src="https://cdn-icons-png.flaticon.com/256/7595/7595571.png" width="25" height="25" alt=""></p>
                        </div>
                        <button @click="rfq_pop = true" type="button" class="btn btn-primary mt-3 btn-rounded waves-effect w-md waves-light">Want to view now</button>
                    </div>
                </div>
                    </div>
                    </van-tab>
                    <van-tab :title="'Quotes Record (5)'">
                        <div class="contant_tab">
                            <vs-table>
                        <template #thead>
                        <vs-tr>
                            <vs-th>
                            Company Name
                            </vs-th>
                            <vs-th>
                            Business Type
                            </vs-th>
                            <vs-th>
                            Location
                            </vs-th>
                            <vs-th>
                            Time Quoted ( U.S. PST )
                            </vs-th>
                        </vs-tr>
                        </template>
                        <template #tbody>
                        <vs-tr
                            :key="i"
                            v-for="(tr, i) in users"
                            :data="tr"
                        >
                            <vs-td>
                            {{ tr.name }}
                            </vs-td>
                            <vs-td>
                            {{ tr.type }}
                            </vs-td>
                            <vs-td>
                            {{ tr.Location }}
                            </vs-td>
                            <vs-td>
                            {{ tr.time }}
                            </vs-td>
                        </vs-tr>
                        </template>
                    </vs-table>
                    </div>
                    </van-tab>
                    </van-tabs>
                </div>
    </div>
    <div class="col-lg-4">
        <div class="reco_rfq">
            <div class="title_sec">Recommend RFQs</div>
            <div class="data_keys">
                            <div class="single_data">
                                <div class="key1">
                                    Product Name
                                </div>
                                <div class="val1">
                                    Clearvue intreducer
                                </div>
                            </div>
                            <div class="single_data">
                                <div class="key1">
                                    Quantity Required
                                </div>
                                <div class="val1">
                                    10 peace/preces
                                </div>
                            </div>
                            <div class="single_data">
                                <div class="key1">
                                    Posted in
                                </div>
                                <div class="val">
                                    <span class="prob_flag"><img src="~@/assets/flag/flag14.png" alt=""> <span>Russia </span></span>
                                </div>
                            </div>
                            <div class="single_data">
                                <div class="key1">
                                    Time
                                </div>
                                <div class="val1">
                                    9 days before
                                </div>
                            </div>
            </div>
            <!--  -->
            <div class="data_keys">
                            <div class="single_data">
                                <div class="key1">
                                    Product Name
                                </div>
                                <div class="val1">
                                    Clearvue intreducer
                                </div>
                            </div>
                            <div class="single_data">
                                <div class="key1">
                                    Quantity Required
                                </div>
                                <div class="val1">
                                    10 peace/preces
                                </div>
                            </div>
                            <div class="single_data">
                                <div class="key1">
                                    Posted in
                                </div>
                                <div class="val">
                                    <span class="prob_flag"><img src="~@/assets/flag/flag13.png" alt=""> <span>Jordan </span></span>
                                </div>
                            </div>
                            <div class="single_data">
                                <div class="key1">
                                    Time
                                </div>
                                <div class="val1">
                                    9 days before
                                </div>
                            </div>
            </div>
            <!--  -->
            <div class="data_keys">
                            <div class="single_data">
                                <div class="key1">
                                    Product Name
                                </div>
                                <div class="val1">
                                    Clearvue intreducer
                                </div>
                            </div>
                            <div class="single_data">
                                <div class="key1">
                                    Quantity Required
                                </div>
                                <div class="val1">
                                    10 peace/preces
                                </div>
                            </div>
                            <div class="single_data">
                                <div class="key1">
                                    Posted in
                                </div>
                                <div class="val">
                                    <span class="prob_flag"><img src="~@/assets/flag/flag3.png" alt=""> <span>Canada </span></span>
                                </div>
                            </div>
                            <div class="single_data">
                                <div class="key1">
                                    Time
                                </div>
                                <div class="val1">
                                    9 days before
                                </div>
                            </div>
            </div>
        </div>
    </div>

    <!--  -->
    <van-popup v-model="rfq_pop" class="rfq_popup" closeable>
        <div class="realted_wraps">
            <div class="txet_ups">
                <h2>Get Free RFQs After Upgrading to Golden Supplier</h2>
                <p class="text-muted">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's</p>
            </div>
            <van-steps :active="active_step">
            <van-step>Apply</van-step>
            <van-step>Upgrade to Golden Supplier</van-step>
            <van-step>Done</van-step>
            </van-steps>
            <div class="row forms" v-if="active_step==0">
                <div class="col-md-6 mrgb">
                   <b-field label="First Name">
                  <vs-input placeholder="" />
                  </b-field>
              </div>
              <div class="col-md-6 mrgb">
                   <b-field label="Last Name">
                  <vs-input placeholder="" />
                  </b-field>
              </div>
              <div class="col-md-6 mrgb">
                  <b-field label="Country of Origin">
               <vs-select placeholder="Select" v-model="value1">
                    <vs-option label="Jordan" value="1">
                        Jordan
                    </vs-option>
                    <vs-option label="India" value="2">
                    India
                    </vs-option>
                    <vs-option label="Spain" value="3">
                        Spain
                    </vs-option>
                    <vs-option  label="Usa" value="4">
                    Usa
                    </vs-option>
                    <vs-option label="Russia" value="6">
                    Russia
                    </vs-option>
                    <vs-option label="Qatar" value="7">
                    Qatar
                    </vs-option>
                    <vs-option label="Egypt" value="8">
                    Egypt
                    </vs-option>
                    </vs-select>
                    </b-field>
            </div>
            <div class="col-md-6 mrgb">
                   <b-field label="Company Name">
                  <vs-input placeholder="" />
                  </b-field>
              </div>
              <div class="col-md-12 mrgb">
                   <b-field label="Phone Number">
                  <vs-input placeholder="" />
                  </b-field>
              </div>
              <div class="col-md-6 mrgb">
                  <b-field label="Bussness Type">
               <vs-select placeholder="Select" v-model="value2">
                    <vs-option label="Type" value="1">
                        Type
                    </vs-option>
                    <vs-option label="Type" value="2">
                        Type
                    </vs-option>
                    <vs-option label="Type" value="3">
                        Type
                    </vs-option>
                    <vs-option  label="Type" value="4">
                        Type
                    </vs-option>
                    <vs-option label="Type" value="6">
                        Type
                    </vs-option>
                    <vs-option label="Type" value="7">
                        Type
                    </vs-option>
                    <vs-option label="Type" value="8">
                        Type
                    </vs-option>
                    </vs-select>
                    </b-field>
            </div>
            <div class="col-md-6 mrgb">
                  <b-field label="Bussness Type">
               <vs-select placeholder="Your Job Title" v-model="value3">
                    <vs-option label="Type" value="1">
                        Type
                    </vs-option>
                    <vs-option label="Type" value="2">
                        Type
                    </vs-option>
                    <vs-option label="Type" value="3">
                        Type
                    </vs-option>
                    <vs-option  label="Type" value="4">
                        Type
                    </vs-option>
                    <vs-option label="Type" value="6">
                        Type
                    </vs-option>
                    <vs-option label="Type" value="7">
                        Type
                    </vs-option>
                    <vs-option label="Type" value="8">
                        Type
                    </vs-option>
                    </vs-select>
                    </b-field>
            </div>
            <div class="col-12 mrgb text-center">
                <van-button @click="active_step = 1" class="submit1" color="#1d9bf0">Submit</van-button>
            </div>
            </div>
            <div class="payments_wrap" v-if="active_step==1">
             <h2> payemnt metods</h2>
              <div class="row row_pays">
                <div class="col-lg-4 col-md-6 col-12">
                <a href="#" class="box_pays">
                    <input checked  id="rad1" type="radio" name="paysmehod"/>
                    <label for="rad1">
                    <img src="https://upload.wikimedia.org/wikipedia/commons/a/a4/Paypal_2014_logo.png" alt="">
                    <!-- <p>Mada Pay</p> -->
                    </label>
                </a>
            </div>
            <!--  -->
            <div class="col-lg-4 col-md-6 col-12">
                <a href="#" class="box_pays">
                    <input checked  id="rad1" type="radio" name="paysmehod"/>
                    <label for="rad1">
                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/41/Visa_Logo.png/640px-Visa_Logo.png" alt="">
                    <!-- <p>Mada Pay</p> -->
                    </label>
                </a>
            </div>
            <div class="col-lg-4 col-md-6 col-12">
                <a href="#" class="box_pays">
                    <input checked  id="rad1" type="radio" name="paysmehod"/>
                    <label for="rad1">
                    <img src="https://upload.wikimedia.org/wikipedia/commons/7/72/MasterCard_early_1990s_logo.png" alt="">
                    <!-- <p>Mada Pay</p> -->
                    </label>
                </a>
            </div>
            <div class="col-lg-4 col-md-6 col-12">
                <a href="#" class="box_pays">
                    <input checked  id="rad1" type="radio" name="paysmehod"/>
                    <label for="rad1">
                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/ba/Stripe_Logo%2C_revised_2016.svg/2560px-Stripe_Logo%2C_revised_2016.svg.png" alt="">
                    <!-- <p>Mada Pay</p> -->
                    </label>
                </a>
            </div>
              </div>
              <div class="text-center">
                <van-button @click="active_step = 2" class="submit1" color="#1d9bf0">Submit</van-button>
              </div>
            </div>
            <div class="done_wraps" v-if="active_step==2">
                <div class="image">
                    <img src="~@/assets/done.svg" alt="done">
                </div>
                <p>Successfully completed, thank you</p>
            </div>
        </div>
        </van-popup>
</div>
</div>
</div>
</template>
<script>
export default {
  name: "rfqDetails",
  data() {
    return {
        rate_val:4,
        time: 30 * 60 * 60 * 1000,
        active:0,
        active_step:0,
        rfq_pop:false,
        value1:'1',
        value2:'1',
        value3:'3',
        users: [
          {
            "name": "k###################",
            "type": "Custom manufacturer",
            "Location": "China, Jiangsu",
            "time": "18/10/2022 06:40",
          },
          {
            "name": "M###################",
            "type": "Custom manufacturer",
            "Location": "Qatar",
            "time": "18/10/2022 06:40",
          },
          {
            "name": "S###################",
            "type": "Custom manufacturer",
            "Location": "Brazil",
            "time": "18/10/2022 06:40",
          },
          {
            "name": "R###################",
            "type": "Custom manufacturer",
            "Location": "Koria",
            "time": "18/10/2022 06:40",
          },
          {
            "name": "O###################",
            "type": "Custom manufacturer",
            "Location": "India",
            "time": "18/10/2022 06:40",
          },
          {
            "name": "V###################",
            "type": "Custom manufacturer",
            "Location": "Jordan",
            "time": "18/10/2022 06:40",
          },
        ]
    };
  },
  components: {},
};
</script>
<style lang="scss">
.rfq_popup{
    .forms , label{
        font-size: 13px !important;
    }
    .vs-select-content{
        max-width: 100%;
    }
    .mrgb{
        margin-top: 24px;
    }
}
</style>
<style lang="scss" scoped>
.rfqDetails_wraps{
    min-height: 100vh;
    padding-top: 200px;
    .box_productTab{
         margin-bottom: 14px;
         display: flex;
         align-items: flex-start;
         justify-content: space-between;
         background-color: white;
         padding: 10px 10px;
         border-radius: 8px;
         flex-wrap: wrap;
         .slider_left{
             flex: 0 0 250px;
         }
         .prod_info{
             padding-left: 10px;
             flex: 0 0 calc(100% - 250px);
            //  border-right: 1px dashed gainsboro;
             .title{
                display: flex;
                align-items: center;
                justify-content: space-between;
                 margin-bottom: 12px;
                 h2{
                     font-size: 20px;
                     color: deepskyblue;
                     .status{
                        font-size: 15px;
                        border: 1px solid #666666;
                        color: #666666;
                        padding: 2px 18px;
                        border-radius: 3px;
                        margin-right: 10px;
                        display: inline-block;

                     }
                 }
                 .date_title{
                    font-size: 14px;
                    color: deepskyblue;
                    i{
                        padding-right: 4px;
                        vertical-align: middle;
                        margin-top: -4px;
                       
                    }
                 }
             }
             .fob_price{
                 display: flex;
                 font-size: 14px;
                 color: black;
                 .single_fob{
                    display: flex;
                    align-items: center;
                    &:last-child{
                        &::after{
                            display: none;
                        }
                    }
                    &::after{
                        content: '|';
                        padding: 0px 8px;
                        opacity: 0.5;
                    }
                 }
                 .key_fob{
                     margin-right: 10px;
                     opacity: 0.6;
                 }
                 .val_fob{
                     font-weight: 500;
                 }
             }
             .parg{
                p{
                    font-size: 14px;
                    opacity: 0.8;
                    margin-top: 4px;
                }
             }
             .properites{
                 display: flex;
                 align-items: center;
                 justify-content: space-between;
                 flex-wrap: wrap;
                 margin-top: 14px;
                 border-bottom: 1px dashed gainsboro;
                 padding-bottom: 5px;
                 .single_prop{
                     flex:0 0 50%;
                     display: flex;
                     align-items: center;
                     justify-content: space-between;
                     font-size: 13px;
                     margin-bottom: 8px;
                     padding-right: 15px;
                     .prob_key{
                         width: 45%;
                         opacity: 0.6;
                     }
                     .prob_val{
                         font-weight: 500;
                         .prob_flag{
                            img{
                                width: 24px;
                                height: 24px;
                                object-fit: contain;
                            }
                         }
                     }
                 }
             }
            //  
            
            .badage_users{
                .badge_up{
                padding-top: 16px;
                display: flex;
                align-items: center;
                }
                .badge_down{
                    padding: 14px 0px;
                    display: flex;
                    align-items: center;
                }
                .user-bad{
                    font-size: 14px;
                    font-weight: 600;
                    margin-right: 14px;
                    i{
                        vertical-align: middle;
                        margin-top: -5px;
                        margin-right: 4px;
                        font-size: 17px;
                    }
                    img{
                        vertical-align: middle;
                        object-fit: contain;
                        margin-left: 8px;
                        margin-top: -4px;
                    }
                }
                .ratings{
                    span{
                        padding-right: 5px;
                    }
                }
         }
         }
       
     }
     .banner_qouteNow{
        background-color: white;
        display: flex;
        align-items: center;
        font-size: 14px;
        padding: 20px 10px;
        .key1{
            opacity: 0.5;
        }
        .avalible{
            margin: 0px 40px;
            .bold{
                font-weight: 600;
                color: var(--active);
                padding-left: 10px;
            }
        }
        .time_left{
            display: flex;
            align-items: center;
            .counterTime{
                padding-left: 10px;
            }
        }
     }
     .data_keys{
    .single_data{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        font-size: 14px;
        border-bottom: 1px solid rgba(220, 220, 220, 0.532);
        padding: 8px 0px;
        &:last-child{
            border-bottom: none;
        }
        .key1{
            font-weight: 600;
            flex: 0 0 100px;
        }
        .val1{
            opacity: 0.5;
        }
    }
}
.reco_rfq{
    .title_sec{
    background-color: var(--active);
    color: white;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    padding: 10px 0px;
    border-radius: 4px;
}
    .prob_flag{
    img{
        width: 24px;
        height: 24px;
        object-fit: contain;
        vertical-align: middle;
        margin-top: -5px;
        margin-right: 5px;
    }
    }
    .data_keys{
        margin-top: 20px;
        background-color: white;
        box-shadow: 1px 2px 0px rgba(220, 220, 220, 0.335);
        padding: 2px 10px;
        .key1{
            flex: 0 0 145px !important;
        }
    }
}
.rfq_popup{
    width: 600px;
    height: 90vh;
    padding-top: 50px;
    .txet_ups{
        text-align: center;
        h2{
            font-weight: 600;
        }
        p{
            font-size: 14px;
            padding: 2px 50px;
        }
    }
    .forms{
        margin-top: 0px;
        padding: 0px 10px;
    }
    .forms , label{
        font-size: 13px !important;
    }
    .submit1{
        width: 200px;
        border-radius: 4px;
        margin-top: 20px;
    }
    .payments_wrap{
      h2{
        font-size: 1rem ;
        color: black;
        font-weight: 700;
        padding-top: 0px;
    }
     .row_pays{
        padding-top: 20px;
    }
     .box_pays{
      display: block;
        text-align: center;
    background-color: #eaeaea;
    width: 100%;
    min-height: 155px;
    border-radius: 10px;
    margin-bottom: 20px;
    border: 1px solid gainsboro;
    transition: 0.3s ease-in-out;
    }
     .box_pays label{
        display: inline-block;
    width: 100%;
    height: 100%;
    padding: 20px 20px 10px 20px;
    cursor: pointer;
    }
     .box_pays.cheked_pay{
        background-color: #cecece;
    }
     .box_pays:hover{
        background-color: #cecece;
    }
     .box_pays input{
        display: none;
    }
     .box_pays img{
        width: 100%;
        height: 120px;
        padding: 8px;
        object-fit: contain;
    }
     .box_pays p{
        font-size: 15px;
        color: black;
        font-weight: 500;
        padding-top: 15px;
        padding-bottom: 10px;
    }
  }
  .done_wraps{
    text-align: center;
    margin-top: 50px;
    .image{
        img{
            width: 100%;
            height: 250px;
        }
    }
    p{
        display: block;
        font-size: 14px;
        font-weight: 600;
        margin-top: 22px;
    }
  }
}


// 
// card user
// 
.card-box {
    padding: 20px;
    border-radius: 3px;
    margin-bottom: 30px;
    background-color: #fff;
}

.social-links li a {
    border-radius: 50%;
    color: rgba(121, 121, 121, .8);
    display: inline-block;
    height: 30px;
    line-height: 27px;
    border: 2px solid rgba(121, 121, 121, .5);
    text-align: center;
    width: 30px
}

.social-links li a:hover {
    color: #797979;
    border: 2px solid #797979
}
.img-thumbnail {
    padding:0;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: 100% !important;
    width: 90px;
    height: 90px;
    margin-bottom: 10px;
}
.text-pink {
    color: #ff679b!important;
}
.btn-rounded {
    border-radius: 2em;
}
.text-muted {
    color: #98a6ad!important;
}
h4 {
    line-height: 22px;
    font-size: 18px;
}
.waves-effect {
    background-color: var(--active);
    border: 1px solid var(--active);
}
}
</style>
