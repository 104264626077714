<template>
<div class="parent_wrap_pofile">
<div class="row row_parents">
    <div class="col-lg-3 p-0">
         <div class="hidden">
      <vs-sidebar
        v-model="active"
        open
        >
        <template #logo>
          <!-- ...img logo -->
        </template>
        <vs-sidebar-item id="dashboard" to="/profile/dashboard">
          <template #icon>
            <i class='bx bx-home'></i>
          </template>
         Dashboard
        </vs-sidebar-item>
        <vs-sidebar-group>
          <template #header>
            <vs-sidebar-item arrow>
              <template #icon>
                <i class='bx bx-group'></i>
              </template>
             My Profile
            </vs-sidebar-item>
          </template>

          <vs-sidebar-item id="pesonal" to="/profile/pesonal">
          <template #icon>
            <i class='bx bx-home'></i>
          </template>
         Personal Information
        </vs-sidebar-item>
        <vs-sidebar-item id="business" to="/profile/business"> 
          <template #icon>
          <i class='bx bx-home'></i>
          </template>
          Business Information
        </vs-sidebar-item>
         <vs-sidebar-item id="map" to="/profile/map"> 
          <template #icon>
          <i class='bx bx-home'></i>
          </template>
          Company Map
        </vs-sidebar-item>
         <vs-sidebar-item id="Branches" to="/profile/branches"> 
          <template #icon>
          <i class='bx bx-home'></i>
          </template>
          Branches
        </vs-sidebar-item>
         <vs-sidebar-item id="certificates" to="/profile/certificates"> 
          <template #icon>
          <i class='bx bx-home'></i>
          </template>
          Certificates
        </vs-sidebar-item>
         <vs-sidebar-item id="trademarks" to="/profile/trademarks"> 
          <template #icon>
          <i class='bx bx-home'></i>
          </template>
          Trademarks
        </vs-sidebar-item>
         <vs-sidebar-item id="Webstore" to="/profile/webstore"> 
          <template #icon>
          <i class='bx bx-home'></i>
          </template>
          Webstore
        </vs-sidebar-item>
        </vs-sidebar-group>
        <vs-sidebar-group>
          <template #header>
            <vs-sidebar-item arrow>
              <template #icon>
                <i class='bx bxs-spreadsheet'></i>
              </template>
            My Listing
            </vs-sidebar-item>
          </template>
          <vs-sidebar-item id="listing" to="/profile/listing"> 
          <template #icon>
            <i class='bx bxs-spreadsheet'></i>
          </template>
          My listing Stats
        </vs-sidebar-item>
          <vs-sidebar-item id="Ads" to="/profile/my-ads"> 
          <template #icon>
            <i class='bx bxs-spreadsheet'></i>
          </template>
          My Ads
        </vs-sidebar-item>
        </vs-sidebar-group>
        <vs-sidebar-item id="wallet" to="/profile/wallet"> 
          <template #icon>
            <i class='bx bxs-wallet-alt' ></i>
          </template>
          Wallet
        </vs-sidebar-item>
        <vs-sidebar-item id="following" to="/profile/following"> 
          <template #icon>
            <i class='bx bxs-user-plus'  ></i>
          </template>
          Following
        </vs-sidebar-item>
        <vs-sidebar-item id="followers" to="/profile/followers"> 
          <template #icon>
            <i class='bx bxs-user-plus'  ></i>
          </template>
          Followers
        </vs-sidebar-item>

        <vs-sidebar-item id="Jobs" to="/profile/jobs">
          <template #icon>
            <i class='bx bx-briefcase'></i>
          </template>
           Careers & Training
        </vs-sidebar-item>
        <vs-sidebar-item id="home5">
          <template #icon>
          <i class='bx bx-home'></i>
          </template>
           View History
        </vs-sidebar-item>
        <vs-sidebar-item id="home6">
          <template #icon>
          <i class='bx bx-home'></i>
          </template>
          Favorite
        </vs-sidebar-item>
         <vs-sidebar-item id="home7">
          <template #icon>
          <i class='bx bx-home'></i>
          </template>
          Followers
        </vs-sidebar-item>
         <vs-sidebar-item id="home21">
          <template #icon>
          <i class='bx bx-home'></i>
          </template>
          Following
        </vs-sidebar-item>
        <vs-sidebar-item id="home8">
          <template #icon>
            <i class='bx bx-home'></i>
          </template>
          Messages Center
        </vs-sidebar-item>
        <vs-sidebar-item id="home9">
          <template #icon>
            <i class='bx bx-home'></i>
          </template>
          Blog
        </vs-sidebar-item>
        <vs-sidebar-item id="home10">
          <template #icon>
            <i class='bx bx-home'></i>
          </template>
          Auctions
        </vs-sidebar-item>
        <vs-sidebar-item id="home11">
          <template #icon>
            <i class='bx bx-home'></i>
          </template>
          Data & Analytics
        </vs-sidebar-item>
        <vs-sidebar-item id="home12">
          <template #icon>
            <i class='bx bx-home'></i>
          </template>
         Reviews
        </vs-sidebar-item>
         <vs-sidebar-item id="home13">
          <template #icon>
            <i class='bx bx-home'></i>
          </template>
         Subsicriptions
        </vs-sidebar-item>
        <vs-sidebar-item id="home14">
          <template #icon>
           <i class='bx bx-home'></i>
          </template>
         Tickets
        </vs-sidebar-item>
        <vs-sidebar-item id="home15">
          <template #icon>
            <i class='bx bx-home'></i>
          </template>
          My adds
        </vs-sidebar-item>
        <vs-sidebar-item id="home16">
          <template #icon>
            <i class='bx bx-home'></i>
          </template>
           Ads Waiting Payment
        </vs-sidebar-item>
        <vs-sidebar-item id="home17">
          <template #icon>
          <i class='bx bx-home'></i>
          </template>
          Quotation Requests
        </vs-sidebar-item>
        <vs-sidebar-item id="home18">
          <template #icon>
          <i class='bx bx-home'></i>
          </template>
          VTS & WebStore
        </vs-sidebar-item>
        <vs-sidebar-item id="home19">
          <template #icon>
          <i class='bx bx-home'></i>
          </template>
         Forum / My Topics
        </vs-sidebar-item>
        <vs-sidebar-item id="home20">
          <template #icon>
          <i class='bx bx-home'></i>
          </template>
          Manage RFQ & RFS
        </vs-sidebar-item>
       
      </vs-sidebar>
    </div>
    </div>
    <div class="col-lg-9">
       <div class="content_childeren">
          <router-view></router-view>
       </div>
    </div>
</div>
</div>
</template>
<script>
// import $ from "jquery";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "profileApp",
  data() {
    return {
        active:true
    };
  },
  computed: {
    ...mapGetters({
    }),
  },
  methods: {
    ...mapActions({
    }),
  },
  mounted() {
  },
};
</script>
<style lang="scss">
.parent_wrap_pofile{
    min-height: 100vh;
    padding-top: 170px;
    .row_parents{
        padding: 0;
    }
    .content_childeren{
        padding: 10px 4px;
    }
    // general all upload
         .uploadIco {
    margin: 0;
    i {
      font-size: 70px;
    }
  }
  .upload-draggable {
    p {
      margin: 0;
      font-weight: 400;
    }
  }
   .upload_img{
      .field.has-addons{
        display: block;
        p.sp_desc{
          padding-top: 10px;
          font-size: 12px;
          font-weight: 500;
        }
        p.sp_desc.danger{
          padding-top: 2px;
        }
      }
    }
    textarea{
      font-size: 14px;
    }
}
</style>
