<template>
    <div class="rfq_list_wraps">
        <div class="center">
           <div class="span_controll">
            <span>Make All As Read</span>
            <span>Mark as unread</span>
            <span>Statted</span>
           </div>
      <vs-table
        v-model="selected"
        >
        <template #header>
          <vs-input v-model="search" border placeholder="Search" />
        </template>
        <template #thead>
          <vs-tr>
            <vs-th>
              <vs-checkbox
                :indeterminate="selected.length == users.length" v-model="allCheck"
                @change="selected = $vs.checkAll(selected, users)"
              />
            </vs-th>
            <vs-th sort @click="users = $vs.sortData($event ,users, 'id')">
            RFQ ID
            </vs-th>
            <vs-th sort @click="users = $vs.sortData($event ,users, 'name')">
                Product Name
            </vs-th>
            <vs-th sort @click="users = $vs.sortData($event ,users, 'Category')">
                Category
            </vs-th>
            <vs-th sort @click="users = $vs.sortData($event ,users, 'qty')">
                Quantity
            </vs-th>
            <vs-th sort @click="users = $vs.sortData($event ,users, 'stDate')">
                Publish Date
            </vs-th>
            <vs-th sort @click="users = $vs.sortData($event ,users, 'edDate')">
                Expiry Date
            </vs-th>
            <vs-th sort @click="users = $vs.sortData($event ,users, 'Date_resp')">
                Date of response
            </vs-th>
            <vs-th sort @click="users = $vs.sortData($event ,users, 'bqr')">
                Buyer Quotes Recived
            </vs-th>
            <vs-th>
                 RFQ
            </vs-th>
            <vs-th >
                Status
            </vs-th>
          
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr
            :key="i"
            v-for="(tr, i) in $vs.getPage($vs.getSearch(users, search), page, max)"
            :data="tr"
            :is-selected="!!selected.includes(tr)">
            <vs-td checkbox>
              <vs-checkbox :val="tr" v-model="selected" />
            </vs-td>
            <vs-td>
            {{ tr.id }}
            </vs-td>
            <vs-td>
              {{ tr.name }}
            </vs-td>
            <vs-td>
              {{ tr.Category }}
            </vs-td>
            <vs-td>
            {{ tr.qty }}
            </vs-td>
            <vs-td>
            {{ tr.stDate }}
            </vs-td>
            <vs-td>
            {{ tr.edDate }}
            </vs-td>
            <vs-td>
            {{ tr.Date_resp }}
            </vs-td>
            <vs-td>
            {{ tr.bqr }}
            </vs-td>
            <vs-td>
                <span class="sp_view" @click="rfq_pop2 = true">View the RFQ</span>
            </vs-td>
            <vs-td>
                <span class="sp_view" @click="rfq_pop = true"> View My Quotation</span>
            </vs-td>
            
          </vs-tr>
        </template>
        <template #footer>
          <vs-pagination v-model="page" :length="$vs.getLength($vs.getSearch(users, search), max)" />
        </template>
      </vs-table>

        <van-popup v-model="rfq_pop" class="rfq_popup" closeable>
        <div class="realted_wraps">
         <div class="row">
            <div class="col-12">
            <div class="card user-card">
                <div class="card-header">
                    <h5>Quotation</h5>
                </div>
                <div class="card-block">
                    <div class="user-image">
                        <img src="https://thumbs.dreamstime.com/z/medical-logo-sign-stock-vector-medical-logo-vector-117790920.jpg" class="img-radius" alt="User-Profile-Image">
                    </div>
                    <h6 class="f-w-600 m-t-25 m-b-10">Mohammad Medical Company</h6>
                    <div class="badage_wraps">
                        <img src="https://www.iconpacks.net/icons/1/free-badge-icon-1361-thumb.png" alt="">
                        <img src="https://www.iconpacks.net/icons/1/free-badge-icon-1194-thumb.png" alt="">
                        <img src="https://www.freeiconspng.com/thumbs/badge-icon-png/badge-certificate-medal-quality-reward-icon--3.png" alt="">
                    </div>
                    <p class="text-muted">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's</p>
                    <hr>
                    <h2 class="title2">Quotation information</h2>
                    <div class="data_keys">
                      <div class="single_data">
                        <div class="key1">
                            <span class="abs_sp">Product</span>
                        </div>
                        <div class="val1">
                            <div class="product_val1">
                                <img src="https://d2jx2rerrg6sh3.cloudfront.net/images/Article_Images/ImageForArticle_22588_16539156642301393.jpg" alt="">
                                <p>Advanced medical x ray angiography machine with??</p>
                                <a href="#">View product details</a>
                            </div>
                        </div>
                      </div>
                      <div class="single_data">
                        <div class="key1">
                            <span class="abs_sp">Serial number</span>
                        </div>
                        <div class="val1">
                            09183901829038129381
                        </div>
                      </div>
                      <div class="single_data">
                        <div class="key1">
                            <span class="abs_sp">Unit price</span>
                        </div>
                        <div class="val1">
                            USD 3500/pieces
                        </div>
                      </div>
                      <div class="single_data">
                        <div class="key1">
                            <span class="abs_sp">Shipping Terms</span>
                        </div>
                        <div class="val1">
                           value 1
                        </div>
                      </div>
                      <div class="single_data">
                        <div class="key1">
                            <span class="abs_sp">Sample lead time</span>
                        </div>
                        <div class="val1">
                           value 1
                        </div>
                      </div>
                      <div class="single_data">
                        <div class="key1">
                            <span class="abs_sp">Sample fee paid by</span>
                        </div>
                        <div class="val1">
                           value 1
                        </div>
                      </div>
                      

                    </div>
                    <h2 class="title2 mt-4">Company information</h2>
                    <div class="data_keys">
                      <div class="single_data">
                        <div class="key1">
                            <span class="abs_sp">Advantages</span>
                        </div>
                        <div class="val1">
                           test value
                        </div>
                      </div>
                      <div class="single_data">
                        <div class="key1">
                            <span class="abs_sp"> User experience</span>
                        </div>
                        <div class="val1">
                            test value
                        </div>
                      </div>
                      <div class="single_data">
                        <div class="key1">
                            <span class="abs_sp">Business type</span>
                        </div>
                        <div class="val1">
                            test value
                        </div>
                      </div>
                      <div class="single_data">
                        <div class="key1">
                            <span class="abs_sp">Main products</span>
                        </div>
                        <div class="val1">
                            Medical Consumables- Nonwoven Face Mask,Nonwoven Cap,Nonwoven Surgical Gown,Shoes Cover and Diaper 
                        </div>
                      </div>
                      <div class="single_data">
                        <div class="key1">
                            <span class="abs_sp">Main markets</span>
                        </div>
                        <div class="val1">
                            Western Europe,South America,North America
                        </div>
                      </div>
                      <div class="single_data">
                        <div class="key1">
                            <span class="abs_sp">Certifications</span>
                        </div>
                        <div class="val1">
                            test value
                        </div>
                      </div>
                      <div class="single_data">
                        <div class="key1">
                            <span class="abs_sp">Quotation Valid to</span>
                        </div>
                        <div class="val1">
                            test value
                        </div>
                      </div>
                      

                    </div>
                 
                 
                    <!-- <p class="text-muted m-t-15">Activity Level: 87%</p>
                    <ul class="list-unstyled activity-leval">
                        <li class="active"></li>
                        <li class="active"></li>
                        <li class="active"></li>
                        <li></li>
                        <li></li>
                    </ul> -->

                </div>
            </div>
        </div>
        
         </div>
        </div>
        </van-popup>
        <!--  -->
        <van-popup v-model="rfq_pop2" class="rfq_popup" closeable>
        <div class="realted_wraps">
         <div class="row">
            <div class="col-12">
            <div class="card user-card">
                <div class="card-header">
                    <h5>RFQ Details</h5>
                </div>
                <div class="card-block">
                    <div class="controll_ups">
                        <van-button type="info">Edit & Post again</van-button>
                        <van-button type="warning">Edit</van-button>
                        <van-button type="danger">Close the Requset</van-button>
                    </div>
                    <div class="data_keys mt-5">
                      <div class="single_data">
                        <div class="key1">
                            <span class="abs_sp">Product Name</span>
                        </div>
                        <div class="val1">
                            Cath lab
                        </div>
                      </div>
                      <div class="single_data">
                        <div class="key1">
                            <span class="abs_sp">Quantity</span>
                        </div>
                        <div class="val1">
                            ( 100 ) Piece/Pieces
                        </div>
                      </div>
                      <div class="single_data">
                        <div class="key1">
                            <span class="abs_sp">Category</span>
                        </div>
                        <div class="val1">
                            Cardiolgy
                        </div>
                      </div>
                    </div>
                    <van-collapse v-model="activeNames">
                    <van-collapse-item title="Show More" name="1">
                        <div class="data_keys">
                        <div class="single_data">
                        <div class="key1">
                            <span class="abs_sp">RFQ ID</span>
                        </div>
                        <div class="val1">
                            6566565656
                        </div>
                      </div>
                      <div class="single_data">
                        <div class="key1">
                            <span class="abs_sp">Publish Date</span>
                        </div>
                        <div class="val1">
                            15.Oct.2022
                        </div>
                      </div>
                      <div class="single_data">
                        <div class="key1">
                            <span class="abs_sp">Expiry Date</span>
                        </div>
                        <div class="val1">
                            16.Oct.2022
                        </div>
                      </div>
                      <div class="single_data">
                        <div class="key1">
                            <span class="abs_sp">Date Of Response</span>
                        </div>
                        <div class="val1">
                            22.Oct.2022
                        </div>
                      </div>
                      <div class="single_data">
                        <div class="key1">
                            <span class="abs_sp">Buyer Quotes Recived</span>
                        </div>
                        <div class="val1">
                           9/10
                        </div>
                      </div>
                      <div class="single_data">
                        <div class="key1">
                            <span class="abs_sp">Status</span>
                        </div>
                        <div class="val1">
                          Rejected
                        </div>
                      </div>
                      
                        </div>
                        <div class="attach">
                            <h2>Attachments</h2>
                            <div class="flex_attach">
                                <img src="https://northsidemedicalsupply.com/wp-content/uploads/2022/12/Medical-Supply-or-Equipment.jpg" alt="">
                                <img src="https://northsidemedicalsupply.com/wp-content/uploads/2022/12/Medical-Supply-or-Equipment.jpg" alt="">
                                <img src="https://northsidemedicalsupply.com/wp-content/uploads/2022/12/Medical-Supply-or-Equipment.jpg" alt="">
                            </div>
                        </div>
                        <div class="qutaion_list">
                            <h2>Quotations from supplier (10)</h2>
                            <div class="single_qoy">
                                <div class="card_compan">
                                    <div class="comp_name">
                                        <img src="https://thumbs.dreamstime.com/z/medical-logo-sign-stock-vector-medical-logo-vector-117790920.jpg" alt="">
                                        <span>Mohammad Medical Company</span>
                                    </div>
                                    <div class="data_keys">
                      <div class="single_data">
                        <div class="key1">
                            <span class="abs_sp">Product</span>
                        </div>
                        <div class="val1">
                            <div class="product_val1">
                                <img src="https://d2jx2rerrg6sh3.cloudfront.net/images/Article_Images/ImageForArticle_22588_16539156642301393.jpg" alt="">
                                <p>Advanced medical x ray angiography machine with??</p>
                            </div>
                        </div>
                      </div>
                      <div class="single_data">
                        <div class="key1">
                            <span class="abs_sp">Quantity</span>
                        </div>
                        <div class="val1">
                            28.00 USD /Pieces | 1 Pieces
                        </div>
                      </div>

                                    </div>
                                    <div class="contact_data">
                                        <vs-button
                                            color="#45c93a"
                                            animation-type="scale">
                                        <i class='bx bxs-conversation' ></i>
                                            <template #animate >
                                            Chat Now
                                            </template>
                                        </vs-button>
                                        <vs-button
                                            color="#00bfff"
                                            animation-type="scale">
                                          View Details
                                        </vs-button>
                                    </div>
                                </div>
                            </div>
                            <div class="single_qoy">
                                <div class="card_compan">
                                    <div class="comp_name">
                                        <img src="https://thumbs.dreamstime.com/z/medical-logo-sign-stock-vector-medical-logo-vector-117790920.jpg" alt="">
                                        <span>Mohammad Medical Company</span>
                                    </div>
                                    <div class="data_keys">
                      <div class="single_data">
                        <div class="key1">
                            <span class="abs_sp">Product</span>
                        </div>
                        <div class="val1">
                            <div class="product_val1">
                                <img src="https://d2jx2rerrg6sh3.cloudfront.net/images/Article_Images/ImageForArticle_22588_16539156642301393.jpg" alt="">
                                <p>Advanced medical x ray angiography machine with??</p>
                            </div>
                        </div>
                      </div>
                      <div class="single_data">
                        <div class="key1">
                            <span class="abs_sp">Quantity</span>
                        </div>
                        <div class="val1">
                            28.00 USD /Pieces | 1 Pieces
                        </div>
                      </div>

                                    </div>
                                    <div class="contact_data">
                                        <vs-button
                                            color="#45c93a"
                                            animation-type="scale">
                                        <i class='bx bxs-conversation' ></i>
                                            <template #animate >
                                            Chat Now
                                            </template>
                                        </vs-button>
                                        <vs-button
                                            color="#00bfff"
                                            animation-type="scale">
                                          View Details
                                        </vs-button>
                                    </div>
                                </div>
                            </div>
                            <div class="single_qoy">
                                <div class="card_compan">
                                    <div class="comp_name">
                                        <img src="https://thumbs.dreamstime.com/z/medical-logo-sign-stock-vector-medical-logo-vector-117790920.jpg" alt="">
                                        <span>Mohammad Medical Company</span>
                                    </div>
                                    <div class="data_keys">
                      <div class="single_data">
                        <div class="key1">
                            <span class="abs_sp">Product</span>
                        </div>
                        <div class="val1">
                            <div class="product_val1">
                                <img src="https://d2jx2rerrg6sh3.cloudfront.net/images/Article_Images/ImageForArticle_22588_16539156642301393.jpg" alt="">
                                <p>Advanced medical x ray angiography machine with??</p>
                            </div>
                        </div>
                      </div>
                      <div class="single_data">
                        <div class="key1">
                            <span class="abs_sp">Quantity</span>
                        </div>
                        <div class="val1">
                            28.00 USD /Pieces | 1 Pieces
                        </div>
                      </div>

                                    </div>
                                    <div class="contact_data">
                                        <vs-button
                                            color="#45c93a"
                                            animation-type="scale">
                                        <i class='bx bxs-conversation' ></i>
                                            <template #animate >
                                            Chat Now
                                            </template>
                                        </vs-button>
                                        <vs-button
                                            color="#00bfff"
                                            animation-type="scale">
                                          View Details
                                        </vs-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </van-collapse-item>
                    </van-collapse>
                </div>
            </div>
        </div>
        
         </div>
        </div>
        </van-popup>
        <van-popup v-model="rfq_pop3" class="rfq_popup w-100" closeable>
        <div class="realted_wraps">
         <div class="row">
            <div class="col-12">
            <div class="card user-card">
                <div class="card-header">
                    <h5>All 10 quotations comparison</h5>
                </div>
                <div class="card-block">
 
                </div>
            </div>
        </div>
        
         </div>
        </div>
        </van-popup>

    </div>
    </div>
</template>
<script>
export default {
  name: "RFQList",
  data() {
    return {
        editActive: false,
        edit: null,
        editProp: {},
        search: '',
        allCheck: false,
        page: 1,
        max: 10,
        active: 0,
        selected: [],
        users: [
          {
            "id": 1,
            "qty": 10,
            "name": "Medical Part",
            "Category": "Medical",
            "stDate": "15.Oct.2022",
            "edDate": "16.Oct.2024",
            "Date_resp": "29 Oct 2022",
            'bqr':'9/10',
            'status':''
          },
          {
            "id": 2,
            "qty": 3,
            "name": "Medical Device",
            "Category": "Medical",
            "stDate": "22.Oct.2022",
            "edDate": "23.Oct.2024",
            "Date_resp": "29 Oct 2022",
            'bqr':'3/3',
            'status':''
          },
          {
            "id": 3,
            "qty": 3,
            "name": "Medical Device",
            "Category": "Medical",
            "stDate": "22.Oct.2022",
            "edDate": "23.Oct.2024",
            "Date_resp": "29 Oct 2022",
            'bqr':'3/3',
            'status':''
          },
          {
            "id": 4,
            "qty": 3,
            "name": "Medical Device",
            "Category": "Medical",
            "stDate": "22.Oct.2022",
            "edDate": "23.Oct.2024",
            "Date_resp": "29 Oct 2022",
            'bqr':'3/3',
            'status':''
          },
          {
            "id": 5,
            "qty": 3,
            "name": "Medical Device",
            "Category": "Medical",
            "stDate": "22.Oct.2022",
            "edDate": "23.Oct.2024",
            "Date_resp": "29 Oct 2022",
            'bqr':'3/3',
            'status':''
          },
          {
            "id": 6,
            "qty": 3,
            "name": "Medical Device",
            "Category": "Medical",
            "stDate": "22.Oct.2022",
            "edDate": "23.Oct.2024",
            "Date_resp": "29 Oct 2022",
            'bqr':'3/3',
            'status':''
          },
          {
            "id": 7,
            "qty": 3,
            "name": "Medical Device",
            "Category": "Medical",
            "stDate": "22.Oct.2022",
            "edDate": "23.Oct.2024",
            "Date_resp": "29 Oct 2022",
            'bqr':'3/3',
            'status':''
          },
          {
            "id": 8,
            "qty": 3,
            "name": "Medical Device",
            "Category": "Medical",
            "stDate": "22.Oct.2022",
            "edDate": "23.Oct.2024",
            "Date_resp": "29 Oct 2022",
            'bqr':'3/3',
            'status':''
          },
          {
            "id": 9,
            "qty": 3,
            "name": "Medical Device",
            "Category": "Medical",
            "stDate": "22.Oct.2022",
            "edDate": "23.Oct.2024",
            "Date_resp": "29 Oct 2022",
            'bqr':'3/3',
            'status':''
          },
          {
            "id": 10,
            "qty": 3,
            "name": "Medical Device",
            "Category": "Medical",
            "stDate": "22.Oct.2022",
            "edDate": "23.Oct.2024",
            "Date_resp": "29 Oct 2022",
            'bqr':'3/3',
            'status':''
          },
          {
            "id": 11,
            "qty": 3,
            "name": "Medical Device",
            "Category": "Medical",
            "stDate": "22.Oct.2022",
            "edDate": "23.Oct.2024",
            "Date_resp": "29 Oct 2022",
            'bqr':'3/3',
            'status':''
          },
          {
            "id": 12,
            "qty": 3,
            "name": "Medical Device",
            "Category": "Medical",
            "stDate": "22.Oct.2022",
            "edDate": "23.Oct.2024",
            "Date_resp": "29 Oct 2022",
            'bqr':'3/3',
            'status':'',
          },
        ],
        rfq_pop:false,
        rfq_pop2:false,
        rfq_pop3:false,
        activeNames: [],
    };
  },
  components: {},
  methods:{
  
  },
  mounted(){
  }
};
</script>
<style lang="scss" scoped>
.rfq_list_wraps{
    min-height: 100vh;
    padding-top: 180px;
    .span_controll{
        font-size: 14px;
        font-weight: 500;
        display: flex;
        align-items: center;
        padding: 5px 20px;
        span{
            margin-right: 20px;
            background-color: var(--active);
            padding: 4px 14px;
            border-radius: 30px;
            color: white;
            cursor: pointer;
        }
    }
    .sp_view{
            background-color: var(--active);
            padding: 4px 10px;
            border-radius: 30px;
            color: white;
            cursor: pointer;
            font-size: 12px;
    }
}

.rfq_popup{
  width: 600px;
  height: 98vh;
  padding: 20px;
  border-radius: 10px;
  padding-top: 50px;
  h2{
    font-size: 20px;
  }
}
.badage_wraps{
    display: flex;
    align-items: center;
    justify-content: center;
    img{
        width: 30px;
        height: 30px;
        object-fit: contain;
    }
}
.title2{
    font-size: 18px;
    font-weight: 500;
    background-color: var(--active);
    color: white;
    padding: 4px 10px;
    border-radius: 8px;
}
.data_keys{
    margin-top: 10px;
    border: 1px solid rgba(220, 220, 220, 0.687);
    .single_data{
        display: flex;
        align-items: stretch;
        border-bottom: 2px solid rgba(220, 220, 220, 0.687);
        font-size: 14px;
        // margin-bottom: 4px;
        // padding-bottom: 4px;
        &:last-child{
            border-bottom: none;
        }
        .key1{
            flex: 0 0 30%;
            background-color: #f0f0f0;
            color: black;
            position: relative;
            padding: 8px 0px;
            display: flex;
            align-items: center;
            justify-content: center;
            // .abs_sp{
            //     position: absolute;
            //     left: 0;
            //     top: 0;
            //     height: 160px;
            // }
        }
        .val1{
            flex:  0 0 70%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .product_val1{
            img{
                width: 100%;
                height: 100px;
                object-fit: contain;
                border-radius: 30px;
            }
            p{
                padding: 0px 40px;
            }
        }

    }
}
// 
.controll_ups{
    margin-top: 20px;
    display: flex;
    align-items: center;
    button{
        margin-right: 10px;
    }
}
.attach{
    h2{
        margin-top: 20px;
        background-color: var(--active);
        color: white;
        font-size: 18px;
        padding: 10px 0px;
    }
    .flex_attach{
        display: flex;
        align-items: center;
        margin-top: 12px;
        img{
            width: 80px;
            height: 80px;
            margin-right: 10px;
            border-radius: 4px;
            object-fit: cover;
            cursor: pointer;
        }
    }
}
.qutaion_list{
    h2{
        margin-top: 20px;
        background-color: var(--active);
        color: white;
        font-size: 18px;
        padding: 10px 0px; 
        margin-bottom: 20px;
    }
    .single_qoy{
        .val1{
            padding-top: 4px;
        }
        .val1 , .key1{
            background-color: white;
        }
        margin-bottom: 30px;
        .card_compan{
            margin: 5px 0px;
            background-color: #f0f0f0;
            padding: 8px 10px;
            border-radius: 10px;
            .comp_name{
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                img{
                    width: 80px;
                    height: 80px;
                    object-fit: cover;
                    border-radius: 100%;
                    border: 1px solid var(--active);
                    margin-top: 10px;
                }
                span{
                    font-size: 15px;
                    font-weight: 600;
                    color: black;
                    display: block;
                    padding-top: 8px;
                }
            }
        }
    }
}


// 

.card.user-card {
    border-top: none;
    -webkit-box-shadow: 0 0 1px 2px rgba(0,0,0,0.05), 0 -2px 1px -2px rgba(0,0,0,0.04), 0 0 0 -1px rgba(0,0,0,0.05);
    box-shadow: 0 0 1px 2px rgba(0,0,0,0.05), 0 -2px 1px -2px rgba(0,0,0,0.04), 0 0 0 -1px rgba(0,0,0,0.05);
    -webkit-transition: all 150ms linear;
    transition: all 150ms linear;
}

.card {
    border-radius: 5px;
    -webkit-box-shadow: 0 1px 2.94px 0.06px rgba(4,26,55,0.16);
    box-shadow: 0 1px 2.94px 0.06px rgba(4,26,55,0.16);
    border: none;
    margin-bottom: 30px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.card .card-header {
    background-color: transparent;
    border-bottom: none;
    padding: 25px;
}

.card .card-header h5 {
    margin-bottom: 0;
    color: #222;
    font-size: 14px;
    font-weight: 600;
    display: inline-block;
    margin-right: 10px;
    line-height: 1.4;
}

.card .card-header+.card-block, .card .card-header+.card-block-big {
    padding-top: 0;
}

.user-card .card-block {
    text-align: center;
}

.card .card-block {
    padding: 25px;
}

.user-card .card-block .user-image {
    position: relative;
    margin: 0 auto;
    display: inline-block;
    padding: 5px;
    width: 110px;
    height: 110px;
    margin-top: 10px;
    border: 2px solid var(--active);
    border-radius: 100%;
}

.user-card .card-block .user-image img {
    z-index: 20;
    position: absolute;
    top: 5px;
    left: 5px;
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 100%;
}

.img-radius {
    border-radius: 50%;
}

.f-w-600 {
    font-weight: 600;
}

.m-b-10 {
    margin-bottom: 10px;
}

.m-t-25 {
    margin-top: 10px;
}

.m-t-15 {
    margin-top: 15px;
}

.card .card-block p {
    line-height: 1.4;
}

.text-muted {
    color: #919aa3!important;
}

.user-card .card-block .activity-leval li.active {
    background-color: #2ed8b6;
}

.user-card .card-block .activity-leval li {
    display: inline-block;
    width: 15%;
    height: 4px;
    margin: 0 3px;
    background-color: #ccc;
}

.user-card .card-block .counter-block {
    color: #fff;
}

.bg-c-blue {
    background: linear-gradient(45deg,#4099ff,#73b4ff);
}

.bg-c-green {
    background: linear-gradient(45deg,#2ed8b6,#59e0c5);
}

.bg-c-yellow {
    background: linear-gradient(45deg,#FFB64D,#ffcb80);
}

.bg-c-pink {
    background: linear-gradient(45deg,#FF5370,#ff869a);
}

.m-t-10 {
    margin-top: 10px;
}

.p-20 {
    padding: 20px;
}

.user-card .card-block .user-social-link i {
    font-size: 30px;
}

.text-facebook {
    color: #3B5997;
}

.text-twitter {
    color: #42C0FB;
}

.text-dribbble {
    color: #EC4A89;
}

.user-card .card-block .user-image:before {
    bottom: 0;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
}


.user-card .card-block .user-image:after {
    top: 0;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
}

</style>

