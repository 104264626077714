//  import axios from 'axios';
import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
export default new Vuex.Store({
    namespaced: true,
    state: {
      getaddressbylatlng:'',
    },
    getters: {
      get_address_by_latlng(state){
        return state.getaddressbylatlng;
      },
    },
    mutations: {
      set_address_by_latlng(state,getaddressbylatlng ){
        state.getaddressbylatlng = getaddressbylatlng
      },
    },
    actions: {
      async getAddressFrom({commit},markerpos) {
        var latLngObj = {
          lat: markerpos.lat,
          lng: markerpos.lng
      }
        Vue.$geocoder.setDefaultMode('lat-lng');
         Vue.$geocoder.send(latLngObj, response => {
        commit('set_address_by_latlng',  response.results[0].formatted_address);
        });
      },
      
          

          
    },
    modules: {}
});