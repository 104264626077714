<template>
    <div class="contact_wrap">
       <div class="container">
        <div class="title_page">
           <h1>Contact Us</h1>
         </div>
          <div class="row">
         <div class="col-lg-12">
             <div class="google-map">
               <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3383.352790891305!2d35.87545272465082!3d32.00555602301136!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x151c9f7f0e7a1e27%3A0x13b234e162bb1efa!2z2LQuINin2YTZhdmE2YPYqSDYsdin2YbZitin2Iwg2LnZhdmR2KfZhg!5e0!3m2!1sar!2sjo!4v1694806907203!5m2!1sar!2sjo" class="maps_go"  style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
         </div>
         </div>
         <div class="col-lg-6">
           <div class="flex_info_contact stikys">
               <div class="single_cont">
                   <h2>Social Media</h2>
               <div class="center social_flex">
         <vs-button
           icon
           color="facebook"
         >
           <i class='bx bxl-facebook-square'></i>
         </vs-button>
   
         <vs-button
           icon
           color="twitter"
         >
           <i class='bx bxl-twitter'></i>
         </vs-button>
   
         <vs-button
           icon
           color="youtube"
         >
           <i class='bx bxl-youtube'></i>
         </vs-button>
   
         <vs-button
           icon
           color="linkedin"
         >
           <i class='bx bxl-linkedin'></i>
         </vs-button>
   
         <vs-button
           icon
           color="whatsapp"
         >
           <i class='bx bxl-whatsapp'></i>
         </vs-button>
               </div>
               </div>
               <div class="single_cont">
                   <h2>E-Mail</h2>
                   <div class="data_cont">
                       <i class='bx bxs-envelope' ></i>
                       <span>info@mail.com</span>
                   </div>
               </div>
               <div class="single_cont">
                   <h2>Phone</h2>
                   <div class="data_cont">
                       <i class='bx bxs-phone' ></i>
                       <span>0787682739</span>
                   </div>
               </div>
               
           </div>
         </div>
         <div class="col-lg-6">
           <div class="flex_info_contact">
               <form action="#">
                   <div class="row">
                       <div class="col-md-12 mrgb" >
                      <b-field label="Full Name">
                      <vs-input placeholder="" />
                     </b-field>
                 </div>
                 <div class="col-md-12 mrgb" >
                      <b-field label="Email">
                      <vs-input placeholder="" />
                     </b-field>
                 </div>
                 <div class="col-md-12 mrgb" >
                      <b-field label="Phone">
                      <vs-input placeholder="" />
                     </b-field>
                 </div>
                 <div class="col-md-12 mrgb">
                      <b-field label="Product Description:">
                      <textarea class="textarea"></textarea>
                     </b-field>
                 </div>
                 <div class="col-12 text-center">
                   <van-button  class="submit1" color="#1d9bf0">Submit</van-button>
                 </div>
                   </div>
               </form>
           </div>
         </div>
        </div>
       </div>
       </div>
   </template>
   <script>
   export default {
     name: "contactArea",
     data() {
       return {};
     },
     components: {},
   };
   </script>
   <style lang="scss" scoped>
   .contact_wrap{
     position: relative;
     z-index: 8;
     padding-top: 200px;
     padding-bottom: 50px;
     min-height: 100vh;
     h1{
     font-size: 26px;
     font-weight: 600;
     color: var(--active) !important;
     margin-bottom: 30px;
   }
     .maps_go{
       width: 100%;
       height: 380px;
       margin-bottom: 40px;
     }
     .stikys{
       position: sticky;
       left: 0;
       top: 220px;
     }
     .mrgb{
       margin-bottom: 10px;
     }
     .submit1{
       width: 200px;
       border-radius: 4px;
       margin-top: 15px;
     }
    
   .flex_info_contact{
       background-color: white;
       border: 1px solid rgba(220, 220, 220, 0.62);
       padding: 30px 20px;
       border-radius: 10px;
       h2{
           font-weight: 15px;
           font-weight: 500;
           margin-bottom: 8px;
       }
       .single_cont{
           margin-bottom: 20px;
           .data_cont{
               i{
                   vertical-align: middle;
                   color: var(--active);
                   font-size: 25px;
                   margin-right: 5px;
                   margin-top: -2px;
               }
           }
       }
   }
   
    .social_flex{
       display: flex;
       align-items: center;
       button{
           flex: 0 0 30px;
           height: 30px;
           width: 30px;
           border-radius: 100%;
       }
    }
   
   }
   </style>
   