<template>
<!-- medical -->
    <div class="medical_text">
      <img src="https://upload.wikimedia.org/wikipedia/commons/8/85/Logo-Test.png" alt="">
      <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, </p>
      <p>when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. </p>
      <p> It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
    </div>
    <!--  -->
</template>
<script>
export default {
  name: "AboutArea",
}
</script>
<style lang="scss" scoped>


.medical_text{
  margin-top: 80px;
  background-image: url('https://images.unsplash.com/photo-1579165466991-467135ad3110?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  text-align: center;
  position: relative;
    padding: 30px 10px 50px 10px;

  &::after{
    content: '';
    background-color: #000;
    width: 100%;
    height: 100%;
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0.6;
  }
  img{
    height: 120px;
    position: relative;
    z-index: 2;
    filter: brightness(1) invert(1);
  }
  p{
     position: relative;
    z-index: 2;
    font-size: 15px;
    color: white;
        width: 80%;
    margin: 0 auto;
    text-align: left;
    font-weight: 500;
    opacity: 0.8;

  }
}

</style>