var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"category_grid"},[_vm._m(0),_c('div',{staticClass:"row row_grid"},[_c('div',{staticClass:"col-xl-2 p-0"},[_c('van-popover',{attrs:{"trigger":"click","actions":_vm.actions,"theme":"dark"},scopedSlots:_vm._u([{key:"reference",fn:function(){return [_c('div',{staticClass:"box_category"},[_c('div',{staticClass:"image_cate"},[_c('img',{attrs:{"src":require("@/assets/c1.png"),"alt":""}})]),_c('div',{staticClass:"name_cate"},[_c('span',[_vm._v("DDL List Groups")])])])]},proxy:true}]),model:{value:(_vm.showPopover[0]),callback:function ($$v) {_vm.$set(_vm.showPopover, 0, $$v)},expression:"showPopover[0]"}})],1),_c('div',{staticClass:"col-xl-2 p-0"},[_c('van-popover',{attrs:{"trigger":"click","actions":_vm.actions,"theme":"dark"},scopedSlots:_vm._u([{key:"reference",fn:function(){return [_c('div',{staticClass:"box_category"},[_c('div',{staticClass:"image_cate"},[_c('img',{attrs:{"src":require("@/assets/c2.png"),"alt":""}})]),_c('div',{staticClass:"name_cate"},[_c('span',[_vm._v("DDL List Groups")])])])]},proxy:true}]),model:{value:(_vm.showPopover[1]),callback:function ($$v) {_vm.$set(_vm.showPopover, 1, $$v)},expression:"showPopover[1]"}})],1),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11),_vm._m(12),_vm._m(13),_vm._m(14),_vm._m(15),_vm._m(16)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sp"},[_c('span',[_vm._v("All Categores")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-xl-2 p-0"},[_c('div',{staticClass:"box_category"},[_c('div',{staticClass:"image_cate"},[_c('img',{attrs:{"src":require("@/assets/c3.png"),"alt":""}})]),_c('div',{staticClass:"name_cate"},[_c('span',[_vm._v("General Instruments")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-xl-2 p-0"},[_c('div',{staticClass:"box_category"},[_c('div',{staticClass:"image_cate"},[_c('img',{attrs:{"src":require("@/assets/c4.png"),"alt":""}})]),_c('div',{staticClass:"name_cate"},[_c('span',[_vm._v("General Instruments")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-xl-2 p-0"},[_c('div',{staticClass:"box_category"},[_c('div',{staticClass:"image_cate"},[_c('img',{attrs:{"src":require("@/assets/c5.png"),"alt":""}})]),_c('div',{staticClass:"name_cate"},[_c('span',[_vm._v("General Instruments")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-xl-2 p-0"},[_c('div',{staticClass:"box_category"},[_c('div',{staticClass:"image_cate"},[_c('img',{attrs:{"src":require("@/assets/c6.png"),"alt":""}})]),_c('div',{staticClass:"name_cate"},[_c('span',[_vm._v("General Instruments")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-xl-2 p-0"},[_c('div',{staticClass:"box_category"},[_c('div',{staticClass:"image_cate"},[_c('img',{attrs:{"src":require("@/assets/c7.png"),"alt":""}})]),_c('div',{staticClass:"name_cate"},[_c('span',[_vm._v("General Instruments")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-xl-2 p-0"},[_c('div',{staticClass:"box_category"},[_c('div',{staticClass:"image_cate"},[_c('img',{attrs:{"src":require("@/assets/c8.png"),"alt":""}})]),_c('div',{staticClass:"name_cate"},[_c('span',[_vm._v("General Instruments")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-xl-2 p-0"},[_c('div',{staticClass:"box_category"},[_c('div',{staticClass:"image_cate"},[_c('img',{attrs:{"src":require("@/assets/c1.png"),"alt":""}})]),_c('div',{staticClass:"name_cate"},[_c('span',[_vm._v("General Instruments")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-xl-2 p-0"},[_c('div',{staticClass:"box_category"},[_c('div',{staticClass:"image_cate"},[_c('img',{attrs:{"src":require("@/assets/c2.png"),"alt":""}})]),_c('div',{staticClass:"name_cate"},[_c('span',[_vm._v("General Instruments")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-xl-2 p-0"},[_c('div',{staticClass:"box_category"},[_c('div',{staticClass:"image_cate"},[_c('img',{attrs:{"src":require("@/assets/c3.png"),"alt":""}})]),_c('div',{staticClass:"name_cate"},[_c('span',[_vm._v("General Instruments")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-xl-2 p-0"},[_c('div',{staticClass:"box_category"},[_c('div',{staticClass:"image_cate"},[_c('img',{attrs:{"src":require("@/assets/c4.png"),"alt":""}})]),_c('div',{staticClass:"name_cate"},[_c('span',[_vm._v("General Instruments")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-xl-2 p-0"},[_c('div',{staticClass:"box_category"},[_c('div',{staticClass:"image_cate"},[_c('img',{attrs:{"src":require("@/assets/c5.png"),"alt":""}})]),_c('div',{staticClass:"name_cate"},[_c('span',[_vm._v("General Instruments")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-xl-2 p-0"},[_c('div',{staticClass:"box_category"},[_c('div',{staticClass:"image_cate"},[_c('img',{attrs:{"src":require("@/assets/c2.png"),"alt":""}})]),_c('div',{staticClass:"name_cate"},[_c('span',[_vm._v("General Instruments")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-xl-2 p-0"},[_c('div',{staticClass:"box_category"},[_c('div',{staticClass:"image_cate"},[_c('img',{attrs:{"src":require("@/assets/c3.png"),"alt":""}})]),_c('div',{staticClass:"name_cate"},[_c('span',[_vm._v("General Instruments")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-xl-2 p-0"},[_c('div',{staticClass:"box_category"},[_c('div',{staticClass:"image_cate"},[_c('img',{attrs:{"src":require("@/assets/c4.png"),"alt":""}})]),_c('div',{staticClass:"name_cate"},[_c('span',[_vm._v("General Instruments")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-xl-2 p-0"},[_c('div',{staticClass:"box_category"},[_c('div',{staticClass:"image_cate"},[_c('img',{attrs:{"src":require("@/assets/c5.png"),"alt":""}})]),_c('div',{staticClass:"name_cate"},[_c('span',[_vm._v("General Instruments")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-xl-2 p-0"},[_c('div',{staticClass:"box_category"},[_c('div',{staticClass:"image_cate"},[_c('img',{attrs:{"src":require("@/assets/c6.png"),"alt":""}})]),_c('div',{staticClass:"name_cate"},[_c('span',[_vm._v("General Instruments")])])])])
}]

export { render, staticRenderFns }