<template>
   <!-- category Grid -->
   <div class="category_grid">
  <div class="sp">
      <span>All Categores</span>
    </div>
    <div class="row row_grid">
    <div class="col-xl-2 p-0">
      <van-popover v-model="showPopover[0]" trigger="click" :actions="actions" theme="dark">
       <template #reference>
      <div class="box_category">
        <div class="image_cate">
          <img src="~@/assets/c1.png" alt="">
        </div>
        <div class="name_cate">
          <span>DDL List Groups</span>
        </div>
      </div>
      </template>
    </van-popover>
    </div>
    <div class="col-xl-2 p-0">
      <van-popover v-model="showPopover[1]" trigger="click" :actions="actions" theme="dark">
       <template #reference>
      <div class="box_category">
        <div class="image_cate">
          <img src="~@/assets/c2.png" alt="">
        </div>
        <div class="name_cate">
          <span>DDL List Groups</span>
        </div>
      </div>
      </template>
    </van-popover>
    </div>
    <div class="col-xl-2 p-0">
        <div class="box_category">
        <div class="image_cate">
          <img src="~@/assets/c3.png" alt="">
        </div>
        <div class="name_cate">
          <span>General Instruments</span>
        </div>
      </div>
    </div>
    <div class="col-xl-2 p-0">
        <div class="box_category">
        <div class="image_cate">
          <img src="~@/assets/c4.png" alt="">
        </div>
        <div class="name_cate">
          <span>General Instruments</span>
        </div>
      </div>
    </div>
    <div class="col-xl-2 p-0">
        <div class="box_category">
        <div class="image_cate">
          <img src="~@/assets/c5.png" alt="">
        </div>
        <div class="name_cate">
          <span>General Instruments</span>
        </div>
      </div>
    </div>
    <div class="col-xl-2 p-0">
        <div class="box_category">
        <div class="image_cate">
          <img src="~@/assets/c6.png" alt="">
        </div>
        <div class="name_cate">
          <span>General Instruments</span>
        </div>
      </div>
    </div>
    <!--  -->
    <div class="col-xl-2 p-0">
        <div class="box_category">
        <div class="image_cate">
          <img src="~@/assets/c7.png" alt="">
        </div>
        <div class="name_cate">
          <span>General Instruments</span>
        </div>
      </div>
    </div>
     <div class="col-xl-2 p-0">
        <div class="box_category">
        <div class="image_cate">
          <img src="~@/assets/c8.png" alt="">
        </div>
        <div class="name_cate">
          <span>General Instruments</span>
        </div>
      </div>
    </div>
     <div class="col-xl-2 p-0">
      <div class="box_category">
        <div class="image_cate">
          <img src="~@/assets/c1.png" alt="">
        </div>
        <div class="name_cate">
          <span>General Instruments</span>
        </div>
      </div>
    </div>
    <div class="col-xl-2 p-0">
        <div class="box_category">
        <div class="image_cate">
          <img src="~@/assets/c2.png" alt="">
        </div>
        <div class="name_cate">
          <span>General Instruments</span>
        </div>
      </div>
    </div>
    <div class="col-xl-2 p-0">
        <div class="box_category">
        <div class="image_cate">
          <img src="~@/assets/c3.png" alt="">
        </div>
        <div class="name_cate">
          <span>General Instruments</span>
        </div>
      </div>
    </div>
    <div class="col-xl-2 p-0">
        <div class="box_category">
        <div class="image_cate">
          <img src="~@/assets/c4.png" alt="">
        </div>
        <div class="name_cate">
          <span>General Instruments</span>
        </div>
      </div>
    </div>
    <div class="col-xl-2 p-0">
        <div class="box_category">
        <div class="image_cate">
          <img src="~@/assets/c5.png" alt="">
        </div>
        <div class="name_cate">
          <span>General Instruments</span>
        </div>
      </div>
    </div>
     <div class="col-xl-2 p-0">
        <div class="box_category">
        <div class="image_cate">
          <img src="~@/assets/c2.png" alt="">
        </div>
        <div class="name_cate">
          <span>General Instruments</span>
        </div>
      </div>
    </div>
    <div class="col-xl-2 p-0">
        <div class="box_category">
        <div class="image_cate">
          <img src="~@/assets/c3.png" alt="">
        </div>
        <div class="name_cate">
          <span>General Instruments</span>
        </div>
      </div>
    </div>
    <div class="col-xl-2 p-0">
        <div class="box_category">
        <div class="image_cate">
          <img src="~@/assets/c4.png" alt="">
        </div>
        <div class="name_cate">
          <span>General Instruments</span>
        </div>
      </div>
    </div>
    <div class="col-xl-2 p-0">
        <div class="box_category">
        <div class="image_cate">
          <img src="~@/assets/c5.png" alt="">
        </div>
        <div class="name_cate">
          <span>General Instruments</span>
        </div>
      </div>
    </div>
    <div class="col-xl-2 p-0">
        <div class="box_category">
        <div class="image_cate">
          <img src="~@/assets/c6.png" alt="">
        </div>
        <div class="name_cate">
          <span>General Instruments</span>
        </div>
      </div>
    </div>
   </div>
   </div>
   <!-- end category Grid -->
</template>
<script>
export default {
  name: "categorySectionArea",
  data(){
    return{
      showPopover: [],
      actions: [
        { text: 'Option 1', icon: 'add-o' },
        { text: 'Option 2', icon: 'music-o' },
        { text: 'Option 3', icon: 'more-o' },
      ],
    }
  }
}
</script>
<style lang="scss" scoped>
// category box
.category_grid{
  margin-top: 80px;
  margin-bottom: 80px;
}
.row_grid{
  margin-top: 25px !important;
  border-top: 1px solid gainsboro;
  border-left: 1px solid gainsboro;
  border-right: 1px solid gainsboro;
}
.box_category{
      display: flex;
    align-items: center;
    background-color: white;
    font-size: 13px;
    font-weight: 500;
      padding: 4px;
    border-right: 1px solid gainsboro;
    border-bottom: 1px solid gainsboro;
    transition: 0.2s ease-in-out;
    cursor: pointer;
    &:hover{
      box-shadow: 0 0 1rem gainsboro;
      transform: scale(1.06);
    }
    img{
          height: 65px;
    object-fit: contain;
    width: 100%;
    transition: 0.3s ease-in-out;
    }
}
// end cagetory
</style>