<template>
<div class="HomeStore_wraps">
    <div class="top_cover_user">
      <div class="container-xl container-fluid position-relative">
        <div class="flex_cover">
          <div class="image_user">
            <img src="https://tukuz.com/wp-content/uploads/2021/01/icu-medical-logo-vector.png" alt="logo">
          </div>
          <div class="info_user">
            <div class="username">
              <span>AbuFarha Medical
                <vs-tooltip class="d-inline-block">
                    <i class='bx bxs-badge-check' ></i>
                <template #tooltip>
                   Account Verificatoins
                </template>
            </vs-tooltip>
               </span>
            </div>
            <div class="role_user">
              <span class="role_name">Mail Product: Cath lab, MRI, CT, Ultrasound, Stent, Catheters</span>
              <span class="dash-tags">|</span>
              <!-- <span class="rank_user"
                >Top Ranked with 11 years of experience</span
              > -->
              <div class="ranK_user">
                <vs-tooltip>
                    <img src="https://cdn-icons-png.flaticon.com/512/4387/4387887.png" alt="best Store">
                <template #tooltip>
                    1 Year Gloden Member
                </template>
            </vs-tooltip>
              </div>
            </div>
            <div class="role_user">
                <span class="role_name">Trade Company 8 Years</span>
              <span class="dash-tags">|</span>
              <div class="ranK_user2">
                <span><img src="~@/assets/flag/flag3.png"><span>Canada</span></span>
              </div>
            </div>
            <div class="rating_user">
              <div class="starts">
                <van-rate
                  allow-half
                  v-model="stars"
                  :size="24"
                  color="#ffd21e"
                  void-icon="star"
                  void-color="#fff"
                  readonly
                />
              </div>
              <span class="countrat">{{stars}}</span>
            </div>
          </div>
        </div>
        <div class="abs_buttons">
            <vs-button
                    color="#00bfff"
                    animation-type="scale">
                    <i class='bx bxs-message' ></i>
                    Contact Supplier
                </vs-button>
                <vs-button
                    color="#45c93a"
                    animation-type="scale">
                    <i class='bx bxs-conversation' ></i>
                    Chat Now
                </vs-button>
                <vs-button
                    color="#e63026"
                    animation-type="scale">
                    <i class='bx bxs-user-plus'></i>
                    Start Follow Now
                </vs-button>
           </div>
      </div>
    </div>
    <!-- navbar store -->
    <div class="navar_sax">
      <div class="center examplex">
      <vs-navbar center-collapsed v-model="active" :fixed="true">
        <vs-navbar-item to="/webstore/home" :active="active == 'Home'" id="Home">
          Home
        </vs-navbar-item>
        <vs-navbar-item to="/webstore/categories" :active="active == 'Categores'" id="Categores">
          Categores
        </vs-navbar-item>
        <!-- <vs-navbar-group>
         Categores
          <template #items>
            <vs-navbar-item to="/webstore/categories" :active="active == '1'" id="1">
              Categore 1
            </vs-navbar-item>
            <vs-navbar-item to="/webstore/categories" :active="active == '2'" id="2">
              Categore 2
            </vs-navbar-item>
            <vs-navbar-item to="/webstore/categories" :active="active == '3'" id="3">
              Categore 3
            </vs-navbar-item>
          </template>
        </vs-navbar-group> -->
        <vs-navbar-item to="/webstore/profile" :active="active == 'Profile'" id="Profile">
          Profile
        </vs-navbar-item>
        <vs-navbar-item to="/webstore/about" :active="active == 'About'" id="About">
          About Us
        </vs-navbar-item>
        <vs-navbar-item to="/webstore/contact" :active="active == 'Contact'" id="Contact">
          Contact Us
        </vs-navbar-item>

        <!-- <vs-navbar-group>
          Ecosystem

          <template #items>
            <h5 class="title">
              Social
            </h5>

            <vs-navbar-item :active="active == 'Github'" id="Github">
              Github
            </vs-navbar-item>
            <vs-navbar-item :active="active == 'Discord'" id="Discord">
              Discord
            </vs-navbar-item>
            <vs-navbar-item :active="active == 'Twitter'" id="Twitter">
              Twitter
            </vs-navbar-item>
            <vs-navbar-item :active="active == 'Medium'" id="Medium">
              Medium
            </vs-navbar-item>
          </template>
        </vs-navbar-group> -->

      </vs-navbar>
    </div>
    </div>
    <!--  -->
    <div class="container">
      <router-view></router-view>
    </div>
</div>
</template>
<script>
export default {
  name: "HomeStore",
  data() {
    return {
        stars:4.5,
        active: 'Home',
    };
  },
  components: {},
};
</script>
<style lang="scss" scoped>
.HomeStore_wraps{
    min-height: 100vh;
    padding-top: 180px;
  .top_cover_user {
    position: relative;
    padding-bottom: 20px;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-image: url("https://wallpapercave.com/wp/wp2506817.jpg");
      background-position: center center;
      background-attachment: fixed;
    }
    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(87deg, #000000, #000);
      opacity: 0.5;
    }
    .flex_cover {
      display: flex;
      justify-content: flex-start;
      justify-content: flex-start;
      padding: 60px 0px;
    }
    .image_user,
    .info_user {
      position: relative;
      z-index: 2;
    }
    .image_user {
      margin-right: 20px;
      img {
        width: 210px;
        height: 210px;
        border-radius: 20px;
        border: 8px solid white;
        object-fit: contain;
        background-color: white;
      }
    }
    .info_user {
      .username {
        i{
          color: #16a1f0;
          vertical-align: middle;
          margin-top: -8px;
          margin-right: 5px;
        }
        font-size: 28px;
        font-weight: 500;
        color: white;
        padding-bottom: 6px;
      }
      .role_user {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        .role_name {
          // &::after {
          //   content: "|";
          //   padding-left: 10px;
          // }
          padding-right: 10px;
        }
        .dash-tags{
          padding-right:10px;
        }
        span {
          font-size: 15px;
          font-weight: 400;
          color: white;
        }
      }
      .rating_user {
        display: flex;
        align-items: center;
        margin-top: 15px;
        .countrat {
          font-size: 15px;
          font-weight: 400;
          color: white;
          padding-left: 14px;
        }
      }
      .statistic_list {
        display: flex;
        margin-top: 20px;
        .single_stat {
          display: flex;
          flex-direction: column;
          justify-content: center;
          text-align: left;
          margin-right: 50px;
          &:last-child {
            margin-right: 0px;
          }
          .name {
            font-size: 18px;
            color: white;
            font-weight: 400;
          }
          .num {
            font-size: 20px;
            font-weight: 600;
            color: white;
            padding-bottom: 4px;
          }
        }
      }
    }
  }
  .ranK_user{
    display: flex;
    align-items: center;
    img{
      width: 30px;
      height: 30px;
      object-fit: contain;
      margin-right: 10px;
    }
  }
  .ranK_user2{
    display: flex;
    align-items: center;
    img{
      width: 22px;
      height: 22px;
      object-fit: contain;
      margin-right: 6px;
      vertical-align: middle;
      margin-top: -4px;
    }
  }
  .abs_buttons{
    position: absolute;
    right: 0px;
    top: 60px;
    button{
      margin-top: 15px;
      font-weight: 600;
      i{
        font-size: 15px;
        vertical-align: middle;
        margin-right: 10px;
      }
    }
  }
}
.latest_products{
  margin-top: 30px;
}
</style>
