var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"products_wraps"},[_vm._m(0),_c('div',{staticClass:"row row_prod"},[_vm._l((8),function(prod,index){return [_c('div',{key:prod.id,staticClass:"col-xl-2"},[_c('div',{staticClass:"box_products"},[_c('div',{staticClass:"fav_box",attrs:{"id":`fav${index}`},on:{"click":function($event){return _vm.add_fav(index)}}},[_c('vs-avatar',{attrs:{"badge-color":"danger","badge-position":"top-right"}},[_c('i',{staticClass:"bx bx-heart"})])],1),_vm._m(1,true),_vm._m(2,true),_c('div',{staticClass:"btn_contact"},[_c('vs-button',{attrs:{"gradient":""}},[_vm._v(" Contact supplier")])],1)])])]})],2),_vm._m(3),_c('div',{staticClass:"row row_prod"},[_vm._l((8),function(prod,index){return [_c('div',{key:prod.id,staticClass:"col-lg-2"},[_c('div',{staticClass:"box_products"},[_c('div',{staticClass:"fav_box",attrs:{"id":`fav${index+8}`},on:{"click":function($event){return _vm.add_fav(index+8)}}},[_c('vs-avatar',{attrs:{"badge-color":"danger","badge-position":"top-right"}},[_c('i',{staticClass:"bx bx-heart"})])],1),_vm._m(4,true),_vm._m(5,true),_c('div',{staticClass:"btn_contact"},[_c('vs-button',{attrs:{"gradient":""}},[_vm._v(" Contact supplier")])],1)])])]})],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sp"},[_c('span',[_vm._v("Cath lab/Angio")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"imagebox"},[_c('img',{attrs:{"src":"http://userscontent2.emaze.com/images/486f275c-cece-4f32-a497-f97fd4701eea/6173aa33-b388-4454-9906-7e9cdecea457.png","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"description"},[_c('h3',[_vm._v("Product Name device")]),_c('p',[_vm._v("Lorem Ipsum is simply dummy ")]),_c('p',{staticClass:"shipping"},[_vm._v("Shipping from: USA "),_c('img',{attrs:{"src":require("@/assets/flag1.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sp"},[_c('span',[_vm._v("Medical Consumables")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"imagebox"},[_c('img',{attrs:{"src":"https://www.3erp.com/wp-content/uploads/2016/12/medical.png","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"description"},[_c('h3',[_vm._v("Product Name device")]),_c('p',[_vm._v("Lorem Ipsum is simply dummy ")]),_c('p',{staticClass:"shipping"},[_vm._v("Shipping from: USA "),_c('img',{attrs:{"src":require("@/assets/flag1.png"),"alt":""}})])])
}]

export { render, staticRenderFns }