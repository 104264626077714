<template>
<div class="profile_store_wrap">
   <div class="row">
    <div class="col-lg-6">
        <div class="title_sec justify-content-center">
        <h2>Basic Information</h2>
        <!-- <router-link to="/">See All <i class='bx bx-chevron-right'></i></router-link> -->
      </div>
        <div class="data_keys">
        <div class="single_data">
            <div class="key1">
                Company Name
            </div>
            <div class="val1">
                Mohammad Medical Company
            </div>
        </div>
        <div class="single_data">
            <div class="key1">
                Business Type 
            </div>
            <div class="val1">
                Medical
            </div>
        </div>
        <div class="single_data">
            <div class="key1">
                Select / Enter Brand
            </div>
            <div class="val1">
                HP / Toshiba
            </div>
        </div>
        <div class="single_data">
            <div class="key1">
                Main Product Keywords
            </div>
            <div class="val1">
               text data / text data
            </div>
        </div>
        <div class="single_data">
            <div class="key1">
                Year Established *	
            </div>
            <div class="val1">
              1980
            </div>
        </div>
        <div class="single_data">
            <div class="key1">
                Employees Total *	
            </div>
            <div class="val1">
              90 
            </div>
        </div>
        <div class="single_data">
            <div class="key1">
                Annual Revenue *	
            </div>
            <div class="val1">
              120,000 $
            </div>
        </div>
        <div class="single_data">
            <div class="key1">
                Export Market
            </div>
            <div class="val1">
              150,000
            </div>
        </div>
        <div class="single_data">
            <div class="key1">
                Import Market
            </div>
            <div class="val1">
              40,000
            </div>
        </div>
        <div class="single_data">
            <div class="key1">
                Company Website URL	
            </div>
            <div class="val1">
            <a href="https://www.google.jo/" target="_blank">Company Website</a>
            </div>
        </div>
       
       
        
      
       
    </div>
    </div>
    <div class="col-lg-6">
        <div class="title_sec justify-content-center">
        <h2>Contact Information</h2>
        <!-- <router-link to="/">See All <i class='bx bx-chevron-right'></i></router-link> -->
      </div>
        <div class="data_keys">
        <div class="single_data">
            <div class="key1">
                Business Location
            </div>
            <div class="val1">
               Amman - Jordan
            </div>
        </div>
        <div class="single_data">
            <div class="key1">
                Phone Landline
            </div>
            <div class="val1">
               07875257XX
            </div>
        </div>
        <div class="single_data">
            <div class="key1">
                Fax
            </div>
            <div class="val1">
               8793240
            </div>
        </div>
        <div class="single_data">
            <div class="key1">
                Mobile 
            </div>
            <div class="val1">
              067328499
            </div>
        </div>
        <div class="single_data">
            <div class="key1">
                WhatsApp
            </div>
            <div class="val1">
              0777839240
            </div>
        </div>
        <div class="single_data">
            <div class="key1">
                Contact Persone Name
            </div>
            <div class="val1">
              Mohammad Abu Alsamen 
            </div>
        </div>
        <div class="single_data">
            <div class="key1">
                Contact Email
            </div>
            <div class="val1">
              moh.samen@gmail.com
            </div>
        </div>
        <div class="single_data">
            <div class="key1">
                Position Title
            </div>
            <div class="val1">
              CEO 
            </div>
        </div>
    </div>
    </div>
    <div class="col-12 branches">
        <div class="title_sec justify-content-center">
        <h2>Branches</h2>
      </div>
      <div class="row row_bran">
            <div class="col-xl-3 col-lg-4 col-md-6 col-12" v-for="branch in 8" :key="branch">
                <div class="single_branch">
                    <vs-card>
    <template #title>
      <h3>Amman Branch</h3>
    </template>
    <template #img>
      <img src="https://c4.wallpaperflare.com/wallpaper/517/901/70/cityscape-frankfurt-germany-sunset-wallpaper-preview.jpg" alt="">
    </template>
    <template #text>
     <div class="branch_txt">
      <p>
        Lorem ipsum dolor sit amet consectetur, adipisicing elit. amet consectetur, adipisicing elit.
      </p>
      <p class="contacts"><i class='bx bx-map'></i> <span>Amman - Jordan , Rania Queen St.</span></p>
      <p class="contacts"><i class='bx bx-phone-call'></i><span>0787525719</span></p>
     </div>
    </template>
  </vs-card>
                </div>
            </div>
            
        </div>
    </div>
   </div>

     <!-- certification -->
     <div class="latest_products">
      <div class="title_sec justify-content-center">
        <h2>Certification & Honors</h2>
        <!-- <router-link to="/">See All <i class='bx bx-chevron-right'></i></router-link> -->
      </div>
      <div class="prod_list_wraps">
        <div class="uk-position-relative uk-visible-toggle uk-light" tabindex="-1" uk-slider="autoplay: 3000">
        <ul class="uk-slider-items uk-child-width-1-2 uk-child-width-1-3@l uk-grid">
            <li v-for="prod in 8" :key="prod">
              <img src="https://marketplace.canva.com/EAFmXHnvaeQ/1/0/1600w/canva-black-and-gold-luxury-achievement-certificate-qsAlK5vJSf4.jpg" alt="">
            </li>
        </ul>
        <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slider-item="previous"></a>
        <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slider-item="next"></a>
      </div>
      </div>
    </div>
    <!-- end -->
    <!-- certification -->
</div>
</template>
<script>
export default {
  name: "prfileStore",
  data() {
    return {};
  },
  components: {},
};
</script>
<style lang="scss" scoped>
.profile_store_wrap{
    padding-top: 30px;
    .data_keys{
        background-color: white;
        padding: 10px 14px;
        border-radius: 4px;
        border: 1px solid rgba(220, 220, 220, 0.455);
    .single_data{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        font-size: 14px;
        border-bottom: 1px solid rgba(220, 220, 220, 0.532);
        padding: 8px 0px;
        &:last-child{
            border-bottom: none;
        }
        .key1{
            font-weight: 600;
            flex: 0 0 240px;
        }
        .val1{
            opacity: 0.5;
        }
    }
}
.title_sec{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--active);
    margin-bottom: 20px;
    padding: 12px 10px;
    border-radius: 8px;
    h2{
      font-size: 18px;
      font-weight: 600;
    }
    h2,a{
      color: white;
      i{
        vertical-align: middle;
        margin-top: -3px;
        font-size: 20px;
      }
    }
  }
  .branches{
    margin-top: 35px;
    .vs-card{
        max-width: 100%;
    }
    .row_bran{
        margin-top: 50px;
    }
    .single_branch{
        margin-bottom: 30px;
    }
    .branch_txt{
        i{
            font-size: 15px;
            vertical-align: middle;
            margin-right: 5px;
            color: var(--active);
        }
        .contacts{
            margin-top: 4px;
        }
    }
  }
}
</style>
