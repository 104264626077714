var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"comment",class:[{ shaddow: _vm.lastOne }, { corner: _vm.hasCorner }]},[_c('div',{staticClass:"comment__header",staticStyle:{"margin-bottom":"10px"}},[_c('div',{staticClass:"comment__author"},[_c('div',{staticClass:"comment__avatar"},[_c('img',{attrs:{"src":_vm.avatar,"alt":""}})]),_c('div',{staticClass:"comment__content"},[_c('h3',{staticClass:"comment__title"},[_vm._v(" "+_vm._s(_vm.author)+" ")]),_c('span',{staticClass:"date_comment"},[_vm._v(_vm._s(_vm.date))])])])]),_c('div',[_c('p',{staticClass:"comment__body"},[_vm._v(" "+_vm._s(_vm.comment)+" ")])])]),(_vm.replies.length)?_c('div',{staticClass:"comment__inner-commment",staticStyle:{"padding-left":"20px"}},[_vm._l((_vm.replies),function(item,index){return [_c('Comment',_vm._b({key:index},'Comment',{
          avatar: item.image,
          author: item.author,
          comment: item.comment,
          replies: item.replies,
          lastOne: index === _vm.replies.length - 1,
          hasCorner: _vm.replies.length >= 1,
        },false))]})],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }