var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"forumDetails_wraps"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"details_topic"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"add_new_comment"},[_c('div',{staticClass:"addArea_comment"},[_c('h2',[_vm._v("Add Comment")]),_c('div',{staticClass:"form"},[_c('b-field',{attrs:{"label":""}},[_c('vs-select',{attrs:{"placeholder":"Select"},model:{value:(_vm.usernameSelect),callback:function ($$v) {_vm.usernameSelect=$$v},expression:"usernameSelect"}},[_c('vs-option',{attrs:{"label":"Yazan Abu Farha","value":"1"}},[_vm._v(" Yazan Abu Farha ")]),_c('vs-option',{attrs:{"label":"Yazan Abu Farha 2","value":"2"}},[_vm._v(" Yazan Abu Farha 2 ")]),_c('vs-option',{attrs:{"label":"Yazan Abu Farha 3","value":"3"}},[_vm._v(" Yazan Abu Farha 3 ")])],1)],1),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.comment),expression:"comment"}],staticClass:"textAreaComment",attrs:{"name":"","id":"","placeholder":"Comment Now"},domProps:{"value":(_vm.comment)},on:{"input":function($event){if($event.target.composing)return;_vm.comment=$event.target.value}}}),_c('van-button',{attrs:{"color":"#1d9bf0"}},[_vm._v(" Send ")])],1)]),_c('div',{staticClass:"list_comment"},[_c('h2',{staticClass:"list_comm"},[_vm._v("Comments")]),_vm._l((_vm.comments),function(item,index){return _c('div',{key:index},[_c('Comment',_vm._b({},'Comment',{
                avatar: item.image,
                author: item.author,
                comment: item.comment,
                replies: item.replies,
                is_liked: item.is_liked,
                is_disliked: item.is_disliked,
                id: item.id,
                votes: item.votes,
                date: item.date,
                },false))],1)})],2)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"user"},[_c('div',{staticClass:"image"},[_c('img',{attrs:{"src":"https://i.pinimg.com/736x/ee/50/de/ee50dec983d90280a90a40afbe5be29d.jpg","alt":""}})]),_c('div',{staticClass:"text_user"},[_c('div',{staticClass:"username"},[_vm._v(" Mohammad Abu Alsamen ")]),_c('div',{staticClass:"title_topic"},[_vm._v(" How do I find the software and BT version of a Voluson Expert ")]),_c('div',{staticClass:"date"},[_vm._v(" August 18, 2010 10:34 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"description_topic"},[_c('p',[_vm._v("Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,")]),_c('p',[_vm._v("Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,")]),_c('p',[_vm._v("Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,")]),_c('p',[_vm._v("Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,")])])
}]

export { render, staticRenderFns }