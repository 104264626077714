var render = function render(){var _vm=this,_c=_vm._self._c;return _c('footer',[_c('div',{staticClass:"row pd_row"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_c('div',{staticClass:"col-lg-3"},[_c('div',{staticClass:"subscripe"},[_c('h2',[_vm._v("Subsicripe Now")]),_c('div',{staticClass:"row mtt_sub"},[_c('div',{staticClass:"col-lg-8"},[_c('vs-input',{attrs:{"placeholder":"Your Email"}})],1),_c('div',{staticClass:"col-lg-4"},[_c('vs-button',{attrs:{"gradient":""}},[_vm._v("Subscribe")])],1)]),_c('p',[_vm._v("We’ll never share your email address with a third-party.")])])]),_c('div',{staticClass:"col-lg-5"}),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7)]),_vm._m(8)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-3"},[_c('div',{staticClass:"logo_desc"},[_c('img',{attrs:{"src":"https://upload.wikimedia.org/wikipedia/commons/8/85/Logo-Test.png","alt":""}}),_c('p',[_vm._v("Medical.com is the world's leading andfastest growing online business-to-business(B2B) marketplace that connects small and medium businesses across the globe for international trade.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-2"},[_c('div',{staticClass:"box_list"},[_c('h2',[_vm._v("Premium Services")]),_c('div',{staticClass:"list_link"},[_c('a',{attrs:{"href":"#"}},[_vm._v("Membership Pricing")]),_c('a',{attrs:{"href":"#"}},[_vm._v("Trade Show")]),_c('a',{attrs:{"href":"#"}},[_vm._v("Companies")]),_c('a',{attrs:{"href":"#"}},[_vm._v("Tenders")]),_c('a',{attrs:{"href":"#"}},[_vm._v("Auction")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-2"},[_c('div',{staticClass:"box_list"},[_c('h2',[_vm._v("Featured Services")]),_c('div',{staticClass:"list_link"},[_c('a',{attrs:{"href":"#"}},[_vm._v("Seller Guide")]),_c('a',{attrs:{"href":"#"}},[_vm._v("Buyer Guide")]),_c('a',{attrs:{"href":"#"}},[_vm._v("Policies & rules")]),_c('a',{attrs:{"href":"#"}},[_vm._v("FAQ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-2"},[_c('div',{staticClass:"box_list"},[_c('h2',[_vm._v("Customer services")]),_c('div',{staticClass:"list_link"},[_c('a',{attrs:{"href":"#"}},[_vm._v("Help Center")]),_c('a',{attrs:{"href":"#"}},[_vm._v("RFQ")]),_c('a',{attrs:{"href":"#"}},[_vm._v("Trade Alert")]),_c('a',{attrs:{"href":"#"}},[_vm._v("Advertise with us")]),_c('a',{attrs:{"href":"#"}},[_vm._v("Forum")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-2"},[_c('div',{staticClass:"box_list"},[_c('h2',[_vm._v("Follow Us")]),_c('div',{staticClass:"list_link social_foot"},[_c('a',{attrs:{"href":"#"}},[_c('i',{staticClass:"bx bxl-facebook-circle"}),_vm._v("facebook")]),_c('a',{attrs:{"href":"#"}},[_c('i',{staticClass:"bx bxl-instagram"}),_vm._v("instagram")]),_c('a',{attrs:{"href":"#"}},[_c('i',{staticClass:"bx bxl-linkedin"}),_vm._v("linkedin")]),_c('a',{attrs:{"href":"#"}},[_c('i',{staticClass:"bx bxl-twitter"}),_vm._v("twitter")]),_c('a',{attrs:{"href":"#"}},[_c('i',{staticClass:"bx bxl-blogger"}),_vm._v("blogger")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-2"},[_c('div',{staticClass:"box_list"},[_c('h2',[_vm._v("Contact Us")]),_c('div',{staticClass:"list_link social_foot"},[_c('a',{attrs:{"href":"#"}},[_c('i',{staticClass:"bx bxl-whatsapp"}),_vm._v("whatsapp")]),_c('a',{attrs:{"href":"#"}},[_c('i',{staticClass:"bx bxl-telegram"}),_vm._v("telegram")]),_c('a',{attrs:{"href":"#"}},[_c('i',{staticClass:"bx bx-mail-send"}),_vm._v("e-Mail")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-3"},[_c('div',{staticClass:"box_list"},[_c('h2',[_vm._v("Download")]),_c('div',{staticClass:"flex_apps"},[_c('a',{staticClass:"google",attrs:{"href":"#"}},[_c('img',{attrs:{"src":"https://hf-files-oregon.s3.amazonaws.com/hdpconcord_kb_attachments/2020/09-18/9501d8c2-babe-4f54-8df8-92692dfbc657/google-play-badge.png","alt":""}})]),_c('a',{staticClass:"apple",attrs:{"href":"#"}},[_c('img',{attrs:{"src":"https://www.freepngimg.com/download/iphone/68600-play-google-apple-app-iphone-store.png","alt":""}})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12"},[_c('div',{staticClass:"flex_link_foot"},[_c('a',{attrs:{"href":"#"}},[_vm._v("Home")]),_c('a',{attrs:{"href":"#"}},[_vm._v("Join us for free")]),_c('a',{attrs:{"href":"#"}},[_vm._v("Statistics")]),_c('a',{attrs:{"href":"#"}},[_vm._v("Category")]),_c('a',{attrs:{"href":"#"}},[_vm._v("Blog")]),_c('a',{attrs:{"href":"#"}},[_vm._v("Career")]),_c('a',{attrs:{"href":"#"}},[_vm._v("Suppliers by Region")]),_c('a',{attrs:{"href":"#"}},[_vm._v("Sitemap")]),_c('a',{attrs:{"href":"#"}},[_vm._v("Logistics services")]),_c('a',{attrs:{"href":"#"}},[_vm._v("Parts")]),_c('a',{attrs:{"href":"#"}},[_vm._v("Services")]),_c('a',{attrs:{"href":"#"}},[_vm._v("About Us")])]),_c('div',{staticClass:"lang_foot"},[_c('span',[_vm._v("Languages:")]),_c('a',{attrs:{"href":"#"}},[_vm._v("English")]),_c('a',{attrs:{"href":"#"}},[_vm._v("العربية")]),_c('a',{attrs:{"href":"#"}},[_vm._v("Español")]),_c('a',{attrs:{"href":"#"}},[_vm._v("한국어")]),_c('a',{attrs:{"href":"#"}},[_vm._v("Pусский")]),_c('a',{attrs:{"href":"#"}},[_vm._v("中文")]),_c('a',{attrs:{"href":"#"}},[_vm._v("日本語")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"copy"},[_c('p',[_vm._v("Copyright © 2023 med.com")])])])])
}]

export { render, staticRenderFns }