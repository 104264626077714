<template>
   <!-- product seprator -->
   <div class="products_wraps">
    <div class="sp">
      <span>Cath lab/Angio</span>
    </div>
    <div class="row row_prod">
      <template v-for="(prod,index) in 8" >
       <div class="col-xl-2" :key="prod.id">
        <div class="box_products">
           <div class="fav_box" :id="`fav${index}`" @click="add_fav(index)">
            <vs-avatar  badge-color="danger" badge-position="top-right">
              <i class='bx bx-heart' ></i>
            </vs-avatar>
           </div>
          <div class="imagebox">
            <img src="http://userscontent2.emaze.com/images/486f275c-cece-4f32-a497-f97fd4701eea/6173aa33-b388-4454-9906-7e9cdecea457.png" alt="">
          </div>
          <div class="description">
            <h3>Product Name device</h3>
            <p>Lorem Ipsum is simply dummy  </p>
            <p class="shipping">Shipping from: USA <img src="~@/assets/flag1.png" alt=""> </p>
          </div>
         <div class="btn_contact">
            <vs-button gradient> Contact supplier</vs-button>
         </div>
        </div>
      </div>
      </template>
    </div>

    <!-- category 2 -->
    <div class="sp">
      <span>Medical Consumables</span>
    </div>
    <div class="row row_prod">
      <template v-for="(prod,index) in 8" >
       <div class="col-lg-2" :key="prod.id">
        <div class="box_products">
           <div class="fav_box" :id="`fav${index+8}`" @click="add_fav(index+8)">
            <vs-avatar  badge-color="danger" badge-position="top-right">
              <i class='bx bx-heart' ></i>
            </vs-avatar>
           </div>
          <div class="imagebox">
            <img src="https://www.3erp.com/wp-content/uploads/2016/12/medical.png" alt="">
          </div>
          <div class="description">
            <h3>Product Name device</h3>
             <p>Lorem Ipsum is simply dummy  </p>
            <p class="shipping">Shipping from: USA <img src="~@/assets/flag1.png" alt=""> </p>
          </div>
         <div class="btn_contact">
            <vs-button gradient> Contact supplier</vs-button>
         </div>
        </div>
      </div>
      </template>
    </div>
   </div>
</template>
<script>
export default {
  name: "productArea",
}
</script>
<style lang="scss" scoped>
// products 
.sp{
  font-size: 1.3rem;
    font-weight: 500;
    position: relative;
    color: #000;
    span{
    position: relative;
    z-index: 2;
    display: inline-block;
    background-color: #f6f8fa;
    padding-right: 20px;

    }
  &::after{
    content: '';
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1.5px;
    background-color: #e6e6e6;
}
}
.products_wraps{
  padding-top: 40px;
.row_prod{
  padding-top: 40px;
}
.box_products{
  border: 1px solid gainsboro;
  background-color: white;
  border-radius: 10px;
  position: relative;
  margin-bottom: 40px;
  transition: filter 300ms;
  &:hover{
    filter: drop-shadow(0 0 1em #00bfff);
    .imagebox{
    background-color: #00bfff;
    border-radius: 10px;
    img{
      transform: scale(1.1) translateY(-25px);
    }
  }
  }
  .imagebox{
    background-color: rgb(240, 240, 240);
    border-radius: 10px;
     transition: 0.3s linear;
    img{
      height: 120px;
      width: 100%;
      object-fit: contain;
      transition: 0.3s linear;
    }
  }
  .description{
    padding: 14px 8px;
    h3{
      font-size: 0.9rem;
      font-weight: 500;
    }
    p{
      font-size: 13px;
      opacity: 0.8;
    }
    p.shipping{
      opacity: 1;
      color: #000;
      img{
        height: 18px;
           vertical-align: top;
           padding-left: 10px;
      }
    }
  }
  .fav_box{
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 2;
    cursor: pointer;
  }
  .bxs-heart{
    color: red;
  }
  .btn_contact{
  padding: 10px 20px;
  }
}
}
</style>