<template>
<div class="side_wrapss">
  <div class="cat__menu-wrapper">
    <div class="cat-toggle">
      <button type="button" class="cat-toggle-btn">
        <i class="fas fa-bars"></i> Shop by Category
      </button>
      <div class="cat__menu">
        <nav id="mobile-menu">
          <ul>
            <li>
              <a href="product.html"
                >EQUIPMENT <i class='bx bx-chevron-right'></i></a>
              <ul class="mega-menu">
                <li>
                  <a href="product.html">Title</a>
                  <ul class="mega-item">
                    <li>
                      <a href="product-details.html">Title</a>
                    </li>
                    <li>
                      <a href="product-details.html">Title</a>
                    </li>
                    <li>
                      <a href="product-details.html">Title</a>
                    </li>
                    <li><a href="product-details.html">Title</a></li>
                    <li><a href="product-details.html">Title</a></li>
                  </ul>
                </li>
                <li>
                  <a href="product.html">Product Pages</a>
                  <ul class="mega-item">
                    <li><a href="product-details.html">Product Details</a></li>
                    <li><a href="product-details.html">Product V2</a></li>
                    <li><a href="product-details.html">Product V3</a></li>
                    <li>
                      <a href="product-details.html">Title</a>
                    </li>
                    <li><a href="product-details.html">Title</a></li>
                  </ul>
                </li>
                <li>
                  <a href="product.html">Other Pages</a>
                  <ul class="mega-item">
                    <li><a href="product-details.html">Title</a></li>
                    <li><a href="product-details.html">Title</a></li>
                    <li><a href="product-details.html">Title</a></li>
                    <li><a href="product-details.html">Title</a></li>
                    <li><a href="product-details.html">Title</a></li>
                  </ul>
                </li>
                <li>
                  <a href="product.html">Title</a>
                  <ul class="mega-item">
                    <li><a href="product-details.html">Catagory 1</a></li>
                    <li><a href="product-details.html">Catagory 2</a></li>
                    <li><a href="product-details.html">Catagory 3</a></li>
                    <li><a href="product-details.html">Catagory 4</a></li>
                  </ul>
                </li>
                <li>
                  <a href="product.html">Title</a>
                  <ul class="mega-item">
                    <li><a href="product-details.html">Catagory 1</a></li>
                    <li><a href="product-details.html">Catagory 2</a></li>
                    <li><a href="product-details.html">Catagory 3</a></li>
                    <li><a href="product-details.html">Catagory 4</a></li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              <a href="product.html"
                >Best Seller Products
                <!-- <span class="cat-label">hot!</span> -->
              </a>
            </li>
            <li>
              <a href="product.html"
                >Top 10 Offers
                <!-- <span class="cat-label green">new!</span> -->
              </a>
            </li>
            <li>
              <a href="product.html"
                >New Arrivals <i class='bx bx-chevron-right'></i></a>
              <ul class="submenu">
                <li><a href="product.html">Home Title</a></li>
                <li>
                  <a href="product.html">Title</a>
                  <ul class="submenu">
                    <li><a href="product.html">Title</a></li>
                    <li><a href="product.html">Title</a></li>
                    <li><a href="product.html">Title</a></li>
                  </ul>
                </li>
                <li><a href="product.html">Office Equipments</a></li>
              </ul>
            </li>
            <li><a href="product.html">Title</a></li>
            <li><a href="product.html">Title</a></li>
            <li class="d-laptop-none">
              <a href="product.html">Title</a>
            </li>
            <li><a href="product.html">Title</a></li>
            <li><a href="product.html">Title</a></li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</div>
</template>
<script>
export default {
  data: () => ({
    active: "home",
  }),
};
</script>

<style scoped lang="scss">

/* cat */
.cat-toggle-btn {
  background: #ffffff;
  color: #222;
   border:none;
  border-bottom: 1px solid #ebebeb;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
  height: 61px;
  line-height: 61px;
  width: 100%;
  text-align: left;
  padding-left: 20px;
  -webkit-border-radius: 3px 3px 0 0;
  -moz-border-radius: 3px 3px 0 0;
  border-radius: 3px 3px 0 0;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .cat-toggle-btn {
    padding-left: 10px;
  }
}
.cat-toggle-btn i {
  margin-right: 10px;
}
.cat-toggle-btn-2 {
  color: #ffffff;
  background: #d01418;
  border: none;
}

.cat-toggle {
  -webkit-border-radius: 3px 3px 0 0;
  -moz-border-radius: 3px 3px 0 0;
  border-radius: 3px 3px 0 0;
  position: relative;
}
.cat-toggle-4 {
  border: none;
  background: #d01418;
  color: #ffffff;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

.cat-toggle-2 {
  border: 2px solid #fcb700;
  -webkit-border-radius: 5px 5px 0 0;
  -moz-border-radius: 5px 5px 0 0;
  border-radius: 5px 5px 0 0;
}

.cat__menu ul {
  background: #ffffff;
  -webkit-border-radius: 0 0 5px 3px;
  -moz-border-radius: 0 0 5px 3px;
  border-radius: 0 0 5px 3px;
}
.cat__menu ul li {
  position: relative;
}
.cat__menu ul li:last-child a {
  border: none;
}
.cat__menu ul li a {
  position: relative;
  display: inline-block;
  padding: 10px 15px;
  text-transform: capitalize;
  background: #ffffff;
  color: #222;
  border-bottom: 1px solid #ebebeb;
  font-weight: 500;
  font-size: 14px;
  text-align: left;
  width: 100%;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .cat__menu ul li a {
    padding: 9px 15px;
  }
}
.cat__menu ul li a .cat-label {
  font-size: 9px;
  color: #ffffff;
  padding: 4px;
  position: relative;
  background: #eb2771;
  top: -5px;
  right: -2px;
  text-transform: uppercase;
}
.cat__menu ul li a .cat-label.green {
  background: #0cc485;
}
.cat__menu ul li a .cat-label.green::after {
  border-right-color: #0cc485;
}
.cat__menu ul li a .cat-label::after {
  position: absolute;
  content: "";
  left: -6px;
  top: 3px;
  bottom: auto;
  border: 3px solid transparent;
  border-top-color: transparent;
  border-right-color: #eb2771;
}
.cat__menu ul li a i {
  position: absolute;
  top: 34%;
  right: 20px;
  font-size: 16px !important;
  // -webkit-transform: rotate(-90deg) translateY(-50%);
  // -moz-transform: rotate(-90deg) translateY(-50%);
  // -ms-transform: rotate(-90deg) translateY(-50%);
  // transform: rotate(-90deg) translateY(-50%);
}
.cat__menu ul li > ul.submenu {
  position: absolute;
  top: 20%;
  left: 100%;
  min-width: 200px;
  background: #ffffff;
  -webkit-box-shadow: 0px 15px 30px 0px rgba(0, 3, 143, 0.1);
  -moz-box-shadow: 0px 15px 30px 0px rgba(0, 3, 143, 0.1);
  box-shadow: 0px 15px 30px 0px rgba(0, 3, 143, 0.1);
  border: 1px solid #b0c6c64d;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  z-index: 11;
}
.cat__menu ul li > ul.submenu li {
  display: block;
  margin-right: 0;
}
.cat__menu ul li > ul.submenu li a {
  padding: 9px 25px;
  font-size: 13px;
  text-transform: capitalize;
  border-bottom: 1px solid #b0c6c64d;
}
.cat__menu ul li > ul.submenu li:last-child a {
  border-bottom: none;
}
.cat__menu ul li > ul.submenu li ul.submenu {
  left: 120%;
  top: 0%;
  visibility: hidden;
  opacity: 0;
}
.cat__menu ul li > ul.submenu li:hover ul.submenu {
  visibility: visible;
  opacity: 1;
  left: 100%;
}
.cat__menu ul li:hover > a {
  color: #fcb700;
}
.cat__menu ul li:hover .mega-menu {
  visibility: visible;
  opacity: 1;
  top: 0;
}
.cat__menu ul li:hover .submenu {
  top: 0;
  visibility: visible;
  opacity: 1;
}
.cat__menu ul li .mega-menu {
  position: absolute;
  left: 100%;
  top: 0;
  width: 800px;
  background: #ffffff;
  z-index: 99;
  padding: 40px 50px;
  padding-bottom: 10px;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  -webkit-box-shadow: 4.316px 4.168px 20px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 4.316px 4.168px 20px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 4.316px 4.168px 20px 0px rgba(0, 0, 0, 0.1);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cat__menu ul li .mega-menu {
    width: 620px;
    left: 110%;
  }
}
.cat__menu ul li .mega-menu li {
  width: 33.33%;
  float: left;
  margin-bottom: 25px;
}
.cat__menu ul li .mega-menu li > a {
  font-size: 16px;
  color: #222;
  margin-bottom: 15px;
  padding: 0;
  border: none;
  padding: 0;
  position: relative;
}
.cat__menu ul li .mega-menu li ul {
  padding: 0;
}
.cat__menu ul li .mega-menu li ul li {
  display: block;
  width: 100%;
  margin-bottom: 8px;
}
.cat__menu ul li .mega-menu li ul li a {
  font-size: 14px;
  color: #666;
  margin-bottom: 0;
  position: relative;
  display: inline-block;
  width: auto;
  font-weight: 400;
}
.cat__menu ul li .mega-menu li ul li a::after {
  position: absolute;
  content: "";
  left: auto;
  right: 0;
  bottom: 0;
  background: #666;
  width: 0;
  height: 1px;
}
.cat__menu ul li .mega-menu li ul li:hover > a {
  color: #222;
}
.cat__menu ul li .mega-menu li ul li:hover > a::after {
  width: 100%;
  left: 0;
  right: auto;
}
.cat__menu ul li .mega-menu li:hover > a {
  color: #222;
}
.cat__menu-4 ul {
  position: absolute;
  top: 100%;
  z-index: 999;
  background: #fff;
  width: 100%;
  left: 0px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  border: 2px solid #ebebeb;
}
.cat__menu-4 ul li:hover > a {
  color: #d01418;
}
.cat__menu-4 ul li ul.submenu li:hover > a {
  color: #d01418;
}
.cat__menu-4 ul .mega-menu {
  position: relative;
}
.cat__menu-4 ul .mega-menu li:hover > a {
  color: #d01418;
}
.cat__menu-4 ul .mega-menu li ul.mega-item {
  position: relative;
  border: 0;
}
.cat__menu-4 ul .mega-menu li ul.mega-item li:hover > a {
  color: #d01418;
}

.cat__menu .mega-menu li ul.mega-item li a {
  font-size: 14px;
  color: #888;
  margin-bottom: 0;
}

</style>
