<template>
    <div class="dashoard_user_info add_adds_wraps">
      <div class="container">
        <h3 class="tabname">Add New News</h3>
     <div class="personal_wraps">
      <!-- input form  -->
        <div class="form_wraps">
          <div class="row">
               <div class="col-md-6 mrgb">
                   <b-field label="Subject">
                   <vs-input placeholder="News Subject ..." />
                  </b-field>
              </div>
              <div class="col-md-6 mrgb">
                   <b-field label="Company Name">
                   <vs-input placeholder="Medx Company ..." />
                  </b-field>
              </div>
              <div class="col-md-6 mrgb">
                   <b-field label="Published Name">
                   <vs-input placeholder="Yazan Abu Farha ..." />
                  </b-field>
              </div>
              <div class="col-md-6 mrgb">
                   <b-field label="Post in">
                   <vs-input placeholder="" />
                  </b-field>
              </div>
              <div class="col-12 tags_w">
                <b-field label="Tages">
                    <vue-tags-input
                        v-model="tag"
                        :tags="tags"
                        :is-draggable="true"
                        @tags-changed="newTags => tags = newTags"
                        @tag-order-changed="newTags => tags = newTags"
                      />
                  </b-field>
                  <span class="sp_enter d-block">Click Enter To Add Tags</span>
              </div>
              <div class="col-md-12 mrgb2">
                   <b-field label="Description">
                       <vue-editor id="editor"
                          :useCustomImageHandler="true"
                          @image-added="handleImageAdded" v-model="htmlForEditor" >
                          </vue-editor>
                  </b-field>
              </div>
              <div class="col-12 mrgb">
                  <h2 class="tabname">Banner Photos</h2>
              </div>
              <div class="col-12 mrgb">
                <van-uploader v-model="fileList" multiple :max-count="max_cont" preview-size="150"	 />
              </div>
                <!-- end upload divs -->
                <div class="col-12 mrgb">
                  <h2 class="tabname">Thump Photos</h2>
              </div>
              <div class="col-12 mrgb">
                <van-uploader v-model="fileList" multiple :max-count="max_cont" preview-size="150"	 />
              </div>
                  <div class="col-md-4 col-12 mr-auto ml-auto mrgt">
                    <vs-button gradient>Publish News</vs-button>
              </div>
          </div>
        </div>
      
     </div>
      </div>
    </div>
  </template>
  <script>
  import { mapActions, mapGetters } from "vuex";
    import { VueEditor } from 'vue2-editor';
    import VueTagsInput from '@johmun/vue-tags-input';
  //  import axios from 'axios'
  
  export default {
    name: "userprofile",
    data() {
      return {
          n_name:1,
          im:1,
          picked:1,
          hasVisiblePassword: false,
          password:'password',
           htmlForEditor: "",
           dropFiles: null,
           dropFiles_arr:[],
          // 
          value:1,
          value2:1,
          value3:1,
          value4:1,
          budget:[1000,25000],
          mySpesification:[],
          fileList: [{ url: 'https://img01.yzcdn.cn/vant/leaf.jpg' }, { url: 'https://img01.yzcdn.cn/vant/leaf.jpg' }],
           option1: '',
           max_cont:4,
           tags:[],
           tag:'',
      };
    },
    components: {
      VueEditor,
      VueTagsInput
    },
    computed: {
      ...mapGetters({}),
    },
    methods: {
      ...mapActions({}),
       handleImageAdded: function(file, Editor, cursorLocation) {
          // An example of using FormData
          // NOTE: Your key could be different such as:
          // formData.append('file', file)
          console.log(file);
          Editor.insertEmbed(cursorLocation, 'image', 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSTB-pULN4tWIgabMt9ooDqzE6mD-tQ3VfkzbHDRqPhTrR3lwS4T1tkU7DMz35OBHl3NVE&usqp=CAU');
          // var formData = new FormData();
          // formData.append('image', file)
   
          // axios({
          //   url: 'https://fakeapi.yoursite.com/images',
          //   method: 'POST',
          //   data: formData
          // })
          // .then((result) => {
          //   let url = result.data.url // Get url from response
          //   Editor.insertEmbed(cursorLocation, 'image', url);
            
          // })
          // .catch((err) => {
          //   console.log(err);
          // })
        },
         deleteDropFile() {
        this.dropFiles=null;
      },
       deleteDropFileMulti(index) {
                  this.dropFiles.splice(index, 1)
              },
              onchangeBudget(){
        },
        onchangePeriod(){
        },
        onChange(value) {
          console.log(value)
      // Toast('current value：' + value);
    },
    addSkills() {
      this.mySpesification.push({key:'',value:''});
    },
    removeSkills(index) {
      this.mySpesification.splice(index, 1);
    },
    },
    mounted() {},
  };
  </script>
  <style lang="scss" scoped>
  .dashoard_user_info{
      padding-top: 200px;
      .mrgb{
          margin-bottom: 20px;
          position: relative;
      }
      .mrgb2{
          margin-bottom: 20px;
          position: relative;
      }
      .mrgt{
          margin-top: 25px;
      }
      .or_span{
              margin-bottom: 6px;
      display: inline-block;
      padding-right: 15px;
      font-size: 15px;
      font-weight: 500;
      }
      .center_flex{
              display: flex;
      height: 100%;
      justify-content: flex-start;
      align-items: flex-end;
      .last_check{
          margin-left: 15px;
      }
      }
      .change_span{
         font-size: 13px;
      color: var(--active);
      cursor: pointer;
      position: absolute;
      right: 20px;
      bottom: -14px;
      font-weight: 400;
      text-transform: capitalize;
      }
      .tabname{
        font-size: 16px;
    margin: 0;
    padding: 0;
    background-color: gainsboro;
    display: inline-block;
    padding: 6px 20px;
    border-radius: 5px;
    font-weight: 600;
    color: black;
    min-width: 200px;
    text-align: center;
    margin-top: 20px;
      }
      .personal_wraps{
      padding-top: 20px;
      .type_user{
      display: flex;
      align-items: center;
      .key_span{
          display: inline-block;
      margin-right: 20px;
      font-size: 16px;
      font-weight: 500;
      }
      .radio_wrap{
          display: flex;
          align-items: center;
          .vs-radio-content{
              margin-right: 10px;
          }
      }
      }
      .form_wraps{
          padding-top: 0px;
          // max-width: 700px;
      }
      }
  }
  .slider_price{
         .flex_bud{
           display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    right: 0;
    top: 0;
    width: 200px;
           span{
             font-style: normal;
             font-weight: 400;
             font-size: 14px;
             line-height: 21px;
             color: #A1A6AD;
           }
         }
       }
       .flex-row{
  padding: 10px 0px;
  position: relative;
  .vuesax-app-is-ltr .vs-select--input{
    height: 48px !important;
  }
}
.con-vs-slider{
  min-width: auto;
}
.deleteicon{
  width: 35px;
  height: 35px;
  background-color: red;
  color: white;
  border-radius: 100%;
  position: absolute;
  right: -10px;
  top: -10px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s ease-in-out;
  &:hover{
    transform: scale(1.1);
  }
}
.newSkillsBtn{
  height: 52px;
  i{
    vertical-align: middle;
    margin-top: -3px;
  }
}
.newFetureBtn{
    display: block;
    text-align: center;
    background-color: #45c93a;
    color: white;
    padding: 6px 34px;
    border-radius: 5px;
    margin-left: 4px;
    transition: 0.3s ease-in-out;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    // &:hover{
    //   transform: scale(1.1);
    // }
  i{
    vertical-align: middle;
    margin-top: -1px;
  }
}
.sp_enter{
    font-size: 13px;
    margin-top: -9px;
    margin-bottom: 15px;
 }  
.vue-tags-input{
    max-width: 100%;
}

  </style>
  