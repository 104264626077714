<template>
   <div class="homeNewsArea_wraps details_news_wraps">
    <!-- main-area -->
    <main>

<!-- breadcrumb-area -->
<div class="breadcrumb-area">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="breadcrumb-content">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="#">Home</a></li>
                            <li class="breadcrumb-item"><a href="#">Medical</a></li>
                            <li class="breadcrumb-item active" aria-current="page">News Title</li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- breadcrumb-area-end -->

<!-- blog-details-area -->
<section class="blog-details-area pt-80 pb-100">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-1">
                <div class="blog-details-social">
                    <ul class="list-wrap">
                        <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                        <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                        <li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
                        <li><a href="#"><i class="fab fa-behance"></i></a></li> 
                         <li><a href="#"><i class="fas fa-share"></i></a></li>
                    </ul>
                </div>
            </div>
            <div class="col-xl-8 col-lg-7">
                <div class="blog-details-wrap">
                    <ul class="tgbanner__content-meta list-wrap">
                        <li class="category"><a href="categories.html">Medical</a></li>
                        <li><span class="by">By</span> <a href="categories.html">Yazan</a></li>
                        <li>Nov 22, 2022</li>
                    </ul>
                    <h2 class="title">Lorem Ipsum is simply dummy text of the printing </h2>
                    <div class="blog-details-thumb">
                        <img src="https://images.unsplash.com/photo-1530210124550-912dc1381cb8?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2940&q=80" alt="">
                    </div>
                    <div class="blog-details-content">
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled</p>
                       <p>make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing</p>
                       <p>Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                      <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.</p>
                        <div class="blog-details-inner">
                            <h3 class="inner-title">Title 2</h3>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled
                       make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing</p>
                       <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled
                       make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing</p>
                            <div class="blog-details-images">
                                <div class="row">
                                    <div class="col-md-4 col-sm-6">
                                        <div class="details-inner-image">
                                            <img src="https://images.unsplash.com/photo-1576091160550-2173dba999ef?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2940&q=80" alt="img">
                                        </div>
                                    </div>
                                    <div class="col-md-4 col-sm-6">
                                        <div class="details-inner-image">
                                            <img src="https://images.unsplash.com/photo-1512069511692-b82d787265cf?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2863&q=80" alt="img">
                                        </div>
                                    </div>
                                    <div class="col-md-4 col-sm-6">
                                        <div class="details-inner-image">
                                            <img src="https://images.unsplash.com/photo-1526256262350-7da7584cf5eb?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2940&q=80" alt="img">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled
                       make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing</p>
                        </div>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled
                       make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing</p>
                    </div>
                    <div class="blog-details-bottom">
                        <div class="row align-items-baseline">
                            <div class="col-xl-6 col-md-7">
                                <div class="blog-details-tags">
                                    <ul class="list-wrap mb-0">
                                        <li><a href="#">Medical</a></li>
                                        <li><a href="#">Medical 2</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-xl-6 col-md-5">
                                <div class="blog-details-share">
                                    <h6 class="share-title">Share Now :</h6>
                                    <ul class="list-wrap mb-0">
                                        <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                                        <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                                        <li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
                                        <li><a href="#"><i class="fab fa-behance"></i></a></li>
                                        <li><a href="#"><i class="fab fa-youtube"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="blog-prev-next-posts">
                        <div class="row">
                            <div class="col-xl-6 col-lg-8 col-md-6">
                                <div class="pn-post-item">
                                    <div class="thumb">
                                        <a href="blog-details.html"><img src="https://plus.unsplash.com/premium_photo-1668487827037-7b88850dea9c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2912&q=80" alt="img"></a>
                                    </div>
                                    <div class="content">
                                        <span>Prev News</span>
                                        <h5 class="title tgcommon__hover"><a href="blog-details.html">Lorem Ipsum is simply dummy text of the printing ...</a></h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-8 col-md-6">
                                <div class="pn-post-item next-post">
                                    <div class="thumb">
                                        <a href="blog-details.html"><img src="https://images.unsplash.com/photo-1577368211130-4bbd0181ddf0?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2920&q=80" alt="img"></a>
                                    </div>
                                    <div class="content">
                                        <span>Next News </span>
                                        <h5 class="title tgcommon__hover"><a href="blog-details.html">Lorem Ipsum is simply dummy text of the printing ...</a></h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-lg-4 col-md-6">
                <aside class="blog-sidebar">
                    <div class="widget sidebar-widget widget_categories">
                        <h4 class="widget-title">Best categories</h4>
                        <ul class="list-wrap">
                            <li>
                                <div class="thumb"><a href="categories.html"><img src="~@/assets/newImage/cat1.jpeg" alt="img"></a></div>
                                <a href="categories.html">Category 1</a>
                                <span class="float-right">12</span>
                            </li>
                            <li>
                                <div class="thumb"><a href="categories.html"><img src="~@/assets/newImage/cat2.jpeg" alt="img"></a></div>
                                <a href="categories.html">Category 2</a>
                                <span class="float-right">08</span>
                            </li>
                            <li>
                                <div class="thumb"><a href="categories.html"><img src="~@/assets/newImage/cat3.jpeg" alt="img"></a></div>
                                <a href="categories.html">Category 3</a>
                                <span class="float-right">13</span>
                            </li>
                            <li>
                                <div class="thumb"><a href="categories.html"><img src="~@/assets/newImage/cat4.jpeg" alt="img"></a></div>
                                <a href="categories.html">Category 4</a>
                                <span class="float-right">09</span>
                            </li>
                            <li>
                                <div class="thumb"><a href="categories.html"><img src="~@/assets/newImage/cat5.jpeg" alt="img"></a></div>
                                <a href="categories.html">Category 5</a>
                                <span class="float-right">15</span>
                            </li>
                            <li>
                                <div class="thumb"><a href="categories.html"><img src="https://images.unsplash.com/photo-1651186857332-5dbda6b94dda?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2940&q=80" alt="img"></a></div>
                                <a href="categories.html">Category 6</a>
                                <span class="float-right">11</span>
                            </li>
                            <li>
                                <div class="thumb"><a href="categories.html"><img src="https://images.unsplash.com/photo-1615486511484-92e172cc4fe0?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2942&q=80" alt="img"></a></div>
                                <a href="categories.html">Category 7</a>
                                <span class="float-right">02</span>
                            </li>
                            <li>
                                <div class="thumb"><a href="categories.html"><img src="https://images.unsplash.com/photo-1504439468489-c8920d796a29?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2942&q=80" alt="img"></a></div>
                                <a href="categories.html">Category 8</a>
                                <span class="float-right">03</span>
                            </li>
                            <li>
                                <div class="thumb"><a href="categories.html"><img src="https://images.unsplash.com/photo-1582719298866-977ee81c87d7?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2940&q=80" alt="img"></a></div>
                                <a href="categories.html">Category 9</a>
                                <span class="float-right">06</span>
                            </li>
                            <li>
                                <div class="thumb"><a href="categories.html"><img src="https://images.unsplash.com/photo-1579165466991-467135ad3110?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2940&q=80" alt="img"></a></div>
                                <a href="categories.html">Category 10</a>
                                <span class="float-right">08</span>
                            </li>
                        </ul>
                    </div>
                </aside>
            </div>
        </div>
    </div>
</section>
<!-- blog-details-area-end -->

</main>
<!-- main-area-end -->
   </div>
</template>
<script>
import '../../scss/newsStyle.scss';
export default {
  name: "newsDetails",
  data() {
    return {};
  },
  components: {},
};
</script>
<style lang="scss" scoped>
.details_news_wraps{
    min-height: 100vh;
    padding-top: 50px;
}
</style>
