<template>
    <div class="homeNewsArea_wraps">
      <homeNewsArea></homeNewsArea>
    </div>
  </template>
  
  <script>
  import homeNewsArea from "@/components/news/homeNewsArea.vue";
  export default {
    name: "addAds",
    data() {
      return {};
    },
    components: {
        homeNewsArea,
    },
    methods: {},
    mounted() {
      document.body.classList.add("homeNewsArea");
    },
    destroyed() {
      document.body.classList.remove("homeNewsArea");
    },
  };
  </script>
  <style scoped>
</style>